import { StyledCard } from "@/components/StyledCard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import {
  ACTIVE,
  APPLICATION_AWAITING_DOCUMENTS,
  APPLICATION_DENIED,
  APPLICATION_PENDING,
  BANK_ACCOUNT_PENDING,
  REAUTHENTICATION_NEEDED,
} from "@/features/Funding/fundingConstants"
import { FundingRegistrationButton } from "@/features/Funding/FundingRegistrationButton"
import { ApplicationFormResponse } from "@/features/Funding/fundingTypes"
import LaunchIcon from "@mui/icons-material/Launch"
import { Alert, Button, Grid, Skeleton, Typography } from "@mui/material"
import { get, noop } from "lodash"
import { useEffect, useState } from "react"
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link"
import {
  useCreateBankAccount,
  useGetApplicationForm,
  useGetBankAccount,
  useReinitiateApplicationForm,
} from "../../settingsService"
import { PlaidOpenFunction } from "../../types/paymentTypes"

// FUTURE SEG-2402: Needs further investigation in local storage audit
const storedTokenData = localStorage.getItem("plaid_oauth_linkTokenData") ?? ""

export interface FundingAlertProps {
  severity: "error" | "warning"
  message: string
  dataQa: string
}

const FundingAlert = ({ severity, message, dataQa }: FundingAlertProps) => (
  <Alert severity={severity} sx={{ my: "1rem" }} data-qa={dataQa}>
    <Typography variant="body1">{message}</Typography>
  </Alert>
)

const AutoPayInformation = () => (
  <Grid item xs={12}>
    <Typography variant="body1" data-qa="autopay-details-description">
      With our streamlined process, you're only a few steps from completing your AutoPay setup with Take Command. Below,
      you'll find the required information necessary to successfully open your new bank account. Make sure to gather
      this information prior to starting the application to prevent any delays.
    </Typography>
    <ul>
      <li>Legal entity name</li>
      <li>Taxpayer identification number</li>
      <li>Physical address</li>
      <li>
        Information for entity's owner or a principal officer (Note: This includes DOB, SSN, and contact information)
      </li>
    </ul>
    <Typography variant="body1" data-qa="autopay-details-required-information" />
  </Grid>
)

interface BankAccountInfoProps {
  unitApplicationData?: ApplicationFormResponse
  openPlaid: PlaidOpenFunction
}

const BankAccountInfo = ({ unitApplicationData, openPlaid }: BankAccountInfoProps) => {
  const buttonText =
    unitApplicationData?.fundingStatus === REAUTHENTICATION_NEEDED ? "Reconnect bank account" : "Update bank account"

  return (
    <Grid container direction="column" spacing={4} alignItems="left" mt={1}>
      <Grid item xs={12}>
        <Typography variant="h5" data-qa="payment-account-label">
          Payment account
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" data-qa="payment-account-description">
          {unitApplicationData?.bankName}
          <br />
          Account #: ••••{unitApplicationData?.last4Digits}
          <br />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" data-qa="payment-account-button" onClick={() => openPlaid()}>
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  )
}

interface ConnectBankAccountProps {
  isApplicationPending?: boolean
  isApplicationAwaitingDocuments?: boolean
  openPlaid: PlaidOpenFunction
  isTokenExpired: boolean
}

const ConnectBankAccount = ({
  isApplicationPending,
  isApplicationAwaitingDocuments,
  openPlaid,
  isTokenExpired,
}: ConnectBankAccountProps) => (
  <Grid container direction="column" spacing={4} alignItems="left" mt={5}>
    <Grid item xs={12}>
      <Typography variant="h5" data-qa="payment-account-label">
        Connect your bank account
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body1" data-qa="payment-account-description">
        Please link your bank account to activate the AutoPay option.
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        data-qa="connect-account-button"
        onClick={() => openPlaid()}
        disabled={isApplicationPending || isApplicationAwaitingDocuments}
        endIcon={<LaunchIcon />}
      >
        {isTokenExpired ? "Reconnect Account" : "Connect bank account"}
      </Button>
    </Grid>
  </Grid>
)

const BankAccountInfoSkeletonLoader = () => (
  <Grid container direction="column" mt={5} sx={{ width: 210 }}>
    <Skeleton />
    <Skeleton width="60%" />
    <Skeleton variant="rounded" sx={{ marginTop: 4, height: "2.5rem", width: "11.25rem" }} />
  </Grid>
)

interface CompanyAutopayProps {
  oAuthStateFullHref?: string
}

export const MAX_APPLICATION_RETRY_COUNT = 4

export const CompanyAutopay = ({ oAuthStateFullHref }: CompanyAutopayProps) => {
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""

  const { data: bankAccountData } = useGetBankAccount(companyId)
  const { data: unitApplicationData } = useGetApplicationForm(companyId)
  const { mutateAsync: postPublicToken } = useCreateBankAccount(companyId)

  const [isTokenExpired, setIsTokenExpired] = useState(false)

  const isOauthRedirect = !!oAuthStateFullHref
  const oAuthReceivedRedirectUri = oAuthStateFullHref ?? undefined
  const reinitiateApplicationFormMutation = useReinitiateApplicationForm(companyId)
  const isApplicationPending = unitApplicationData?.fundingStatus === APPLICATION_PENDING
  const isApplicationPendingReview = unitApplicationData?.fundingStatus === APPLICATION_PENDING
  const isApplicationAwaitingDocuments = unitApplicationData?.fundingStatus === APPLICATION_AWAITING_DOCUMENTS
  const isBankAccountPending = unitApplicationData?.fundingStatus === BANK_ACCOUNT_PENDING
  const isBankAccountActive = unitApplicationData?.fundingStatus === ACTIVE
  const isApplicationDenied = unitApplicationData?.fundingStatus === APPLICATION_DENIED
  const isReauthenticating = unitApplicationData?.fundingStatus === REAUTHENTICATION_NEEDED

  const handleOnSuccess = async (publicToken: string, metadata: any) => {
    if (metadata) {
      const vendorFundingSourceId =
        get(metadata, "account_id") ?? get(metadata, "account.id") ?? metadata.accounts[0].id

      try {
        await postPublicToken({
          publicToken: get(window, "injectPublicToken") ?? publicToken,
          vendorFundingSourceId,
        })
        setIsTokenExpired(false)
      } catch (error) {
        setIsTokenExpired(true)
        console.error("Error posting public token:", error)
      }
    }
  }

  const plaidConfig: PlaidLinkOptions = {
    onSuccess: (token, meta) => {
      handleOnSuccess(token, meta)
    },
    onEvent: noop,
    receivedRedirectUri: isOauthRedirect ? oAuthReceivedRedirectUri : undefined,
    token: isOauthRedirect ? storedTokenData : (bankAccountData?.linkToken ?? ""),
  }

  const { open: openPlaid, ready: isPlaidReady } = usePlaidLink(plaidConfig)

  useEffect(() => {
    if (isOauthRedirect && isPlaidReady) {
      openPlaid()
    }
  }, [isOauthRedirect, isPlaidReady, openPlaid])

  return (
    <StyledCard>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container direction="column" spacing={4} alignItems="left">
            <Grid item xs={12}>
              {isApplicationDenied &&
                unitApplicationData &&
                (unitApplicationData.reapplyCount < MAX_APPLICATION_RETRY_COUNT ? (
                  <Alert severity="error" data-qa="autopay-application-denied-alert" sx={{ my: "1rem" }}>
                    <Typography variant="body1">
                      Your AutoPay application has been <b>denied</b> by our banking partner. If you feel you've
                      received this message in error, or to correct a mistake, you may try again by clicking{" "}
                      <b>Reapply</b>.
                    </Typography>
                  </Alert>
                ) : (
                  <FundingAlert
                    severity="error"
                    message="You have reached the maximum number of application attempts. Please contact support for
                        assistance."
                    dataQa="autopay-application-max-attempts-alert"
                  />
                ))}
              {isReauthenticating && (
                <FundingAlert
                  severity="error"
                  message="Your bank account is no longer connected. Click reconnect your bank account and follow the prompts."
                  dataQa="vendor-application-reauthenticating-alert"
                />
              )}
              {isTokenExpired && (
                <FundingAlert
                  severity="error"
                  message="Your access to Plaid has been lost, click Reconnect Account to continue."
                  dataQa="token-expired-alert"
                />
              )}
              {isApplicationPendingReview && (
                <FundingAlert
                  severity="warning"
                  message="Your AutoPay account application is under review."
                  dataQa="vendor-application-pending-review-alert"
                />
              )}
              {isApplicationAwaitingDocuments && (
                <FundingAlert
                  severity="warning"
                  message="Upload required documents to complete your application."
                  dataQa="vendor-application-documents-alert"
                />
              )}
              <Typography variant="h5" data-qa="autopay-details-label">
                AutoPay
              </Typography>
            </Grid>
            <AutoPayInformation />
            <Grid item xs={12} data-qa="funding-registration-button">
              {!isBankAccountActive && (isApplicationPending || isApplicationAwaitingDocuments) && (
                <FundingRegistrationButton companyId={companyId} onApplicationError={noop} />
              )}
              {isApplicationDenied && (
                <Button
                  variant="contained"
                  color="primary"
                  data-qa="reapply-button"
                  disabled={unitApplicationData?.reapplyCount >= MAX_APPLICATION_RETRY_COUNT}
                  onClick={async () => {
                    const response = await reinitiateApplicationFormMutation.mutateAsync()

                    window.open(response.applicationUrl)
                  }}
                  endIcon={<LaunchIcon />}
                >
                  Reapply
                </Button>
              )}
            </Grid>
          </Grid>
          {isPlaidReady && (isBankAccountPending || isTokenExpired) && (
            <ConnectBankAccount
              openPlaid={openPlaid}
              isApplicationPending={isApplicationPending}
              isApplicationAwaitingDocuments={isApplicationAwaitingDocuments}
              isTokenExpired={isTokenExpired}
            />
          )}
          {isPlaidReady && !isTokenExpired && (isBankAccountActive || isReauthenticating) ? (
            <BankAccountInfo unitApplicationData={unitApplicationData} openPlaid={openPlaid} />
          ) : (
            <BankAccountInfoSkeletonLoader />
          )}
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Typography variant="caption" data-qa="autopay-fine-print">
              Take Command Health is a financial technology company and is not a bank. Banking services are provided by
              TransPecos Banks, a Member of the FDIC. FDIC insurance is available for funds on deposit up to $250,000
              through TransPecos Banks, a Member of the FDIC. Accounts are eligible for pass-through deposit insurance
              only to the extent pass-through insurance is permitted by the rules and regulations of the FDIC, and if
              the requirements for pass-through insurance are satisfied. There may be a risk that pass-through deposit
              insurance is not available because conditions have not been satisfied. In such cases, funds may not be
              fully insured in the event the insured depository institution where the funds have been deposited were to
              fail.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  )
}
