import { createDataQa } from "@/utils/dataQa"
import { Search as SearchIcon } from "@mui/icons-material"
import { Autocomplete, CircularProgress, Grid, InputAdornment, SxProps, TextField, Typography } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { debounce, isString, noop } from "lodash"
import { ReactNode, useMemo, useState } from "react"
import { DeletableChip } from "./Chips"

type BaseKey = string | number

export interface SearchFieldProps<Option> {
  name: Lowercase<string>
  handleSelection?: (option: Option) => void
  handleSearch: (searchTerm: string) => Promise<Option[]>
  formatOption: (option: Option) => string
  handleBlur?: (e: any) => void
  handleChange?: (value: Option | null) => void
  title?: string
  dropdown?: boolean
  placeholder?: string
  helperText?: string
  minimumInputLength?: number
  error?: string
  clearValueOnSelection?: boolean
  value?: Option | null
  noResultsText?: ReactNode
}

export const SearchField = <Option,>({
  name,
  handleSelection,
  handleSearch,
  formatOption,
  handleBlur,
  handleChange,
  title = "",
  dropdown = false,
  placeholder = "Search",
  helperText = "",
  error = "",
  minimumInputLength = 1,
  clearValueOnSelection = true,
  value: currentValue,
  noResultsText = "No results",
}: SearchFieldProps<Option>) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [inputValue, setInputValue] = useState("")
  const searchTermHasMinimumLength = searchTerm?.length >= minimumInputLength
  const dataQa = createDataQa(name, "search")
  const inputDataQa = createDataQa(dataQa, "field")

  const {
    data: options,
    isLoading: loading,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: [`searchField-${name}`, searchTerm],
    queryFn: () => handleSearch(searchTerm),
    enabled: searchTermHasMinimumLength,
  })

  const finishedFetching = isSuccess || isError

  const handleInputChange = useMemo(() => debounce(newValue => setSearchTerm(newValue), 300), [])

  return (
    <>
      {title && (
        <Typography variant="h6" sx={{ pb: ".85rem" }}>
          {title}
        </Typography>
      )}
      <Autocomplete
        forcePopupIcon={dropdown}
        handleHomeEndKeys
        blurOnSelect
        clearOnEscape
        {...(currentValue && { value: currentValue })}
        clearOnBlur
        noOptionsText={searchTermHasMinimumLength && finishedFetching ? noResultsText : "Type to search"}
        onInputChange={(_, value, reason) => {
          setInputValue(value)
          if (reason === "input") {
            handleInputChange(value)
          } else if (reason === "reset" || reason === "clear") {
            setSearchTerm("")
          }
        }}
        {...(clearValueOnSelection && { value: null, inputValue })}
        filterOptions={x => x}
        loading={loading}
        data-qa={dataQa}
        options={options ?? []}
        onChange={(_, value) => {
          handleChange?.(value)
          if (value && !isString(value)) {
            handleSelection?.(value)
          }
        }}
        onBlur={handleBlur}
        getOptionLabel={option => (isString(option) ? option : formatOption(option))}
        renderInput={({ InputProps, ...params }) => (
          <TextField
            {...params}
            name={name + "-field"}
            placeholder={placeholder}
            helperText={helperText}
            data-qa={inputDataQa}
            error={!!error}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start" sx={{ pl: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: loading ? (
                <CircularProgress color="inherit" size={20} sx={{ mx: 3 }} />
              ) : (
                InputProps.endAdornment
              ),
            }}
          />
        )}
      />
    </>
  )
}

export interface SearchFieldWithChipsProps<Option, Key extends BaseKey> extends SearchFieldProps<Option> {
  selections: Option[]
  formatSelection: (option: Option) => { key: Key; label: string }
  handleDelete?: (key: Key) => void
  sx?: SxProps
}

export const SearchFieldWithChips = <Option, Key extends BaseKey = BaseKey>({
  selections,
  formatSelection,
  handleDelete = noop,
  sx,
  ...props
}: SearchFieldWithChipsProps<Option, Key>) => {
  const marginTop = selections?.length > 0 ? 2 : 0

  return (
    <>
      <SearchField {...props} />
      <Grid container sx={{ gap: 2, mt: marginTop }}>
        {selections.map(selection => {
          const { key, label } = formatSelection(selection)

          return (
            <DeletableChip
              key={key}
              label={label}
              onDelete={() => handleDelete(key)}
              data-qa={createDataQa(props.name, "selection-chip")}
              sx={sx}
            />
          )
        })}
      </Grid>
    </>
  )
}
