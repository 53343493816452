import { LoadingContentArea } from "@/components/LoadingContentArea"
import { SelectField } from "@/components/SelectField"
import { StyledCard } from "@/components/StyledCard"
import { MICRO_DEPOSIT_STATUS_OPTIONS, REGISTRATION_STATUS_OPTIONS } from "@/features/Funding/fundingConstants"
import { useNotifications } from "@/services/notificationService"
import { toTitleCase } from "@/utils/formatting"
import { SaveOutlined } from "@mui/icons-material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import EditOutlined from "@mui/icons-material/EditOutlined"
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { Formik, FormikProps, useFormikContext } from "formik"
import { useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useParams } from "react-router-dom"
import { editRegistrationTrackingValidationSchema, EditRegistrationTrackingValues } from "../../editCompanyValidations"
import {
  useAutoPayCompanies,
  useRegistrationTrackingDetails,
  useUpdateRegistrationTrackingDetails,
} from "../../tcHubService"
import { RegistrationTrackingDetailModel } from "../../tcHubTypes"
import { CompanySet } from "../Accounts/TcHubAccountsPage"

interface PaymentRegistrationInformationProps {
  registrationTrackingDetails: RegistrationTrackingDetailModel
  companySet: Map<string, string>
  isCompaniesLoading: boolean
  isEditing: boolean
}

const getInitialValues = (
  registrationTrackingDetails: RegistrationTrackingDetailModel
): EditRegistrationTrackingValues => ({
  registrationStatus: registrationTrackingDetails.registrationStatus ?? "",
  vendorApplicationFormId: registrationTrackingDetails.vendorApplicationFormId ?? "",
  vendorApplicationId: registrationTrackingDetails.vendorApplicationId ?? "",
  vendorCustomerId: registrationTrackingDetails.vendorCustomerId ?? "",
  microDepositStatus: registrationTrackingDetails.microDepositStatus ?? "",
  microDepositAchReturnCode: registrationTrackingDetails.microDepositAchReturnCode ?? "",
  microDepositReturnDescription: registrationTrackingDetails.microDepositReturnDescription ?? "",
  reapplyCount: registrationTrackingDetails.reapplyCount ?? 0,
})

const PaymentRegistrationInformation = ({
  registrationTrackingDetails,
  companySet,
  isCompaniesLoading,
  isEditing,
}: PaymentRegistrationInformationProps) => {
  const formikProps: FormikProps<EditRegistrationTrackingValues> = useFormikContext()
  return (
    <StyledCard>
      <Grid container spacing={6} data-qa="account-details-business-information">
        <Grid item xs={12} mb={3}>
          <Typography variant="h5" gutterBottom>
            Change payment registration tracking
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="companyName"
            label="Company Name"
            value={registrationTrackingDetails?.companyId ? companySet.get(registrationTrackingDetails.companyId) : ""}
            disabled
            InputProps={{
              endAdornment: isCompaniesLoading && <CircularProgress size={20} />,
            }}
            // shrink the label if there is a value in the field
            InputLabelProps={{ shrink: Boolean(registrationTrackingDetails?.companyId) }}
            data-qa="registration-tracking-detail-company-name"
            onChange={formikProps.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="registrationStatus"
            label="Registration Status"
            data={REGISTRATION_STATUS_OPTIONS}
            value={formikProps.values.registrationStatus}
            disabled={!isEditing}
            required={isEditing}
            data-qa="registration-tracking-detail-registration-status"
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="vendorApplicationFormId"
            label="Vendor Application Form ID"
            value={toTitleCase(formikProps.values.vendorApplicationFormId)}
            disabled={!isEditing}
            required={isEditing}
            data-qa="registration-tracking-detail-vendor-application-form-id"
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            error={Boolean(formikProps.touched.vendorApplicationFormId && !!formikProps.errors.vendorApplicationFormId)}
            helperText={formikProps.touched.vendorApplicationFormId && formikProps.errors.vendorApplicationFormId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="vendorApplicationId"
            label="Vendor Application ID"
            value={formikProps.values.vendorApplicationId}
            disabled={!isEditing}
            data-qa="registration-tracking-detail-vendor-application-id"
            onChange={formikProps.handleChange}
            required={isEditing}
            onBlur={formikProps.handleBlur}
            error={Boolean(formikProps.touched.vendorApplicationId && !!formikProps.errors.vendorApplicationId)}
            helperText={formikProps.touched.vendorApplicationId && formikProps.errors.vendorApplicationId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="vendorCustomerId"
            label="Vendor Customer ID"
            value={formikProps.values.vendorCustomerId}
            disabled={!isEditing}
            data-qa="registration-tracking-detail-vendor-customer-id"
            onChange={formikProps.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="microDepositStatus"
            label="Micro Deposit Status"
            data={MICRO_DEPOSIT_STATUS_OPTIONS}
            value={formikProps.values.microDepositStatus}
            disabled={!isEditing}
            data-qa="registration-tracking-detail-micro-deposit-status"
            onChange={formikProps.handleChange}
            required={isEditing}
            onBlur={formikProps.handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="microDepositAchReturnCode"
            label="Micro Deposit ACH Return Code"
            value={formikProps.values.microDepositAchReturnCode}
            disabled={!isEditing}
            data-qa="registration-tracking-detail-micro-deposit-ach-return-code"
            onChange={formikProps.handleChange}
            required={isEditing}
            onBlur={formikProps.handleBlur}
            error={Boolean(
              formikProps.touched.microDepositAchReturnCode && !!formikProps.errors.microDepositAchReturnCode
            )}
            helperText={formikProps.touched.microDepositAchReturnCode && formikProps.errors.microDepositAchReturnCode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="microDepositReturnDescription"
            label="Micro Deposit Return Description"
            value={formikProps.values.microDepositReturnDescription}
            disabled={!isEditing}
            data-qa="registration-tracking-detail-micro-deposit-return-description"
            onChange={formikProps.handleChange}
            required={isEditing}
            onBlur={formikProps.handleBlur}
            error={Boolean(
              formikProps.touched.microDepositReturnDescription && !!formikProps.errors.microDepositReturnDescription
            )}
            helperText={
              formikProps.touched.microDepositReturnDescription && formikProps.errors.microDepositReturnDescription
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="reapplyCount"
            label="Reapply Count"
            value={formikProps.values.reapplyCount}
            disabled={!isEditing}
            data-qa="registration-tracking-detail-reapply-count"
            onChange={formikProps.handleChange}
            required={isEditing}
            onBlur={formikProps.handleBlur}
            error={Boolean(formikProps.touched.reapplyCount && !!formikProps.errors.reapplyCount)}
            helperText={formikProps.touched.reapplyCount && formikProps.errors.reapplyCount}
          />
        </Grid>
      </Grid>
    </StyledCard>
  )
}

type CompanySetType = ReturnType<typeof CompanySet>

interface RegistrationDataProps {
  companySet: CompanySetType
  details: RegistrationTrackingDetailModel | undefined
  isPageLoading: boolean
  isCompaniesLoading: boolean
  isEditing: boolean
}
const RegistrationData = ({
  companySet,
  details,
  isPageLoading,
  isCompaniesLoading,
  isEditing,
}: RegistrationDataProps) => {
  if (isPageLoading) return <LoadingContentArea data-qa="loading-registration-tracking-details" />

  if (details)
    return (
      <PaymentRegistrationInformation
        registrationTrackingDetails={details}
        companySet={companySet}
        isCompaniesLoading={isCompaniesLoading}
        isEditing={isEditing}
      />
    )

  return <Typography variant="body1">No registration tracking details found.</Typography>
}

interface Params extends Record<string, string | undefined> {
  registrationTrackingId: string
}

export const TcHubOnboardingRegistrationTrackingDetailPage = () => {
  const { registrationTrackingId } = useParams<Params>()
  const [isEditing, setIsEditing] = useState(false)
  const { notify } = useNotifications("registration-tracking-update-success")

  const { data: registrationTrackingDetails, isLoading: isRegistrationTrackingDetailsLoading } =
    useRegistrationTrackingDetails(registrationTrackingId ?? "")

  const updateRegistrationTracking = useUpdateRegistrationTrackingDetails()

  const { data: companies, isLoading: isCompaniesLoading } = useAutoPayCompanies()
  const companySet = useMemo(() => CompanySet(companies ?? []), [companies])
  const isPageLoading = isRegistrationTrackingDetailsLoading || !registrationTrackingDetails

  if (!registrationTrackingId) {
    return <Typography variant="body1">No registration tracking id</Typography>
  }

  return (
    <Grid container data-qa="tc-hub-registration-tracking-details" spacing={6}>
      <Grid item>
        <Helmet title="TC Hub Account Detail" />
        <Button
          color="primary"
          component={Link}
          to="/admin/autopay/registration-trackings"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        {!isPageLoading && registrationTrackingDetails && (
          <Formik
            initialValues={getInitialValues(registrationTrackingDetails) ?? {}}
            validationSchema={editRegistrationTrackingValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              try {
                const data = {
                  ...values,
                  id: registrationTrackingId,
                  companyId: registrationTrackingDetails?.companyId,
                  microDepositTimestamp: registrationTrackingDetails?.microDepositTimestamp,
                } as RegistrationTrackingDetailModel
                await updateRegistrationTracking.mutateAsync({ registrationTrackingId, data })
                notify("Registration Tracking Updated", "success")
                setIsEditing(false)
              } catch (error) {
                console.error(error)
                notify("Failed to update Registration Tracking", "error")
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {formProps => (
              <form noValidate onSubmit={formProps.handleSubmit}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography variant="h1" gutterBottom display="inline" data-qa="registration-tracking-details-title">
                    Registration Tracking Detail
                  </Typography>
                  {!isEditing ? (
                    <Grid item>
                      <Button
                        data-qa="edit-registration-tracking-button"
                        variant="contained"
                        onClick={() => setIsEditing(true)}
                        startIcon={<EditOutlined />}
                      >
                        Edit
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item container xs={12} sm="auto" spacing={2} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          data-qa="cancel-edit-button"
                          variant="outlined"
                          onClick={() => {
                            formProps.resetForm()
                            formProps.setValues(getInitialValues(registrationTrackingDetails) ?? {})
                            setIsEditing(false)
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          data-qa="update-registration-tracking-button"
                          disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}
                          startIcon={<SaveOutlined />}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <RegistrationData
                  companySet={companySet}
                  details={registrationTrackingDetails}
                  isPageLoading={isPageLoading}
                  isCompaniesLoading={isCompaniesLoading}
                  isEditing={isEditing}
                />
              </form>
            )}
          </Formik>
        )}
      </Grid>
    </Grid>
  )
}
