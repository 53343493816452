import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"
import { StatementCreationDate, UnsafeDateValue } from "./planSetupTypes"
interface HraPlanSetupStore {
  hraStartDateStore: UnsafeDateValue
  statementCreationDateStore: StatementCreationDate
  eligibleEmployeesStore: number | string
  participatingEmployeesStore: number | string
  isAutoPayStore: boolean
  dayOfMonthStore: number
  willOfferHraStore: boolean
  packageNameStore: string
  getHraStartDateStore: () => UnsafeDateValue
  getStatementCreationDateStore: () => StatementCreationDate
  getEligibleEmployeesStore: () => number | string
  getParticipatingEmployeesStore: () => number | string
  getIsAutoPayStore: () => boolean
  getDayOfMonthStore: () => number
  getWillOfferHraStore: () => boolean
  getPackageNameStore: () => string
  getHraPlanSetup: () => {
    hraStartDateStore: UnsafeDateValue
    statementCreationDateStore: StatementCreationDate
    eligibleEmployeesStore: number | string
    participatingEmployeesStore: number | string
    isAutoPayStore: boolean
    dayOfMonthStore: number
    willOfferHraStore: boolean
    packageNameStore: string
  }
  setHraStartDateStore: (value: any) => void
  setStatementCreationDateStore: (value: any) => void
  setEligibleEmployeesStore: (value: any) => void
  setParticipatingEmployeesStore: (value: any) => void
  setIsAutoPayStore: (value: any) => void
  setDayOfMonthStore: (value: any) => void
  setWillOfferHraStore: (value: any) => void
  setPackageNameStore: (value: any) => void
  clearStore: () => void
}

const INITIAL_STATE = {
  hraStartDateStore: "",
  statementCreationDateStore: "",
  eligibleEmployeesStore: "",
  participatingEmployeesStore: "",
  isAutoPayStore: false,
  dayOfMonthStore: 0,
  willOfferHraStore: false,
  packageNameStore: "Starter",
}
export const useHraPlanSetupStore = create<HraPlanSetupStore>()(
  persist(
    (set, get) => ({
      ...INITIAL_STATE,

      getHraStartDateStore: () => get().hraStartDateStore,
      getStatementCreationDateStore: () => get().statementCreationDateStore,
      getEligibleEmployeesStore: () => get().eligibleEmployeesStore,
      getParticipatingEmployeesStore: () => get().participatingEmployeesStore,
      getIsAutoPayStore: () => get().isAutoPayStore,
      getDayOfMonthStore: () => get().dayOfMonthStore,
      getWillOfferHraStore: () => get().willOfferHraStore,
      getPackageNameStore: () => get().packageNameStore,
      getHraPlanSetup: () => ({
        hraStartDateStore: get().hraStartDateStore,
        statementCreationDateStore: get().statementCreationDateStore,
        eligibleEmployeesStore: get().eligibleEmployeesStore,
        participatingEmployeesStore: get().participatingEmployeesStore,
        isAutoPayStore: get().isAutoPayStore,
        dayOfMonthStore: get().dayOfMonthStore,
        willOfferHraStore: get().willOfferHraStore,
        packageNameStore: get().packageNameStore,
      }),

      setHraStartDateStore: value => set({ hraStartDateStore: value }),
      setStatementCreationDateStore: value => set({ statementCreationDateStore: value }),
      setEligibleEmployeesStore: value => set({ eligibleEmployeesStore: value }),
      setParticipatingEmployeesStore: value => set({ participatingEmployeesStore: value }),
      setIsAutoPayStore: value => set({ isAutoPayStore: value }),
      setDayOfMonthStore: value => set({ dayOfMonthStore: value }),
      setWillOfferHraStore: value => set({ willOfferHraStore: value }),
      setPackageNameStore: value => set({ packageNameStore: value }),
      clearStore: () => set(INITIAL_STATE),
    }),
    {
      name: "hraPlanSetupStore",
      storage: createJSONStorage(() => localStorage),
      version: 1,
    }
  )
)
