import { LoadingMasonryArea } from "@/components/LoadingContentArea"
import { InfoCard, InfoCardItem, makeInfoItems, StyledCard } from "@/components/StyledCard"
import { ObscuredField } from "@/components/TextFields"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { useAuth, useCompanyId } from "@/features/Auth/useAuth"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { DashboardLayout } from "@/features/Dashboard/components/DashboardLayout"
import { FundingStatusChip } from "@/features/Funding/components/FundingStatusChip"
import { APPLICATION_PENDING, CLOSED } from "@/features/Funding/fundingConstants"
import { useGetPaymentAccountInformation } from "@/features/Funding/fundingService"
import { FundingStatus } from "@/features/Funding/fundingTypes"
import { useCancelTerminatedDate, useGetPersonById } from "@/features/People/peopleService"
import { useNotifications } from "@/services/notificationService"
import { getDisplayPhoneNumber } from "@/utils/formatting"
import { IsoDateString, Uuid } from "@/utils/types"
import { DeleteOutlined, ModeEdit } from "@mui/icons-material"
import { Button, Divider, Grid, IconButton, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { noop } from "lodash"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { ChangePasswordModal } from "./ChangePasswordModal"

export interface AboutCardProps {
  firstName: string
  lastName: string
  employmentId: Uuid
  companyName: string
  fullAddress: string
  email: string
  phoneNumber: string
  editPassword?: () => void
}

export const AboutCard = ({
  firstName,
  lastName,
  editPassword,
  employmentId,
  companyName,
  email,
  phoneNumber,
  fullAddress,
}: AboutCardProps) => (
  <InfoCard
    title="About"
    items={makeInfoItems([
      ["First Name (Legal)", firstName],
      ["Last Name (Legal)", lastName],
      ["Employee ID", employmentId],
      ["Company", companyName],
      ["Address", fullAddress],
      ["Email", email],
      ["Phone Number", phoneNumber],
    ])}
    data-qa="profile-about-card"
  >
    <Grid item>
      <Grid container alignItems="center" justifyContent="space-between">
        <InfoCardItem label="Password" value="*********" />
        {editPassword && (
          <Grid item>
            <IconButton onClick={editPassword}>
              <ModeEdit />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  </InfoCard>
)

export interface TerminationDateCardProps {
  personId: Uuid
  employmentId: Uuid
  terminatedDate: string | undefined
}

export const TerminationDateCard = ({ personId, employmentId, terminatedDate }: TerminationDateCardProps) => {
  const { notify } = useNotifications("cancel-terminate-card")
  const { mutateAsync: cancelTermination } = useCancelTerminatedDate()
  const hanldeCancelTermination = async () => {
    try {
      await cancelTermination({ personId, employmentId })
      notify("Termination date canceled successfully", "success")
    } catch {
      notify("Termination date could not be canceled", "error")
    }
  }

  const getEndOfMonth = (date: IsoDateString) => {
    const currentDate = new Date(date)
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    return endOfMonth.toLocaleDateString()
  }

  const isTerminateDateInPast = (date: string) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const terminated = new Date(date)
    return terminated < today
  }

  const normalizeDate = (date: string) => {
    const utcDate = new Date(date)
    const normalizedDate = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate())
    return normalizedDate
  }
  return (
    <StyledCard data-qa="profile-terminate-date-card">
      <Typography variant="h5">Terminate Employee</Typography>
      <Typography variant="caption">Benefits will be discontinued at the end of the termination month.</Typography>
      <Divider sx={{ my: 3 }} />
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <DatePicker
            value={normalizeDate(terminatedDate!)}
            label="Terminate date"
            disabled
            readOnly
            minDate={isTerminateDateInPast(terminatedDate!) ? new Date(terminatedDate!) : new Date()}
            onError={err => {
              console.error("error", err)
            }}
            renderInput={props => (
              <TextField
                helperText={
                  <Typography variant="caption">
                    Benefits will end on <strong>{getEndOfMonth(terminatedDate!)}</strong>
                  </Typography>
                }
                sx={{ width: { xs: "100%", sm: "31rem", color: "inherit" } }}
                {...props}
              />
            )}
            onChange={noop}
          />
        </Grid>
        {!isTerminateDateInPast(terminatedDate!) && (
          <Grid item container justifyContent="flex-end">
            <Button
              startIcon={<DeleteOutlined />}
              variant="contained"
              color="error"
              onClick={() => hanldeCancelTermination()}
              sx={{ minWidth: "6.5rem" }}
            >
              Cancel scheduled termination
            </Button>
          </Grid>
        )}
      </Grid>
    </StyledCard>
  )
}
export interface PersonPaymentAccountInformationProps {
  bankName: string
  accountNumber: string
  routingNumber: string
  fundingStatus: FundingStatus
  error: any
}

const PersonPaymentAccountInformatation = ({
  bankName,
  accountNumber,
  routingNumber,
  fundingStatus,
  error,
}: PersonPaymentAccountInformationProps) => (
  <StyledCard data-qa="person-payment-account-information">
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Typography variant="h5">Payment Information</Typography>
      </Grid>
      <Grid item>
        <FundingStatusChip fundingStatus={fundingStatus} />
      </Grid>
    </Grid>
    <Divider sx={{ my: 3 }} />
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="caption">Bank Name</Typography>
            <Typography variant="body1">{bankName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ObscuredField name="AccountNumber" label="Account Number" value={accountNumber} />
      </Grid>
      <Grid item>
        <ObscuredField name="RoutingNumber" label="Routing Number" value={routingNumber} />
      </Grid>
      <Grid item mt={4}>
        <Typography variant="caption">Do not share your payment information.</Typography>
      </Grid>
    </Grid>
  </StyledCard>
)

export const Profile = () => {
  const { user } = useAuth()
  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const personId = user?.id
  const companyId = useCompanyId(false)
  const editPassword = () => setIsChangingPassword(show => !show)
  const { data: person, isLoading: isLoadingPerson } = useGetPersonById(companyId, personId)
  const { data: company, isLoading: isCompanyLoading } = useGetCompany(companyId)
  const {
    data: fundingEntityInformation,
    isLoading: isLoadingFundingEntityInformation,
    isError: isFundingError,
  } = useGetPaymentAccountInformation(companyId, person?.employmentId)
  // About info
  const companyName = company?.companyInfo.companyName ?? ""
  const fullAddress = person?.location ?? ""

  // Contact info
  const emailNew = person?.email ?? ""
  const phoneNumber = getDisplayPhoneNumber(person?.phoneNumber ?? "")

  // Payment info
  const bankName = fundingEntityInformation?.bankName ?? ""
  const accountNumber = fundingEntityInformation?.accountNumber ?? ""
  const routingNumber = fundingEntityInformation?.routingNumber ?? ""
  const fundingStatus = (fundingEntityInformation?.status as FundingStatus) ?? APPLICATION_PENDING

  const isEmployeeInformationLoading = isLoadingPerson || isCompanyLoading || isLoadingFundingEntityInformation
  const isAccountClosed = fundingStatus === CLOSED

  return (
    <AuthGuard>
      <DashboardLayout>
        <Helmet title="Profile" />
        <Typography variant="h1" gutterBottom display="inline">
          Profile
        </Typography>
        {isEmployeeInformationLoading ? (
          <Grid item mt={10}>
            <LoadingMasonryArea data-qa="loading-employee-profile" />
          </Grid>
        ) : (
          <Grid container spacing={4} sx={{ mt: 1 }}>
            {!isFundingError && !isAccountClosed ? (
              <Grid item xs={12} lg={6}>
                <AboutCard
                  firstName={person?.firstName ?? ""}
                  lastName={person?.lastName ?? ""}
                  employmentId={person?.employmentId ?? ("" as never)}
                  companyName={companyName}
                  email={emailNew}
                  phoneNumber={phoneNumber}
                  fullAddress={fullAddress}
                  editPassword={editPassword}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <AboutCard
                  firstName={person?.firstName ?? ""}
                  lastName={person?.lastName ?? ""}
                  employmentId={person?.employmentId ?? ("" as never)}
                  companyName={companyName}
                  email={emailNew}
                  phoneNumber={phoneNumber}
                  fullAddress={fullAddress}
                  editPassword={editPassword}
                />
              </Grid>
            )}
            {!isFundingError && !isAccountClosed && (
              <Grid item xs={12} lg={6}>
                <PersonPaymentAccountInformatation
                  bankName={bankName}
                  accountNumber={accountNumber}
                  routingNumber={routingNumber}
                  fundingStatus={fundingStatus}
                  error={isFundingError}
                />
              </Grid>
            )}
          </Grid>
        )}
        {isChangingPassword && (
          <ChangePasswordModal onClose={() => setIsChangingPassword(false)} editPassword={editPassword} />
        )}
      </DashboardLayout>
    </AuthGuard>
  )
}
