import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { EMPLOYEE_ROSTER } from "@/features/CreateCompany/createCompanyConstants"
import { PeoplePageContainer } from "@/features/People/PeoplePage"
import { activePeopleFilter, useGetPeople } from "@/features/People/peopleService"
import { createDataQa } from "@/utils/dataQa"
import { Grid, Typography } from "@mui/material"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { OnboardingStep } from "../components/OnboardingStep"
import { useOnboardingStatuses, useUpdateOnboardingStatus } from "../employerOnboardingService"

const baseDataQa = "employer-onboarding-employees"

export const Employees = () => {
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId
  const [searchParams, setSearchParams] = useSearchParams()
  const tabParam = searchParams.get("tab")
  const { data: activeEmployees } = useGetPeople(companyId, activePeopleFilter)
  const amountOfEmployees = activeEmployees?.length
  const disableContinueButton = !amountOfEmployees
  const { statuses } = useOnboardingStatuses()
  const isEmployeesComplete = !!statuses.EMPLOYEE_ROSTER?.isComplete
  const { updateStepOnboardingStatus } = useUpdateOnboardingStatus()

  useEffect(() => {
    if (!tabParam) {
      setSearchParams({ tab: "active" })
    }
  }, [tabParam, setSearchParams])

  useEffect(() => {
    if (isEmployeesComplete && amountOfEmployees === 0) {
      updateStepOnboardingStatus(EMPLOYEE_ROSTER, false)
    }
  }, [amountOfEmployees, isEmployeesComplete, updateStepOnboardingStatus])

  return (
    <OnboardingStep
      title="Employee roster"
      subtitle="Add your employees"
      stepName={EMPLOYEE_ROSTER}
      disabled={disableContinueButton}
    >
      <Grid item xs={12} mb={8}>
        <PeoplePageContainer
          peopleTitle={
            <Typography data-qa={createDataQa(baseDataQa, "description")} mt={4}>
              Please include all employees eligible for the benefit. This is important for year-end reporting. You may
              make adjustments or add employees later in your member portal.
            </Typography>
          }
          hideSendSignUpLink
          hideWaive
        />
      </Grid>
    </OnboardingStep>
  )
}
