import { StyledCard } from "@/components/StyledCard"
import { Grid, Skeleton } from "@mui/material"

export const SkeletonSnapshotLoader = () => (
  <StyledCard sx={{ p: 4, minHeight: "10.3rem" }}>
    <Skeleton variant="rectangular" width={220} height={20} sx={{ mb: 6, mt: 5 }} />
    <Grid container spacing={2} sx={{ mt: 4 }} justifyContent="space-between">
      <Grid item>
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={90} />
        <Skeleton variant="rectangular" width={180} height={30} />
      </Grid>
      <Grid item>
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={90} />
        <Skeleton variant="rectangular" width={180} height={30} />
      </Grid>
      <Grid item>
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={90} />
        <Skeleton variant="rectangular" width={180} height={30} />
      </Grid>
    </Grid>
  </StyledCard>
)
