import {
  ADMINISTRATOR_EXTERNAL_ROLE_ID,
  EMPLOYEE_EXTERNAL_ROLE_ID,
  SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID,
} from "@/features/People/peopleConstants"
import { create } from "zustand"
import { persist } from "zustand/middleware"
import { CompanyRole } from "./authTypes"

interface RoleState {
  activeRole: string | null
  setActiveRole: (role: string) => void
  resetActiveRole: () => void
}

export const useRoleStore = create<RoleState>()(
  persist(
    set => ({
      activeRole: null,
      setActiveRole: role => set({ activeRole: role }),
      resetActiveRole: () => set({ activeRole: null }),
    }),
    {
      name: "role-storage",
    }
  )
)

export const hasMultipleRoles = (roles: CompanyRole[]): boolean => {
  const hasAdminRole = roles.some(
    role => role.roleId === ADMINISTRATOR_EXTERNAL_ROLE_ID || role.roleId === SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID
  )
  const hasEmployeeRole = roles.some(role => role.roleId === EMPLOYEE_EXTERNAL_ROLE_ID)

  return hasAdminRole && hasEmployeeRole
}
