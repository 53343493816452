import { ConfirmationModal } from "@/components/ConfirmationModal"
import { SelectField } from "@/components/SelectField"
import { useGetCurrentClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import { ADMIN_CLASS_ASSIGNMENT } from "@/features/EmployerOnboarding/employerOnboardingConstants"
import { useUpdateOnboardingStatus } from "@/features/EmployerOnboarding/employerOnboardingService"
import {
  calculateFirstBenefitDate,
  calculateHireDate,
  getAllClassNames,
} from "@/features/People/AddPersonModal/addPersonUtils"
import { addEmployeeToClass } from "@/features/People/peopleManagementEndpoints"
import { EmployeeClassRequest, EmploymentModel } from "@/features/People/peopleTypes"
import { useNotifications } from "@/services/notificationService"
import { Grid, Typography } from "@mui/material"
import { Formik } from "formik"
import { useMemo } from "react"

export interface HraClasses {
  name: string
  id: string
  waitingPeriod: string
}

export interface ClassAssignmentModalProps {
  isOpen: boolean
  companyId: string
  planId: string
  onClose: () => void
  employee: EmploymentModel
  hraStartDate: string
}

export const ClassAssignmentModal = ({
  isOpen,
  companyId,
  planId,
  onClose,
  employee,
  hraStartDate,
}: ClassAssignmentModalProps) => {
  const { data: allClasses } = useGetCurrentClasses(companyId, planId)
  const classNames = useMemo(() => (allClasses ? getAllClassNames(allClasses) : []), [allClasses])
  const { notify } = useNotifications("class-assignment")
  const ASSIGN_CLASS_INITAL_VALUES = {
    className: "",
  }
  const { updateStepOnboardingStatus } = useUpdateOnboardingStatus()

  const handleCancelOverride = async () => {
    try {
      await updateStepOnboardingStatus(ADMIN_CLASS_ASSIGNMENT, true)
      notify("Benefit eligibility skipped", "success")
      onClose()
    } catch (error: any) {
      const message = error.message || "Something went wrong"
      console.error(message)
    }
  }

  return (
    <Formik initialValues={ASSIGN_CLASS_INITAL_VALUES} onSubmit={async values => {}}>
      {({ values, touched, errors, isValid, handleChange, handleBlur, setFieldTouched, setFieldValue }) => (
        <ConfirmationModal
          title="Looks like you don't have a class assigned to you"
          actionLabel="Assign class"
          isCancelable
          disableCloseIcon
          cancelLabel="Skip"
          onCancelOverride={handleCancelOverride}
          onConfirm={async () => {
            const currentClassObject = classNames.find(classItem => classItem.id === values.className)
            const currentClassWaitingPeriod = currentClassObject?.waitingPeriod
            if (!currentClassObject || !currentClassWaitingPeriod) {
              notify("Class or waiting period not found.", "error")
              return
            }
            const hireDate = calculateHireDate(new Date(employee?.activeFrom), currentClassWaitingPeriod)
            const firstBenefitDate = calculateFirstBenefitDate(hireDate, new Date(hraStartDate))
            const employeeBody: EmployeeClassRequest = {
              companyId,
              employmentId: employee.id!,
              planId,
              classId: currentClassObject.id,
              eligibilityDateFrom: firstBenefitDate.toISOString().split("T")[0],
            }

            try {
              await addEmployeeToClass(employeeBody)
              notify("Class assigned successfully", "success")
              updateStepOnboardingStatus(ADMIN_CLASS_ASSIGNMENT, true)
              onClose()
            } catch (error: any) {
              const message = error.message || error || "Something went wrong"
              notify(`${message}`, "error")
            }
          }}
          isOpen={isOpen}
        >
          {classNames.length === 1 && (
            <Typography mb={4}>
              To begin shopping for a health plan, a class must be assigned to you, such as{" "}
              <Typography variant="body1bold">{classNames[0].name}</Typography>. Skip if you are not benefit eligible.
            </Typography>
          )}
          {classNames.length > 1 && (
            <Typography mb={4}>
              To begin shopping for a health plan, a class must be assigned to you, such as{" "}
              <Typography variant="body1bold">{classNames[0].name}</Typography>,{" "}
              <Typography variant="body1bold">{classNames[1].name}</Typography>, etc. Skip if you are not benefit
              eligible.
            </Typography>
          )}
          <Grid container>
            <Grid item xs={12}>
              <SelectField
                dataQa="class-name"
                data={classNames.map(className => ({ value: className.id, label: className.name }))}
                type="text"
                name="className"
                label="Class"
                required
                value={values.className}
                placeholder="Select class"
                onChange={handleChange}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="colors.lightGrayText">
                Assign yourself a class that matches your employment type.
              </Typography>
            </Grid>
          </Grid>
        </ConfirmationModal>
      )}
    </Formik>
  )
}
