import { APPLICATION_AWAITING_DOCUMENTS, APPLICATION_PENDING } from "@/features/Funding/fundingConstants"
import { useGetApplicationForm } from "@/features/Settings/settingsService"
import LaunchIcon from "@mui/icons-material/Launch"
import { Button, Skeleton } from "@mui/material"
import { noop } from "lodash"

interface BankingVendorButtonProps {
  applicationUrl?: string
  fundingStatus?: string
  onButtonClick?: () => void
}

/**
 * This component is used to render a button that links to the banking vendor application.
 * @param applicationUrl
 * @constructor
 */
const BankingVendorButton = ({
  applicationUrl = "",
  fundingStatus = "",
  onButtonClick = noop,
}: BankingVendorButtonProps) => {
  const buttonText = fundingStatus === APPLICATION_AWAITING_DOCUMENTS ? "Upload documents" : "View application"
  return (
    <Button
      variant="contained"
      href={applicationUrl}
      target="_blank"
      color="primary"
      onClick={onButtonClick}
      endIcon={<LaunchIcon fontSize="small" />}
    >
      {buttonText}
    </Button>
  )
}

interface FundingRegistrationButtonProps {
  companyId: string
  onButtonClick?: () => void
  onApplicationError: (error: any) => void
}
/**
 * This component is used to render a button that links to the banking vendor application.
 * @param companyId
 * @constructor
 */
export const FundingRegistrationButton = ({
  companyId,
  onButtonClick,
  onApplicationError,
}: FundingRegistrationButtonProps) => {
  const { data, isLoading, isError, error } = useGetApplicationForm(companyId)

  if (isLoading) {
    return <Skeleton variant="rounded" width={192} height={40} />
  }

  if (isError) {
    onApplicationError(error)

    return (
      <Button variant="contained" color="primary" disabled>
        Continue
      </Button>
    )
  }

  if (!data) {
    return null
  }

  const { fundingStatus, applicationUrl } = data

  if (fundingStatus === APPLICATION_PENDING || fundingStatus === APPLICATION_AWAITING_DOCUMENTS) {
    return (
      <BankingVendorButton
        applicationUrl={applicationUrl}
        fundingStatus={fundingStatus}
        onButtonClick={onButtonClick}
      />
    )
  }

  return null
}
