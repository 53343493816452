import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { ClampedTextField, PhoneNumberTextField, ZipCodeTextField } from "@/components/TextFields"
import { EXTERNAL_LINKS } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import {
  convertCompanyToFormValues,
  EinInput,
  mapFormValuesToDispatchPayload,
} from "@/features/CreateCompany/components/Steps/Setup/Setup"
import {
  tcHubcreateCompanyValidationSchema,
  useCreateCompany,
} from "@/features/CreateCompany/components/Steps/Setup/setupService"
import {
  APPLICABLE_LARGE_EMPLOYER,
  BUSINESS_STRUCTURES,
  INDUSTRIES,
} from "@/features/CreateCompany/createCompanyConstants"
import { useCreateCompanyAccount } from "@/features/CreateCompany/CreateCompanyProvider"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"
import { useNotifications } from "@/services/notificationService"
import { ensureUsPhoneNumber } from "@/utils/formatting"
import { STATE_OPTIONS } from "@/utils/States"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Alert, Grid, Link, Stack, Tooltip, Typography } from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

export const AdpSetup = ({ stepData }: PurchaseHraStepProps) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { notify } = useNotifications("company-creation")
  const { mutateAsync } = useCreateCompany()
  const [showSignInHelper, setShowSignInHelper] = useState(true)
  const { company, setCompanyAcount } = useCreateCompanyAccount()
  const [isLoading, setIsLoading] = useState(false)

  const hasErrors = (touched: { [field: string]: boolean }, errors: { [field: string]: string }) => {
    Object.keys(touched)?.forEach(field => {
      if (errors[field]) {
        return true
      }
    })
    return false
  }

  const alphabetizedIndustries = INDUSTRIES.toSorted((a, b) => a.label.localeCompare(b.label))

  return (
    <Formik
      initialValues={convertCompanyToFormValues(company)}
      validationSchema={tcHubcreateCompanyValidationSchema}
      enableReinitialize
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const updatedValues = {
            ...values,
            benefitsAdministratorPhoneNumber: ensureUsPhoneNumber(values.benefitsAdministratorPhoneNumber),
            isADP: true,
          }
          setIsLoading(true)
          await mutateAsync(updatedValues)
          setCompanyAcount(mapFormValuesToDispatchPayload(updatedValues, company.id))
          if (values.aleQuestion === false) {
            stepData.forEach(step => {
              step.isDiy = true
            })
          }
          let successToastMessage = "Company Creation Successful"

          if (!user || user.displayName === "-") {
            successToastMessage = `Company Creation Successful. Please check your email (${values.benefitsAdministratorEmail}) to set up your account and continue.`
          }
          notify(successToastMessage, "success")
          navigate("/sign-in")
        } catch (error: any) {
          notify(`Error Creating ${values.companyName}`, "error")
          const message = error.message || "Something went wrong"

          setStatus({ success: false })
          setErrors({ submit: message })
          setSubmitting(false)
        } finally {
          setIsLoading(false)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
        isValid,
        dirty,
      }) => (
        <>
          <Stepper steps={stepData} activeStepIndex={0} isError={hasErrors(touched, errors)} />
          <form noValidate onSubmit={handleSubmit} data-qa="create-company-setup-form">
            <Typography variant="h1" textAlign="left" data-qa="" sx={{ py: ".5rem" }}>
              Welcome! Let's Build Your HRA
            </Typography>
            <Typography variant="body1" textAlign="left" data-qa="" sx={{ pb: "2rem" }}>
              We promise this will be easy-- just a few minutes. Let's get started!
            </Typography>
            {showSignInHelper && (
              <Alert severity="info" onClose={() => setShowSignInHelper(false)}>
                Already have an account?{" "}
                <Link color="black" href="/sign-in">
                  sign in
                </Link>
                , if not, continue
              </Alert>
            )}
            {hasErrors(touched, errors) && (
              <Alert sx={{ mt: 2, mb: 1 }} severity="error" data-qa="field-error-banner-create-company-setup-form">
                Please fill and select missing elements to continue
              </Alert>
            )}
            <Grid container spacing={4} mt={5}>
              <Grid item xs={12}>
                <Typography variant="h6" textAlign="left" data-qa="">
                  Company information
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ClampedTextField
                  data-qa="companyName-textfield"
                  type="text"
                  name="companyName"
                  label="Company Name"
                  required
                  value={values.companyName}
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  helperText={touched.companyName && errors.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectField
                  data={BUSINESS_STRUCTURES}
                  type="text"
                  name="businessStructure"
                  label="Business Structure"
                  placeholder="Please Select"
                  required
                  value={values.businessStructure}
                  dataQa="businessStructure-dropdown"
                />
              </Grid>
              <Grid item xs={6}>
                <ClampedTextField
                  data-qa="ein-textfield"
                  type="text"
                  name="ein"
                  label="EIN"
                  value={values.ein}
                  placeholder="xx-xxxxxxx"
                  required
                  InputProps={{ inputComponent: EinInput as any }}
                  error={Boolean(touched.ein && errors.ein)}
                  fullWidth
                  helperText={touched.ein && errors?.ein?.length !== 1 && errors.ein}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors?.ein?.length === 1 && (
                  <Alert
                    severity="warning"
                    color="error"
                    sx={{
                      mb: 3,
                      textAlign: "start",
                      backgroundColor: "transparent",
                      color: "colors.lightErrorDark",
                    }}
                  >
                    This EIN already exists. Contact your systems administrator. If you feel like you’ve received this
                    message in error{" "}
                    <a target="_blank" href={EXTERNAL_LINKS.SUPPORT} rel="noreferrer">
                      contact us
                    </a>
                  </Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <ClampedTextField
                  data-qa="confirmein-textfield"
                  type="text"
                  name="confirmEin"
                  label="Confirm EIN"
                  placeholder="xx-xxxxxxx"
                  required
                  InputProps={{ inputComponent: EinInput as any }}
                  value={values.confirmEin}
                  error={Boolean(touched.confirmEin && errors.confirmEin)}
                  fullWidth
                  helperText={touched.confirmEin && errors.confirmEin}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectField
                  data={alphabetizedIndustries}
                  dataQa="industries-dropdown"
                  type="text"
                  name="industry"
                  label="Industry"
                  required
                  placeholder="Please Select"
                  value={values.industry}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} mt={5}>
              <Grid item>
                <Typography variant="h6" textAlign="left">
                  Applicable Large Employer
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography variant="subtitle1">
                      An Applicable Large Employer (ALE) is a formal definition established by the Internal Revenue
                      Service (IRS). ALEs are defined as having 50+ full-time equivalent employees on average during the
                      prior year. ALEs are subject to the employer shared responsibility provisions of the Affordable
                      Care Act - also known as the "employer mandate." Not sure if the employer is an ALE? Consult with
                      a knowledgeable tax advisor and{" "}
                      <a href={EXTERNAL_LINKS.ALE_INFO} target="_blank" rel="noopener noreferrer">
                        review the IRS guidance.
                      </a>
                    </Typography>
                  }
                  placement="right"
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" textAlign="left" sx={{ pt: 1, pb: 5 }}>
                  Are you an applicable large employer?
                </Typography>
              </Grid>
              <RadioGroupCard
                formName="accountSetup"
                name="aleQuestion"
                value={values.aleQuestion}
                handleChange={(field, value) => {
                  setFieldValue(field, value)
                }}
                elements={APPLICABLE_LARGE_EMPLOYER}
              />
            </Grid>

            <Grid container spacing={4} mt={5}>
              <Grid item xs={12}>
                <Typography variant="h6" textAlign="left">
                  Company address
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ClampedTextField
                  data-qa="companyAddress-textfield"
                  type="text"
                  name="companyAddress"
                  label="Company Address"
                  value={values.companyAddress}
                  error={Boolean(touched.companyAddress && errors.companyAddress)}
                  fullWidth
                  required
                  helperText={touched.companyAddress && errors.companyAddress}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <ClampedTextField
                  data-qa="companyAddress2-textfield"
                  type="text"
                  name="companyAddressLine2"
                  label="Suite/Apt (optional)"
                  value={values.companyAddressLine2}
                  error={Boolean(touched.companyAddressLine2 && errors.companyAddressLine2)}
                  fullWidth
                  helperText={touched.companyAddressLine2 && errors.companyAddressLine2}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={4} my={3}>
                  <ClampedTextField
                    data-qa="companyCity-textfield"
                    type="text"
                    name="companyCity"
                    label="City"
                    required
                    value={values.companyCity}
                    error={Boolean(touched.companyCity && errors.companyCity)}
                    fullWidth
                    helperText={touched.companyCity && errors.companyCity}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <SelectField
                    dataQa="companyState-dropdown"
                    data={[...STATE_OPTIONS]}
                    type="text"
                    name="companyState"
                    label="State"
                    required
                    value={values.companyState}
                    placeholder="Please Select"
                  />
                  <ZipCodeTextField
                    data-qa="benefits-companyZip-textfield"
                    type="text"
                    name="companyZip"
                    label="ZIP code"
                    required
                    value={values.companyZip}
                    error={Boolean(touched.companyZip && errors.companyZip)}
                    fullWidth
                    helperText={touched.companyZip && errors.companyZip}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={4} mt={5}>
              <Grid item xs={12}>
                <Typography variant="h6" textAlign="left">
                  Personal information
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ClampedTextField
                  data-qa="benefitsFirstName-textfield"
                  type="text"
                  name="benefitsAdministratorFirstName"
                  label="First Name"
                  required
                  value={values.benefitsAdministratorFirstName}
                  error={Boolean(touched.benefitsAdministratorFirstName && errors.benefitsAdministratorFirstName)}
                  fullWidth
                  helperText={touched.benefitsAdministratorFirstName && errors.benefitsAdministratorFirstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ my: 3 }}
                />
              </Grid>
              <Grid item xs={6}>
                <ClampedTextField
                  data-qa="benefitsLastName-textfield"
                  type="text"
                  name="benefitsAdministratorLastName"
                  label="Last Name"
                  required
                  value={values.benefitsAdministratorLastName}
                  error={Boolean(touched.benefitsAdministratorLastName && errors.benefitsAdministratorLastName)}
                  fullWidth
                  helperText={touched.benefitsAdministratorLastName && errors.benefitsAdministratorLastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ my: 3 }}
                />
              </Grid>
              <Grid item xs={6}>
                <ClampedTextField
                  data-qa="benefitsEmail-textfield"
                  type="text"
                  name="benefitsAdministratorEmail"
                  label="Email"
                  required
                  value={values.benefitsAdministratorEmail}
                  error={Boolean(touched.benefitsAdministratorEmail && errors.benefitsAdministratorEmail)}
                  fullWidth
                  helperText={touched.benefitsAdministratorEmail && errors.benefitsAdministratorEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneNumberTextField
                  data-qa="benefitsPhoneNumber-textfield"
                  type="text"
                  name="benefitsAdministratorPhoneNumber"
                  label="Phone Number"
                  required
                  value={values.benefitsAdministratorPhoneNumber}
                  error={Boolean(touched.benefitsAdministratorPhoneNumber && errors.benefitsAdministratorPhoneNumber)}
                  fullWidth
                  helperText={touched.benefitsAdministratorPhoneNumber && errors.benefitsAdministratorPhoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <FlowNavigationButtons type="submit" disabled={!(isValid && dirty)} isSubmitting={isLoading} />
          </form>
        </>
      )}
    </Formik>
  )
}
