import { UsaState } from "@/utils/States"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

interface SetupStore {
  companyNameStore: string
  businessStructureStore: string
  industryStore: string
  einStore: string
  confirmEinStore: string
  aleQuestionStore: boolean
  companyAddressStore: string
  companyAddressLine2Store: string
  companyCityStore: string
  companyStateStore: UsaState
  companyZipStore: string
  benefitsAdministratorFirstNameStore: string
  benefitsAdministratorLastNameStore: string
  benefitsAdministratorEmailStore: string
  benefitsAdministratorPhoneNumberStore: string
  isAssistedStore: boolean

  getCompanyName: () => string
  getBusinessStructure: () => string
  getIndustry: () => string
  getEin: () => string
  getConfirmEin: () => string
  getAleQuestion: () => boolean
  getCompanyAddress: () => string
  getCompanyAddressLine2: () => string
  getCompanyCity: () => string
  getCompanyState: () => UsaState
  getCompanyZip: () => string
  getBenefitsAdministratorFirstName: () => string
  getBenefitsAdministratorLastName: () => string
  getBenefitsAdministratorEmail: () => string
  getBenefitsAdministratorPhoneNumber: () => string
  getIsAssisted: () => boolean

  setCompanyNameStore: (value: string) => void
  setBusinessStructureStore: (value: string) => void
  setIndustryStore: (value: string) => void
  setEinStore: (value: string) => void
  setConfirmEinStore: (value: string) => void
  setAleQuestionStore: (value: boolean) => void
  setCompanyAddressStore: (value: string) => void
  setCompanyAddressLine2Store: (value: string) => void
  setCompanyCityStore: (value: string) => void
  setCompanyStateStore: (value: UsaState) => void
  setCompanyZipStore: (value: string) => void
  setBenefitsAdministratorFirstNameStore: (value: string) => void
  setBenefitsAdministratorLastNameStore: (value: string) => void
  setBenefitsAdministratorEmailStore: (value: string) => void
  setBenefitsAdministratorPhoneNumberStore: (value: string) => void
  setIsAssistedStore: (value: boolean) => void

  clearStore: () => void
}

const INITIAL_STATE = {
  companyNameStore: "",
  businessStructureStore: "",
  industryStore: "",
  einStore: "",
  confirmEinStore: "",
  aleQuestionStore: false,
  companyAddressStore: "",
  companyAddressLine2Store: "",
  companyCityStore: "",
  companyStateStore: "" as UsaState,
  companyZipStore: "",
  benefitsAdministratorFirstNameStore: "",
  benefitsAdministratorLastNameStore: "",
  benefitsAdministratorEmailStore: "",
  benefitsAdministratorPhoneNumberStore: "",
  isAssistedStore: false,
}

export const useSetupStore = create<SetupStore>()(
  persist(
    (set, get) => ({
      ...INITIAL_STATE,

      getCompanyName: () => get().companyNameStore,
      getBusinessStructure: () => get().businessStructureStore,
      getIndustry: () => get().industryStore,
      getEin: () => get().einStore,
      getConfirmEin: () => get().confirmEinStore,
      getAleQuestion: () => get().aleQuestionStore,
      getCompanyAddress: () => get().companyAddressStore,
      getCompanyAddressLine2: () => get().companyAddressLine2Store,
      getCompanyCity: () => get().companyCityStore,
      getCompanyState: () => get().companyStateStore,
      getCompanyZip: () => get().companyZipStore,
      getBenefitsAdministratorFirstName: () => get().benefitsAdministratorFirstNameStore,
      getBenefitsAdministratorLastName: () => get().benefitsAdministratorLastNameStore,
      getBenefitsAdministratorEmail: () => get().benefitsAdministratorEmailStore,
      getBenefitsAdministratorPhoneNumber: () => get().benefitsAdministratorPhoneNumberStore,
      getIsAssisted: () => get().isAssistedStore,

      setCompanyNameStore: (value: string) => set({ companyNameStore: value }),
      setBusinessStructureStore: (value: string) => set({ businessStructureStore: value }),
      setIndustryStore: (value: string) => set({ industryStore: value }),
      setEinStore: (value: string) => set({ einStore: value }),
      setConfirmEinStore: (value: string) => set({ confirmEinStore: value }),
      setAleQuestionStore: (value: boolean) => set({ aleQuestionStore: value }),
      setCompanyAddressStore: (value: string) => set({ companyAddressStore: value }),
      setCompanyAddressLine2Store: (value: string) => set({ companyAddressLine2Store: value }),
      setCompanyCityStore: (value: string) => set({ companyCityStore: value }),
      setCompanyStateStore: (value: UsaState) => set({ companyStateStore: value }),
      setCompanyZipStore: (value: string) => set({ companyZipStore: value }),
      setBenefitsAdministratorFirstNameStore: (value: string) => set({ benefitsAdministratorFirstNameStore: value }),
      setBenefitsAdministratorLastNameStore: (value: string) => set({ benefitsAdministratorLastNameStore: value }),
      setBenefitsAdministratorEmailStore: (value: string) => set({ benefitsAdministratorEmailStore: value }),
      setBenefitsAdministratorPhoneNumberStore: (value: string) =>
        set({ benefitsAdministratorPhoneNumberStore: value }),
      setIsAssistedStore: (value: boolean) => set({ isAssistedStore: value }),

      clearStore: () => set(INITIAL_STATE),
    }),
    {
      name: "setupStore",
      storage: createJSONStorage(() => localStorage),
      version: 1,
    }
  )
)
