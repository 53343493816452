import { appConfig } from "@/config"
import { PermissionGuard } from "@/features/Auth/guards/PermissionGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { Box, Button, Card, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { useScript } from "usehooks-ts"
import { useAuthToken } from "./autopayService"
import { BankingActivityModal } from "./components/BankingActivityModal"

export const AutoPayActivity = () => {
  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  const { data: authTokenData, isLoading: loading } = useAuthToken(companyId)

  useScript(appConfig.unitScriptUrl)

  const [showActivityModal, setShowActivityModal] = useState(false)

  const onClose = () => {
    setShowActivityModal(false)
  }

  const openActivityModal = () => {
    setShowActivityModal(true)
  }

  return (
    <PermissionGuard in="accounts_account_management">
      <Box>
        <Box display="flex" justifyContent="flex-end" mt={1} mb={4}>
          <Button variant="contained" onClick={openActivityModal}>
            Download
          </Button>
        </Box>
        <Card sx={{ p: 5, mt: 3 }}>
          <Grid container justifyContent="flex-end" alignItems="center" sx={{ mb: 1 }}>
            <BankingActivityModal companyId={companyId} onClose={onClose} open={showActivityModal} />
          </Grid>
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <unit-elements-activity
              customer-token={authTokenData?.token ?? ""}
              theme={appConfig.unitThemeUrl}
              query-filter=""
              hide-title="false"
              hide-filter-button="false"
              transactions-per-page={10}
              pagination-type="pagination"
            />
          )}
        </Card>
      </Box>
    </PermissionGuard>
  )
}
