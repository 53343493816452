import { HraClassModelResponse } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import { WAITING_PERIODS } from "@/features/CreateCompany/createCompanyConstants"
import { HraHubEditPersonValues } from "@/features/TCHub/editPersonValidations"
import { ROLES } from "@/roles_permissions"
import { transformDate } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { QueryClient } from "@tanstack/react-query"
import { FormikErrors, FormikTouched } from "formik"
import { get, isObject, set } from "lodash"
import { DateTime } from "luxon"
import { PEOPLE_STATUS } from "../peopleConstants"
import { EmployeeClassRequest, EmploymentModel, Person, PersonModel } from "../peopleTypes"
import { AddPersonValues } from "./addPersonValidations"
import { BenefitClass, PAYLOAD_ADDRESS_TYPE } from "./types"

const administratorRole = ROLES.find(role => role.name === "Administrator")
const systemAdministratorRole = ROLES.find(role => role.name === "System Administrator")
// FUTURE: Use or remove this unused variable and remove this eslint-disable directive
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const employeeRole = ROLES.find(role => role.name === "Employee")
// FUTURE: Use or remove this unused variable and remove this eslint-disable directive
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const brokerRole = ROLES.find(role => role.name === "Broker")
const adminRoleIds: (string | undefined)[] = [administratorRole?.id, systemAdministratorRole?.id]

export const mapAddEmployeeRespToPayload = (classRequestData: EmployeeClassRequest): EmployeeClassRequest => ({
  ...classRequestData,
  eligibilityDateFrom: transformDate(classRequestData.eligibilityDateFrom),
})

const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) {
    return ""
  }
  return `+1${phoneNumber.replace(/\D/g, "")}`
}

/*
 * This function is used to convert the form values to a payload.
 */
export const addPersonFormToPayload = (
  formValues: AddPersonValues,
  companyId: Uuid,
  planId?: Uuid
): Partial<PersonModel> => {
  const payload: Partial<PersonModel> = {}

  payload.firstName = formValues.firstName
  payload.lastName = formValues.lastName
  payload.planId = planId
  payload.classId = formValues.className
  if (formValues.middleName) {
    payload.middleName = formValues.middleName
  }
  if (formValues.preferredName) {
    payload.preferredName = formValues.preferredName
  }
  payload.email = formValues.email
  payload.phoneNumber = formValues.phoneNumber
  if (formValues.employeeNumber) payload.employeeNumber = formValues.employeeNumber

  if (adminRoleIds.includes(formValues.selectedRole.roleId)) {
    payload.companyAdministrators = [
      {
        activeFrom: DateTime.now().toISODate(),
        roleId: formValues.selectedRole.roleId,
        roleName: formValues.selectedRole?.roleName ?? "",
        companyId,
      },
    ]
  }

  payload.employments = [
    {
      activeFrom: DateTime.now().toISODate(),
      hireDate: DateTime.now().toISODate(),
      jobTitle: formValues.employmentType ?? formValues.selectedRole.roleId,
      isEligible: false,
      companyId,
      status: PEOPLE_STATUS.INVITED,
    },
  ]

  if (formValues.benefitEligible) {
    payload.addresses = [
      {
        id: "",
        type: PAYLOAD_ADDRESS_TYPE,
        street1: formValues.homeAddress?.street1 ?? "",
        street2: formValues.homeAddress?.street2 ?? "",
        city: formValues.homeAddress?.city ?? "",
        county: formValues.homeAddress?.county ?? "",
        state: formValues.homeAddress?.state ?? "",
        zip: formValues.homeAddress?.zip ?? "",
      },
    ]

    // FUTURE: Remove this unsafe non-null assertion
    const hireDateIso = DateTime.fromISO(formValues.hireDate?.toISOString()).toISODate()!

    payload.employments = [
      {
        activeFrom: hireDateIso,
        hireDate: hireDateIso,
        jobTitle: formValues.employmentType,
        isEligible: true,
        companyId,
        eligibilityDate: formValues.eligibilityDate,
        status: PEOPLE_STATUS.INVITED,
      },
    ]
    payload.dateOfBirth = formValues.dateOfBirth!
    payload.hireDate = hireDateIso
  }

  return payload
}

export const handleAddPersonResult = (companyId: string, result: Partial<Person>, queryClient: QueryClient) => {
  const newPerson = {
    id: result.id,
    firstName: result.firstName,
    lastName: result.lastName,
    email: result.email,
    preferredName: result.preferredName,
  }

  // FUTURE: Add keyCreator method for this queryKey
  queryClient.invalidateQueries({ queryKey: ["companies", companyId, "people"] })
  queryClient.setQueriesData({ queryKey: ["companies", companyId, "people"] }, cache => {
    const oldPeopleData = cache as Person[]

    return oldPeopleData ? [...oldPeopleData, newPerson] : [newPerson]
  })
  queryClient.invalidateQueries({ queryKey: ["getCurrentClasses"] })

  return result
}

/**
 * This function is used to convert the errors to touched fields.
 * @param touched
 * @param errors
 */
export const convertToTouched = <T>(touched: FormikTouched<T>, errors: FormikErrors<T>) => {
  const errorWalker = (nextTouched: never, errorIterator: never) => {
    Object.keys(errorIterator).forEach(key => {
      if (!isObject(get(errorIterator, key))) {
        return set(nextTouched, key, true)
      }
      set(nextTouched, key, errorWalker({} as never, get(errorIterator, key)))
    })

    return nextTouched
  }

  return errorWalker({ ...touched } as never, errors as never)
}

export const getAllClassNames = (allClasses: HraClassModelResponse[]) => {
  const classNames: BenefitClass[] = allClasses.map(currentClass => ({
    name: currentClass?.name ?? currentClass.classStructure,
    id: currentClass.id,
    waitingPeriod: currentClass.waitingPeriod,
  }))

  return [...new Set(classNames)]
}

const offset30Days = WAITING_PERIODS[1].value
const offset60Days = WAITING_PERIODS[2].value
const offset90Days = WAITING_PERIODS[3].value

export const calculateFirstBenefitDate = (hireDate: Date, companyHraStartDate: Date): Date => {
  const hireDateTime = DateTime.fromJSDate(hireDate, { zone: "local" }).startOf("day")
  const companyHraStartDateTime = DateTime.fromJSDate(companyHraStartDate, { zone: "local" }).startOf("day")
  const lastMonth = DateTime.local().minus({ months: 1 }).startOf("month")
  const maxDate = DateTime.max(hireDateTime, companyHraStartDateTime, lastMonth)
  return maxDate.toJSDate()
}

export const calculateHireDate = (hireDate: Date, currentClassWaitingPeriod: string): Date => {
  const hireDatetime = DateTime.fromJSDate(hireDate).startOf("day")

  switch (currentClassWaitingPeriod) {
    case offset90Days:
      return hireDatetime.plus({ days: 90 }).startOf("month").toJSDate()
    case offset60Days:
      return hireDatetime.plus({ days: 60 }).startOf("month").toJSDate()
    case offset30Days:
      return hireDatetime.plus({ days: 30 }).startOf("month").toJSDate()
    default:
      return hireDatetime.startOf("month").toJSDate()
  }
}

export const updateHraHubPersonFormToPayload = (
  {
    id,
    firstName,
    lastName,
    employeeId,
    homeAddress: { id: idAddress, street1, street2, city, county, state, zip },
    email,
    phoneNumber,
  }: HraHubEditPersonValues,
  companyId: string,
  employmentId: string
): Partial<PersonModel> => {
  const payload: Partial<PersonModel> = {}

  payload.id = id
  payload.firstName = firstName
  payload.lastName = lastName
  payload.email = email
  payload.phoneNumber = formatPhoneNumber(phoneNumber)
  payload.addresses = [
    {
      id: idAddress ?? "",
      type: PAYLOAD_ADDRESS_TYPE,
      street1: street1 ?? "",
      street2: street2 ?? "",
      city: city ?? "",
      county: county ?? "",
      state: state ?? "",
      zip: zip ?? "",
    },
  ]
  const employment: Partial<EmploymentModel> = {
    id: employmentId as Uuid,
    companyId,
    personId: id,
    employeeNumber: employeeId,
  }
  payload.employments = [employment as EmploymentModel]
  return payload
}
