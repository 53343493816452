import { colors, takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { Chip, SxProps, Typography } from "@mui/material"
import { memoize } from "lodash"
import {
  ACTIVE,
  APPLICATION_AWAITING_DOCUMENTS,
  APPLICATION_DENIED,
  APPLICATION_PENDING,
  BANK_ACCOUNT_PENDING,
  FAILED,
  FROZEN,
  NOT_STARTED,
} from "../fundingConstants"
import { FundingStatus } from "../fundingTypes"

interface FundingStatusStatusChipProps {
  dataQa?: string
  fundingStatus: FundingStatus
  sx?: SxProps
}

export const getVariantConfigByStatus = memoize((fundingStatus: string) => {
  switch (fundingStatus.toUpperCase()) {
    case ACTIVE:
      return {
        label: "Active",
        backgroundColor: colors.gumDropGreen,
        color: takeCommandPrimary.main,
      } as const
    case APPLICATION_DENIED:
      return {
        label: "Exipired",
        backgroundColor: colors.seaPink,
        color: colors.glazedRinglet,
      } as const
    case NOT_STARTED:
      return {
        label: "Not Started",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
    case APPLICATION_AWAITING_DOCUMENTS:
      return {
        label: "Awaiting Documents",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
    case BANK_ACCOUNT_PENDING:
      return {
        label: "Reauthentication Needed",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
    case FROZEN:
      return {
        label: "Frozen",
        backgroundColor: colors.seaPink,
        color: colors.white,
      } as const
    case FAILED:
      return {
        label: "Failed",
        backgroundColor: colors.seaPink,
        color: colors.white,
      } as const
    case APPLICATION_PENDING:
    default:
      return {
        label: "Pending",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
  }
})

export const FundingStatusChip = ({ dataQa, fundingStatus, sx }: FundingStatusStatusChipProps) => {
  const variantConfig = getVariantConfigByStatus(fundingStatus)

  return (
    <Chip
      data-qa={createDataQa(dataQa, "funding-status-chip")}
      sx={{
        backgroundColor: variantConfig.backgroundColor,
        color: variantConfig.color,
        px: 2,
        ...sx,
      }}
      label={<Typography variant="body2">{variantConfig.label}</Typography>}
    />
  )
}
