import styled from "@emotion/styled"
import { LibraryBooksOutlined } from "@mui/icons-material"
import { BackdropProps, Grid, ListItemButton, ModalProps, Drawer as MuiDrawer, PaperProps } from "@mui/material"
import { ReactNode, useState } from "react"
import { TakeCommandLogoHeader, TakeCommandMarkHeader } from "../Branding"
import { ResourceCenterDrawer } from "../ResourceCenter/ResourceCenterDrawer"
import { SidebarHeader } from "./SidebarHeader"
import { SidebarNav } from "./SidebarNav"
import { SidebarItem } from "./types"

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const Brand = styled(ListItemButton)<{
  component?: ReactNode
  to?: string
}>`
  font-size: 1.25rem;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.palette.colors.darkBody};
  font-family: "Tiempos Headline";
  min-height: 56px;
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${props => props.theme.sidebar.header.background};
  }
`

export interface SidebarProps {
  PaperProps: Partial<PaperProps>
  items: SidebarItem[]
  variant?: "permanent" | "persistent" | "temporary"
  open?: boolean
  onClose?: () => void
  showFooter?: boolean
  BackdropProps?: Partial<BackdropProps>
  ModalProps?: Partial<ModalProps>
  showMark?: boolean
  showHeader?: boolean
  showSectionDivider?: boolean
  isViewingTcHub?: boolean
  children?: ReactNode
}

export const Sidebar = ({
  items,
  showFooter = true,
  showMark = false,
  showHeader = true,
  showSectionDivider = false,
  isViewingTcHub,
  children,
  onClose,
  ...props
}: SidebarProps) => {
  const [resourceCenterOpen, setResourceCenterOpen] = useState(false)

  const handleResourceCenterClick = () => {
    if (props.variant === "temporary") {
      onClose?.()
    }
    setResourceCenterOpen(true)
  }

  return (
    <>
      <Drawer variant="permanent" {...props} data-qa="sidebar">
        <Grid container alignItems="center" sx={{ backgroundColor: isViewingTcHub ? "colors.boneChilling" : null }}>
          {showHeader && (
            <Brand data-qa="sidebar-header">
              {showMark ? <TakeCommandMarkHeader /> : <TakeCommandLogoHeader isViewingTcHub={isViewingTcHub} />}
            </Brand>
          )}
        </Grid>
        <SidebarNav items={items} showSectionDivider={showSectionDivider} />
        {children}
        <SidebarHeader
          icon={LibraryBooksOutlined}
          title="Resource Center"
          noActiveClass
          onClick={handleResourceCenterClick}
          data-qa="resource-center"
        />
      </Drawer>

      <ResourceCenterDrawer open={resourceCenterOpen} onClose={() => setResourceCenterOpen(false)} />
    </>
  )
}
