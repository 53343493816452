import { createDataQa } from "@/utils/dataQa"
import { Search as SearchIcon } from "@mui/icons-material"
import { Autocomplete, InputAdornment, TextField, Typography } from "@mui/material"
import { debounce } from "lodash"

export interface SearchInputProps {
  name: Lowercase<string>
  options: string[]
  title?: string
  small?: boolean
  debounceDelay?: number
  handleChange: (searchTerm: string) => void
}

export const SearchInput = ({
  name,
  title,
  small = false,
  options,
  debounceDelay = 300,
  handleChange,
}: SearchInputProps) => {
  const dataQa = createDataQa(name, "search")
  const handleInputChange = debounce(newValue => handleChange(newValue), debounceDelay)

  return (
    <>
      {title && (
        <Typography variant="h6" sx={{ pb: ".85rem" }}>
          {title}
        </Typography>
      )}

      <Autocomplete
        freeSolo
        id={dataQa}
        data-qa={dataQa}
        options={options}
        size={small ? "small" : "medium"}
        onInputChange={(_, value, reason) => {
          if (reason === "clear" || !value) {
            handleInputChange.cancel()
            handleChange("")
          } else if (reason === "reset") {
            handleChange(value)
          } else {
            handleInputChange(value)
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              placeholder: "Search",
            }}
          />
        )}
      />
    </>
  )
}
