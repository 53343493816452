import { appConfig } from "@/config"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { axiosInstance as axios } from "@/services/axios"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { memoize } from "lodash"
import {
  bulkSubmitEnrollmentsToCarrier,
  copyRecurringPremium,
  createTcHubRecurringPremium,
  getRecurringPremium,
  updateRecurringPremium,
} from "../tcHubEndpoints"
import {
  CreateRecurringPremiumTcHubRequest,
  HealthBenefitElectionRequest,
  PersonDetailResponse,
  RecurringPremium,
  RecurringPremiumCopyRequest,
  RecurringPremiumDetailModel,
  RecurringPremiumFilter,
  RecurringPremiumsResponse,
  RecurringPremiumUpdateRequest,
} from "../tcHubTypes"

const ledgerBaseUrl = `${appConfig.baseApiUrl}/ledger`
const BATCH_LIMIT = 5000

export const CompanySet = memoize((companies: CompanyModel[] | undefined) => {
  const companySet = new Map<string, string>()

  companies?.forEach(company => {
    companySet.set(company.id, company.companyInfo.companyName)
  })

  return companySet
})

const recurringPremiumInitialValueItem = {
  id: null as string | null,
  companyId: null as string | null,
  employmentId: null as string | null,
  enrolledAllowanceCents: null as number | null,
  premiumAmountCents: null as number | null,
  coverageStartDate: null as Date | null | string,
  coverageEndDate: null as Date | null | string,
  insuranceType: null as string | null,
  firstName: null as string | null,
  lastName: null as string | null,
  status: null as string | null,
  easyEnroll: false as boolean | null,
  employeeSubAccountId: null as string | null,
  isReimbursement: false as boolean | null,
  coverages: [] as {
    id: string | null
    validFrom: string | null
    validTo: string | null
    documentId: string | null
    status: string | null
    primary: boolean | null
    createdAt: string | null
    createdBy: string | null
    updatedAt: string | null
    updatedBy: string | null
  }[],
  familyMembers: [] as {
    firstName: string | null
    lastName: string | null
    relation: string | null
    dateOfBirth: string | null
  }[],
  email: null as string | null,
  company: null as string | null,
} as const

export const recurringPremiumInitialValues = {
  recurringPremiums: [recurringPremiumInitialValueItem] as (typeof recurringPremiumInitialValueItem)[],
} as const

export const convertToFormFields = (
  companySet: Map<string, string>,
  recurringPremium: RecurringPremiumDetailModel,
  tcHubPersonDetails: PersonDetailResponse
) => {
  const recurringPremiums = recurringPremium.recurringPremiums?.map(premium => ({
    id: premium.id ?? null,
    companyId: premium.companyId ?? null,
    employmentId: premium.employmentId ?? null,
    enrolledAllowanceCents: premium.enrolledAllowanceCents ?? null,
    premiumAmountCents: premium.premiumAmountCents ?? null,
    coverageStartDate: premium.coverageStartDate ?? null,
    coverageEndDate: premium.coverageEndDate ?? null,
    insuranceType: premium.insuranceType ?? null,
    firstName: premium.firstName ?? null,
    lastName: premium.lastName ?? null,
    status: premium.status ?? null,
    easyEnroll: premium.easyEnroll ?? false,
    isReimbursement: premium.isReimbursement ?? false,
    employeeSubAccountId: premium.employeeSubAccountId ?? null,
    coverages:
      premium.coverages?.map(coverage => ({
        id: coverage.id ?? null,
        validFrom: coverage.validFrom ?? null,
        validTo: coverage.validTo ?? null,
        documentId: coverage.documentId ?? null,
        status: coverage.status ?? null,
        primary: coverage.primary ?? false,
        createdAt: coverage.createdAt ?? null,
        createdBy: coverage.createdBy ?? null,
        updatedAt: coverage.updatedAt ?? null,
        updatedBy: coverage.updatedBy ?? null,
      })) ?? [],
    familyMembers:
      premium.familyMembers?.map(member => ({
        firstName: member.firstName ?? null,
        lastName: member.lastName ?? null,
        relation: member.relation ?? null,
        dateOfBirth: member.dateOfBirth ?? null,
      })) ?? [],
    company: companySet.get(tcHubPersonDetails.companyId) ?? null,
    email: tcHubPersonDetails.email ?? null,
  }))

  return {
    recurringPremiums,
  } as typeof recurringPremiumInitialValues
}

export const useRecurringPremiumByRecurringPremiumId = (employmentId: string) =>
  useQuery({
    queryKey: ["recurringPremium", employmentId],
    queryFn: () => getRecurringPremium(employmentId),
    enabled: Boolean(employmentId),
  })

export const useRecurringPremiumUpdate = (employmentId: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (recurringPremiumUpdate: RecurringPremiumUpdateRequest) =>
      updateRecurringPremium(employmentId!, recurringPremiumUpdate),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["recurring-premiums", employmentId],
      })
      queryClient.invalidateQueries({
        queryKey: ["updateRecurringPremium"],
      })
    },
  })
}

export const useBulkSubmitEnrollmentsToCarrier = () =>
  useMutation({
    mutationFn: async (healthBenefitElectionRequest: HealthBenefitElectionRequest) =>
      bulkSubmitEnrollmentsToCarrier(healthBenefitElectionRequest),
  })

export const getRecurringPremiumSearch = async (filter: RecurringPremiumFilter) => {
  const recurringPremiums = (
    await axios.post<RecurringPremiumsResponse>(`${ledgerBaseUrl}/v1/compliance/recurring-premiums`, filter)
  ).data

  return recurringPremiums
}

export const useGetRecurringPremiumSearch = (
  search: {
    createdBefore?: string
    employeeName?: string
    employeeEmail?: string
    companyName?: string
    planYear?: string
    enrollmentType?: string
    status?: string
    autoPay?: boolean
    olderThan10Days?: boolean
    insuranceType?: string
  },
  options?: { enabled?: boolean }
) => {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: ["getRecurringPremiumSearch"],
    queryFn: async () => {
      const result = [] as RecurringPremium[]
      if (
        !options?.enabled &&
        !search.employeeEmail &&
        !search.employeeName &&
        !search.companyName &&
        !search.planYear &&
        !search.insuranceType &&
        !search.enrollmentType &&
        !search.status &&
        search.autoPay === undefined &&
        search.olderThan10Days === undefined
      ) {
        return result
      }

      let current
      let index = 0
      do {
        current = await getRecurringPremiumSearch({
          offset: BATCH_LIMIT * index,
          limit: BATCH_LIMIT,
          createdBefore: new Date().toISOString(),
          ...search,
        })

        result.push(...current.complianceRecurringPremiums)
        queryClient.setQueryData(["getRecurringPremiumSearch"], result)
        index = index + 1
      } while (current.meta.hasNext)

      return result
    },
    enabled: options?.enabled ?? true,
  })
}

export const useCopyRecurringPremium = (recurringPremiumId: string, employmentId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: RecurringPremiumCopyRequest) => copyRecurringPremium(recurringPremiumId, payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["recurringPremium", employmentId],
      }),
  })
}

export const useCreateRecurringPremiumTcHub = (employmentId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: CreateRecurringPremiumTcHubRequest) => createTcHubRecurringPremium(employmentId, payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["recurringPremium", employmentId],
      }),
  })
}
