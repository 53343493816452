import { useGenerateCompanyDocuments } from "@/features/Documents/documentsService"
import { usePollingTaskStatus } from "@/features/Tasks/taskManagementService"
import { TASK_STATUS_COMPLETED, TASK_STATUS_FAILED } from "@/features/TCHub/tcHubConstants"
import { useNotifications } from "@/services/notificationService"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Box, CircularProgress, IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { SyntheticEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

interface TcHubCompanyOptionsDropdownProps {
  companyId: string
}

export const TcHubCompanyOptionsDropdown = ({ companyId }: TcHubCompanyOptionsDropdownProps) => {
  const [showCompanyOptions, setShowCompanyOptions] = useState<any>(null)
  const [isGeneratingDocuments, setIsGeneratingDocuments] = useState(false)
  const navigate = useNavigate()

  const { notify } = useNotifications("generate-documents")
  const { mutateAsync: generateCompanyDocuments } = useGenerateCompanyDocuments(companyId)
  const { task, startPolling } = usePollingTaskStatus()

  const showMenu = (event: SyntheticEvent) => {
    setShowCompanyOptions(event.currentTarget)
  }
  const closeMenu = () => {
    setShowCompanyOptions(null)
  }

  const handleManageBusinessUnits = () => {
    closeMenu()
    navigate(`${companyId}/business-units`)
  }

  useEffect(() => {
    const taskCompleted = task?.status === TASK_STATUS_COMPLETED
    const taskFailed = task?.status === TASK_STATUS_FAILED
    if (taskCompleted || taskFailed) {
      notify(
        taskCompleted ? "Documents generated successfully!" : "Error generating documents. Please try again later.",
        taskCompleted ? "success" : "error"
      )
      setIsGeneratingDocuments(false)
      closeMenu()
    }
  }, [task?.status, notify])

  const handleGenerateDocuments = async () => {
    setIsGeneratingDocuments(true)

    try {
      const response = await generateCompanyDocuments()
      if (!response?.taskId) return

      startPolling(response.taskId)
    } catch (error) {
      console.error("Error generating documents: ", error)
      notify("Error generating documents. Please try again later.", "error")
      setIsGeneratingDocuments(false)
      closeMenu()
    }
  }

  return (
    <div data-qa={`icon-more-actions-${companyId}`}>
      <Tooltip title="Options">
        <IconButton
          data-qa={`dropdown-button-${companyId}`}
          aria-owns={showCompanyOptions ? "menu-companies-options" : undefined}
          aria-haspopup="true"
          size="large"
          onClick={showMenu}
          sx={{ width: "2.5rem", height: "2.5rem", borderRadius: "50%" }}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        data-qa="menu-companies-options"
        id="menu-companies-options"
        anchorEl={showCompanyOptions}
        open={Boolean(showCompanyOptions)}
        onClose={closeMenu}
      >
        <MenuItem
          className="tch-companies-menu-item"
          data-qa="manage-business-units"
          onClick={handleManageBusinessUnits}
        >
          Manage Business Units
        </MenuItem>
        <MenuItem
          className="tch-companies-menu-item"
          data-qa="generate-documents"
          onClick={handleGenerateDocuments}
          disabled={isGeneratingDocuments}
        >
          {isGeneratingDocuments ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem", p: 0 }}>
              <CircularProgress size={16} />
              Generating Documents...
            </Box>
          ) : (
            "Generate Documents"
          )}
        </MenuItem>
      </Menu>
    </div>
  )
}
