import { BaseObject } from "@/features/Auth/authTypes"
import { useRoleStore } from "@/features/Auth/roleStore"
import { setActiveCompanyById } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import {
  ADMINISTRATOR_EXTERNAL_ROLE_ID,
  EMPLOYEE_EXTERNAL_ROLE_ID,
  SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID,
} from "@/features/People/peopleConstants"
import { useGetAssociatedCompanies } from "@/features/People/peopleService"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import { AppBar, Grid, IconButton, Stack, Toolbar, Typography, useMediaQuery } from "@mui/material"
import { MouseEventHandler, ReactElement, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { TakeCommandLogoHeader, TakeCommandMarkHeader } from "../Branding"
import { NavbarNotificationsDropdown } from "./NavbarNotificationsDropdown"
import { NavbarUserDropdown, NavbarUserDropdownProps } from "./NavbarUserDropdown"

export interface NavbarProps extends NavbarUserDropdownProps {
  onDrawerToggle?: MouseEventHandler<HTMLElement>
  hasMark?: boolean
  hasLogo?: boolean
  centerAlignMark?: boolean
  hasDrawer?: boolean
  hasNotificationDrawer?: boolean
  title?: string
  toggleDrawerOnMedium?: boolean
  children?: ReactElement
  isViewingTcHub?: boolean
}

const useGetLabelRole = () => {
  const { activeRole } = useRoleStore.getState()

  if (activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID) return "Admin"
  if (activeRole === EMPLOYEE_EXTERNAL_ROLE_ID) return "Employee"
  return ""
}

export const Navbar = ({
  onDrawerToggle,
  hasMark = false,
  centerAlignMark = false,
  hasLogo,
  title,
  hideProfileItem,
  hasDrawer = true,
  hasNotificationDrawer = true,
  toggleDrawerOnMedium,
  children,
  isViewingTcHub,
}: NavbarProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [activeCompany, setActiveCompany] = useState<{ id: string; name: string } | null>(null)
  const { data: associatedCompanies, isLoading: isLoadingAssociatedCompanies } = useGetAssociatedCompanies()
  // FUTURE: start getting active companyId from auth instead of getActiveCompany
  const { isLoggedIn, user, refresh, companyId } = useAuth()
  const { setActiveRole } = useRoleStore()
  const isAdminPage = location.pathname.startsWith("/admin")
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"))

  useEffect(() => {
    if (associatedCompanies && user) {
      const selectedCompany = associatedCompanies.find((company: { id: string }) => company.id === companyId)
      if (selectedCompany) {
        setActiveCompany({ id: selectedCompany.id, name: selectedCompany.name })
      } else {
        setActiveCompany({ id: "unknown", name: "Unknown Company" })
      }
    }
  }, [associatedCompanies, user, companyId])

  const labelRole = useGetLabelRole()

  const handleCompanySelection = async (id: string) => {
    if (!user) return

    const selectedCompany = associatedCompanies?.find((company: BaseObject) => company.id === id)
    if (selectedCompany) {
      await setActiveCompanyById(user, selectedCompany.id)
      const companyRoles = user.company?.roles || []
      const hasAdminRole = companyRoles.some(
        role => role.roleId === ADMINISTRATOR_EXTERNAL_ROLE_ID || role.roleId === SYSTEM_ADMINISTRATOR_EXTERNAL_ROLE_ID
      )
      const hasEmployeeRole = companyRoles.some(role => role.roleId === EMPLOYEE_EXTERNAL_ROLE_ID)

      if (hasAdminRole) {
        setActiveRole(ADMINISTRATOR_EXTERNAL_ROLE_ID)
      } else if (hasEmployeeRole) {
        setActiveRole(EMPLOYEE_EXTERNAL_ROLE_ID)
      }

      setActiveCompany({ id: selectedCompany.id, name: selectedCompany.name })
      refresh()
      navigate("/")
    }
  }

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={theme => ({
        background: theme.header.background,
        color: theme.header.color,
        borderBottom: "1px solid",
        borderColor: "colors.borderGray",
      })}
    >
      <Toolbar sx={{ backgroundColor: isViewingTcHub ? "colors.boneChilling" : null, pt: hasMark ? 2 : 0 }}>
        <Grid
          container
          alignItems="center"
          justifyContent={centerAlignMark ? "space-between" : "flex-start"}
          data-qa="navbar-container"
        >
          {hasDrawer && (
            <>
              <Grid
                item
                sx={{
                  display: toggleDrawerOnMedium ? { xs: "block", lg: "none" } : { xs: "block", md: "none" },
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  data-qa="navbar-menu-icon"
                  size="medium"
                >
                  {/* FUTURE: remove this important directives*/}
                  <MenuOutlinedIcon sx={{ width: "2rem !important", height: "2rem !important" }} />
                </IconButton>
              </Grid>
              {isMobile && (
                <Grid item sx={{ mr: 2 }}>
                  <TakeCommandMarkHeader />
                </Grid>
              )}
            </>
          )}
          {hasMark && (
            <Grid item alignItems="center" justifyContent="center" data-qa="navbar-mark">
              <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
                <TakeCommandMarkHeader />
                {title && <Typography variant="h2tiempos">{title}</Typography>}
              </Stack>
            </Grid>
          )}
          {hasLogo && (
            <Grid item alignItems="center" justifyContent="center" sx={{ paddingTop: ".75rem" }} data-qa="navbar-logo">
              <Stack direction="row" spacing={4} alignItems="center">
                <TakeCommandLogoHeader isViewingTcHub={isViewingTcHub} />
                {title && <Typography variant="h2tiempos">{title}</Typography>}
              </Stack>
            </Grid>
          )}
          {associatedCompanies && associatedCompanies.length > 1 && !isAdminPage && (
            <Grid item xs data-qa="navbar-spacer">
              <Typography
                variant="body1bold"
                noWrap
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {activeCompany?.name}
              </Typography>
            </Grid>
          )}
          {!centerAlignMark && <Grid item xs data-qa="navbar-spacer" />}
          <Grid item data-qa="navbar-user-details">
            <Grid container alignItems="center">
              {isLoggedIn && hasNotificationDrawer && !isViewingTcHub && <NavbarNotificationsDropdown />}
              <NavbarUserDropdown
                hideProfileItem={hideProfileItem}
                associatedCompanies={associatedCompanies}
                onCompanySelect={handleCompanySelection}
                activeCompanyId={activeCompany?.id}
                isLoadingAssociatedCompanies={isLoadingAssociatedCompanies}
                labelRole={labelRole}
              />
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
