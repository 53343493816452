import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { PermissionGuard } from "@/features/Auth/guards/PermissionGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { getValueLabelsFromYearsToNext, months } from "@/utils/dates"
import { Uuid } from "@/utils/types"
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { useState } from "react"
import { FundingEventSummary } from "../Funding/fundingTypes"
import { useCompanyFundingEventEntries } from "./autopayService"
import { AutoPayFundingReportsTable } from "./components/AutoPayFundingReportsTable"
import { SkeletonSnapshotLoader } from "./components/SkeletonSnapshotLoader"
import { UpcomingFundingEventSnapshot } from "./components/UpcomingFundingEventSnapshot"

interface AutoPayFundingReportsProps {
  latestScheduledEvent: FundingEventSummary
  loadingCompanyFundingEvents: boolean
}

export const AutoPayFundingReports = ({
  latestScheduledEvent,
  loadingCompanyFundingEvents,
}: AutoPayFundingReportsProps) => {
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>()
  const [selectedYear, setSelectedYear] = useState<number | undefined>()

  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  const { data: companyFundingEventEntries, isLoading: loadingCompanyFundingEventEntries } =
    useCompanyFundingEventEntries({ companyId, month: selectedMonth, year: selectedYear })

  if (!latestScheduledEvent) {
    return <SkeletonSnapshotLoader />
  }

  return (
    <PermissionGuard in="accounts_account_management">
      {latestScheduledEvent && <UpcomingFundingEventSnapshot latestScheduledEvent={latestScheduledEvent} />}
      <Grid container direction="row" spacing={4} sx={{ mt: 6 }}>
        <Grid item>
          <FormControl variant="outlined" size="small" sx={{ backgroundColor: "white", minWidth: 150 }}>
            <InputLabel id="month">Month</InputLabel>
            <Select
              labelId="month"
              id="month"
              value={selectedMonth}
              onChange={e => setSelectedMonth(Number(e.target.value))}
              sx={{ height: "2.5rem" }}
              label="Month"
            >
              {months.map(month => (
                <MenuItem key={`month-select-${month.value}`} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl size="small" sx={{ backgroundColor: "white", minWidth: 120 }}>
            <InputLabel id="year">Year</InputLabel>
            <Select
              id="year"
              labelId="year"
              value={selectedYear}
              onChange={e => setSelectedYear(Number(e.target.value))}
              label="Year"
            >
              {getValueLabelsFromYearsToNext(2024).map(year => (
                <MenuItem key={`year-select-${year.value}`} value={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0 }}>
        <Grid item xs={12}>
          {selectedMonth &&
          selectedYear &&
          companyId &&
          (loadingCompanyFundingEvents || loadingCompanyFundingEventEntries) ? (
            <SkeletonTableLoader
              headerTitles={["Name", "Amount", "Date"]}
              bodyRowsCount={7}
              data-qa="skeleton-table-loader-company-funding-events"
            />
          ) : (
            <AutoPayFundingReportsTable
              events={companyFundingEventEntries ?? []}
              companyId={(companyId as Uuid) ?? null}
            />
          )}
        </Grid>
      </Grid>
    </PermissionGuard>
  )
}
