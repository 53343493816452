import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { EnrollmentSearch, useEnrollmentSearch } from "@/features/TCHub/Enrollment/EnrollmentSearch"
import { ExportEnrollmentToCsvButton } from "@/features/TCHub/Enrollment/ExportEnrollmentToCsvButton"
import { ExportEnrollmentToPdfButton } from "@/features/TCHub/Enrollment/ExportEnrollmentToPdfButton"
import {
  filterEnrollments,
  getCarrierSet,
  isCheckoutCompleted,
  isEnrolledThroughTch,
  useCarriers,
} from "@/features/TCHub/Enrollment/tcHubEnrollmentService"
import { EnrollmentModel, EnrollmentsTableHeader } from "@/features/TCHub/tcHubTypes"
import { toTitleCase } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import FilterListIcon from "@mui/icons-material/FilterList"
import { Button, Checkbox, Grid, TableCell, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { EnrollmentFilterDrawer } from "./EnrollmentFilterDrawer"
import { EnrollmentOptionsDropDown } from "./EnrollmentOptionsDropdown"
import { SubmittedToCarrierButton } from "./SubmittedToCarrierButton"

const headCells: EnrollmentsTableHeader[] = [
  { id: "select", label: "", sortable: true, alignment: "left" },
  {
    id: "employeeName",
    field: row => `${row.employee?.firstName} ${row.employee?.lastName}`.toLowerCase(),
    label: "Employee Name",
    alignment: "left",
    sortable: true,
  },
  { id: "companyName", field: "employmentId", label: "Company Name", alignment: "left", sortable: true },
  { id: "enrollmentType", field: "enrollmentType", label: "Enrollment Type", alignment: "left", sortable: true },
  { id: "planYear", field: "planEffectiveDate", label: "Plan Year", alignment: "left", sortable: true },
  {
    id: "enrolledThroughTC",
    field: "enrollmentStatus",
    label: "Enrolled through TC",
    alignment: "left",
    sortable: true,
  },
  {
    id: "checkoutCompleted",
    field: "enrollmentStatus",
    label: "Checkout Completed",
    alignment: "left",
    sortable: true,
  },
  {
    id: "carrierName",
    field: "carrierId",
    label: "Carrier Name",
    alignment: "left",
    sortable: true,
  },
  {
    id: "healthPlan",
    field: "healthPlanName",
    label: "Health Plan",
    alignment: "left",
    sortable: true,
  },
  {
    id: "healthPlanState",
    field: "address",
    label: "State",
    alignment: "left",
    sortable: true,
  },
  {
    id: "membersOnPlan",
    field: "shoppingPersons",
    label: "Members",
    alignment: "left",
    sortable: true,
  },
  {
    id: "planMarket",
    field: "planMarket",
    label: "Plan Market",
    alignment: "left",
    sortable: true,
  },
  {
    id: "createdAt",
    field: "createdAt",
    label: "Created",
    alignment: "left",
    sortable: true,
  },
  {
    id: "updatedAt",
    field: "updatedAt",
    label: "Updated",
    alignment: "left",
    sortable: true,
  },
  {
    id: "actions",
    label: "Actions",
    alignment: "left",
    sortable: false,
  },
]
export const TcHubEnrollmentPage = () => {
  const navigate = useNavigate()
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
  const [sortBy, setSortBy] = useState("createdAt")

  const toggleSortBy = (headId: string) => {
    if (sortBy === headId) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc")
    } else {
      setSortBy(headId)
      setSortDirection("asc")
    }
  }

  const { data: carriers } = useCarriers()
  const carrierSet = getCarrierSet(carriers)

  const { filters, isLoading, enrollmentsData } = useEnrollmentSearch()
  const filteredEnrollments = filterEnrollments(enrollmentsData ?? [], filters)

  const [selectedEnrollments, setSelectedEnrollments] = useState<EnrollmentModel[]>([])

  useEffect(() => {
    setSelectedEnrollments([])
  }, [enrollmentsData])

  const handleToggleSelectAll = () => {
    setSelectedEnrollments(selectedEnrollments.length === 0 ? (enrollmentsData ?? []) : [])
  }

  const handleToggleSelect = (row: EnrollmentModel) => {
    const selectedIndex = selectedEnrollments.findIndex(selectedRow => selectedRow.id === row.id)
    let newSelectedRows: EnrollmentModel[] = []

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedEnrollments, row)
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedEnrollments.slice(1))
    } else if (selectedIndex === selectedEnrollments.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedEnrollments.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedEnrollments.slice(0, selectedIndex),
        selectedEnrollments.slice(selectedIndex + 1)
      )
    }
    setSelectedEnrollments(newSelectedRows)
  }

  const handleRowClick = (row: EnrollmentModel) => {
    navigate({
      pathname: `${row.id}`,
    })
  }

  return (
    <Grid container display="grid">
      <Grid item xs={12}>
        <Helmet title="TC Hub Enrollments" />
        <Typography variant="h1" gutterBottom>
          Enrollment
        </Typography>
        <Grid item xs={12}>
          <Typography display="block" gutterBottom mb={4}>
            All records for all shoppers.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} mb={2} spacing={4}>
        <Grid item xs={12} xl={10}>
          <EnrollmentSearch />
        </Grid>
        <Grid
          container
          item
          xs={12}
          xl={2}
          spacing={4}
          mb={2}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", xl: "column" },
            alignItems: "flex-end",
          }}
        >
          <Grid item mt={1}>
            <Button
              variant="outlined"
              endIcon={<FilterListIcon />}
              onClick={() => {
                setIsFilterDrawerOpen(!isFilterDrawerOpen)
              }}
            >
              More filters
            </Button>
          </Grid>
          <Grid item>
            <ExportEnrollmentToCsvButton
              disabled={selectedEnrollments.length === 0}
              ids={selectedEnrollments.map(row => row.id)}
            />
          </Grid>
          <Grid item>
            <ExportEnrollmentToPdfButton
              disabled={selectedEnrollments.length === 0}
              ids={selectedEnrollments.map(row => row.id)}
            />
          </Grid>
          <Grid item>
            <SubmittedToCarrierButton
              disabled={selectedEnrollments.length === 0}
              ids={selectedEnrollments.map(row => row.id)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          overflowX: "auto",
        }}
      >
        {!isLoading ? (
          <BaseTable
            rows={filteredEnrollments}
            selected={selectedEnrollments}
            searchCriteria=""
            onToggleSelect={handleToggleSelect}
            onToggleSelectAll={handleToggleSelectAll}
            onToggleOrderBy={headId => toggleSortBy(headId)}
            onPageChange={changedPage => setPage(changedPage)}
            onRowsPerPageChange={rows => setRowsPerPage(rows)}
            uniqueIdSelector={row => row.id}
            headCells={headCells}
            rowsPerPage={rowsPerPage}
            page={page}
            fullWidth
            orderBy={[{ headCellId: sortBy, direction: sortDirection }]}
            onRowClick={row => handleRowClick(row)}
          >
            {({ row, isSelected, toggleSelect }) => {
              const planEffectiveDate = DateTime.fromISO(row.planEffectiveDate!)

              return (
                <>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      onChange={toggleSelect}
                      onClick={event => event.stopPropagation()}
                      inputProps={{ "aria-label": "select all" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {row.employee?.firstName} {row.employee?.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {row.companyName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {toTitleCase(row.enrollmentType!)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {planEffectiveDate.isValid ? planEffectiveDate.year : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {isEnrolledThroughTch(row.enrollmentType!) ? "Yes" : "No"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {isCheckoutCompleted(row.shoppingSession?.shoppingStatus) ? "Yes" : "No"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {row.carrierName ?? carrierSet.get(row.carrierId!)?.name ?? "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {row.healthPlanName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {row.address?.state}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {row.shoppingPersons?.length ?? 0}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {toTitleCase(row.planMarket ?? "")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {DateTime.fromISO(row.createdAt).toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                      {DateTime.fromISO(row.updatedAt).toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <EnrollmentOptionsDropDown benefitId={row.id} companyId={row.employee?.companyId! as Uuid} />
                  </TableCell>
                </>
              )
            }}
          </BaseTable>
        ) : (
          <SkeletonTableLoader
            data-qa="skeleton-table-loader-enrollments"
            headerTitles={headCells.map(cell => `${cell.label}`)}
            bodyRowsCount={10}
          />
        )}
      </Grid>
      <EnrollmentFilterDrawer
        open={isFilterDrawerOpen}
        setOpen={value => {
          setIsFilterDrawerOpen(value)
        }}
      />
    </Grid>
  )
}
