import { ConfirmationModal, ModalConfig } from "@/components/ConfirmationModal"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { SEARCH_THRESHOLD } from "@/constants"
import { toTitleCase } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import styled from "@emotion/styled"
import { AddOutlined } from "@mui/icons-material"
import { Avatar, Button, TableCell } from "@mui/material"
import { Box } from "@mui/system"
import Fuse from "fuse.js"
import { Dispatch, SetStateAction, useState } from "react"
import { useNavigate } from "react-router-dom"
import { STATUSES_TO_HIDE } from "../peopleConstants"
import { EditPersonInfo, Person, PersonModel } from "../peopleTypes"
import { ChipPersonStatus } from "./ChipPersonStatus"
import { PeopleOptionsDropdown } from "./PeopleOptionsDropdown"

const defaultOrder = { headCellId: "name", direction: "asc" } as const

const roleSelector = (person: PersonModel) => person.roles?.map(role => role.name).join(", ")

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const fuseOptions = {
  keys: ["firstName", "lastName", "email"],
  threshold: SEARCH_THRESHOLD,
}

const fuseOptionsStatus = {
  keys: ["status"],
  threshold: 0.0,
}

const fuseOptionsClass = {
  keys: [["classes", "classId"]],
  threshold: 0.0,
}

const AddClassButon = ({ handleClick }: { handleClick: () => void }) => (
  <Button
    variant="outlined"
    size="small"
    startIcon={<AddOutlined />}
    sx={{ color: "colors.darkBody" }}
    onClick={handleClick}
  >
    Add Class
  </Button>
)

export interface PeopleTableProps {
  searchCriteria: string
  filterCriteria: { status: string[]; class: string[] }
  hideSendSignUpLink?: boolean
  hideCopySignUpLink?: boolean
  hideWaive?: boolean
  people: Person[]
  isLoading: boolean
  isTchubPage: boolean
  showClassColumn: boolean
  companyField?: (row: PersonModel) => string
  setOpen: Dispatch<SetStateAction<boolean>>
  setPersonProfileId: Dispatch<SetStateAction<Uuid | undefined>>
  setPersonEditClassInfo?: EditPersonInfo
}

export const PeopleTable = ({
  searchCriteria,
  filterCriteria,
  hideSendSignUpLink,
  hideCopySignUpLink,
  hideWaive,
  setOpen,
  setPersonProfileId,
  people,
  isLoading,
  showClassColumn,
  companyField,
  isTchubPage,
  setPersonEditClassInfo,
}: PeopleTableProps) => {
  const [page, setPage] = useState(0)
  const initialRowsPerPage = isTchubPage ? 25 : 10
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
  const [selected, setSelected] = useState<Person[]>([])
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([defaultOrder])
  const [modal, setModal] = useState<ModalConfig>(null)
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)
  const navigate = useNavigate()

  const filterPeople = () => {
    const fuse = new Fuse(people, fuseOptions)
    let fuseSearchResults: Person[] = [...people]

    if (searchCriteria) {
      fuseSearchResults = fuse.search(searchCriteria).map(result => result.item)
    }

    if (filterCriteria.status.length > 0) {
      const fuseStatus = new Fuse(fuseSearchResults, fuseOptionsStatus)

      fuseSearchResults = fuseStatus
        .search({
          $or: filterCriteria.status.map(criteria => ({
            status: criteria,
          })),
        })
        .map(result => result.item)
    }

    if (filterCriteria.class.length > 0) {
      const fuseStatus = new Fuse(fuseSearchResults, fuseOptionsClass)

      // No class assigned
      const filteredClassCriteria = filterCriteria.class.filter(criteria => !!criteria)
      const isFilteringByNoClass = filteredClassCriteria.length !== filterCriteria.class.length
      const peopleWithoutClass = fuseSearchResults.filter(person => !person.classes || person.classes.length === 0)

      fuseSearchResults = fuseStatus
        .search({
          $or: filteredClassCriteria.map(criteria => ({
            "classes.classId": criteria,
          })),
        })
        .map(result => result.item)

      if (isFilteringByNoClass) {
        fuseSearchResults = [...peopleWithoutClass, ...fuseSearchResults]
      }
    }

    const filtersAreEmpty =
      searchCriteria.length === 0 && filterCriteria.status.length === 0 && filterCriteria.class.length === 0
    return filtersAreEmpty ? people : fuseSearchResults
  }

  const filteredPeople = filterPeople()

  return (
    <>
      {isLoading ? (
        <SkeletonTableLoader
          data-qa="skeleton-table-loader-people"
          headerTitles={[
            "Name/Email",
            "Status",
            ...(showClassColumn ? ["Class"] : []),
            "Role",
            ...(isTchubPage ? ["Company Name"] : []),
            "Actions",
          ]}
          bodyRowsCount={10}
        />
      ) : (
        <BaseTable
          rows={filteredPeople}
          selected={selected}
          orderBy={orderBy}
          onToggleOrderBy={headId =>
            setOrderBy(state => {
              const result = [] as OrderByItemType[]

              if (state && state.length > 0 && state[0].headCellId === headId) {
                result.push({
                  headCellId: headId,
                  direction: state[0].direction === "asc" ? "desc" : "asc",
                })
              } else {
                result.push({ headCellId: headId, direction: "asc" })
              }
              if (defaultOrder.headCellId !== headId) {
                result.push(defaultOrder)
              }

              return result
            })
          }
          onToggleSelect={selection =>
            setSelected(state => {
              if (state.some(r => r.id === selection.id)) {
                return state.filter(r => r.id !== selection.id)
              }

              return [...state, selection]
            })
          }
          onToggleSelectAll={() => {
            setSelected(state => (state.length > 0 ? [] : people))
          }}
          uniqueIdSelector={row => row.id.toString()}
          headCells={[
            {
              id: "name",
              label: "Name/Email",
              field: (row: PersonModel) => toTitleCase(row.firstName) + toTitleCase(row.lastName) + row.email,
              sortable: true,
            },
            { id: "status", label: "Status", sortable: true },
            ...(showClassColumn
              ? [
                  {
                    id: "class",
                    label: "Class",
                    field: (row: PersonModel) =>
                      row.classes?.map(customClass => customClass.customClassName).join(" ,") ?? "-",
                    sortable: true,
                  },
                ]
              : []),
            {
              id: "role",
              label: "Role",
              field: (row: PersonModel) => row.roles?.map(role => role.name).join(", "),
              sortable: true,
            },
            ...(isTchubPage
              ? [
                  {
                    id: "company",
                    label: "Company Name",
                    field: (row: PersonModel) => row.companyName,
                    sortable: true,
                  },
                ]
              : []),
            { id: "actions", label: "", sortable: false },
          ].filter(Boolean)}
          onPageChange={changedPage => setPage(changedPage)}
          onRowsPerPageChange={rows => setRowsPerPage(rows)}
          page={page}
          rowsPerPage={rowsPerPage}
        >
          {({ row, labelId }) => {
            const shouldHideWaive = STATUSES_TO_HIDE.includes(row.status)
            const hasClasses = !!row.classes?.length

            return (
              <>
                <TableCell component="th" id={labelId} scope="row" data-qa="person">
                  <Customer
                    onClick={() => {
                      if (!isTchubPage) {
                        setPersonProfileId(row.id)
                        setOpen(true)
                      } else {
                        navigate(`${row.id}/${row.employmentId}/${row.companyId}`)
                      }
                    }}
                  >
                    <Avatar>
                      {row.firstName.charAt(0).toUpperCase()}
                      {row.lastName.charAt(0).toUpperCase()}
                    </Avatar>
                    <Box ml={3}>
                      {row.firstName} {row.lastName}
                      <br />
                      {row.email}
                    </Box>
                  </Customer>
                </TableCell>
                <TableCell data-qa="status-chip">
                  <ChipPersonStatus status={row.status} />
                </TableCell>
                {showClassColumn && (
                  <TableCell data-qa="class">
                    {hasClasses ? (
                      row.classes!.map(customClass => customClass.customClassName).join(" ,")
                    ) : (
                      <AddClassButon
                        handleClick={() => setPersonEditClassInfo?.({ personId: row.id, classes: row.classes ?? [] })}
                      />
                    )}
                  </TableCell>
                )}
                <TableCell data-qa="role">{roleSelector(row)}</TableCell>
                {isTchubPage && <TableCell data-qa="company">{row.companyName}</TableCell>}
                <TableCell>
                  <PeopleOptionsDropdown
                    personId={row.id}
                    employmentId={row.employmentId}
                    status={row.status}
                    setModal={setModal}
                    setIsModalSubmitting={setIsModalSubmitting}
                    setOpen={setOpen}
                    setPersonProfileId={setPersonProfileId}
                    hideSendSignUpLink={hideSendSignUpLink}
                    hideCopySignUpLink={hideCopySignUpLink}
                    hideWaive={hideWaive || shouldHideWaive}
                    companyId={row.companyId}
                  />
                </TableCell>
              </>
            )
          }}
        </BaseTable>
      )}

      {modal && (
        <ConfirmationModal
          isOpen={!!modal}
          {...modal}
          onClose={() => {
            setModal(null)
          }}
          isSubmitting={isModalSubmitting}
        />
      )}
    </>
  )
}
