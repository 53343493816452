import { appConfig } from "@/config"
import { useAuth } from "@/features/Auth/useAuth"
import { useGetHraPlan } from "@/features/CreateCompany/components/Steps/Setup/PlanSetup/planSetupService"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { AddPeopleStepProps } from "@/features/People/AddPersonModal/addPersonValidations"
import { AddPersonStep } from "@/features/People/AddPersonModal/steps/AddPersonStep"
import { STEPS } from "@/features/People/AddPersonModal/types"
import { CompanySearchAutoComplete } from "@/features/TCHub/components/CompanySearchAutoComplete"
import { Uuid } from "@/utils/types"
import { Alert, Grid, Typography } from "@mui/material"
import { useEffect } from "react"

export const TcHubConfirmCompanyStep = ({
  onClose,
  onNextStep,
  onBack,
  values,
  touched,
  errors,
  setFieldValue,
  handleBlur,
}: AddPeopleStepProps) => {
  const { data: currentHraPlanData, isLoading } = useGetHraPlan(values.company?.id)
  const showPlanIdError = errors.planId && values.company?.id && !isLoading
  const { user } = useAuth()
  const userId = user!.id as Uuid
  const token = user?.signInUserSession?.idToken?.jwtToken

  useEffect(() => {
    if (currentHraPlanData && currentHraPlanData?.length > 0) {
      const planId = currentHraPlanData[0].id
      const hraStartDate = currentHraPlanData[0].hraStartDate
      setFieldValue("hraStartDate", hraStartDate)
      setFieldValue("planId", planId)
    } else {
      setFieldValue("planId", undefined)
    }
  }, [currentHraPlanData, setFieldValue])

  return (
    <AddPersonStep
      title="Company"
      onClose={onClose}
      onBack={onBack}
      onContinue={() => onNextStep(STEPS.TC_HUB_ABOUT_PERSON_FORM)}
      isAdmin={values.isAdmin}
      licenseKey={appConfig.csvBoxLicense}
      userId={userId}
      planId={values.planId as Uuid}
      companyId={values.company?.id as Uuid}
      token={token}
      isLoading={isLoading}
    >
      <Grid item xs={12} sm={12} data-qa="tc-hub-search-step">
        <Typography variant="body1" gutterBottom>
          Select the company to which the users you are going to upload belong.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <CompanySearchAutoComplete
          name="company"
          label="Company"
          touched={Boolean(touched.company)}
          error={errors.company?.toString()}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          value={values.company !== undefined ? (values.company as unknown as CompanyModel) : null}
        />
        {showPlanIdError && (
          <Alert
            severity="warning"
            color="error"
            sx={{
              mb: 3,
              textAlign: "start",
              backgroundColor: "transparent",
              color: "colors.lightErrorDark",
            }}
          >
            No Plan ID - Please choose an active company.
          </Alert>
        )}
      </Grid>
    </AddPersonStep>
  )
}
