import { useNotifications } from "@/services/notificationService"
import { useEffect } from "react"
import { useGetCompanyBillingInformation } from "../settingsService"

export const useGetPaymentDetails = (companyId?: string) => {
  const { notify } = useNotifications("get-payment-details")
  const {
    data: paymentDetails,
    isLoading: loadingPaymentDetails,
    isError,
    error,
  } = useGetCompanyBillingInformation(companyId)

  useEffect(() => {
    if (isError) {
      const message = error.message || "Something went wrong"
      console.error(message)
      notify("An error occurred when retrieving your Payment Details. Please try again later.", "error")
    }
  }, [isError, error, notify])

  return { loadingPaymentDetails, paymentDetails }
}
