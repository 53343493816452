import { RadioGroupElement } from "@/components/RadioGroupCard"
import { EASY_ENROLL, EXTRA_STEP, SELF_ENROLL } from "@/constants"
import {
  BANK_ACCOUNT_PAYMENT_OPTION,
  BOTH_PAYMENT_OPTION,
  CREDIT_CARD_PAYMENT_OPTION,
} from "../BenefitsElection/benefitsElectionConstants"

export const TRANSFER_MAXIMUM_AMOUNT_DOLLARS = 250000
export const FIVE_PERCENT = 0.05

export const OPEN = "OPEN"
export const CLOSED = "CLOSED"
export const PENDING = "PENDING"

export const FUNDING_STATUSES = [OPEN, CLOSED, PENDING] as const

export const RESERVE_PAYMENT_TO_DISBURSEMENT = "Reserve"
export const PREMIUMS_PAYMENT_TO_DISBURSMENT = "Premium"

export const ACCRUE_ALLOWANCE = "ACCRUE_ALLOWANCE"
export const RECORD_CARRIER_PAYMENT = "RECORD_CARRIER_PAYMENT"
export const USE_UP_ALLOWANCE = "USE_UP_ALLOWANCE"
export const REPLENISH_RESERVE = "REPLENISH_RESERVE"
export const FUND_DISBURSEMENT_PREMIUM_ACCOUNT = "FUND_DISBURSEMENT_PREMIUM_ACCOUNT"
export const PARTITION_FUNDS_TO_EMPLOYEE_SUB_ACCOUNT = "PARTITION_FUNDS_TO_EMPLOYEE_SUB_ACCOUNT"
export const REALLOCATE_FUNDS_FROM_SUB_ACCOUNT_RESERVE = "REALLOCATE_FUNDS_FROM_SUB_ACCOUNT_RESERVE"
export const RECORD_REFUND_FROM_CARRIER = "RECORD_REFUND_FROM_CARRIER"
export const RECORD_FAILED_ACH_PAYMENT_TO_CARRIER = "RECORD_FAILED_ACH_PAYMENT_TO_CARRIER"
export const RECORD_EMPLOYEE_LIABILITY = "RECORD_EMPLOYEE_LIABILITY"
export const RECORD_EMPLOYEE_LIABILITY_FAILURE = "RECORD_EMPLOYEE_LIABILITY_FAILURE"
export const RECORD_EMPLOYEE_LIABILITY_REFUND = "RECORD_EMPLOYEE_LIABILITY_REFUND"
export const RECORD_PAYCHECK_WITHHOLDING = "RECORD_PAYCHECK_WITHHOLDING"
export const RECORD_REIMBURSABLE_CLAIM = "RECORD_REIMBURSABLE_CLAIM"
export const RECORD_AUTOPAY_CLAIM = "RECORD_AUTOPAY_CLAIM"
export const USE_UP_ALLOWANCE_REIMBURSEMENT = "USE_UP_ALLOWANCE_REIMBURSEMENT"
export const RECORD_EXTERNAL_REFUND = "RECORD_EXTERNAL_REFUND"
export const REALLOCATE_FUNDS_FROM_DISBURSEMENT_PREMIUM_TO_RESERVE =
  "REALLOCATE_FUNDS_FROM_DISBURSEMENT_PREMIUM_TO_RESERVE"

export const JOURNAL_ENTRY_ACTIVITY_CATEGORIES = [
  ACCRUE_ALLOWANCE,
  RECORD_CARRIER_PAYMENT,
  USE_UP_ALLOWANCE,
  REPLENISH_RESERVE,
  FUND_DISBURSEMENT_PREMIUM_ACCOUNT,
  PARTITION_FUNDS_TO_EMPLOYEE_SUB_ACCOUNT,
  REALLOCATE_FUNDS_FROM_SUB_ACCOUNT_RESERVE,
  RECORD_REFUND_FROM_CARRIER,
  RECORD_FAILED_ACH_PAYMENT_TO_CARRIER,
  RECORD_EMPLOYEE_LIABILITY,
  RECORD_EMPLOYEE_LIABILITY_FAILURE,
  RECORD_EMPLOYEE_LIABILITY_REFUND,
  RECORD_PAYCHECK_WITHHOLDING,
  RECORD_REIMBURSABLE_CLAIM,
  RECORD_AUTOPAY_CLAIM,
  USE_UP_ALLOWANCE_REIMBURSEMENT,
  RECORD_EXTERNAL_REFUND,
  REALLOCATE_FUNDS_FROM_DISBURSEMENT_PREMIUM_TO_RESERVE,
] as const

export const JOURNAL_ENTRY_ACTIVITY_CATEGORY_ACCOUNT_TYPE_MAP = {
  ACCRUE_ALLOWANCE: {
    fromAccountCategory: ["HRA_INSURANCE_PAYABLE"],
    toAccountCategory: ["ALLOWANCE"],
  },
  RECORD_CARRIER_PAYMENT: {
    fromAccountCategory: ["SUB_ACCOUNT_PREMIUMS"],
    toAccountCategory: ["HEALTH_INSURANCE_CARRIER"],
  },
  USE_UP_ALLOWANCE: {
    fromAccountCategory: ["ALLOWANCE"],
    toAccountCategory: ["EMPLOYEE_PAYCHECK_WITHHOLDING"],
  },
  REPLENISH_RESERVE: {
    fromAccountCategory: ["PAYMENT_ACCOUNT_RESERVE"],
    toAccountCategory: ["DISBURSEMENT_ACCOUNT_RESERVE"],
  },
  FUND_DISBURSEMENT_PREMIUM_ACCOUNT: {
    fromAccountCategory: ["PAYMENT_ACCOUNT_PREMIUMS"],
    toAccountCategory: ["DISBURSEMENT_ACCOUNT_PREMIUMS"],
  },
  PARTITION_FUNDS_TO_EMPLOYEE_SUB_ACCOUNT: {
    fromAccountCategory: ["DISBURSEMENT_ACCOUNT_PREMIUMS", "DISBURSEMENT_ACCOUNT_RESERVE"],
    toAccountCategory: ["SUB_ACCOUNT_PREMIUMS"],
  },
  REALLOCATE_FUNDS_FROM_SUB_ACCOUNT_RESERVE: {
    fromAccountCategory: ["SUB_ACCOUNT_PREMIUMS"],
    toAccountCategory: ["DISBURSEMENT_ACCOUNT_RESERVE"],
  },
  RECORD_REFUND_FROM_CARRIER: {
    fromAccountCategory: ["HEALTH_INSURANCE_CARRIER"],
    toAccountCategory: ["SUB_ACCOUNT_PREMIUMS"],
  },
  RECORD_FAILED_ACH_PAYMENT_TO_CARRIER: {
    fromAccountCategory: ["HEALTH_INSURANCE_CARRIER"],
    toAccountCategory: ["SUB_ACCOUNT_PREMIUMS"],
  },
  RECORD_EMPLOYEE_LIABILITY: {
    fromAccountCategory: ["EMPLOYEE_LIABILITY"],
    toAccountCategory: ["HRA_INSURANCE_PAYABLE"],
  },
  RECORD_EMPLOYEE_LIABILITY_FAILURE: {
    fromAccountCategory: ["HRA_INSURANCE_PAYABLE"],
    toAccountCategory: ["EMPLOYEE_LIABILITY"],
  },
  RECORD_EMPLOYEE_LIABILITY_REFUND: {
    fromAccountCategory: ["HRA_INSURANCE_PAYABLE"],
    toAccountCategory: ["EMPLOYEE_LIABILITY"],
  },
  RECORD_PAYCHECK_WITHHOLDING: {
    fromAccountCategory: ["EMPLOYEE_PAYCHECK_WITHHOLDING"],
    toAccountCategory: ["EMPLOYEE_LIABILITY"],
  },
  RECORD_REIMBURSABLE_CLAIM: {
    fromAccountCategory: ["REIMBURSABLE_CLAIMS"],
    toAccountCategory: ["HRA_PAYOUT_DEFICIT"],
  },
  RECORD_AUTOPAY_CLAIM: {
    fromAccountCategory: ["AUTOPAY_CLAIMS_DEFICIT"],
    toAccountCategory: ["AUTOPAY_CLAIMS"],
  },
  USE_UP_ALLOWANCE_REIMBURSEMENT: {
    fromAccountCategory: ["ALLOWANCE", "HRA_PAYOUT_DEFICIT"],
    toAccountCategory: ["HRA_INSURANCE_PAYABLE", "EMPLOYEE_REIMBURSEMENT"],
  },
  RECORD_EXTERNAL_REFUND: {
    fromAccountCategory: ["DISBURSEMENT_ACCOUNT_RESERVE"],
    toAccountCategory: ["PAYMENT_ACCOUNT_RESERVE"],
  },
  REALLOCATE_FUNDS_FROM_DISBURSEMENT_PREMIUM_TO_RESERVE: {
    fromAccountCategory: ["DISBURSEMENT_ACCOUNT_PREMIUMS"],
    toAccountCategory: ["DISBURSEMENT_ACCOUNT_RESERVE"],
  },
}

export const FUNDING_TYPES = {
  RESERVE_PAYMENT_TO_DISBURSEMENT,
  PREMIUMS_PAYMENT_TO_DISBURSMENT,
} as const

export const FUNDED = "FUNDED"
export const IN_PROGRESS = "IN_PROGRESS"
export const SCHEDULED = "SCHEDULED"
export const FAILED = "FAILED"

export const FUNDING_EVENT_STATUSES = [FUNDED, IN_PROGRESS, SCHEDULED, FAILED] as const

export const CARRIER_ACCOUNT = "CARRIER_ACCOUNT"
export const PAYMENT_ACCOUNT = "PAYMENT_ACCOUNT"
export const DISBURSEMENT_ACCOUNT = "DISBURSEMENT_ACCOUNT"
export const SUB_ACCOUNT = "SUB_ACCOUNT"

export const ENTITY_TYPES = [CARRIER_ACCOUNT, PAYMENT_ACCOUNT, DISBURSEMENT_ACCOUNT, SUB_ACCOUNT] as const
export const OFF_EXCHANGE = "Off Exchange"
export const ON_EXCHANGE = "On Exchange"
export const BOTH_EXCHANGE = "Both"
export const EXCHANGE_STATUSES = [OFF_EXCHANGE, ON_EXCHANGE, BOTH_EXCHANGE] as const

export const EXCHANGE_OPTIONS = [
  {
    value: ON_EXCHANGE,
    label: ON_EXCHANGE,
  },
  {
    value: OFF_EXCHANGE,
    label: OFF_EXCHANGE,
  },
  {
    value: BOTH_EXCHANGE,
    label: BOTH_EXCHANGE,
  },
] as const

export const SELF_ENROLL_LABEL = "Self"
export const EASY_ENROLL_LABEL = "Easy"
export const EXTRA_STEP_LABEL = "Extra Step"

export const ENROLLMENT_OPTIONS = [
  {
    value: SELF_ENROLL,
    label: SELF_ENROLL_LABEL,
  },
  {
    value: EASY_ENROLL,
    label: EASY_ENROLL_LABEL,
  },
  {
    value: EXTRA_STEP,
    label: EXTRA_STEP_LABEL,
  },
] as const

export const CARRIER_SITE_INITIAL_RECURRING =
  "Make the initial payment and setup recurring payments on the carrier’s site"
export const CARRIER_CALL_INITIAL_RECURRING =
  "Make the initial payment and setup recurring payments by calling the carrier"
export const CARRIER_SITE_RECURRING = "Setup recurring payments on the carrier’s site"
export const CARRIER_CALL_RECURRING = "Setup recurring payments by calling the carrier"

export const EXTRA_STEP_VALUES = [
  CARRIER_SITE_INITIAL_RECURRING,
  CARRIER_CALL_INITIAL_RECURRING,
  CARRIER_SITE_RECURRING,
  CARRIER_CALL_RECURRING,
] as const

export const EXTRA_STEP_OPTIONS = [
  {
    value: CARRIER_SITE_INITIAL_RECURRING,
    label: CARRIER_SITE_INITIAL_RECURRING,
  },
  {
    value: CARRIER_CALL_INITIAL_RECURRING,
    label: CARRIER_CALL_INITIAL_RECURRING,
  },
  {
    value: CARRIER_SITE_RECURRING,
    label: CARRIER_SITE_RECURRING,
  },
  {
    value: CARRIER_CALL_RECURRING,
    label: CARRIER_CALL_RECURRING,
  },
] as const

export const TEXT = "TEXT"
export const BOOLEAN = "BOOLEAN"
export const DATE = "DATE"
export const MULTIPLE_CHOICE = "MULTIPLE_CHOICE"
export const CARRIER_QUESTION_FIELD_TYPE = [TEXT, BOOLEAN, DATE, MULTIPLE_CHOICE] as const

export const INDIVIDUAL = "INDIVIDUAL"
export const GROUP = "GROUP"
export const CARRIER_QUESTION_SCOPE = [INDIVIDUAL, GROUP] as const
export const CARRIER_QUESTION_FORM_KEY_DELIMITER = "_"

export const CARRIER_QUESTION_TYPE: ReadonlyArray<RadioGroupElement<string>> = [
  { title: "Short Answer", value: TEXT },
  { title: "Yes/No", value: BOOLEAN },
  { title: "Date", value: DATE },
  { title: "Multiple Choice", value: MULTIPLE_CHOICE },
]

export const CARRIER_QUESTION_SCOPE_ARRAY = [
  { label: "Group", value: GROUP },
  { label: "Individual", value: INDIVIDUAL },
]

export const PAYMENT_TYPE_OPTIONS = [
  { title: "ACH", value: BANK_ACCOUNT_PAYMENT_OPTION },
  { title: "Credit Card", value: CREDIT_CARD_PAYMENT_OPTION },
  { title: "ACH or Credit Card", value: BOTH_PAYMENT_OPTION },
] as const

export const MAX_TASK_WAIT_DURATION_IN_MILLISECONDS = 300000 // 5 minutes
export const TASK_STATUS_STARTED = "STARTED"
export const TASK_STATUS_COMPLETED = "COMPLETED"
export const TASK_STATUS_FAILED = "FAILED"
export const TASK_STATUS_IDLE = "IDLE"

export const APPROVED_RECURRING_PREMIUM = "APPROVED"
export const PENDING_RECURRING_PREMIUM = "PENDING"
export const DECLINED_RECURRING_PREMIUM = "DECLINED"

export const RECURRING_PREMIUM_STATUS_OPTIONS = [
  { value: APPROVED_RECURRING_PREMIUM, label: "Approved" },
  { value: PENDING_RECURRING_PREMIUM, label: "Pending" },
  { value: DECLINED_RECURRING_PREMIUM, label: "Declined" },
] as const

export const RECURRING_PREMIUM_STATUS = {
  APPROVED_RECURRING_PREMIUM,
  PENDING_RECURRING_PREMIUM,
  DECLINED_RECURRING_PREMIUM,
} as const

export const EXCHANGE_VALUES = ["onExchange", "offExchange", "bothExchanges"] as const

export const AUTOPAY = "AUTOPAY"
export const REIMBURSEMENT = "REIMBURSEMENT"

export const REIMBURSEMENT_TYPE_OPTIONS: readonly RadioGroupElement<boolean>[] = [
  {
    title: "Reimbursement",
    value: true,
  },
  {
    title: "Autopay",
    value: false,
  },
] as const
