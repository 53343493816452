import { useNotifications } from "@/services/notificationService"
import { useEffect } from "react"
import { useGetCompanyBillingProviderCustomerId } from "../settingsService"

export const useGetBillingCustomerId = (companyId?: string) => {
  const { notify } = useNotifications("get-billing-customer-id")
  const {
    data: billingProviderCustomerId,
    isLoading: loadingBillingProviderCustomerId,
    isError,
    error,
  } = useGetCompanyBillingProviderCustomerId(companyId)

  useEffect(() => {
    if (isError) {
      const message = error.message || "Something went wrong"
      console.error(message)
      notify("An error occurred when retrieving your Billing Customer Id. Please try again later.", "error")
    }
  }, [isError, error, notify])

  return { loadingBillingProviderCustomerId, billingProviderCustomerId }
}
