import { AsyncButton } from "@/components/Buttons"
import { DatePickerField } from "@/components/DatePickerField"
import { SelectField } from "@/components/SelectField"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import {
  ENROLLMENT_STATUS_OPTIONS,
  RECURRING_PREMIUM_INSURANCE_TYPE_OPTIONS,
} from "@/features/BenefitsElection/benefitsElectionConstants"
import { useHealthBenefitElections } from "@/features/BenefitsElection/benefitsElectionService"
import { HealthBenefitElection, HealthBenefitElectionsList } from "@/features/BenefitsElection/benefitsElectionTypes"
import { Person } from "@/features/People/peopleTypes"
import { useNotifications } from "@/services/notificationService"
import { formatDateToMmDdYyyy, formatDateToYyyyMmDd, getDateWithoutTime } from "@/utils/dates"
import { formatCents, toTitleCase } from "@/utils/formatting"
import CloseIcon from "@mui/icons-material/Close"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { Formik, FormikProps } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"
import { PersonSearchAutoComplete } from "../components/PersonSearchAutoComplete"
import { useCreateRecurringPremiumTcHub } from "./tcHubComplianceService"

const buildInitialValues = (selectedHealthBenefitEnrollment: HealthBenefitElection | null) => ({
  healthBenefitElection: selectedHealthBenefitEnrollment?.healthBenefitElectionId ?? "",
  insuranceType: selectedHealthBenefitEnrollment?.insuranceType ?? "",
  enrollmentType: selectedHealthBenefitEnrollment?.enrollmentType ?? "",
  carrierName: selectedHealthBenefitEnrollment?.carrierName ?? "",
  healthPlanName: selectedHealthBenefitEnrollment?.healthPlanName ?? "",
  allowanceAmount: selectedHealthBenefitEnrollment?.enrolledAllowanceCents ?? 0,
  premiumAmountCents: selectedHealthBenefitEnrollment?.premiumAmountCents ?? 0,
  coverageStartDate: selectedHealthBenefitEnrollment?.coverageStartDate ?? "",
  coverageEndDate: selectedHealthBenefitEnrollment?.coverageEndDate ?? "",
  enrollmentStatus: selectedHealthBenefitEnrollment?.enrollmentStatus ?? "",
  allFamilyMembers: selectedHealthBenefitEnrollment?.allFamilyMembers ?? [],
  isPrimary: true,
  submit: false,
})

type RecurringPremiumFormValues = ReturnType<typeof buildInitialValues>
interface AddRecurringPremiumFormProps extends FormikProps<RecurringPremiumFormValues> {
  selectedEmployee: Person | null
  hbeList: HealthBenefitElectionsList | null
  hbeLoading: boolean
  handleHealthBenefitSelection: (hbe: HealthBenefitElection | null) => void
  handleEmployeeSelect: (employee: Person) => void
}

const AddRecurringPremiumForm = ({
  selectedEmployee,
  handleBlur,
  values,
  handleChange,
  handleHealthBenefitSelection,
  handleEmployeeSelect,
  hbeList,
  hbeLoading,
  setFieldValue,
}: AddRecurringPremiumFormProps) => {
  const healthBenefitElections = useMemo(() => hbeList?.healthBenefitElections || [], [hbeList])

  const selectedHealthBenefitElectionId = values.healthBenefitElection

  useEffect(() => {
    const selectedHealthBenefitEnrollment = healthBenefitElections?.find?.(
      hbe => hbe.healthBenefitElectionId === selectedHealthBenefitElectionId
    )
    handleHealthBenefitSelection(selectedHealthBenefitEnrollment || null)
  }, [healthBenefitElections, selectedHealthBenefitElectionId, handleHealthBenefitSelection])

  const healthBenefitElectionFieldOptions = useMemo(() => {
    if (hbeLoading) {
      return [{ value: "", label: "Loading health benefit elections..." }]
    }
    const healthBenefitElectionsShoppingCompleted = healthBenefitElections?.filter(
      hbe => hbe.enrollmentStatus === "EMPLOYEE_PURCHASED"
    )
    if (Array.isArray(healthBenefitElectionsShoppingCompleted) && healthBenefitElectionsShoppingCompleted.length > 0) {
      return healthBenefitElectionsShoppingCompleted.map(hbe => ({
        label: `${formatDateToMmDdYyyy(hbe.coverageStartDate)} - ${formatDateToMmDdYyyy(hbe.coverageEndDate)} | ${hbe.carrierName}`,
        value: hbe.healthBenefitElectionId,
      }))
    }
    return [{ value: "", label: "No health benefit elections available" }]
  }, [hbeLoading, healthBenefitElections])

  return (
    <Grid container spacing={5} my={6} data-qa="add-recurring-premium-form">
      <Grid item xs={12}>
        <PersonSearchAutoComplete
          name="employeeName"
          value={selectedEmployee ?? null}
          setFieldValue={(_, newValue) => {
            if (newValue) {
              handleEmployeeSelect(newValue)
            }
          }}
          handleBlur={handleBlur}
          touched={false}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          data={healthBenefitElectionFieldOptions}
          data-qa="health-benefit-elections-select"
          type="text"
          name="healthBenefitElection"
          label="Health Benefit Election"
          required
          placeholder="Please Select"
          value={values.healthBenefitElection}
          onChange={handleChange}
        />
      </Grid>
      <>
        <Grid item xs={12} sm={6}>
          <SelectField
            data={RECURRING_PREMIUM_INSURANCE_TYPE_OPTIONS}
            data-qa="insurance-type-select"
            type="text"
            name="isPrimary"
            label="Type"
            required
            placeholder="Please Select"
            value="PRIMARY"
            onBlur={handleBlur}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Insurance Type"
            value={toTitleCase(values.insuranceType ?? "")}
            InputProps={{ readOnly: true }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Covered Family Members"
            value={
              values.allFamilyMembers?.length
                ? values.allFamilyMembers
                    .map(member => `${member.firstName} ${member.lastName} (${member.relationship})`)
                    .join(", ")
                : ""
            }
            InputProps={{ readOnly: true }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Carrier"
            value={values.carrierName ?? ""}
            InputProps={{ readOnly: true }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Plan" value={values.healthPlanName} InputProps={{ readOnly: true }} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Enrollment Type"
            value={toTitleCase(values.enrollmentType ?? "")}
            InputProps={{ readOnly: true }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Monthly Allowance Amount"
            value={formatCents(values.allowanceAmount)}
            InputProps={{ readOnly: true }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Monthly Premium Amount"
            value={formatCents(values.premiumAmountCents)}
            InputProps={{ readOnly: true }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name="coverageStartDate"
            label="Start Date"
            value={getDateWithoutTime(values.coverageStartDate)}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name="coverageEndDate"
            label="End Date"
            value={getDateWithoutTime(values.coverageEndDate)}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            data-qa="recurring-premium-status-select"
            data={ENROLLMENT_STATUS_OPTIONS}
            label="Enrollment Status"
            name="enrollmentStatus"
            value={values.enrollmentStatus}
            placeholder="Enrollment Status"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </>
    </Grid>
  )
}

interface AddRecurringPremiumModalProps {
  showAddRecurringPremiumModal: boolean
  onClose: () => void
}

export const AddRecurringPremiumModal = ({ onClose, showAddRecurringPremiumModal }: AddRecurringPremiumModalProps) => {
  const { notify } = useNotifications("add-recurring-premium")

  const [selectedEmployee, setSelectedEmployee] = useState<Person | null>(null)

  const [selectedHealthBenefitEnrollment, setSelectedHealthBenefitEnrollment] = useState<HealthBenefitElection | null>(
    null
  )

  const { data: hbeList, isLoading: hbeLoading } = useHealthBenefitElections(selectedEmployee?.employmentId)
  const { mutateAsync: createRecurringPremiumTcHub } = useCreateRecurringPremiumTcHub(
    selectedEmployee?.employmentId ?? ""
  )

  const handleEmployeeSelect = (employee: Person) => {
    setSelectedEmployee(employee)
    setSelectedHealthBenefitEnrollment(null)
  }

  const handleHealthBenefitSelection = useCallback(
    (hbe: HealthBenefitElection | null, formikProps: FormikProps<RecurringPremiumFormValues>) =>
      setSelectedHealthBenefitEnrollment(hbe ?? null),
    []
  )

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Formik
        enableReinitialize
        initialValues={buildInitialValues(selectedHealthBenefitEnrollment)}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const payload = {
              healthBenefitElectionId: selectedHealthBenefitEnrollment?.healthBenefitElectionId,
              companyId: selectedHealthBenefitEnrollment?.companyId,
              coverageStartDate: formatDateToYyyyMmDd(values.coverageStartDate),
              coverageEndDate: formatDateToYyyyMmDd(values.coverageEndDate),
              enrollmentStatus: values.enrollmentStatus,
              isPrimary: values.isPrimary,
              carrierId: selectedHealthBenefitEnrollment?.carrierId,
              carrierName: selectedHealthBenefitEnrollment?.carrierName,
              enrolledAllowanceCents: values.allowanceAmount,
              enrollmentType: selectedHealthBenefitEnrollment?.enrollmentType,
              healthBenefitId: selectedHealthBenefitEnrollment?.healthBenefitId,
              insuranceType: selectedHealthBenefitEnrollment?.insuranceType,
              isReimbursement: selectedHealthBenefitEnrollment?.isReimbursement,
              planMarket: selectedHealthBenefitEnrollment?.planMarket,
              coveredFamilyMembers: selectedHealthBenefitEnrollment?.allFamilyMembers?.filter(row => row.isCovered),
              healthPlanId: selectedHealthBenefitEnrollment?.healthPlanId,
              healthPlanName: selectedHealthBenefitEnrollment?.healthPlanName,
              premiumAmountCents: values.premiumAmountCents,
              status: "APPROVED",
            }

            await createRecurringPremiumTcHub(payload)
            notify("Recurring Premium created successfully", "success")
            onClose()
          } catch (error: any) {
            console.error("Error creating recurring premium:", error.response || error)
            const message = error.message || "Something went wrong"
            setErrors({ submit: error.message })
            notify(`${message}`, "error")
            setSubmitting(false)
          }
        }}
      >
        {formikProps => (
          <Dialog
            open={showAddRecurringPremiumModal}
            onClose={(evt, reason) => {
              if (reason === "backdropClick") {
                onClose()
              }
            }}
            scroll="body"
            data-qa="add-recurring-premium-modal"
            aria-labelledby="add-recurring-premium-modal"
          >
            <DialogTitle>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography className="dialog-headline" variant="h3tiempos">
                  Add Recurring Premium
                </Typography>
                <CloseIcon
                  onClick={onClose}
                  data-qa="add-recurring-premium-dialog-close-icon-button"
                  className="dialog-close-icon"
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </DialogTitle>
            <DialogContent>
              <form noValidate onSubmit={formikProps.handleSubmit}>
                <AddRecurringPremiumForm
                  hbeLoading={hbeLoading}
                  {...formikProps}
                  selectedEmployee={selectedEmployee}
                  handleEmployeeSelect={handleEmployeeSelect}
                  hbeList={hbeList || { healthBenefitElections: [] }}
                  handleHealthBenefitSelection={hbe => handleHealthBenefitSelection(hbe, formikProps)}
                />
                <DialogActions>
                  <Button variant="text" onClick={onClose} data-qa="cancel-add-recurring-premium">
                    Cancel
                  </Button>
                  <AsyncButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    isLoading={formikProps.isSubmitting}
                    disabled={!selectedHealthBenefitEnrollment}
                    data-qa="save-add-recurring-premium"
                  >
                    Save
                  </AsyncButton>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        )}
      </Formik>
    </TcHubGuard>
  )
}
