import { DatePickerField } from "@/components/DatePickerField"
import { SelectField } from "@/components/SelectField"
import { StyledCard } from "@/components/StyledCard"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { useNotifications } from "@/services/notificationService"
import { autoPayYearOptions, getCurrentYear, getOnlyDate, months } from "@/utils/dates"
import { formatDollarsToCents } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { makeOptionsSentenceTitleCase } from "@/utils/util"
import { validateJournalEntry } from "@/utils/validations"
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material"
import { Formik, FormikProps, useFormikContext } from "formik"
import { NumericFormat } from "react-number-format"
import * as Yup from "yup"
import { CompanyAutopaySearchAutoComplete } from "../../components/CompanyAutopaySearchAutoComplete"
import {
  JOURNAL_ENTRY_ACTIVITY_CATEGORIES,
  JOURNAL_ENTRY_ACTIVITY_CATEGORY_ACCOUNT_TYPE_MAP,
  USE_UP_ALLOWANCE_REIMBURSEMENT,
} from "../../tcHubConstants"
import { useCreateJournalEntry, useRecurringPremiums } from "../../tcHubService"
import { JournalEntryRequest } from "../../tcHubTypes"

const DEFAULT_YEAR = new Date().getFullYear()
const DEFAULT_MONTH = new Date().getMonth() + 1

type JournalEntrySchema = Yup.InferType<typeof validateJournalEntry>

// FUTURE: Remove these unsafe casts
const JOURNAL_ENTRY_FORM_INITIAL_VALUES: JournalEntrySchema = {
  company: { id: null as any },
  activityCategory: "" as string,
  premiumYear: DEFAULT_YEAR,
  premiumMonth: DEFAULT_MONTH,
  fromAccountCategory: "" as string,
  toAccountCategory: "" as string,
  vendorTransactionId: "" as string,
  vendorWebhookTransactionId: "" as string,
  vendorWebhookTransactionTimestampUtc: "" as string,
  entryTimestampUtc: "" as string,
  companyFundingEventId: "" as Uuid,
  recurringPremiumId: "" as Uuid,
  amount: 0 as number,
  activityComments: "" as string,
}

const RecurringPremiumDetailsForm = () => {
  const { values } = useFormikContext<JournalEntrySchema>()
  const { isLoading: isLoadingRecurringPremiums, data: recurringPremiumCollection } = useRecurringPremiums(
    values.company?.id ?? "",
    {
      month: values.premiumMonth ?? new Date().getMonth() + 1,
      year: values.premiumYear ?? new Date().getFullYear(),
      limit: 1000,
      offset: 0,
    }
  )

  return (
    <StyledCard>
      {isLoadingRecurringPremiums || !recurringPremiumCollection ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} mb={2}>
            <Typography variant="h6">Recurring Premium details</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              name="recurringPremiumId"
              label="Recurring Premium"
              placeholder="Please Select"
              data={recurringPremiumCollection.recurringPremiums?.map((option: any) => ({
                value: option.id,
                label: option.employeeName + " - " + option.carrierName + " - " + option.planName,
              }))}
            />
          </Grid>
        </Grid>
      )}
    </StyledCard>
  )
}

const JournalEntryDetailsForm = () => {
  const { handleChange, handleBlur, values, touched, errors } = useFormikContext<JournalEntrySchema>()
  return (
    <StyledCard>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} mb={2}>
          <Typography variant="h6">Journal Entry details</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            required
            name="activityCategory"
            label="Activity Category"
            placeholder="Please Select"
            value={values.activityCategory}
            onBlur={handleBlur}
            dataQa="activity-type-dropdown"
            data={makeOptionsSentenceTitleCase(JOURNAL_ENTRY_ACTIVITY_CATEGORIES)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="activity-comments-input"
              label="Activity Comments"
              name="activityComments"
              value={values.activityComments}
              onBlur={handleBlur}
              onChange={handleChange}
              data-qa="activity-comments-input"
            />
          </FormControl>
        </Grid>
        {values.activityCategory && values.activityCategory !== USE_UP_ALLOWANCE_REIMBURSEMENT && (
          <>
            <Grid item xs={12} sm={6}>
              <SelectField
                required
                name="fromAccountCategory"
                label="Credit Account Type (From)"
                placeholder="Please Select"
                data={JOURNAL_ENTRY_ACTIVITY_CATEGORY_ACCOUNT_TYPE_MAP[
                  values.activityCategory as keyof typeof JOURNAL_ENTRY_ACTIVITY_CATEGORY_ACCOUNT_TYPE_MAP
                ].fromAccountCategory.map(option => ({
                  value: option,
                  label: option,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                required
                name="toAccountCategory"
                label="Debit Account Type (To)"
                placeholder="Please Select"
                data={JOURNAL_ENTRY_ACTIVITY_CATEGORY_ACCOUNT_TYPE_MAP[
                  values.activityCategory as keyof typeof JOURNAL_ENTRY_ACTIVITY_CATEGORY_ACCOUNT_TYPE_MAP
                ].toAccountCategory.map(option => ({
                  value: option,
                  label: option,
                }))}
              />
            </Grid>
          </>
        )}
        {values.activityCategory !== "REPLENISH_RESERVE" && values.activityCategory !== "RECORD_EXTERNAL_REFUND" && (
          <>
            <Grid item xs={12} sm={6}>
              <SelectField
                name="premiumMonth"
                label="Premium Month"
                placeholder="Please Select"
                data={months.map(option => ({
                  value: option.value.toString(),
                  label: option.label,
                }))}
                value={values.premiumMonth}
                onBlur={handleBlur}
                dataQa="premium-month-dropdown"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                name="premiumYear"
                label="Premium Year"
                placeholder="Please Select"
                data={autoPayYearOptions}
                value={values.premiumYear}
                onBlur={handleBlur}
                dataQa="premium-year-dropdown"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="vendor-transaction-input"
              label="Vendor Transaction Id"
              name="vendorTransactionId"
              value={values.vendorTransactionId}
              onBlur={handleBlur}
              onChange={handleChange}
              data-qa="vendor-transaction-input"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="vendor-webhook-transaction-input"
              label="Vendor Webhook Transaction Id"
              name="vendorWebhookTransactionId"
              value={values.vendorWebhookTransactionId}
              onBlur={handleBlur}
              onChange={handleChange}
              data-qa="vendor-webhook-transaction-input"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            data-qa="vendor-webhook-date"
            name="vendorWebhookTransactionTimestampUtc"
            label="Vendor Webhook Timestamp (UTC)"
            fullWidth
            variant="outlined"
            type="date"
            maxDate={new Date()}
            value={getOnlyDate(values.vendorWebhookTransactionTimestampUtc ?? null)}
            error={Boolean(touched.vendorWebhookTransactionTimestampUtc && errors.vendorWebhookTransactionTimestampUtc)}
            helperText={touched.vendorWebhookTransactionTimestampUtc && errors.vendorWebhookTransactionTimestampUtc}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            data-qa="entry-timestamp-date"
            name="entryTimestampUtc"
            label="Journal Entry Timestamp (UTC)"
            fullWidth
            variant="outlined"
            type="date"
            maxDate={new Date()}
            value={getOnlyDate(values.entryTimestampUtc ?? null)}
            error={Boolean(touched.entryTimestampUtc && errors.entryTimestampUtc)}
            helperText={touched.entryTimestampUtc && errors.entryTimestampUtc}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="funding-event-input"
              label="Funding Event Id"
              name="companyFundingEventId"
              value={values.companyFundingEventId}
              onBlur={handleBlur}
              onChange={handleChange}
              data-qa="company-funding-event-input"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="journal-entry-amount-label" error={touched.amount && Boolean(errors.amount)}>
              Amount
            </InputLabel>
            <NumericFormat
              id="journal-entry-amount-input"
              customInput={OutlinedInput}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              name="amount"
              label="Amount"
              value={values.amount}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.amount && errors.amount)}
              decimalSeparator="."
              decimalScale={2}
              prefix=""
              data-qa="journal-entry-amount-input"
            />
            {touched.amount && errors.amount && <FormHelperText error>{errors.amount}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

const JournalEntryForm = ({
  handleBlur,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
  setTouched,
  handleSubmit,
  isValid,
  isValidating,
  isSubmitting,
}: FormikProps<JournalEntrySchema>) => (
  <FormControl fullWidth sx={{ mt: 6 }}>
    <form onSubmit={handleSubmit}>
      <StyledCard>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} mb={2}>
            <Typography variant="h6">Company Search</Typography>
          </Grid>
          <Grid item xs={12}>
            <CompanyAutopaySearchAutoComplete
              name="company"
              touched={false}
              error={errors.company?.toString()}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              value={values.company as CompanyModel}
            />
          </Grid>
        </Grid>
      </StyledCard>
      {values.company && <JournalEntryDetailsForm />}
      {values.activityCategory &&
        values.activityCategory !== "REPLENISH_RESERVE" &&
        values.activityCategory !== "RECORD_EXTERNAL_REFUND" &&
        values.company?.id && <RecurringPremiumDetailsForm />}
      <Grid container justifyContent="flex-end">
        <Button
          disabled={!Object.keys(touched).length || !isValid || isValidating || isSubmitting}
          type="submit"
          variant="contained"
          sx={{ mt: 5 }}
          data-qa="submit-button"
        >
          Submit
        </Button>
      </Grid>
    </form>
  </FormControl>
)

export const TcHubCreateJournalEntryPage = () => {
  const { notify } = useNotifications("journal-entry-created-success")
  const createJournalEntry = useCreateJournalEntry()

  return (
    <TcHubGuard requiredPermissions={["tc_hub_journal_entry_management"]}>
      <Formik
        initialValues={JOURNAL_ENTRY_FORM_INITIAL_VALUES}
        validationSchema={validateJournalEntry}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            setSubmitting(true)
            const data = {
              companyId: values.company.id,
              activityCategory: values.activityCategory,
              fromAccountCategory: values.fromAccountCategory,
              toAccountCategory: values.toAccountCategory,
              companyFundingEventId: values.companyFundingEventId,
              vendorTransactionId: values.vendorTransactionId,
              vendorWebhookTransactionId: values.vendorWebhookTransactionId,
              vendorWebhookTransactionTimestampUtc: values.vendorWebhookTransactionTimestampUtc,
              recurringPremiumId: values.recurringPremiumId,
              premiumYear: values.premiumYear ?? getCurrentYear(),
              premiumMonth: values.premiumMonth ?? new Date().getMonth() + 1,
              entryTimestampUtc: values.entryTimestampUtc,
              amountCents: formatDollarsToCents(values.amount),
              activityComments: values.activityComments,
            } as JournalEntryRequest
            await createJournalEntry.mutateAsync({ data })
            resetForm()
            notify("Journal Entry created successfully", "success")
          } catch (error) {
            console.error(error)
            notify("Failed to create Journal Entry", "error")
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {formikProps => <JournalEntryForm {...formikProps} />}
      </Formik>
    </TcHubGuard>
  )
}
