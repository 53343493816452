import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { MultipleClassesGrid } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/CustomClasses"
import { useGetCurrentClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import { DashboardLayout } from "@/features/Dashboard/components/DashboardLayout"
import { Uuid } from "@/utils/types"
import { Box, Typography } from "@mui/material"
import { constant, noop } from "lodash"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { getCurrentPlanId } from "../../Auth/services/authService"
import { convertHraClassModelToCustomClassDetails } from "../benefitsUtils"

export const Benefits = () => {
  const [hraPlanId, setHraPlanId] = useState<string>("")
  const { user } = useAuth()
  //FUTURE Remove this unsafe type cast
  const companyId = getActiveCompany(user)?.companyId as Uuid
  const { data } = useGetCurrentClasses(companyId, hraPlanId)
  const customClassDetails = convertHraClassModelToCustomClassDetails(data) ?? []

  useEffect(() => {
    getCurrentPlanId(companyId).then(id => {
      setHraPlanId(id)
    })
  }, [companyId])

  return (
    <AuthGuard>
      <DashboardLayout>
        <Helmet title="Benefits" />
        <Box mb={12}>
          <Typography variant="h1" gutterBottom display="inline" data-qa="page-header">
            Benefits
          </Typography>
        </Box>
        <Box mb={9}>
          <Typography variant="h5" gutterBottom>
            Compliance review
          </Typography>
          <Typography variant="body1" gutterBottom>
            To continue receiving reimbursements, employees must submit proof of health insurance, which Take Command
            will review <Typography variant="body1bold">annually.</Typography>
          </Typography>
        </Box>
        <Box mb={9}>
          <Typography variant="h5" gutterBottom>
            Eligibility classes
          </Typography>
          <Typography variant="body1" gutterBottom>
            Reimbursements for insurance premiums + medical expenses
          </Typography>
        </Box>
        <Box>
          <MultipleClassesGrid
            classes={customClassDetails}
            toggleSelection={noop}
            isSelected={constant(false)}
            createClass={noop}
            canAddNewClass={false}
          />
        </Box>
      </DashboardLayout>
    </AuthGuard>
  )
}
