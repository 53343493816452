import { SelectableChip } from "@/components/Chips"
import { InfoTooltip } from "@/components/InfoTooltip"
import { PLAN_PREMIUM_LABEL } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa, DataQa, WithDataQa } from "@/utils/dataQa"
import { formatCents, toTitleCase } from "@/utils/formatting"
import { Cents, Uuid, YesOrNo } from "@/utils/types"
import { dedupe, valueIsYes } from "@/utils/util"
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined"
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined"
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined"
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  TableCell as MuiTableCell,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCellProps,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useFeatureFlag } from "configcat-react"
import { isEmpty, isString } from "lodash"
import { ReactElement, ReactNode, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { DRUG_TIERS, NONE, PERSONAL_INFO, SORT_OPTIONS } from "../../benefitsElectionConstants"
import { useIsCompanyAutoPay } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import {
  DoctorPreference,
  DrugCoverage,
  DrugCoverageHasId,
  DrugPreference,
  FilterPreferences,
  HospitalPreference,
  Plan,
  PlanProviderDetail,
  PlanRawData,
  PlanView,
  SortOption,
  SortValue,
} from "../../benefitsElectionTypes"
import { createPlanFeatures, formatProvider, getColorByMetal, getPlanBalance } from "../../benefitsElectionUtils"
import {
  CarrierLogo,
  ChoosePlanButton,
  ComparePlanButton,
  PerMonthSpan,
  PlanDetailsChips,
} from "../../components/PlanComponents"
import { useSearchPlansState } from "../../hooks/useSearchPlansState"
import { useSelectPlan } from "../../hooks/useSelectPlan"
import { ComparePlansFooter } from "./ComparePlansFooter"
import { LoadingGrid } from "./LoadingPlanGrid"
import { PlanDetailsDrawer } from "./PlanDetailsDrawer"
import { WithCoverage } from "./PlanDetailsElements"

const customButtonStyle: SxProps = {
  color: "colors.darkBody",
  borderColor: "colors.darkBody",
  paddingX: "1rem !important",
  whiteSpace: "nowrap",
  overflow: "hidden",
}

const buttonIconSize: SxProps = { width: "1rem", height: "1rem" }

export interface SortOrderButtonsProps extends WithDataQa {
  isLoading: boolean
  isAscendingOrder: boolean
  setAscendingOrder: () => void
  setDescendingOrder: () => void
  isSortValueSelected: boolean
}

export const SortOrderButtons = ({
  isLoading,
  isAscendingOrder,
  setAscendingOrder,
  setDescendingOrder,
  isSortValueSelected,
  "data-qa": dataQa,
}: SortOrderButtonsProps) => (
  <Box>
    <SelectableChip
      label={<Typography variant="body2">Low to High</Typography>}
      onClick={setAscendingOrder}
      disabled={isLoading || !isSortValueSelected}
      selected={isAscendingOrder && isSortValueSelected}
      icon={<ArrowUpwardOutlinedIcon sx={{ ...buttonIconSize, ml: "0.5rem !important" }} />}
      data-qa={createDataQa(dataQa, "sort-low-to-high")}
      selectedBackgroundColor="colors.lightTealShade"
      sx={{
        borderRadius: "32px 0px 0px 32px",
      }}
    />

    <SelectableChip
      label={<Typography variant="body2">High to Low</Typography>}
      onClick={setDescendingOrder}
      disabled={isLoading || !isSortValueSelected}
      selected={!isAscendingOrder && isSortValueSelected}
      icon={<ArrowDownwardOutlinedIcon sx={buttonIconSize} />}
      data-qa={createDataQa(dataQa, "sort-high-to-low")}
      selectedBackgroundColor="colors.lightTealShade"
      sx={{
        borderRadius: " 0px 32px 32px 0px",
        borderLeft: "none",
      }}
    />
  </Box>
)

export interface SortFilterControlsProps extends WithDataQa {
  isLoading: boolean
  sortOption: SortOption
  handleSort: (sortOption: SortOption) => void
  toggleView: (isHorizontal: boolean) => void
}

export const SortFilterControls = ({
  isLoading,
  sortOption,
  handleSort,
  "data-qa": dataQa,
  isHorizontalView,
  toggleView,
  isMobile,
}: SortFilterControlsProps & { isHorizontalView: boolean; isMobile: boolean }) => {
  const { sortValue, isAscendingOrder } = sortOption
  const isSortValueSelected = sortValue && sortValue !== NONE

  const handleSortChange = (selectedSortValue: SortValue) => {
    const newSortValue = sortValue !== selectedSortValue ? selectedSortValue : NONE

    handleSort({ sortValue: newSortValue, isAscendingOrder })
  }

  const setAscendingOrder = () => {
    if (!isAscendingOrder) {
      handleSort({ sortValue, isAscendingOrder: true })
    }
  }

  const setDescendingOrder = () => {
    if (isAscendingOrder) {
      handleSort({ sortValue, isAscendingOrder: false })
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      data-qa={createDataQa(dataQa, "filter-controls")}
    >
      <Grid item>
        <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <Typography component="span" variant="body2">
              Sort:
            </Typography>
          </Grid>
          <Grid item>
            {SORT_OPTIONS.map(({ label, value }) => (
              <SelectableChip
                key={value}
                label={<Typography variant="body2">{label}</Typography>}
                onClick={() => handleSortChange(value)}
                disabled={isLoading}
                selected={sortValue === value}
                selectedBackgroundColor="colors.lightTealShade"
                data-qa={createDataQa(dataQa, "sort", label)}
                sx={{ ml: 1 }}
              />
            ))}
          </Grid>
          <Grid item>
            <SortOrderButtons
              isAscendingOrder={isAscendingOrder}
              isLoading={isLoading}
              data-qa={dataQa}
              setAscendingOrder={setAscendingOrder}
              setDescendingOrder={setDescendingOrder}
              isSortValueSelected={isSortValueSelected}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          color="primary"
          exclusive
          size="small"
          value={isHorizontalView ? "horizontal" : "grid"}
          sx={{ mt: isMobile ? 2 : null }}
          onChange={(event, value) => {
            if (value !== null) {
              toggleView(value === "horizontal")
            }
          }}
          aria-label="plan-grid-view"
        >
          <ToggleButton value="grid" aria-label="grid-view">
            <GridViewOutlinedIcon />
          </ToggleButton>
          <ToggleButton value="horizontal" aria-label="list-view">
            <ViewListOutlinedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  )
}

interface PlanInformationRowProps {
  leftComponent: ReactElement
  rightComponent: ReactElement
}

const PlanInformationRow = ({ leftComponent, rightComponent }: PlanInformationRowProps) => (
  <>
    <Grid item xs={6}>
      {leftComponent}
    </Grid>
    <Grid item xs={6} sx={{ textAlign: "right" }}>
      {rightComponent}
    </Grid>
  </>
)

interface DifferenceTooltipProps extends WithDataQa {
  balanceLabelColor: string
  balanceValue: string
}

const DifferenceTooltip = ({ balanceLabelColor, balanceValue, "data-qa": dataQa }: DifferenceTooltipProps) => (
  <Stack alignItems="end" direction="row" gap={1} mt={0.5}>
    <Typography
      variant="body2bold"
      sx={{ color: balanceLabelColor }}
      data-qa={createDataQa(dataQa, "monthly-balance-value")}
    >
      {balanceValue}
      <PerMonthSpan />
    </Typography>
    <InfoTooltip title="This amount is the difference between your allowance and the premium." />
  </Stack>
)

interface PlanMonthlyInformationProps extends WithDataQa {
  allowanceCents: Cents
  premiumAmountCents: Cents
  deductibleCents: Cents
  maxOutOfPocketCents: Cents
}

const PlanMonthlyInformation = ({
  allowanceCents,
  premiumAmountCents,
  deductibleCents,
  maxOutOfPocketCents,
  "data-qa": dataQa,
}: PlanMonthlyInformationProps) => {
  const { balanceLabel, balanceValue, balanceLabelColor } = getPlanBalance(allowanceCents, premiumAmountCents)

  return (
    <Grid container sx={{ mt: 2, mb: 4 }} spacing={2}>
      <PlanInformationRow
        leftComponent={
          <Typography variant="body2bold" data-qa={createDataQa(dataQa, "monthly-premium-label")}>
            {PLAN_PREMIUM_LABEL}
          </Typography>
        }
        rightComponent={
          <Typography variant="body2bold" data-qa={createDataQa(dataQa, "deductible-label")}>
            Deductible
          </Typography>
        }
      />
      <PlanInformationRow
        leftComponent={
          <Typography
            variant="h5"
            sx={{ color: takeCommandPrimary.main }}
            data-qa={createDataQa(dataQa, "monthly-premium-value")}
          >
            {formatCents(premiumAmountCents)}
            <PerMonthSpan />
          </Typography>
        }
        rightComponent={
          <Typography variant="h5" color="colors.lightGrayText" data-qa={createDataQa(dataQa, "deductible-value")}>
            {formatCents(deductibleCents)}
          </Typography>
        }
      />
      <PlanInformationRow
        leftComponent={
          <Typography variant="body2" data-qa={createDataQa(dataQa, "monthly-balance-label")}>
            {balanceLabel}
          </Typography>
        }
        rightComponent={
          <Typography variant="body2" data-qa={createDataQa(dataQa, "allowance-label")}>
            Max out of pocket
          </Typography>
        }
      />
      <PlanInformationRow
        leftComponent={
          <DifferenceTooltip balanceValue={balanceValue} balanceLabelColor={balanceLabelColor} data-qa={dataQa} />
        }
        rightComponent={
          <Typography variant="body2bold" data-qa={createDataQa(dataQa, "allowance-value")}>
            {formatCents(maxOutOfPocketCents)}
          </Typography>
        }
      />
    </Grid>
  )
}

const TableCell = ({ sx = {}, ...props }: TableCellProps) => (
  <MuiTableCell sx={{ paddingX: 0, paddingTop: 2, paddingBottom: 1, ...sx }} {...props} />
)

const PlanDetailsTableRow = ({
  leftLabel,
  leftDataQa,
  rightLabel,
  rightDataQa,
  extraData,
}: {
  leftLabel: string
  leftDataQa: string
  rightLabel: string
  rightDataQa: string
  extraData?: string | ReactNode
}) => (
  <TableRow>
    <TableCell data-qa={leftDataQa}>{leftLabel}</TableCell>
    <TableCell data-qa={rightDataQa} align="right" sx={{ color: takeCommandPrimary.main }}>
      <Tooltip
        placement="right"
        title={isString(extraData) ? <Typography variant="body1">{extraData}</Typography> : extraData}
      >
        <Grid item>
          {!isEmpty(rightLabel) && rightLabel}
          {isEmpty(rightLabel) && (
            <Grid item>
              <InfoOutlinedIcon />
            </Grid>
          )}
        </Grid>
      </Tooltip>
    </TableCell>
  </TableRow>
)

interface PlanFeatureProps extends WithDataQa {
  label: string
  amount: string | number
  extraData?: string | ReactNode
}

const PlanFeatureSection = ({ label, amount, extraData, "data-qa": dataQa }: PlanFeatureProps) => {
  const hasExtraData = !!extraData
  const amountLabel = `${amount}${hasExtraData ? "*" : ""}`.replace(/\*{2,}/, "*")

  return (
    <PlanDetailsTableRow
      leftLabel={label}
      rightLabel={amountLabel}
      leftDataQa={`${dataQa}-label`}
      rightDataQa={`${dataQa}-value`}
      extraData={extraData}
    />
  )
}

interface PlanDetailsTableProps extends WithDataQa {
  pcpCost: string
  specialistCost: string
  genericDrugCost: string
  urgentCareCost: string
  mentalHealthCost: string
  rawData: PlanRawData
  uniqueProviders: PlanProviderDetail[]
  uniqueDrugs: DrugCoverage[]
  filterPreferences: Pick<FilterPreferences, "doctorPreferences" | "hospitalPreferences" | "drugPreferences">
}

const PlanDetailsTable = ({
  pcpCost,
  specialistCost,
  genericDrugCost,
  urgentCareCost,
  mentalHealthCost,
  rawData,
  uniqueProviders,
  uniqueDrugs,
  filterPreferences: { doctorPreferences, hospitalPreferences, drugPreferences },
  "data-qa": dataQa,
}: PlanDetailsTableProps) => {
  const providersCovered = uniqueProviders.filter(p => p.inNetwork)

  const doctorsFound = providersCovered
    .map(d => doctorPreferences?.find(pref => pref.npi === d.npi))
    .filter((doctorPreference): doctorPreference is DoctorPreference => !!doctorPreference)

  const hospitalsFound = providersCovered
    .map(h => hospitalPreferences?.find(pref => pref.npi === h.npi))
    .filter((hospitalPreference): hospitalPreference is HospitalPreference => !!hospitalPreference)

  const drugsFound = uniqueDrugs.filter(
    (drug): drug is DrugCoverageHasId => drug.tier !== DRUG_TIERS.NOT_COVERED && !!drug.drugPackageId
  )

  const hasDoctors = doctorsFound.length > 0
  const hasHospitals = hospitalsFound.length > 0
  const hasCoverages = drugsFound.length > 0

  const showHoverText =
    [pcpCost, specialistCost, genericDrugCost, urgentCareCost, mentalHealthCost].some(item => item?.includes("*")) ||
    hasDoctors ||
    hasHospitals ||
    hasCoverages

  const isFilteringDoctors = !!doctorPreferences?.length
  const isFilteringHospitals = !!hospitalPreferences?.length
  const isFilteringDrugs = !!drugPreferences?.length

  return (
    <>
      <Table aria-label="plan details table" sx={{ mt: 6 }}>
        <TableBody sx={{ padding: 0 }}>
          {createPlanFeatures([
            ["pcp", "PCP Cost per visit", pcpCost, rawData?.primaryCarePhysician],
            ["specialists", "Specialist Cost per visit", specialistCost, rawData?.specialist],
            ["generic-prescription", "Generic prescription cost", genericDrugCost, rawData?.genericDrugs],
            ["urgent-care", "Urgent Care Cost per visit", urgentCareCost, rawData?.urgentCare],
            ["mental-health", "Mental Health Cost per visit", mentalHealthCost, rawData?.outpatientMentalHealth],
          ]).map(({ name, label, cost, extraData }) => (
            <PlanFeatureSection
              label={label}
              amount={cost}
              extraData={extraData}
              data-qa={createDataQa(dataQa, name)}
              key={name}
            />
          ))}
          {isFilteringDoctors && (
            <PlanFeatureSection
              label="Doctors in network"
              amount={doctorsFound.length}
              extraData={
                hasDoctors &&
                doctorsFound.map(doctorPreference => (
                  <Typography key={doctorPreference.id} gutterBottom>
                    {formatProvider(doctorPreference)}
                  </Typography>
                ))
              }
              data-qa={createDataQa(dataQa, "providers")}
            />
          )}
          {isFilteringHospitals && (
            <PlanFeatureSection
              label="Hospitals in network"
              amount={hospitalsFound.length}
              extraData={
                hasHospitals &&
                hospitalsFound.map(hospitalPreference => (
                  <Typography key={hospitalPreference.id} gutterBottom>
                    {formatProvider(hospitalPreference)}
                  </Typography>
                ))
              }
              data-qa={createDataQa(dataQa, "providers")}
            />
          )}
          {isFilteringDrugs && (
            <PlanFeatureSection
              label="Prescriptions covered"
              amount={drugsFound.length}
              extraData={
                hasCoverages &&
                drugsFound.map(drug => {
                  const foundDrug = drugPreferences?.find(pref => pref.medId.toString() === drug.drugPackageId)

                  return (
                    foundDrug && (
                      <Typography key={drug.drugPackageId} gutterBottom>
                        {foundDrug.description}
                      </Typography>
                    )
                  )
                })
              }
              data-qa={createDataQa(dataQa, "drugs")}
            />
          )}
        </TableBody>
      </Table>
      <Box sx={{ height: "3rem" }}>
        {showHoverText && (
          <Typography variant="caption" data-qa="card-caption">
            * Please hover over the amount value to view more details.
          </Typography>
        )}
      </Box>
    </>
  )
}

export interface PlanCardProps extends WithDataQa {
  plan: Plan
  allowanceCents: Cents
  isAutoPay: boolean
  isSelectingPlan: boolean
  isSelected: boolean
  isSelectingThisPlan: boolean
  isComparing: boolean
  disableCompareButton: boolean
  filterPreferences: FilterPreferences
  handlePlanSelect: () => void
  handleComparePlan: () => void
}

export const PlanCard = ({
  plan,
  allowanceCents,
  "data-qa": dataQa,
  isAutoPay,
  isSelectingPlan,
  isSelected,
  isSelectingThisPlan,
  isComparing,
  disableCompareButton,
  filterPreferences,
  handlePlanSelect,
  handleComparePlan,
  isHorizontalView,
  isMobile,
}: PlanCardProps & { isHorizontalView: boolean; isMobile: boolean }) => {
  const {
    displayName,
    level,
    logoUrl,
    premiumAmountCents,
    individualMedicalDeductibleAmountCents,
    individualMedicalMaxOutOfPocketAmountCents,
    planType,
    carrier,
    primaryCarePhysician,
    specialist,
    genericDrugs,
    urgentCare,
    outpatientMentalHealth,
    rawData,
    onExchange,
    offExchange,
    providers,
    coverages,
  } = plan

  const { doctorPreferences, hospitalPreferences, drugPreferences } = filterPreferences
  const { balanceLabel, balanceValue, balanceLabelColor } = getPlanBalance(allowanceCents, premiumAmountCents)

  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false)
  const levelColor = getColorByMetal(level)
  const cardDataQa = createDataQa(dataQa, "card")

  const uniqueProviders = dedupe(providers)
  const uniqueDrugs = dedupe(coverages)

  const doctorsFound = uniqueProviders
    .map(doctor => {
      const doctorResult = doctorPreferences?.find(pref => pref.npi === doctor.npi)
      return doctorResult ? { ...doctorResult, covered: doctor.inNetwork } : undefined
    })
    .filter((doctorPreference): doctorPreference is WithCoverage<DoctorPreference> => !!doctorPreference)

  const hospitalsFound = uniqueProviders
    .map(hospital => {
      const hospitalResult = hospitalPreferences?.find(pref => pref.npi === hospital.npi)
      return hospitalResult ? { ...hospitalResult, covered: hospital.inNetwork } : undefined
    })
    .filter((hospitalPreference): hospitalPreference is WithCoverage<HospitalPreference> => !!hospitalPreference)

  const drugsFound = uniqueDrugs
    .map(drug => {
      const drugResult = drugPreferences?.find(pref => pref.medId.toString() === drug.drugPackageId)
      return drugResult ? { ...drugResult, covered: drug.tier !== DRUG_TIERS.NOT_COVERED } : undefined
    })
    .filter((drugPreference): drugPreference is WithCoverage<DrugPreference> => !!drugPreference)

  return (
    <>
      <PlanDetailsDrawer
        open={showDetailsDrawer}
        plan={plan}
        isSelectingPlan={isSelectingPlan}
        isComparing={isComparing}
        uniqueDoctors={doctorsFound}
        uniqueHospitals={hospitalsFound}
        uniqueDrugs={drugsFound}
        handleClose={() => setShowDetailsDrawer(false)}
        handlePlanSelect={handlePlanSelect}
        handleComparePlan={handleComparePlan}
      />
      {isHorizontalView ? (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            border: "2px solid",
            borderColor: levelColor,
            borderRadius: "0.5rem",
            backgroundColor: isSelected ? "colors.lightTealShade" : "",
            p: 3,
            m: 1,
          }}
        >
          <Grid item sm={9} xs={12}>
            <Grid item sx={{ mb: 3, pr: 2 }}>
              <Typography data-qa={createDataQa(cardDataQa, "display-name")} variant="h6" gutterBottom>
                {displayName}
              </Typography>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-start" spacing={2} sx={{ mb: 4 }}>
              <Grid item>
                <Typography variant="body2bold" data-qa={createDataQa(dataQa, "monthly-premium-label")}>
                  {PLAN_PREMIUM_LABEL}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: takeCommandPrimary.main, fontWeight: "bold" }}
                  data-qa={createDataQa(dataQa, "monthly-premium-value")}
                >
                  {formatCents(premiumAmountCents)}
                  <PerMonthSpan />
                </Typography>
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ px: 2 }} />
              <Grid item>
                <Typography variant="body2bold" data-qa={createDataQa(dataQa, "deductible-label")}>
                  Deductible
                </Typography>
                <Typography
                  variant="body2"
                  color="colors.lightGrayText"
                  data-qa={createDataQa(dataQa, "deductible-value")}
                  sx={{ fontWeight: "bold" }}
                >
                  {formatCents(individualMedicalDeductibleAmountCents)}
                </Typography>
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ px: 2 }} />
              <Grid item>
                <Typography variant="body2bold" data-qa={createDataQa(dataQa, "allowance-label")}>
                  Max out of pocket
                </Typography>
                <Typography
                  variant="body2"
                  data-qa={createDataQa(dataQa, "allowance-value")}
                  sx={{ fontWeight: "bold" }}
                >
                  {formatCents(individualMedicalMaxOutOfPocketAmountCents)}
                </Typography>
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ px: 2 }} />
              <Grid item>
                <Typography variant="body2bold" data-qa={createDataQa(dataQa, "monthly-balance-label")}>
                  {balanceLabel}
                </Typography>
                <DifferenceTooltip balanceValue={balanceValue} balanceLabelColor={balanceLabelColor} data-qa={dataQa} />
              </Grid>
            </Grid>
            <PlanDetailsChips
              level={level}
              planType={planType}
              data-qa={cardDataQa}
              enrollmentType={carrier.enrollment}
              payLater={carrier.payLater}
              onExchange={onExchange}
              offExchange={offExchange}
              isAutoPay={isAutoPay}
              isHorizontalView={isHorizontalView}
            />
          </Grid>
          <Grid item sx={{ mt: isMobile ? 4 : 0 }} sm={3} xs={12}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Button
                variant="outlined"
                sx={customButtonStyle}
                fullWidth
                data-qa={createDataQa(cardDataQa, "view-details-button")}
                onClick={() => setShowDetailsDrawer(true)}
              >
                View details
              </Button>
              <ComparePlanButton
                dataQa={cardDataQa}
                isComparing={isComparing}
                disabled={disableCompareButton}
                handleClick={handleComparePlan}
                isHorizontalView={isHorizontalView}
              />
              <ChoosePlanButton
                dataQa={createDataQa(cardDataQa, "choose-plan-button")}
                isSelectingPlan={isSelectingPlan}
                isSelectingThisPlan={isSelectingThisPlan}
                handleClick={handlePlanSelect}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} sm={6} xl={4}>
          <Card
            data-qa={cardDataQa}
            sx={{
              border: "2px solid",
              borderColor: levelColor,
              height: "100%",
              borderRadius: "0.5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: isSelected ? "colors.lightTealShade" : "",
            }}
          >
            <CardHeader
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingBottom: 0,
              }}
              avatar={
                <CarrierLogo
                  carrierName={carrier.name}
                  logoUrl={logoUrl}
                  sx={{ height: "3.125rem", mb: 2, p: "1rem 0.5rem" }}
                  data-qa={createDataQa(cardDataQa, "logo")}
                />
              }
              title={
                <Typography data-qa={createDataQa(cardDataQa, "display-name")} variant="h6" gutterBottom>
                  {displayName}
                </Typography>
              }
            />
            <CardContent>
              <PlanMonthlyInformation
                allowanceCents={allowanceCents}
                premiumAmountCents={premiumAmountCents}
                deductibleCents={individualMedicalDeductibleAmountCents}
                maxOutOfPocketCents={individualMedicalMaxOutOfPocketAmountCents}
                data-qa={cardDataQa}
              />
              <PlanDetailsChips
                level={level}
                planType={planType}
                data-qa={cardDataQa}
                enrollmentType={carrier.enrollment}
                payLater={carrier.payLater}
                onExchange={onExchange}
                offExchange={offExchange}
                isAutoPay={isAutoPay}
              />
              <PlanDetailsTable
                pcpCost={primaryCarePhysician}
                specialistCost={specialist}
                genericDrugCost={genericDrugs}
                urgentCareCost={urgentCare}
                mentalHealthCost={outpatientMentalHealth}
                rawData={rawData}
                uniqueProviders={uniqueProviders}
                uniqueDrugs={uniqueDrugs}
                data-qa={cardDataQa}
                filterPreferences={filterPreferences}
              />
              <Grid container spacing={2} sx={{ mt: 6 }}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    sx={customButtonStyle}
                    fullWidth
                    data-qa={createDataQa(cardDataQa, "view-details-button")}
                    onClick={() => setShowDetailsDrawer(true)}
                  >
                    View details
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <ComparePlanButton
                    dataQa={cardDataQa}
                    isComparing={isComparing}
                    disabled={disableCompareButton}
                    handleClick={handleComparePlan}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ChoosePlanButton
                    dataQa={createDataQa(cardDataQa, "choose-plan-button")}
                    isSelectingPlan={isSelectingPlan}
                    isSelectingThisPlan={isSelectingThisPlan}
                    handleClick={handlePlanSelect}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  )
}

interface PlanCardsContainerProps {
  isSuccess: boolean
  isError: boolean
  currentPlans: Plan[]
  plansToCompare: Plan[]
  dataQa: DataQa
  handleComparePlan: (plan: Plan) => void
  planView: PlanView
  filterPreferences: FilterPreferences
}

const PlanCardsContainer = ({
  isSuccess,
  isError,
  currentPlans,
  plansToCompare,
  dataQa,
  handleComparePlan,
  planView,
  filterPreferences,
  isHorizontalView,
  isMobile,
}: PlanCardsContainerProps & { isHorizontalView: boolean; isMobile: boolean }) => {
  const { user } = useAuth()
  const companyId = user?.company?.companyId as Uuid
  const { isAutoPay } = useIsCompanyAutoPay(companyId)
  const selectedPlan = useBenefitsElectionStore(state => state.selectedPlan)
  const allowance = useBenefitsElectionStore(state => state.allowance)
  const amountOfPlans = currentPlans.length
  const amountOfPlansToCompare = plansToCompare.length
  const { isSelectingPlan, clickedPlanId, selectPlan } = useSelectPlan(planView)
  const noPlansFound = isSuccess && amountOfPlans === 0

  if (noPlansFound || isError) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "40vh",
        }}
      >
        <Typography sx={{ px: 8 }}>
          {noPlansFound
            ? "There are no available plans based on your current filters and location."
            : "An error occurred while retrieving your plans. Please try again later."}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid item container spacing={4} sx={{ mb: 4 }}>
      {currentPlans?.map(plan => (
        <PlanCard
          key={plan.id}
          plan={plan}
          // SAFETY: This assertion cannot fail because planned steps are not reachable without going through Family and having an allowance selected.
          allowanceCents={allowance!.amountCents}
          handlePlanSelect={() => selectPlan(plan)}
          handleComparePlan={() => handleComparePlan(plan)}
          isSelectingPlan={isSelectingPlan}
          isSelected={selectedPlan?.id === plan.id}
          isSelectingThisPlan={clickedPlanId === plan.id}
          isComparing={plansToCompare.map(planToCompare => planToCompare.id).includes(plan.id)}
          disableCompareButton={amountOfPlansToCompare >= 4}
          data-qa={dataQa}
          isAutoPay={isAutoPay}
          filterPreferences={filterPreferences}
          isHorizontalView={isHorizontalView}
          isMobile={isMobile}
        />
      ))}
    </Grid>
  )
}

export interface PlanGridProps extends WithDataQa {
  planView: PlanView
  searchPlansState: ReturnType<typeof useSearchPlansState>
}

const stringifyHsaEligible = (isHsaEligible: YesOrNo) =>
  valueIsYes(isHsaEligible) ? "Eligible for HSA savings" : "Not eligible for HSA savings"

const stringifyFilters = ({
  preferredPlanTypes,
  carrierPreferences,
  categories,
  isHsaEligible,
  enrollmentTypes,
}: FilterPreferences) =>
  [
    carrierPreferences.map(x => x.name),
    preferredPlanTypes,
    categories.map(x => toTitleCase(x)),
    isHsaEligible !== "" && stringifyHsaEligible(isHsaEligible),
    enrollmentTypes.map(x => toTitleCase(x)),
  ]
    .flat()
    .filter(e => !!e)
    .join(", ")

export const PlanGrid = ({
  "data-qa": dataQa,
  planView,
  searchPlansState: {
    currentPlans,
    isLoading,
    isSuccess,
    isError,
    showLoading,
    filterPreferences,
    currentSortOption,
    page,
    plansPerPage,
    totalDisplayedPlans,
    foundNoPlans,
    setPlansPerPage,
    setPage,
    handleSort,
    persistPlansState,
  },
}: PlanGridProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const plansToCompare = useBenefitsElectionStore(state => state.plansToCompare)
  const setPlansToCompare = useBenefitsElectionStore(state => state.setPlansToCompare)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const personalInformation = useBenefitsElectionStore(state => state.employee.personalInformation)
  const amountOfPlansToCompare = plansToCompare.length
  const storedZipCode = personalInformation?.zipCode
  const { value: maPlanBanner } = useFeatureFlag("maPlanBanner", false)
  const { user } = useAuth()
  const materialTheme = useTheme()
  const isMobile = useMediaQuery(materialTheme.breakpoints.down("sm"))

  const planYear = user?.shoppingSession?.planYear ?? new Date().getFullYear()

  const [isHorizontalView, setIsHorizontalView] = useState(false)

  const toggleView = (isHorizontal: boolean) => {
    setIsHorizontalView(isHorizontal)
  }

  const handleComparePlan = (plan: Plan) => {
    const isPlanContained = plansToCompare.map(planToCompare => planToCompare.id).includes(plan.id)

    if (isPlanContained) {
      setPlansToCompare(plansToCompare.filter(planToCompare => planToCompare.id !== plan.id))
    } else if (amountOfPlansToCompare < 4 && !isPlanContained) {
      setPlansToCompare([...plansToCompare, plan])
    }
  }

  const handleCancelComparison = () => {
    setPlansToCompare([])
  }

  useEffect(() => {
    if (!storedZipCode?.fipsCode || !storedZipCode?.state || !storedZipCode?.zipCode) {
      console.error(`Found inconsistent ZIP Code in plans page: ${JSON.stringify(storedZipCode)}`)
      setCurrentStep(PERSONAL_INFO)
    }
  }, [storedZipCode, setCurrentStep])

  return (
    <>
      <Grid container justifyContent="space-between" spacing={6} mb={amountOfPlansToCompare > 0 ? 12 : 0}>
        {foundNoPlans && !isLoading && personalInformation?.zipCode?.state !== "MA" && (
          <Grid item xs={12}>
            <Alert severity="info" data-qa="no-plans-alert">
              We couldn't find any plans that match all of your current filters: [{stringifyFilters(filterPreferences)}
              ]. All available plans are displayed below. Adjust your filters to refine your search further.
            </Alert>
          </Grid>
        )}
        {maPlanBanner &&
          foundNoPlans &&
          !isLoading &&
          personalInformation?.zipCode?.state === "MA" &&
          planYear === 2025 && (
            <Grid item xs={12}>
              <Alert severity="info" data-qa="ma-state-alert">
                There are additional plans available for the state of Massachusetts on the HSA website.{" "}
                <Link
                  href="https://www.hsainsurance.com/Broker/ICHRA/Welcome.aspx?ac=5461&bc=00&PC=418286"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to view.
                </Link>
              </Alert>
            </Grid>
          )}
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant="outlined"
            label="Search by plan name"
            fullWidth
            disabled={isLoading}
            data-qa={createDataQa(dataQa, "search")}
            value={searchParams.get("search") ?? ""}
            onChange={evt => {
              const search = evt.target.value ?? ""
              setSearchParams({ search })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SortFilterControls
            isLoading={isLoading}
            sortOption={currentSortOption}
            handleSort={handleSort}
            data-qa={dataQa}
            isHorizontalView={isHorizontalView}
            toggleView={toggleView}
            isMobile={isMobile}
          />
        </Grid>

        {showLoading ? (
          <LoadingGrid />
        ) : (
          <PlanCardsContainer
            isSuccess={isSuccess}
            isError={isError}
            dataQa={dataQa}
            currentPlans={currentPlans}
            plansToCompare={plansToCompare}
            handleComparePlan={handleComparePlan}
            planView={planView}
            filterPreferences={filterPreferences}
            isHorizontalView={isHorizontalView}
            isMobile={isMobile}
          />
        )}

        <Grid item xs={12}>
          <TablePagination
            labelRowsPerPage="Plans per page"
            rowsPerPageOptions={[3, 6, 9]}
            component="div"
            count={totalDisplayedPlans}
            rowsPerPage={plansPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={event => {
              const newPlansPerPage = event.target.value

              setPlansPerPage(Number(newPlansPerPage))
              setPage(0)
            }}
            data-qa={createDataQa(dataQa, "pagination")}
          />
        </Grid>
      </Grid>

      <ComparePlansFooter
        plansToCompare={plansToCompare}
        handleDeletePlan={handleComparePlan}
        handleCancelComparison={handleCancelComparison}
        persistPlansState={persistPlansState}
      />
    </>
  )
}
