import { DateTime } from "luxon"
import { FIVE_PERCENT } from "../TCHub/tcHubConstants"

export const formatDateRange = (startDateISO: string) => {
  const startDate = DateTime.fromISO(startDateISO)
  const endDate = startDate.endOf("month")

  const formattedStartDate = startDate.toLocaleString({
    month: "short",
    day: "numeric",
  })

  const formattedEndDate = endDate.toLocaleString({
    month: "short",
    day: "numeric",
    year: "numeric",
  })

  return `${formattedStartDate} - ${formattedEndDate}`
}

// Calculate minimum reserve amount based on 5% of the total premiums for a time period
export const calculateMinimumReserveAmount = (recurringPremiums: any[] | undefined) => {
  if (!Array.isArray(recurringPremiums) || recurringPremiums.length === 0) {
    return 0
  }

  const totalPremiumsCents = recurringPremiums
    .map(premium => premium.premiumAmountCents)
    .reduce((total: number, amount: number) => total + amount, 0)

  const minimumReserveRequired = totalPremiumsCents * FIVE_PERCENT

  return minimumReserveRequired
}
