import { useAuth } from "@/features/Auth/useAuth"
import { SHOPPING } from "@/features/BenefitsElection/benefitsElectionConstants"
import { useOnboardingStatuses } from "@/features/EmployerOnboarding/employerOnboardingService"
import { ACCOUNTS_ACCESS_HRAS } from "@/roles_permissions"
import { useAsyncRetry } from "@/utils/useAsyncRetry"
import { useLocation, useNavigate } from "react-router-dom"
import { usePermissions } from "./permissionsService"

export class UnsafeRedirectError extends Error {
  constructor() {
    super("Redirect function cannot be called while statuses are pending")
  }
}

export const useLoginRedirect = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { user, isLoggedIn } = useAuth()
  const isEmployer = usePermissions(ACCOUNTS_ACCESS_HRAS.id)

  const {
    isPending,
    isAccountComplete,
    isPlanSetupComplete,
    isPlanStructureComplete,
    isEmployerOnboarding,
    isAssisted,
    isAdp,
  } = useOnboardingStatuses()

  const shoppingSession = user?.shoppingSession

  const redirect = useAsyncRetry(() => {
    const target = location.state?.from ?? "/"

    if (isPending || !isLoggedIn) {
      throw new UnsafeRedirectError()
    }

    if (shoppingSession?.shoppingStatus === SHOPPING) {
      navigate(`/benefits-election/${shoppingSession.id}/welcome`)

      return
    }

    console.info("User is not shopping")
    console.info(user)

    if (isEmployer) {
      if (isAccountComplete) {
        if ((!isPlanSetupComplete || !isPlanStructureComplete) && isAssisted) {
          navigate("/pending-plan-setup")

          return
        }
        if (!isPlanSetupComplete) {
          if (isAdp) {
            navigate("/adp/create-company/plan-setup")
            return
          }
          navigate("/create-company/plan-setup")

          return
        }
        if (!isPlanStructureComplete) {
          if (isAdp) {
            navigate("/adp/create-company/plan-structure")
            return
          }
          navigate("/create-company/plan-structure")

          return
        }
      }

      if (isEmployerOnboarding) {
        navigate("/employer-onboarding")

        return
      }
    }

    navigate(target)
  })

  return { isPending, redirect }
}
