import { appConfig } from "@/config"
import { HrisConfig } from "@/features/Hris/hrisEndpoints"
import { HRIS_STATUS_CONNECTED, HRIS_STATUS_ERROR, HRIS_STATUS_PROCESSING } from "@/features/Hris/hrisTypes"
import { colors, takeCommandPrimary } from "@/theme/palette"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Button, Typography } from "@mui/material"
import { useFinchConnect } from "@tryfinch/react-connect"
import { useState } from "react"

const getBackgroundColor = ({ status, isLoading, isOpen }: { status: string; isLoading: boolean; isOpen: boolean }) => {
  if (status === HRIS_STATUS_ERROR) {
    return takeCommandPrimary.main
  }
  if (status === HRIS_STATUS_CONNECTED || status === HRIS_STATUS_PROCESSING) {
    return colors.lightGreyButton
  }

  return takeCommandPrimary.main
}

const getButtonLabel = ({ status, isOpen, isLoading }: { status: string; isLoading: boolean; isOpen: boolean }) => {
  if (isOpen || status === HRIS_STATUS_PROCESSING) {
    return "Processing..."
  }
  if (isLoading) {
    return "Connecting..."
  }
  if (status === HRIS_STATUS_ERROR) {
    return "Reconnect"
  }
  if (status === HRIS_STATUS_CONNECTED) {
    return "Reconnect"
  }

  return "Connect"
}

export const HrisConnectButton = ({
  hrisConfig,
  onPublicToken,
  isLoading,
}: {
  hrisConfig: HrisConfig
  isLoading: boolean
  onPublicToken: (publicToken: string) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { open } = useFinchConnect({
    clientId: appConfig.hrisClientId,
    products: ["company", "directory", "individual", "employment", "payment", "pay_statement", "benefits"],

    sandbox: appConfig.isProduction ? false : "provider",
    onSuccess: ({ code }) => {
      onPublicToken(code)
      setIsOpen(false)
    },
    onError: ({ errorMessage }) => {
      console.error(errorMessage)
      setIsOpen(false)
    },
    onClose: () => {
      setIsOpen(false)
    },
    zIndex: 99999,
  })

  const shouldBeDisabled = hrisConfig?.status === HRIS_STATUS_PROCESSING

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={isLoading || shouldBeDisabled}
      data-qa="hris-connect-button"
      sx={{
        backgroundColor: getBackgroundColor({
          status: hrisConfig.status,
          isLoading,
          isOpen,
        }),
      }}
      onClick={e => {
        e.preventDefault()
        open()
        setIsOpen(true)
      }}
      endIcon={<OpenInNewIcon />}
    >
      <Typography variant="body1">
        {getButtonLabel({
          status: hrisConfig.status,
          isLoading,
          isOpen,
        })}
      </Typography>
    </Button>
  )
}
