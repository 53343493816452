import {
  AmountTextField,
  AmountTextFieldWithChips,
  HoursPerWeekAmountTextField,
  SingleEmployeeAmountTextFieldWithTable,
} from "@/components/AmountTextField"
import { CheckBoxGroupCard } from "@/components/CheckBoxGroupCard"
import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { AmountAgesTable } from "@/features/CreateCompany/components/common/AmountAgesTable"
import { Stepper } from "@/features/CreateCompany/components/Stepper"
import { useFormValuesWithCustomClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/classesStore"
import { CustomClasses } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/CustomClasses"
import {
  useCreatePlanStructure,
  useGetCurrentClasses,
  useUpdatePlanStructure,
} from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import {
  CustomClassDataWithoutDeleted,
  PlanStructureFormValues,
  PlanStructurePatchRequest,
} from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import {
  buildCustomClassesPatchRequest,
  hraReimbursementStructureElements,
  hraVaryByFamilySizeOrAgeElements,
} from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureUtils"
import {
  ALL_EMPLOYEES,
  ELIGIBLE_FOR_REIMBURSEMENT,
  PREMIUM_ONLY,
  SEASONALITY_RANGES,
  VARY_BY_AGE,
  VARY_BY_FAMILY,
  VARY_BY_FAMILY_SIZE,
  VARY_BY_FAMILY_SIZE_AND_AGE,
  WAITING_PERIODS,
} from "@/features/CreateCompany/createCompanyConstants"
import { useCreateCompanyAccount, useCreateCompanyHraPlan } from "@/features/CreateCompany/CreateCompanyProvider"
import { PurchaseHraStepProps } from "@/features/CreateCompany/pages/CreateCompany"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { AssistedPlanStructureValidationSchema } from "@/utils/validations"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Alert, Collapse, Grid, Link, Stack, Typography } from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

export interface EligibleForReimbursementRadioCardProps {
  subsection?: string
  value: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const EligibleForReimbursementRadioCard = ({
  subsection,
  value,
  setFieldValue,
}: EligibleForReimbursementRadioCardProps) => (
  <Grid item xs={12}>
    <RadioGroupCard
      formName={`plan-structure${subsection && "-" + subsection}`}
      name="eligibleForReimbursement"
      value={value}
      handleChange={setFieldValue}
      elements={ELIGIBLE_FOR_REIMBURSEMENT}
    />
  </Grid>
)

export const WaitingPeriodRadioCard = ({
  subsection,
  value,
  setFieldValue,
  small,
}: {
  subsection?: string
  value: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  small?: boolean
}) => (
  <Grid item xs={12}>
    <RadioGroupCard
      formName={`plan-structure${subsection && "-" + subsection}`}
      name="waitingPeriod"
      value={value}
      handleChange={setFieldValue}
      elements={WAITING_PERIODS}
    />
  </Grid>
)

interface InclusionCheckBoxCardProps {
  elements: { title: string; value: string }[]
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void
  subsection?: string
  small?: boolean
}

export const InclusionCheckBoxCard = ({ subsection, setFieldValue, small, elements }: InclusionCheckBoxCardProps) => (
  <Grid item xs={12} mt={5} mb={10}>
    <CheckBoxGroupCard
      formName={createDataQa("plan-structure", subsection)}
      handleChange={setFieldValue}
      small={small}
      elements={elements}
      error={false}
    />
  </Grid>
)

export const PLAN_STRUCTURE_INITIAL_VALUES = {
  reimbursementStructure: "",
  eligibleForReimbursement: PREMIUM_ONLY,
  waitingPeriod: "",
  varyByFamilySizeOrAge: "",
  employeeAmount: "0",
  employeeAndChildrenAmount: "0",
  employeeAndSpouseAmount: "0",
  employeeAndSpouseAndChildrenAmount: "0",
  hoursPerWeekAmountLetMeCreate: "",
  monthsPerYearSelectionLetMeCreate: "",
  submit: "false",
}

const hasErrors = (touched: { [field: string]: boolean }, errors: { [field: string]: string }) =>
  Object.keys(touched).some(field => errors[field])

export const TcHubPlanStructure = ({ stepData }: PurchaseHraStepProps) => {
  const navigate = useNavigate()
  const { company } = useCreateCompanyAccount()
  const { hraPlan } = useCreateCompanyHraPlan()
  const { mutateAsync: createPlanStructure } = useCreatePlanStructure(company?.id, hraPlan.id)
  const { mutateAsync: updatePlanStructure } = useUpdatePlanStructure(company?.id, hraPlan.id)
  const { data } = useGetCurrentClasses(company?.id, hraPlan.id)
  const classesStoreKey = `${company?.id}-classes`

  const {
    initialFormValues,
    customClassData,
    clearCustomClassesFromStore,
    addCustomClassToStore,
    deleteCustomClassesFromStore,
    loadedClassesType,
    deletedClasses,
  } = useFormValuesWithCustomClasses({ data, hraPlan, classesStoreKey })

  const [isLoading, setIsLoading] = useState(false)
  const [agesTableOpen, setAgesTableOpen] = useState(false)
  const { notify: notifyCreate } = useNotifications("create-plan-structure-classes")
  const { notify: notifyUpdate } = useNotifications("update-plan-structure-classes")

  const createNewClasses = async (values: PlanStructureFormValues) => {
    if (values.reimbursementStructure === "CUSTOM") {
      await createPlanStructure({
        values: null,
        customclassValues: {
          seasonalMonthQualification: Number(values.monthsPerYearSelectionLetMeCreate),
          partTimeHourQualification: Number(values.hoursPerWeekAmountLetMeCreate),
          customClassData,
        },
      })
      clearCustomClassesFromStore()
    } else if (values.reimbursementStructure === ALL_EMPLOYEES || values.reimbursementStructure === VARY_BY_FAMILY) {
      console.info("Creating Plan Structure for company: ", company?.id, " and plan: ", hraPlan.id)
      await createPlanStructure({
        values,
        customclassValues: null,
      })
    } else {
      throw Error("Invalid Reimbursement Structure")
    }
    notifyCreate("Successfully created Plan Structure classes", "success")
    navigate("/admin/companies")
  }

  const showUpdateSuccessMessage = ({
    successMessage = "Successfully updated Plan Structure classes",
  }: {
    successMessage?: string
  }) => {
    notifyUpdate(successMessage, "success")
    navigate("/admin/companies")
  }

  const updateCustomClasses = async (patchRequest: PlanStructurePatchRequest) => {
    if (patchRequest) {
      await updatePlanStructure(patchRequest)
      clearCustomClassesFromStore()
      showUpdateSuccessMessage({})
    } else {
      showUpdateSuccessMessage({
        successMessage: "No changes made to Plan Structure Custom Classes",
      })
    }
  }

  /**
   * Updates the plan structure based on four cases:
   * 1. A fixed class was updated.
   * 2. Custom classes were deleted, and a fixed class was created.
   * 3. Custom classes were updated.
   * 4. A fixed class was deleted, and custom classes were created.
   * @param values Form values
   */
  const updateClasses = async (values: PlanStructureFormValues) => {
    if (values.reimbursementStructure === ALL_EMPLOYEES || values.reimbursementStructure === VARY_BY_FAMILY) {
      // Case 1: Fixed class was updated
      if (loadedClassesType === "FIXED") {
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "UPDATE",
        })

        await updatePlanStructure(patchRequest!)
        showUpdateSuccessMessage({})
      }
      // Case 2: Custom classes were deleted, and a fixed class was created.
      else if (loadedClassesType === "CUSTOM") {
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "CREATE",
          loadedData: data,
        })

        await updatePlanStructure(patchRequest!)
        clearCustomClassesFromStore()
        showUpdateSuccessMessage({})
      }
    } else if (values.reimbursementStructure === "CUSTOM") {
      // Case 3: Custom classes were updated
      if (loadedClassesType === "CUSTOM") {
        const deletedClassesArray = deletedClasses ? (deletedClasses as string[]) : []

        const patchRequest = buildCustomClassesPatchRequest({
          customClassData,
          deletedClasses: deletedClassesArray,
          loadedData: data,
        })

        await updateCustomClasses(patchRequest!)
      }
      // A fixed class was deleted, and custom classes were created.
      else if (loadedClassesType === "FIXED") {
        // Implement the logic for this case
        const patchRequest = buildCustomClassesPatchRequest({
          fixedClassData: values,
          fixedClassOperation: "DELETE",
          customClassData,
        })

        await updatePlanStructure(patchRequest!)
        clearCustomClassesFromStore()
        showUpdateSuccessMessage({})
      }
    } else {
      throw Error("Invalid Reimbursement Structure")
    }
  }

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      validationSchema={AssistedPlanStructureValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setIsLoading(true)
        const isUpdate = !!loadedClassesType

        const errorMessage = isUpdate
          ? `Error Updating classes for company ${company?.id}`
          : `Error Creating classes for company ${company?.id}`

        try {
          await (isUpdate ? updateClasses(values) : createNewClasses(values))
        } catch (error: any) {
          console.error(error)
          setStatus({ success: false })
          setSubmitting(false)
          notifyCreate(errorMessage, "error")
        } finally {
          setIsLoading(false)
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isValid,
        dirty,
        values,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        resetForm,
      }) => (
        <>
          <Stepper steps={stepData} activeStepIndex={2} isError={hasErrors(touched, errors)} />
          <form noValidate onSubmit={handleSubmit} data-qa="create-company-setup-form">
            {errors.submit && (
              <Alert severity="warning" data-qa="error-banner-create-company-setup-form">
                {errors.submit}
              </Alert>
            )}
            <Grid container spacing={4} mt={5}>
              <Grid item xs={12}>
                <Typography variant="h1" mb={5}>
                  Plan Structure
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">How do you want to structure your reimbursements?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">You will only reimburse what employees actually spend.</Typography>
              </Grid>
              <RadioGroupCard
                formName="plan-structure"
                name="reimbursementStructure"
                value={values.reimbursementStructure}
                handleChange={(field: string, value: string) => {
                  resetForm()
                  setFieldValue(field, value)
                }}
                elements={hraReimbursementStructureElements}
              />
            </Grid>

            <Grid item>
              <Collapse
                in={values.reimbursementStructure === ALL_EMPLOYEES}
                timeout="auto"
                orientation="vertical"
                translate="yes"
                unmountOnExit
              >
                <>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Reimbursement amount per month</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <AmountTextField
                        name="employeeAmount"
                        touched={touched.employeeAmount!}
                        errorString={errors.employeeAmount!}
                        error={Boolean(touched.employeeAmount && errors.employeeAmount)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.employeeAmount?.toString()}
                        label="Employee"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Waiting Period</Typography>
                    </Grid>
                    <WaitingPeriodRadioCard
                      subsection={ALL_EMPLOYEES}
                      value={values.waitingPeriod}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </>
              </Collapse>
              <Collapse
                in={values.reimbursementStructure === VARY_BY_FAMILY}
                timeout="auto"
                orientation="vertical"
                translate="yes"
                unmountOnExit
              >
                <>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="caption">Select one.</Typography>
                    </Grid>
                    <RadioGroupCard
                      formName="plan-structure"
                      name="varyByFamilySizeOrAge"
                      value={values.varyByFamilySizeOrAge}
                      handleChange={(field: string, value: string) => {
                        resetForm()
                        setFieldValue("reimbursementStructure", VARY_BY_FAMILY)
                        setFieldValue(field, value)
                      }}
                      elements={hraVaryByFamilySizeOrAgeElements}
                    />
                  </Grid>
                  <Collapse
                    in={values.varyByFamilySizeOrAge === VARY_BY_AGE}
                    timeout="auto"
                    orientation="vertical"
                    translate="yes"
                    unmountOnExit
                  >
                    <>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Reimbursement amounts per month</Typography>
                          <SingleEmployeeAmountTextFieldWithTable
                            errorString={errors.employeeAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Employee - Age 21"
                            agesTableOpen={agesTableOpen}
                            setAgesTableOpen={setAgesTableOpen}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Waiting period</Typography>
                        </Grid>
                        <WaitingPeriodRadioCard
                          subsection={VARY_BY_AGE}
                          value={values.waitingPeriod}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </>
                  </Collapse>

                  <Collapse
                    in={values.varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE}
                    timeout="auto"
                    orientation="vertical"
                    translate="yes"
                    unmountOnExit
                  >
                    <>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Reimbursement amounts per month</Typography>
                          <Typography variant="caption">
                            Amounts entered for each group are total monthly costs for the entire group, not per person.
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <AmountTextField
                            error={Boolean(touched.employeeAmount && errors.employeeAmount)}
                            touched={touched.employeeAmount!}
                            errorString={errors.employeeAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employeeAmount?.toString()}
                            label="Employee - Age 21"
                            name="employeeAmount"
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <AmountTextField
                            error={Boolean(touched.employeeAndSpouseAmount && errors.employeeAndSpouseAmount)}
                            touched={touched.employeeAndSpouseAmount!}
                            errorString={errors.employeeAndSpouseAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employeeAndSpouseAmount?.toString()}
                            label="Employee + Spouse"
                            name="employeeAndSpouseAmount"
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <AmountTextField
                            error={Boolean(touched.employeeAndChildrenAmount && errors.employeeAndChildrenAmount)}
                            touched={touched.employeeAndChildrenAmount!}
                            errorString={errors.employeeAndChildrenAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employeeAndChildrenAmount?.toString()}
                            label="Employee + Children"
                            name="employeeAndChildrenAmount"
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <AmountTextField
                            error={Boolean(
                              touched.employeeAndSpouseAndChildrenAmount && errors.employeeAndSpouseAndChildrenAmount
                            )}
                            touched={touched.employeeAndSpouseAndChildrenAmount!}
                            errorString={errors.employeeAndSpouseAndChildrenAmount!}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employeeAndSpouseAndChildrenAmount?.toString()}
                            label="Employee + Spouse + Children"
                            name="employeeAndSpouseAndChildrenAmount"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Waiting period</Typography>
                        </Grid>
                        <WaitingPeriodRadioCard
                          subsection={VARY_BY_FAMILY_SIZE}
                          value={values.waitingPeriod}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </>
                  </Collapse>
                  <Collapse
                    in={values.varyByFamilySizeOrAge === VARY_BY_FAMILY_SIZE_AND_AGE}
                    timeout="auto"
                    orientation="vertical"
                    translate="yes"
                    unmountOnExit
                  >
                    <>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6" data-qa="varybyfamilysizeandage-reimbursement-amounts">
                            Reimbursement amounts per month
                          </Typography>
                          <Typography variant="caption">
                            Amounts entered for each group are total monthly costs for the entire group, not per person.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex">
                          <Typography variant="caption" data-qa="varybyfamilysizeandage-reimbursement-description">
                            Here you can see your generated reimbursement roles by age:
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={2}
                            display="flex"
                            ml="auto"
                            alignItems="center"
                            sx={{ cursor: "pointer" }}
                            onClick={() => setAgesTableOpen(!agesTableOpen)}
                          >
                            {agesTableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            <Link ml="auto" alignItems="center" color="black">
                              <Typography variant="body2" alignItems="center">
                                Expand
                              </Typography>
                            </Link>
                          </Stack>
                        </Grid>
                        <AmountAgesTable agesTableOpen={agesTableOpen} isAgeAndFamily />
                        <AmountTextFieldWithChips
                          touched={touched.employeeAmount!}
                          errorString={errors.employeeAmount!}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.employeeAmount?.toString()}
                          label="Employee - Age 21"
                          name="employeeAmount"
                        />
                        <AmountTextFieldWithChips
                          touched={touched.employeeAndSpouseAmount!}
                          errorString={errors.employeeAndSpouseAmount!}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.employeeAndSpouseAmount?.toString()}
                          label="Employee + Spouse"
                          name="employeeAndSpouseAmount"
                        />
                        <AmountTextFieldWithChips
                          touched={touched.employeeAndChildrenAmount!}
                          errorString={errors.employeeAndChildrenAmount!}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.employeeAndChildrenAmount?.toString()}
                          label="Employee + Children"
                          name="employeeAndChildrenAmount"
                        />
                        <AmountTextFieldWithChips
                          touched={touched.employeeAndSpouseAndChildrenAmount!}
                          errorString={errors.employeeAndSpouseAndChildrenAmount!}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.employeeAndSpouseAndChildrenAmount?.toString()}
                          label="Employee + Spouse + Children"
                          name="employeeAndSpouseAndChildrenAmount"
                        />
                      </Grid>
                      <Grid container spacing={4} mt={5}>
                        <Grid item xs={12}>
                          <Typography variant="h6" data-qa="varybyfamilysizeandage-waiting-period">
                            Waiting period
                          </Typography>
                        </Grid>
                        <WaitingPeriodRadioCard
                          subsection={VARY_BY_FAMILY_SIZE_AND_AGE}
                          value={values.waitingPeriod}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </>
                  </Collapse>
                </>
              </Collapse>
              <Collapse
                in={values.reimbursementStructure === "CUSTOM"}
                timeout="auto"
                orientation="vertical"
                translate="yes"
                unmountOnExit
              >
                <>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6" data-qa="plan-structure-custom-help-label">
                        Next, we'll help you design your employee classes and reimbursement structure for each class.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" data-qa="plan-structure-custom-help-description">
                        You will need to design your classes so that each employee can only fall into one class. Don't
                        worry, we'll help you make sure your classes are compliant!
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomClasses
                        customClasses={customClassData as CustomClassDataWithoutDeleted}
                        createCustomClass={addCustomClassToStore}
                        deleteCustomClasses={deleteCustomClassesFromStore}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6" data-qa="plan-structure-custom-hours-label">
                        How many hours per week must employees be scheduled to work to be considered full-time?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" data-qa="plan-structure-custom-hours-description">
                        This information helps us distinguish between part-time and full-time employees.
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <HoursPerWeekAmountTextField
                        name="hoursPerWeekAmountLetMeCreate"
                        touched={touched.hoursPerWeekAmountLetMeCreate!}
                        errorString={errors.hoursPerWeekAmountLetMeCreate!}
                        error={Boolean(touched.hoursPerWeekAmountLetMeCreate && errors.hoursPerWeekAmountLetMeCreate)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.hoursPerWeekAmountLetMeCreate}
                        label="Hours per week"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        Please enter the amount of hours considered to be full-time
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} mt={5}>
                    <Grid item xs={12} mt={5}>
                      <Typography variant="h6" data-qa="plan-structure-custom-months-label">
                        How many months per year must employees be scheduled to work to be considered non-seasonal?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" data-qa="plan-structure-custom-months-description">
                        This information helps us distinguish between seasonal and non-seasonal employees.
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField
                        dataQa="months-per-year-option"
                        data={SEASONALITY_RANGES}
                        type="text"
                        name="monthsPerYearSelectionLetMeCreate"
                        label="Months per year"
                        required
                        value={values.monthsPerYearSelectionLetMeCreate}
                        placeholder="Please select"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        Please select the range of months considered to be non-seasonal
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              </Collapse>

              <FlowNavigationButtons
                handleBack={() => {
                  navigate("/admin/create-company/plan-setup")
                }}
                type="submit"
                disabled={!(isValid && dirty)}
                isSubmitting={isLoading}
              />
            </Grid>
          </form>
        </>
      )}
    </Formik>
  )
}
