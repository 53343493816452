import { COMPLETE, INCOMPLETE } from "@/constants"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { FormWrapper } from "@/features/CreateCompany/pages/CreateCompany"
import { Grid } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { StepData } from "../../CreateCompany/components/Stepper"
import { getHraPlan } from "../../CreateCompany/components/Steps/Setup/PlanSetup/planSetupEndpoints"
import { PlanStructure } from "../../CreateCompany/components/Steps/Setup/PlanStructure/PlanStructure"
import { ACCOUNT, PLAN_SETUP, PLAN_STRUCTURE, PURCHASE_HRA_STEPS } from "../../CreateCompany/createCompanyConstants"
import { CompanyOnboardingStatus, getCompany, getOnboardingStatus } from "../../CreateCompany/createCompanyEndpoints"
import {
  useCreateCompanyAccount,
  useCreateCompanyHraPlan,
  useInitializeCreateCompany,
} from "../../CreateCompany/CreateCompanyProvider"
import { PurchaseHraStep } from "../../CreateCompany/createCompanyTypes"
import { PurchaseHraLayout } from "../../CreateCompany/layouts/PurchaseHRALayout"
import { AdpPlanSetup } from "../Steps/AdpPlanSetup"
import { AdpSetup } from "../Steps/AdpSetup"

export type CreateCompanyStepData = StepData<PurchaseHraStep>

export interface PurchaseHraStepProps {
  setErrors?: Dispatch<SetStateAction<boolean>>
  stepData: CreateCompanyStepData[]
}

const renderStep = (currentStep: PurchaseHraStep, stepData: CreateCompanyStepData[]) => {
  switch (currentStep) {
    case ACCOUNT:
      return <AdpSetup stepData={stepData} />
    case PLAN_SETUP:
      return <AdpPlanSetup stepData={stepData} />
    case PLAN_STRUCTURE:
      return <PlanStructure stepData={stepData} />
    default:
      return <Navigate to="/adp/create-company/account" />
  }
}

const isPurchaseHraStep = (step: string): step is PurchaseHraStep => PURCHASE_HRA_STEPS.some(e => e === step)

interface Params extends Record<string, string | undefined> {
  step: string
}

const useStep = () => {
  const step = useParams<Params>().step?.toUpperCase().replaceAll("-", "_")

  if (step && isPurchaseHraStep(step)) {
    return step
  }

  return ACCOUNT
}

const STEP_DATA: CreateCompanyStepData[] = [
  {
    label: "Account",
    step: ACCOUNT,
    url: "/adp/create-company/account",
    status: INCOMPLETE,
    isActive: true,
    isDiy: true,
    isAdp: true,
  },
  {
    label: "Plan Setup",
    step: PLAN_SETUP,
    url: "/adp/create-company/plan-setup",
    status: INCOMPLETE,
    isActive: false,
    isDiy: true,
    isAdp: true,
  },
  {
    label: "Plan Structure",
    step: PLAN_STRUCTURE,
    url: "/adp/create-company/plan-structure",
    status: INCOMPLETE,
    isActive: false,
    isDiy: true,
    isAdp: true,
  },
]

export const AdpCreateCompany = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { setInitialCreateCompanyAccount } = useInitializeCreateCompany()
  const { setCompanyHraPlan } = useCreateCompanyHraPlan()
  const { setCompanyAcount } = useCreateCompanyAccount()

  const [stepData, setStepData] = useState(STEP_DATA)

  const [currentCompanyId, setCurrentCompanyId] = useState("")

  const setIsActive = (currentStep: PurchaseHraStep, index: number) =>
    currentStep === ACCOUNT || stepData[index - 1]?.status === COMPLETE

  const step = useStep()

  useEffect(
    () => {
      const currentUserCompany = getActiveCompany(user)
      const currentUserCompanyId = currentUserCompany?.companyId

      setCurrentCompanyId(currentUserCompanyId)
      const loadData = async () => {
        if (currentUserCompanyId) {
          const currentCompanyData = await getCompany(currentUserCompanyId)

          setCompanyAcount(currentCompanyData)

          const currentHraPlanData = await getHraPlan(currentUserCompanyId as never)

          if (currentHraPlanData) {
            const ActiveHraPlan = currentHraPlanData[0]

            setCompanyHraPlan(ActiveHraPlan)
          }
          setInitialCreateCompanyAccount(true)
        }
      }

      loadData().catch(err => console.error(err))
    },
    // FUTURE: Code pasted from createCompany should be fix later
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )

  const mapOnboardingStatusDataToStepData = (onboardingStatus: CompanyOnboardingStatus[]) => {
    const transformedOnboardingStatus = onboardingStatus.map((status: CompanyOnboardingStatus) => ({
      step: status.step.toLowerCase().replace("_", "-"),
      isComplete: !!status.isComplete,
    }))

    return transformedOnboardingStatus
  }

  useEffect(
    () => {
      const hasStep = Boolean(step)

      if (!hasStep) {
        navigate("/adp/create-company/account")
      }
      const updateStepData = async () => {
        const onboardingStatusData = await getOnboardingStatus(currentCompanyId)

        if (onboardingStatusData) {
          const onboardingStatus = mapOnboardingStatusDataToStepData(onboardingStatusData)

          const updatedStepData = stepData.map((currentStep, index) => {
            const onboardingIndex = onboardingStatus?.findIndex(status => status.step === currentStep.step)

            if (onboardingIndex !== -1 && onboardingStatus) {
              return {
                ...currentStep,
                status: onboardingStatus[onboardingIndex].isComplete ? COMPLETE : INCOMPLETE,
                isActive: setIsActive(currentStep.step, index),
              } as const
            }

            return currentStep
          })

          setStepData(updatedStepData)
        }
      }

      if (currentCompanyId) {
        updateStepData().catch(console.error)
      }
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step, currentCompanyId]
  )

  return (
    <PurchaseHraLayout>
      <FormWrapper data-qa="create-company-form-container">
        <Helmet title="Create Company" />
        <Grid container item xs={12} justifyContent="flex-start" spacing={4}>
          <Grid item xs={12} textAlign="start" mb={10}>
            {step && renderStep(step, stepData)}
          </Grid>
        </Grid>
      </FormWrapper>
    </PurchaseHraLayout>
  )
}
