import { COMPLETE, INCOMPLETE } from "@/constants"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import {
  ACCOUNT,
  PLAN_SETUP,
  PLAN_STRUCTURE,
  PURCHASE_HRA_STEPS,
} from "@/features/CreateCompany/createCompanyConstants"
import { CompanyOnboardingStatus, getOnboardingStatus } from "@/features/CreateCompany/createCompanyEndpoints"
import { useCreateCompanyAccount } from "@/features/CreateCompany/CreateCompanyProvider"
import { PurchaseHraStep } from "@/features/CreateCompany/createCompanyTypes"
import { PurchaseHraLayout } from "@/features/CreateCompany/layouts/PurchaseHRALayout"
import { CreateCompanyStepData } from "@/features/CreateCompany/pages/CreateCompany"
import styled from "@emotion/styled"
import { Container, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { TcHubPlanSetup } from "./TcHubPlanSetup"
import { TcHubPlanStructure } from "./TcHubPlanStructure"
import { TcHubSetup } from "./TcHubSetup"

const FormWrapper = styled(Container)`
  padding: ${props => props.theme.spacing(0.25)} ${props => props.theme.spacing(4)};
  background: ${props => props.theme.header.background};
  text-align: center;
  justify-items: center;
  min-width: 100%;
  margin-bottom: 10px;
`

const renderStep = (currentStep: PurchaseHraStep, stepData: CreateCompanyStepData[]) => {
  switch (currentStep) {
    case ACCOUNT:
      return (
        <TcHubGuard
          requiredPermissions={[
            "tc_hub_users",
            "tc_hub_companies",
            "tc_hub_autopay",
            "tc_hub_compliance",
            "tc_hub_site_settings",
          ]}
        >
          <TcHubSetup stepData={stepData} />
        </TcHubGuard>
      )
    case PLAN_SETUP:
      return (
        <TcHubGuard
          requiredPermissions={[
            "tc_hub_users",
            "tc_hub_companies",
            "tc_hub_autopay",
            "tc_hub_compliance",
            "tc_hub_site_settings",
          ]}
        >
          <TcHubPlanSetup stepData={stepData} />
        </TcHubGuard>
      )
    case PLAN_STRUCTURE:
      return (
        <TcHubGuard
          requiredPermissions={[
            "tc_hub_users",
            "tc_hub_companies",
            "tc_hub_autopay",
            "tc_hub_compliance",
            "tc_hub_site_settings",
          ]}
        >
          <TcHubPlanStructure stepData={stepData} />
        </TcHubGuard>
      )
    default:
      return <Navigate to="/admin/create-company/account" />
  }
}

const isPurchaseHraStep = (step: string): step is PurchaseHraStep => PURCHASE_HRA_STEPS.some(e => e === step)

interface Params extends Record<string, string | undefined> {
  step: string
}

const useStep = () => {
  const step = useParams<Params>().step?.toUpperCase().replaceAll("-", "_")

  if (step && isPurchaseHraStep(step)) {
    return step
  }

  return ACCOUNT
}

const STEP_DATA: CreateCompanyStepData[] = [
  {
    label: "Account",
    step: ACCOUNT,
    url: "/admin/create-company/account",
    status: INCOMPLETE,
    isActive: true,
  },
  {
    label: "Plan Setup",
    step: PLAN_SETUP,
    url: "/admin/create-company/plan-setup",
    status: INCOMPLETE,
    isActive: false,
  },
  {
    label: "Plan Structure",
    step: PLAN_STRUCTURE,
    url: "/admin/create-company/plan-structure",
    status: INCOMPLETE,
    isActive: false,
  },
]

export const TcHubCreateCompany = () => {
  const navigate = useNavigate()
  const { company } = useCreateCompanyAccount()

  const [stepData, setStepData] = useState(STEP_DATA)

  const setIsActive = (currentStep: PurchaseHraStep, index: number) =>
    currentStep === ACCOUNT || stepData[index - 1]?.status === COMPLETE

  const step = useStep()

  const mapOnboardingStatusDataToStepData = (onboardingStatus: CompanyOnboardingStatus[]) => {
    const transformedOnboardingStatus = onboardingStatus.map((status: CompanyOnboardingStatus) => ({
      step: status.step.toLowerCase().replace("_", "-"),
      isComplete: !!status.isComplete,
    }))

    return transformedOnboardingStatus
  }

  useEffect(() => {
    if (step !== ACCOUNT) {
      const element = document.getElementById("stepper-linear-progress-bar")
      element?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [step])

  useEffect(
    () => {
      const hasStep = Boolean(step)

      if (!hasStep) {
        navigate("/admin/create-company/account")
      }
      const updateStepData = async () => {
        const onboardingStatusData = await getOnboardingStatus(company.id)

        if (onboardingStatusData) {
          const onboardingStatus = mapOnboardingStatusDataToStepData(onboardingStatusData)

          const updatedStepData = stepData.map((currentStep, index) => {
            const onboardingIndex = onboardingStatus?.findIndex(status => status.step === currentStep.step)

            if (onboardingIndex !== -1 && onboardingStatus) {
              return {
                ...currentStep,
                status: onboardingStatus[onboardingIndex].isComplete ? COMPLETE : INCOMPLETE,
                isActive: setIsActive(currentStep.step, index),
              } as const
            }

            return currentStep
          })

          setStepData(updatedStepData)
        }
      }

      if (company.id) {
        updateStepData().catch(console.error)
      }
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step, company.id]
  )

  return (
    <PurchaseHraLayout>
      <FormWrapper data-qa="create-company-form-container">
        <Helmet title="Create Company" />
        <Grid container item xs={12} justifyContent="flex-start" spacing={4}>
          <Grid item xs={12} textAlign="start" mb={10}>
            {step && renderStep(step, stepData)}
          </Grid>
        </Grid>
      </FormWrapper>
    </PurchaseHraLayout>
  )
}
