import { appConfig } from "@/config"
import { AuthUser } from "@/features/Auth/authTypes"
import { axiosInstance as axios } from "@/services/axios"
import {
  CompanyDocAcknowledgeResponse,
  CreateDocumentRequest,
  CreateDocumentResponse,
  DocumentsResponse,
  GenerateDocumentsTaskResponse,
} from "./documentsTypes"

const baseUrl = appConfig.baseApiUrl
const docManagementBaseUrl = `${baseUrl}/doc-management/v1`
const companyDocumentListUrl = (companyId: string, documentType?: string) => {
  const url = `${docManagementBaseUrl}/companies/${companyId}/documents`
  return documentType ? `${url}?documentType=${documentType}` : url
}

const employeeDocumentListUrl = (companyId: string, employeeId: string) =>
  `${docManagementBaseUrl}/companies/${companyId}/employees/${employeeId}/documents`

const employeeDocDownloadUrl = (companyId: string, employeeId: string, documentId: string) =>
  `${docManagementBaseUrl}/companies/${companyId}/employees/${employeeId}/documents/${documentId}/download`

const companyDocDownloadUrl = (companyId: string, documentId: string) =>
  `${docManagementBaseUrl}/companies/${companyId}/documents/${documentId}/download`

const companyDocAcknowledgeUrl = (userInfo: AuthUser, documentId: string) =>
  `${docManagementBaseUrl}/companies/${userInfo?.company?.companyId}/documents/${documentId}/acknowledge`

const companyDocCreateUrl = (userInfo: AuthUser) =>
  `${docManagementBaseUrl}/companies/${userInfo?.company?.companyId}/documents`

interface UrlDownloadResponse {
  url: string
}

export const getCompanyDocuments = async (companyId: string, documentType?: string) =>
  (await axios.get<DocumentsResponse>(companyDocumentListUrl(companyId, documentType))).data

export const getEmployeeDocuments = async (companyId: string, employeeId: string) =>
  (await axios.get<DocumentsResponse>(employeeDocumentListUrl(companyId, employeeId))).data

export const getCompanyDocDownloadUrl = async (companyId: string, documentId: string | undefined) =>
  (await axios.get<UrlDownloadResponse>(companyDocDownloadUrl(companyId, documentId as string))).data

export const getEmployeeDocDownloadUrl = async (
  companyId: string,
  employeeId: string,
  documentId: string | undefined
) => (await axios.get<UrlDownloadResponse>(employeeDocDownloadUrl(companyId, employeeId, documentId as string))).data

export const getAdminDocDownloadUrl = async (documentId: string) =>
  (await axios.get<UrlDownloadResponse>(`${docManagementBaseUrl}/documents/${documentId}/download`)).data

export const generateCompanyDocuments = async (companyId: string) =>
  (await axios.post<GenerateDocumentsTaskResponse>(`${docManagementBaseUrl}/companies/${companyId}/documents/generate`))
    .data

export const createDocument = async (
  companyId: string,
  { documentType, documentName, document, employeeId }: CreateDocumentRequest
) =>
  (
    await axios.post<CreateDocumentResponse>(companyDocumentListUrl(companyId), {
      documentType,
      documentName,
      document,
      employeeId,
    })
  ).data

export const acknowledgeDocument = async (userInfo: AuthUser, documentId?: string) => {
  let createdDocumentId = documentId

  // Create document if it does not exist
  if (!documentId) {
    const response = await axios.post<any>(companyDocCreateUrl(userInfo), {
      documentType: "services_agreement",
      url: "ServicesAgreement2023.pdf",
      isAcknowledgementRequired: true,
    })

    createdDocumentId = response.data.documentId
  }

  // Acnowledge document
  if (createdDocumentId) {
    if (!userInfo?.username) {
      throw new Error("User info not found, cannot determine who will acknowledge the document.")
    }

    const data = await axios.post<CompanyDocAcknowledgeResponse>(
      companyDocAcknowledgeUrl(userInfo, createdDocumentId),
      {
        acknowledgeBy: userInfo.username,
      }
    )

    return data
  }
}
