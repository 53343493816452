import { AsyncButton } from "@/components/Buttons"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SelectField } from "@/components/SelectField"
import { SelectMultiField } from "@/components/SelectMultiField"
import { StyledCard } from "@/components/StyledCard"
import { ClampedTextField } from "@/components/TextFields"
import { ENROLLMENT_TYPES, EXTRA_STEP, SELF_ENROLL, YES_NO_OPTIONS_BOOLEAN } from "@/constants"
import { PAYMENT_OPTIONS } from "@/features/BenefitsElection/benefitsElectionConstants"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { getStateLabel, UsaState } from "@/utils/States"
import { Uuid } from "@/utils/types"
import { requiredMessage } from "@/utils/validations"
import { Close, EditOutlined } from "@mui/icons-material"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { Formik, useFormikContext } from "formik"
import { useState } from "react"
import * as Yup from "yup"
import {
  BOTH_EXCHANGE,
  ENROLLMENT_OPTIONS,
  EXCHANGE_OPTIONS,
  EXCHANGE_STATUSES,
  EXTRA_STEP_OPTIONS,
  EXTRA_STEP_VALUES,
  OFF_EXCHANGE,
  ON_EXCHANGE,
  PAYMENT_TYPE_OPTIONS,
} from "../tcHubConstants"
import {
  useCreateCarrierIntegration,
  useGetSignedUrl,
  useUpdateCarrierIntegration,
  useUploadCarrierFile,
} from "../tcHubService"
import { CarrierIntegration, CarrierPreSignUrlResponse, Exchange } from "../tcHubTypes"

const buildFileName = (carrierIntegration: CarrierIntegration) => {
  const today = new Date()
  const milliseconds = today.getTime()

  return `${carrierIntegration.id}-${carrierIntegration.state}-${milliseconds}`
}

const STATUS_OK = 200

const validateExchange = Yup.object().shape({
  enrollmentType: Yup.string().label("Enrollment type").required(requiredMessage).oneOf(ENROLLMENT_TYPES),
  extraStepDescription: Yup.string()
    .label("Extra step (Description)")
    .when("enrollmentType", {
      is: EXTRA_STEP,
      then: schema => schema.required(requiredMessage),
      otherwise: schema => schema.optional(),
    })
    .oneOf(EXTRA_STEP_VALUES),
  link: Yup.string()
    .label("Link")
    .when("enrollmentType", {
      is: SELF_ENROLL,
      then: schema => schema.required(({ label }) => `${label} is required for Self-Enroll`),
      otherwise: schema => schema.optional(),
    }),
  cutOffDate: Yup.number().label("Cut off day").min(1).max(31),
  premiumPullDate: Yup.number().label("Premium pull day").min(1).max(31),
  isAutoPay: Yup.boolean().label("AutoPay").defined().required(requiredMessage),
  paymentOption: Yup.string().label("Payment Information").required(requiredMessage).oneOf(PAYMENT_OPTIONS),
})

type ExchangeValidation = Yup.InferType<typeof validateExchange>

const emptyValidation = Yup.object().shape({}).optional()

const validationSchema = Yup.object().shape({
  exchangeOption: Yup.array()
    .of(Yup.string().defined().oneOf(EXCHANGE_STATUSES))
    .label("Exchange")
    .min(1, requiredMessage)
    .required(requiredMessage),
  overdraftFeeCents: Yup.number().label("Overdraft fee").min(0).required(requiredMessage),
  crossStateCare: Yup.boolean().label("Cross state care").defined().required(requiredMessage),
  payLater: Yup.boolean().label("Pay later").defined().required(requiredMessage),

  onExchange: Yup.lazy((_values, { context }) =>
    context?.exchangeOption.includes(ON_EXCHANGE) ? validateExchange : emptyValidation
  ) as Yup.Lazy<ExchangeValidation | undefined>,
  offExchange: Yup.lazy((_values, { context }) =>
    context?.exchangeOption.includes(OFF_EXCHANGE) ? validateExchange : emptyValidation
  ) as Yup.Lazy<ExchangeValidation | undefined>,
  bothExchanges: Yup.lazy((_values, { context }) =>
    context?.exchangeOption.includes(BOTH_EXCHANGE) ? validateExchange : emptyValidation
  ) as Yup.Lazy<ExchangeValidation | undefined>,
})

type CarrierModalValidation = Yup.InferType<typeof validationSchema>

const mapCarrierIntegrationPayloadToForm = ({
  overdraftFeeCents = 0,
  payLater = false,
  crossStateCare = false,

  onExchange,
  onExchangePaymentOption,
  onExchangeEnrollment,
  onExchangeExtraStepDescription,
  onExchangeLink,
  onExchangePremiumPullDate,
  onExchangeCutOffDate,
  onExchangeIsAutoPay,

  offExchange,
  offExchangePaymentOption,
  offExchangeEnrollment,
  offExchangeExtraStepDescription,
  offExchangeLink,
  offExchangePremiumPullDate,
  offExchangeCutOffDate,
  offExchangeIsAutoPay,

  bothExchanges,
  bothExchangesPaymentOption,
  bothExchangesEnrollment,
  bothExchangesExtraStepDescription,
  bothExchangesLink,
  bothExchangesPremiumPullDate,
  bothExchangesCutOffDate,
  bothExchangesIsAutoPay,
}: CarrierIntegration) => {
  const values: CarrierModalValidation = {
    // We know these ones already
    overdraftFeeCents,
    crossStateCare,
    payLater,

    // Need a bit of work to figure this one out
    exchangeOption: [],
  }

  if (onExchange) {
    values.exchangeOption.push(ON_EXCHANGE)

    const onExchangeValues: ExchangeValidation = {
      // SAFETY: These assertions are safe because if onExchange is true these values are present
      enrollmentType: onExchangeEnrollment!,
      extraStepDescription: onExchangeExtraStepDescription,
      link: onExchangeLink!,
      cutOffDate: onExchangeCutOffDate!,
      premiumPullDate: onExchangePremiumPullDate!,
      isAutoPay: onExchangeIsAutoPay!,
      paymentOption: onExchangePaymentOption,
    }

    values.onExchange = onExchangeValues
  }

  if (offExchange) {
    values.exchangeOption.push(OFF_EXCHANGE)

    const offExchangeValues: ExchangeValidation = {
      // SAFETY: These assertions are safe because if offExchange is true these values are present
      enrollmentType: offExchangeEnrollment!,
      extraStepDescription: offExchangeExtraStepDescription,
      link: offExchangeLink!,
      cutOffDate: offExchangeCutOffDate!,
      premiumPullDate: offExchangePremiumPullDate!,
      isAutoPay: offExchangeIsAutoPay!,
      paymentOption: offExchangePaymentOption,
    }

    values.offExchange = offExchangeValues
  }

  if (bothExchanges) {
    values.exchangeOption.push(BOTH_EXCHANGE)

    const bothExchangesValues: ExchangeValidation = {
      // SAFETY: These assertions are safe because if bothExchanges is true these values are present
      enrollmentType: bothExchangesEnrollment!,
      extraStepDescription: bothExchangesExtraStepDescription,
      link: bothExchangesLink!,
      cutOffDate: bothExchangesCutOffDate!,
      premiumPullDate: bothExchangesPremiumPullDate!,
      isAutoPay: bothExchangesIsAutoPay!,
      paymentOption: bothExchangesPaymentOption,
    }

    values.bothExchanges = bothExchangesValues
  }

  return values
}

const mapCarrierModalToPayload = (
  id: Uuid,
  name: string,
  state: UsaState,
  logoUrl: string,
  {
    exchangeOption,
    overdraftFeeCents,
    payLater,
    crossStateCare,
    onExchange,
    offExchange,
    bothExchanges,
  }: CarrierModalValidation
): CarrierIntegration => ({
  id,
  name,
  state,
  logoUrl,
  overdraftFeeCents,
  payLater,
  crossStateCare,

  onExchange: exchangeOption.includes(ON_EXCHANGE),
  onExchangePaymentOption: onExchange?.paymentOption ?? ("" as never),
  onExchangeEnrollment: onExchange?.enrollmentType,
  onExchangeExtraStepDescription: onExchange?.extraStepDescription,
  onExchangeLink: onExchange?.link,
  onExchangePremiumPullDate: onExchange?.premiumPullDate ?? undefined,
  onExchangeCutOffDate: onExchange?.cutOffDate ?? undefined,
  onExchangeIsAutoPay: onExchange?.isAutoPay,

  offExchange: exchangeOption.includes(OFF_EXCHANGE),
  offExchangePaymentOption: offExchange?.paymentOption ?? ("" as never),
  offExchangeEnrollment: offExchange?.enrollmentType,
  offExchangeExtraStepDescription: offExchange?.extraStepDescription,
  offExchangeLink: offExchange?.link,
  offExchangePremiumPullDate: offExchange?.premiumPullDate ?? undefined,
  offExchangeCutOffDate: offExchange?.cutOffDate ?? undefined,
  offExchangeIsAutoPay: offExchange?.isAutoPay,

  bothExchanges: exchangeOption.includes(BOTH_EXCHANGE),
  bothExchangesPaymentOption: bothExchanges?.paymentOption ?? ("" as never),
  bothExchangesEnrollment: bothExchanges?.enrollmentType,
  bothExchangesExtraStepDescription: bothExchanges?.extraStepDescription,
  bothExchangesLink: bothExchanges?.link,
  bothExchangesPremiumPullDate: bothExchanges?.premiumPullDate ?? undefined,
  bothExchangesCutOffDate: bothExchanges?.cutOffDate ?? undefined,
  bothExchangesIsAutoPay: bothExchanges?.isAutoPay,
})

const getExchangeKey = <
  const T extends Exchange = Exchange,
  const U extends keyof ExchangeValidation = keyof ExchangeValidation,
>(
  exchange: T,
  key: U
) => `${exchange}.${key}` as const

interface EnrollmentContainerProps {
  exchange: Exchange
  label: string
  disabled?: boolean
}

const EnrollmentContainer = ({ exchange, label, disabled }: EnrollmentContainerProps) => {
  const {
    values,
    touched: touchedContext,
    errors: errorsContext,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext<CarrierModalValidation>()

  // FUTURE: Update formik to remove these unsafe type casts
  const touched = touchedContext as any
  const errors = errorsContext as any

  const enrollmentType = values[exchange]?.enrollmentType

  const enrollmentTypeKey = getExchangeKey(exchange, "enrollmentType")
  const extraStepDescriptionKey = getExchangeKey(exchange, "extraStepDescription")
  const linkKey = getExchangeKey(exchange, "link")
  const cutOffDateKey = getExchangeKey(exchange, "cutOffDate")
  const premiumPullDateKey = getExchangeKey(exchange, "premiumPullDate")
  const isAutoPayKey = getExchangeKey(exchange, "isAutoPay")
  const paymentOptionKey = getExchangeKey(exchange, "paymentOption")

  return (
    <StyledCard>
      <Grid container item mt={3} ml={3}>
        <Typography variant="h6" mb={4}>
          {label}
        </Typography>
        <Grid container item xs={12} py={4} pr={4} spacing={4}>
          <Grid item xs={12}>
            <SelectField
              data-qa="enrollment-select"
              data={ENROLLMENT_OPTIONS}
              type="text"
              name={enrollmentTypeKey}
              label="Enrollment type"
              value={values[exchange]?.enrollmentType ?? ("" as never)}
              placeholder="Select"
              onChange={handleChange}
              handleChange={(e: any) => {
                const newVal = e.target.value
                if (newVal !== EXTRA_STEP && values[exchange]?.extraStepDescription) {
                  setFieldValue(extraStepDescriptionKey, "")
                }
              }}
              onBlur={handleBlur}
              disabled={disabled}
              required
            />
          </Grid>
          {enrollmentType === EXTRA_STEP && (
            <Grid item xs={12}>
              <SelectField
                data-qa="extra-step-select"
                data={EXTRA_STEP_OPTIONS}
                type="text"
                required
                name={extraStepDescriptionKey}
                label="Extra Step (Description)"
                value={values[exchange]?.extraStepDescription ?? ("" as never)}
                placeholder="Select"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ClampedTextField
              id="link"
              label="Link"
              type="text"
              data-qa="link"
              value={values[exchange]?.link}
              name={linkKey}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              required={enrollmentType === SELF_ENROLL}
              disabled={disabled}
              error={!!touched[exchange]?.link && !!errors[exchange]?.link}
              helperText={touched[exchange]?.link && errors[exchange]?.link}
            />
          </Grid>
          <Grid item xs={6}>
            <ClampedTextField
              id="cut-off-date"
              data-qa="cut-off-date"
              label="Cut-off day"
              type="number"
              value={values[exchange]?.cutOffDate}
              name={cutOffDateKey}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ min: 1, max: 31 }}
              disabled={disabled}
              error={!!touched[exchange]?.cutOffDate && !!errors[exchange]?.cutOffDate}
              helperText={touched[exchange]?.cutOffDate && errors[exchange]?.cutOffDate}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ClampedTextField
              id="premium-pull-date"
              data-qa="premium-pull-date"
              label="Premium pull day"
              type="number"
              value={values[exchange]?.premiumPullDate}
              name={premiumPullDateKey}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
              inputProps={{ min: 1, max: 31 }}
              error={!!touched[exchange]?.premiumPullDate && !!errors[exchange]?.premiumPullDate}
              helperText={touched[exchange]?.premiumPullDate && errors[exchange]?.premiumPullDate}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RadioGroupCard
              name={isAutoPayKey}
              label="AutoPay"
              value={values[exchange]?.isAutoPay ?? ""}
              formName="autopay"
              elements={YES_NO_OPTIONS_BOOLEAN}
              handleChange={setFieldValue}
              disabled={disabled}
              required
            />
            {touched[exchange]?.isAutoPay && errors[exchange]?.isAutoPay}
          </Grid>
          <Grid item xs={12} sm={12}>
            <RadioGroupCard
              name={paymentOptionKey}
              label="Payment Information"
              value={values[exchange]?.paymentOption ?? ("" as never)}
              formName="paymentOption"
              elements={PAYMENT_TYPE_OPTIONS}
              handleChange={setFieldValue}
              disabled={disabled}
              required
            />
            {touched[exchange]?.paymentOption && errors[exchange]?.paymentOption}
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

interface CarrierModalProps {
  isOpen: boolean
  onClose: () => void
  carrierIntegration: CarrierIntegration
  isEditing?: boolean
}

export const CarrierModal = ({ isOpen, onClose, carrierIntegration, isEditing }: CarrierModalProps) => {
  const { mutateAsync: getSignedUrl } = useGetSignedUrl()
  const { mutateAsync: uploadCarrierFile } = useUploadCarrierFile()
  const { mutateAsync: updateCarrierIntegration, isPending: isUpdating } = useUpdateCarrierIntegration()
  const { mutateAsync: createCarrierIntegration, isPending: isCreating } = useCreateCarrierIntegration()

  const { notify } = useNotifications("carrier-updated-success")
  const [logoUrl, setLogoUrl] = useState(carrierIntegration.logoUrl)
  const [selectedLogo, setSelectedLogo] = useState("")

  const handleLogoChange = async (event: any) => {
    const file = event.target.files[0]

    setSelectedLogo(URL.createObjectURL(file))

    const fileName = buildFileName(carrierIntegration)
    const signedUrl: CarrierPreSignUrlResponse = await getSignedUrl({ fileName })

    const response = await uploadCarrierFile({
      file,
      signedUrl: signedUrl.url,
    })

    if (response.status === STATUS_OK) {
      setLogoUrl(signedUrl.url.split("?")[0])
    }
  }

  const initialValues = mapCarrierIntegrationPayloadToForm(carrierIntegration)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-carrier-data"
      aria-describedby=""
      data-qa="modal-carrier-data"
      maxWidth="md"
      PaperProps={{
        sx: {
          overflowX: "hidden",
          p: 4,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" mt={4}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={8}>
            <Box
              sx={{
                border: "1px solid",
                borderRadius: "1rem",
                px: 4,
                borderColor: "colors.lightGreyButton",
                position: "relative",
              }}
            >
              <img
                src={selectedLogo || logoUrl}
                alt={`${carrierIntegration.name} logo`}
                style={{
                  objectFit: "contain",
                  maxWidth: "7rem",
                  height: "3rem",
                  borderRadius: "0.5rem",
                }}
                data-qa={createDataQa("modal-carrier-data", "carrier-logo")}
              />
              <Fab
                onClick={() => document.getElementById("upload-logo-input")?.click()}
                aria-label="edit carrier logo"
                sx={{ bgcolor: "colors.white", position: "absolute", top: "-1.25rem", right: "-1.25rem" }}
                size="small"
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  style={{ display: "none" }}
                  id="upload-logo-input"
                />
                <EditOutlined />
              </Fab>
            </Box>

            <Typography variant="h3tiempos">
              {carrierIntegration.name} - {getStateLabel(carrierIntegration.state)}
            </Typography>
          </Stack>

          <IconButton data-qa="close-carrier-modal" aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" pt={4} pb={4}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              const carrier = mapCarrierModalToPayload(
                carrierIntegration.id,
                carrierIntegration.name,
                carrierIntegration.state,
                logoUrl,
                values
              )

              if (isEditing) {
                const carriersUpdated = await updateCarrierIntegration({
                  carrierIntegration: carrier,
                  applyAllStates: false, // FUTURE: This value should be isSelectedApplyAllCarriers,
                })

                notify(`${carriersUpdated?.length} Carriers updated successfully`, "success")
              } else {
                const carrierCreated = await createCarrierIntegration({ carrierIntegration: carrier })

                notify(`Carrier ${carrierCreated.name} created successfully`, "success")
              }
              setSubmitting(false)
              resetForm()
              onClose()
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setErrors,
              isSubmitting,
              errors,
              touched,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <SelectMultiField
                      placeholder="Please select exchange types"
                      selectedValues={values.exchangeOption}
                      data-qa="exchange-options"
                      fieldLabel="Exchange"
                      data={EXCHANGE_OPTIONS}
                      name="exchangeOption"
                      onChange={e => {
                        setErrors({})
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      required
                      error={!!touched.exchangeOption && !!errors.exchangeOption}
                      helperText={errors.exchangeOption as never}
                    />
                  </Grid>

                  <Grid item xs={6.5}>
                    <ClampedTextField
                      id="overdraft-fee"
                      data-qa="overdraft-fee"
                      label="Overdraft fee"
                      type="number"
                      value={values.overdraftFeeCents}
                      name="overdraftFeeCents"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        startAdornment: <Typography sx={{ marginRight: 2 }}>$</Typography>,
                      }}
                      fullWidth
                      required
                      error={!!errors.overdraftFeeCents}
                      helperText={errors.overdraftFeeCents}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroupCard
                      name="crossStateCare"
                      label="Cross state care"
                      value={values.crossStateCare}
                      formName="crossStateCare"
                      elements={YES_NO_OPTIONS_BOOLEAN}
                      handleChange={setFieldValue}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <RadioGroupCard
                      name="payLater"
                      label="Pay later"
                      value={values.payLater}
                      formName="payLater"
                      elements={YES_NO_OPTIONS_BOOLEAN}
                      handleChange={setFieldValue}
                      required
                    />
                  </Grid>
                  {values.exchangeOption.includes(ON_EXCHANGE) && (
                    <EnrollmentContainer exchange="onExchange" label={ON_EXCHANGE} />
                  )}
                  {values.exchangeOption.includes(OFF_EXCHANGE) && (
                    <EnrollmentContainer exchange="offExchange" label={OFF_EXCHANGE} />
                  )}
                  {values.exchangeOption.includes(BOTH_EXCHANGE) && (
                    <EnrollmentContainer exchange="bothExchanges" label="Both Exchanges" />
                  )}

                  <Grid container spacing={4} sx={{ paddingTop: 8 }}>
                    <Grid item xs={12}>
                      <DialogActions sx={{ pr: 0, pt: 6 }}>
                        <Button onClick={onClose} color="inherit" data-qa="cancel-button">
                          Cancel
                        </Button>
                        <AsyncButton
                          isLoading={isUpdating || isCreating}
                          type="submit"
                          variant="contained"
                          color="primary"
                          autoFocus
                          disabled={!isValid || !dirty}
                          data-qa="confirm-button"
                        >
                          Save changes
                        </AsyncButton>
                      </DialogActions>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
