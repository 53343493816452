import { StandardChip } from "@/components/Chips"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { TchTablePagination } from "@/components/Table/Table"
import { FIVE, TEN, TWENTY_FIVE } from "@/components/Table/tableConstants"
import { EnrollmentType, PaymentOption } from "@/features/BenefitsElection/benefitsElectionTypes"
import { CarrierFilter, CarrierIntegration, CarrierIntegrationTableHeader } from "@/features/TCHub/tcHubTypes"
import { createDataQa } from "@/utils/dataQa"
import { formatDollarsFlat } from "@/utils/formatting"
import { getStateLabel } from "@/utils/States"
import { CheckOutlined } from "@mui/icons-material"
import LinkIcon from "@mui/icons-material/Link"
import {
  Box,
  Icon,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { sortBy } from "lodash"
import { Fragment, isValidElement, ReactNode, useEffect, useMemo, useState } from "react"
import { ENROLLMENT_OPTIONS, EXCHANGE_VALUES, PAYMENT_TYPE_OPTIONS } from "../tcHubConstants"
import { CarrierStateItemDropdownMenu } from "./CarrierStateItemDropdownMenu"

const headers: CarrierIntegrationTableHeader[] = [
  { id: "carrier", label: "Carrier", sortable: true, field: "name", alignment: "left" },
  { id: "state", label: "State", sortable: true, field: "state", alignment: "center" },
  { id: "logoUrl", label: "Logo", sortable: false, field: "logoUrl", alignment: "center" },
  { id: "payLater", label: "Pay later", sortable: false, field: "payLater", alignment: "center" },
  {
    id: "crossStateCare",
    label: "Cross state care",
    sortable: false,
    field: "crossStateCare",
    alignment: "center",
  },
  {
    id: "overdraftFee",
    label: "Overdraft fee",
    sortable: false,
    field: "overdraftFeeCents",
    alignment: "center",
  },

  { id: "exchange", label: "Exchange", sortable: false, alignment: "center" },
  { id: "link", label: "Link", sortable: false, alignment: "center" },
  { id: "enrollment", label: "Enrollment", sortable: false, alignment: "center" },
  { id: "isAutoPay", label: "AutoPay", sortable: false, alignment: "center" },
  { id: "premiumPullDate", label: "Premium pull day", sortable: false, alignment: "center" },
  { id: "cutOffDate", label: "Cut-off date", sortable: false, alignment: "center" },
  { id: "paymentOption", label: "Payment Information", sortable: false, alignment: "center" },
  { id: "action", label: "Actions", sortable: false, alignment: "center" },
]

const getEnrollmentLabel = (enrollment?: EnrollmentType) => {
  if (enrollment) {
    const enrollmentOption = ENROLLMENT_OPTIONS.find(option => option.value === enrollment)

    return enrollmentOption ? enrollmentOption.label : ""
  }

  return ""
}

const getPaymentOptionLabel = (value: PaymentOption) =>
  PAYMENT_TYPE_OPTIONS.find(option => option.value === value)!?.title

const applyFilters = (carrierFilter: CarrierFilter, carriers: CarrierIntegration[]) =>
  carriers.filter(carrier => {
    const carrierMatchesOnExchange =
      carrierFilter.onExchange === undefined || carrier.onExchange === carrierFilter.onExchange

    const carrierMatchesOffExchange =
      carrierFilter.offExchange === undefined || carrier.offExchange === carrierFilter.offExchange

    const carrierMatchesBothExchanges =
      carrierFilter.bothExchanges === undefined || carrier.bothExchanges === carrierFilter.bothExchanges

    const carrierMatchesPayLater = carrierFilter.payLater === undefined || carrierFilter.payLater === carrier.payLater

    const carrierMatchesCrossStateCare =
      carrierFilter.crossStateCare === undefined || carrierFilter.crossStateCare === carrier.payLater

    const carrierMatchesName =
      carrierFilter.names === undefined ||
      carrierFilter.names.length === 0 ||
      carrierFilter.names.includes(carrier.name)

    const carrierMatchesState =
      carrierFilter.states === undefined ||
      carrierFilter.states.length === 0 ||
      carrierFilter.states.includes(carrier.state)

    const carrierMatchesPremiumPullDate =
      carrierFilter.premiumPullDate === undefined ||
      carrier.onExchangePremiumPullDate === carrierFilter.premiumPullDate ||
      carrier.offExchangePremiumPullDate === carrierFilter.premiumPullDate ||
      carrier.bothExchangesPremiumPullDate === carrierFilter.premiumPullDate

    const carrierMatchesEnrollment =
      carrierFilter.enrollment === undefined ||
      carrier.onExchangeEnrollment === carrierFilter.enrollment ||
      carrier.offExchangeEnrollment === carrierFilter.enrollment ||
      carrier.bothExchangesEnrollment === carrierFilter.enrollment

    const carrierMatchesCutOffDate =
      carrierFilter.cutOffDate === undefined ||
      carrier.onExchangeCutOffDate === carrierFilter.cutOffDate ||
      carrier.offExchangeCutOffDate === carrierFilter.cutOffDate ||
      carrier.bothExchangesCutOffDate === carrierFilter.cutOffDate

    return (
      carrierMatchesOnExchange &&
      carrierMatchesOffExchange &&
      carrierMatchesPayLater &&
      carrierMatchesCrossStateCare &&
      carrierMatchesName &&
      carrierMatchesState &&
      carrierMatchesPremiumPullDate &&
      carrierMatchesCutOffDate &&
      carrierMatchesBothExchanges &&
      carrierMatchesEnrollment
    )
  })

interface CellConfig {
  align: "left" | "center"
  style?: SxProps
  content: ReactNode | null | undefined
}

const renderCell = (config: CellConfig, key?: string | number): ReactNode => {
  const { align, style, content } = config

  return (
    <TableCell key={key} align={align} sx={style}>
      {isValidElement(content) ? content : <Typography variant="body1">{content}</Typography>}
    </TableCell>
  )
}

const renderCellContent = (hasExchange: boolean | undefined, row: any, component: any, nullValue: null | number) => {
  if (hasExchange) {
    return ""
  }

  return row ? component : nullValue
}

const generateRows = (row: CarrierIntegration): ReactNode[] => {
  const rows = []
  let processedExchanges = 0

  const totalExchanges = EXCHANGE_VALUES.reduce((count, key) => (row[key] ? count + 1 : count), 0)

  const createCommonCells = (
    exchangeLabel?: string,
    exchangeLink?: string,
    exchangeEnrollmentLabel?: string,
    exchangeIsAutoPay?: boolean | string,
    premiumPullDate?: number,
    cutOffDate?: number,
    paymentOption?: string,
    hasExchange?: boolean
  ) => {
    const shouldIncreaseProccesedExchange =
      exchangeLabel ||
      exchangeLink ||
      exchangeEnrollmentLabel ||
      exchangeIsAutoPay !== undefined ||
      premiumPullDate !== undefined ||
      cutOffDate !== undefined ||
      paymentOption !== undefined

    if (shouldIncreaseProccesedExchange) {
      processedExchanges = processedExchanges + 1
    }
    const showBorderBottom = processedExchanges === totalExchanges

    const borderStyle = {
      borderTop: hasExchange ? "none" : "1px solid rgba(224, 224, 224, 1)",
      borderBottom: showBorderBottom ? "1px solid rgba(224, 224, 224, 1)" : "none",
    }

    const overdraftFeeContent = hasExchange ? "" : formatDollarsFlat(row.overdraftFeeCents)

    return [
      renderCell({
        align: "left",
        content: hasExchange ? "" : row.name,
        style: borderStyle,
      }),
      renderCell({
        align: "center",
        content: hasExchange ? "" : getStateLabel(row.state),
        style: borderStyle,
      }),
      renderCell({
        align: "center",
        style: {
          pl: 9,
          minHeight: "3.5rem",
          maxHeight: "3.5rem",
          ...borderStyle,
        },
        content: hasExchange ? (
          ""
        ) : (
          <Box sx={{ minHeight: "3.5rem", maxHeight: "3.5rem" }}>
            <img
              src={row.logoUrl}
              alt={`${row.name} logo`}
              style={{ objectFit: "contain", width: "90px", minHeight: "3.5rem", maxHeight: "3.5rem" }}
              data-qa={createDataQa("carrier-integration-table", "carrier-logo")}
            />
          </Box>
        ),
      }),
      renderCell({
        align: "center",
        content: renderCellContent(hasExchange, row.payLater, <CheckOutlined />, null),
        style: borderStyle,
      }),
      renderCell({
        align: "center",
        content: renderCellContent(hasExchange, row.crossStateCare, <CheckOutlined />, null),
        style: borderStyle,
      }),
      renderCell({
        align: "center",
        content: overdraftFeeContent,
        style: borderStyle,
      }),
      renderCell({
        align: "center",
        style: borderStyle,
        content: exchangeLabel ? (
          <StandardChip
            key={`${row.state}-${row.name}-${exchangeLabel}`}
            sx={{ width: "5rem" }}
            label={exchangeLabel}
          />
        ) : null,
      }),
      renderCell({
        align: "center",
        style: borderStyle,
        content: (
          <a href={exchangeLink}>
            <Icon sx={{ color: "black" }}>
              <LinkIcon />
            </Icon>
          </a>
        ),
      }),
      renderCell({
        align: "center",
        style: borderStyle,
        content: exchangeEnrollmentLabel ? (
          <StandardChip
            key={`${exchangeEnrollmentLabel}-${row.name}-${exchangeLabel}`}
            sx={{ width: "7rem" }}
            label={exchangeEnrollmentLabel}
          />
        ) : null,
      }),
      renderCell({
        align: "center",
        style: borderStyle,
        content: exchangeIsAutoPay ? <CheckOutlined /> : null,
      }),
      renderCell({
        align: "center",
        style: borderStyle,
        content: premiumPullDate,
      }),
      renderCell({
        align: "center",
        style: borderStyle,
        content: cutOffDate,
      }),
      renderCell({
        align: "center",
        style: borderStyle,
        content: paymentOption,
      }),
      renderCell({
        align: "center",
        style: borderStyle,
        content: hasExchange ? (
          ""
        ) : (
          <CarrierStateItemDropdownMenu
            itemId={`${row.state}-${row.name}-${exchangeLabel}`}
            dataQa={`${row.state}-${row.name}-${exchangeLabel}-menu`}
            carrierIntegration={row}
            isEditing
          />
        ),
      }),
    ]
  }

  let hasExchange = false

  if (row.onExchange) {
    rows.push(
      <TableRow key={`${row.state}-${row.name}-onExchange`}>
        {createCommonCells(
          "On",
          row.onExchangeLink,
          getEnrollmentLabel(row.onExchangeEnrollment),
          row.onExchangeIsAutoPay,
          row.onExchangePremiumPullDate,
          row.onExchangeCutOffDate,
          getPaymentOptionLabel(row.onExchangePaymentOption),
          hasExchange
        )}
      </TableRow>
    )
    hasExchange = true
  }

  if (row.offExchange) {
    rows.push(
      <TableRow key={`${row.state}-${row.name}-offExchange`}>
        {createCommonCells(
          "Off",
          row.offExchangeLink,
          getEnrollmentLabel(row.offExchangeEnrollment),
          row.offExchangeIsAutoPay,
          row.offExchangePremiumPullDate,
          row.offExchangeCutOffDate,
          getPaymentOptionLabel(row.offExchangePaymentOption),
          hasExchange
        )}
      </TableRow>
    )
    hasExchange = true
  }

  if (row.bothExchanges) {
    rows.push(
      <TableRow key={`${row.state}-${row.name}-bothExchanges`}>
        {createCommonCells(
          "Both",
          row.bothExchangesLink,
          getEnrollmentLabel(row.bothExchangesEnrollment),
          row.bothExchangesIsAutoPay,
          row.bothExchangesPremiumPullDate,
          row.bothExchangesCutOffDate,
          getPaymentOptionLabel(row.bothExchangesPaymentOption),
          hasExchange
        )}
      </TableRow>
    )
    hasExchange = true
  }

  if (!row.onExchange && !row.offExchange && !row.bothExchanges) {
    rows.push(<TableRow key={`${row.state}-${row.name}-noExchange`}>{createCommonCells()}</TableRow>)
  }

  return rows
}

const getCarriersPage = (carrierIntegrations: CarrierIntegration[], page: number, rowsPerPage: number) => {
  const carriersOrdered = sortBy(carrierIntegrations, ["name"])
  const startIndex = page * rowsPerPage

  return carriersOrdered.slice(startIndex, startIndex + rowsPerPage)
}

export interface CarrierIntegrationTableProps {
  multiSelect?: boolean
  carriers: CarrierIntegration[]
  isLoading?: boolean
  carrierFilter?: CarrierFilter
}
export const CarrierIntegrationTable = ({
  carriers: carriersProp,
  isLoading,
  carrierFilter,
}: CarrierIntegrationTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const filteredCarriers = carrierFilter ? applyFilters(carrierFilter, carriersProp) : carriersProp
  const [carriers, setCarriers] = useState(filteredCarriers)
  const carrierPaginated = getCarriersPage(carriers, page, rowsPerPage)
  const paginatedRows = useMemo(() => carrierPaginated.flatMap(row => generateRows(row)), [carrierPaginated])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  useEffect(() => {
    if (carrierFilter) {
      setCarriers(applyFilters(carrierFilter, carriersProp))
      setPage(0)
    }
  }, [carrierFilter, carriersProp])

  if (isLoading) {
    return (
      <SkeletonTableLoader
        headerTitles={[
          "Carrier",
          "State",
          "Logo",
          "Pay later",
          "Cross state care",
          "Overdraft fee",
          "Exchange",
          "Link",
          "Enrollment",
          "AutoPay",
          "Premium pull day",
          "Cut-off date",
          "Actions",
        ]}
        bodyRowsCount={8}
        data-qa="skeleton-carrier-integration-table"
      />
    )
  }

  return (
    <Paper sx={{ width: "100%", mt: 5 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map(head => (
                <TableCell key={`${head.id}-header`} align={head.alignment}>
                  <Typography variant="subtitle1">{head.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={12} sx={{ height: "18rem" }}>
                  No data available.
                </TableCell>
              </TableRow>
            )}
            {paginatedRows.map((row, index) => (
              // FUTURE: The index is going to be removed when we fix the data in the table
              <Fragment key={index}>{row}</Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TchTablePagination
        rowsPerPageOptions={[FIVE, TEN, TWENTY_FIVE]}
        component="div"
        count={carriers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, changedPage) => handlePageChange(changedPage)}
        onRowsPerPageChange={event => handleRowsPerPageChange(Number(event.target.value))}
        data-qa="table-pagination"
      />
    </Paper>
  )
}
