import { ReturnButton } from "@/components/Buttons"
import { DrawerForm } from "@/components/DrawerForm"
import { FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { useAuth } from "@/features/Auth/useAuth"
import { MultipleClassesGrid } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/CustomClasses"
import { CustomClassDetails } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { Uuid } from "@/utils/types"
import { Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useAddEmployeeClass } from "../AddPersonModal/addPersonService"
import { calculateFirstBenefitDate, calculateHireDate } from "../AddPersonModal/addPersonUtils"
import { useGetPersonById } from "../peopleService"
import { EmployeeClassRequest, PersonEditClassInfo } from "../peopleTypes"

const dataQa = "person-edit-class-drawer"

interface PeopleClassesDrawerProps {
  customClassDetails: CustomClassDetails[]
  open: boolean
  personEditClassInfo?: PersonEditClassInfo
  handleClose: () => void
}

export const PeopleClassesDrawer = ({
  open,
  customClassDetails,
  personEditClassInfo,
  handleClose,
}: PeopleClassesDrawerProps) => {
  const { notify } = useNotifications(dataQa, true)

  const { user, companyId } = useAuth()
  const hraPlan = user?.companyHRAPlan?.[0]
  const planId = hraPlan?.id!
  const hraStartDate = hraPlan?.hraStartDate?.toString()

  const { data: person } = useGetPersonById(companyId as Uuid, personEditClassInfo?.personId)
  const { mutateAsync: addClassToEmployee, isPending } = useAddEmployeeClass()

  const [selectedClass, setSelectedClass] = useState<string | undefined>()
  const initialClass = personEditClassInfo?.classes.length ? personEditClassInfo.classes[0].classId : undefined

  const employee = person?.employments?.[0]
  const hasSameClass = initialClass && selectedClass === initialClass
  const disableContinue = Boolean(!selectedClass || !employee || hasSameClass)

  useEffect(() => {
    setSelectedClass(initialClass)
  }, [initialClass])

  const toggleSelection = (id: string) => {
    const isCurrentlySelected = id === selectedClass
    if (isCurrentlySelected) {
      setSelectedClass(undefined)
    } else {
      setSelectedClass(id)
    }
  }

  const handleContinue = async () => {
    try {
      const currentClass = customClassDetails.find(customClass => customClass.classId === selectedClass)
      const hireDate = calculateHireDate(new Date(employee?.activeFrom!), currentClass?.waitingPeriod!)
      const firstBenefitDate = calculateFirstBenefitDate(hireDate, new Date(hraStartDate!))
      const eligibilityDateFrom = firstBenefitDate.toISOString().split("T")[0]

      if (!currentClass || !eligibilityDateFrom || !employee?.id) {
        throw new Error("Missing data in the request")
      }

      const employeeBody: EmployeeClassRequest = {
        companyId,
        employmentId: employee.id,
        planId,
        classId: currentClass.classId,
        eligibilityDateFrom,
      }

      await addClassToEmployee(employeeBody)
      notify("The class has been successfully assigned!", "success")
      handleClose()
    } catch (error) {
      console.error("Error adding a class to user:", JSON.stringify(personEditClassInfo), error)
      notify("An error occurred while assigning the class.", "error")
    }
  }

  return (
    <DrawerForm
      open={open}
      onClose={handleClose}
      paperStyle={{ padding: "1.5rem 2rem", width: { xs: "90%", md: "80%" }, maxWidth: "50rem" }}
      data-qa={dataQa}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ReturnButton data-qa={createDataQa(dataQa, "return-button")} text="People" handleClose={handleClose} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom data-qa={createDataQa(dataQa, "title")}>
            Select a class
          </Typography>
          <Typography variant="body1" data-qa={createDataQa(dataQa, "description")}>
            By selecting a class, you can assign your employee to a specific group, but only one class can be selected
            per employee.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MultipleClassesGrid
          classes={customClassDetails.toSorted((a, b) => a.customClassName.localeCompare(b.customClassName))}
          canAddNewClass={false}
          isSelected={id => selectedClass === id}
          toggleSelection={toggleSelection}
          showRadio
        />
      </Grid>

      <Grid item xs={12}>
        <FlowNavigationButtons
          continueLabel={hasSameClass ? "This class is already selected" : "Select this class"}
          handleContinue={handleContinue}
          handleBack={handleClose}
          disabled={disableContinue}
          sx={{ mt: 8 }}
          isSubmitting={isPending}
        />
      </Grid>
    </DrawerForm>
  )
}
