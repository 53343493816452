import { createDataQa } from "@/utils/dataQa"
import { Option } from "@/utils/types"
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
} from "@mui/material"
import { ReactNode } from "react"

type SelectOption = Option<string, string>

export interface SelectMultiFieldProps<T extends SelectOption = SelectOption> {
  selectedValues: string[]
  fieldLabel: string
  data: readonly T[]
  name: string
  placeholder?: string
  required?: boolean
  "data-qa": string
  sx?: SxProps
  formControlSx?: SxProps
  helperText?: string
  error?: boolean
  small?: boolean
  children?: ReactNode
  onChange?: (event: any) => void
  onBlur?: (event: any) => void
  renderValue?: (selected: string[]) => string
}

export const SelectMultiField = ({
  selectedValues,
  fieldLabel,
  data,
  name,
  placeholder,
  required,
  "data-qa": dataQa,
  sx = {},
  formControlSx = {},
  helperText,
  error,
  small = false,
  children,
  onChange,
  onBlur,
  renderValue,
}: SelectMultiFieldProps) => (
  <FormControl fullWidth error={error} sx={formControlSx} size={small ? "small" : "medium"}>
    <InputLabel id={`${dataQa}-label`} required={required}>
      {fieldLabel}
    </InputLabel>
    <Select
      error={error}
      labelId={`${dataQa}-label`}
      id={dataQa}
      data-qa={dataQa}
      multiple
      name={name}
      label={fieldLabel}
      required={required}
      value={selectedValues}
      sx={{
        "& .MuiSelect-select .notranslate::after": placeholder
          ? {
              content: `"${placeholder}"`,
              opacity: 0.42,
            }
          : {},

        textAlign: "start",
        ...sx,
      }}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      onClose={() => onBlur?.({ target: { name, value: selectedValues } })}
      renderValue={renderValue ?? (selected => selected.join(", "))}
      fullWidth
      MenuProps={{ MenuListProps: { sx: { py: 0 } } }}
      input={<OutlinedInput label={fieldLabel} />}
    >
      {data.map(({ value, label, ...optionProps }) => (
        <MenuItem key={value} value={value} data-qa={createDataQa(dataQa, "option", value)} {...optionProps}>
          <Checkbox checked={selectedValues.includes(value)} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
      {children}
    </Select>
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </FormControl>
)
