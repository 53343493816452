import { ADULT_MINIMUM_AGE, NO, YES } from "@/constants"
import { AuthUser } from "@/features/Auth/authTypes"
import { CustomClassDetails } from "@/features/CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import { defaultTo, flatMap, isEqual, uniqWith } from "lodash"
import { toTitleCase } from "./formatting"
import { Uuid, VoidFn } from "./types"

/**
 * If value is nullish, return defaultValue and emit a console warning,
 * otherwise return value untouched
 * @param value returned if not nullish
 * @param defaultValue returned if value is nullish
 * @param warning warning text to emit to the console if value is nullish
 */

interface SafeOptions {
  message: string
  severity?: "info" | "warn" | "error"
}

const safeLoggerMap = { info: console.info, warn: console.warn, error: console.error } as const

export const safe = <T>(value: T | undefined | null, defaultValue: T, { message, severity = "warn" }: SafeOptions) => {
  const safeValue = defaultTo(value, defaultValue)

  if (value !== safeValue) {
    const emit = safeLoggerMap[severity]

    emit(message)
    emit("Received " + value?.toString() || JSON.stringify(value))
  }

  return safeValue
}

export const dbg = <T = unknown>(x: T) => {
  console.info(x)

  return x
}

export const trace =
  <F extends (...args: Parameters<F>) => ReturnType<F>>(f: F) =>
  (...args: Parameters<F>): ReturnType<F> => {
    const x = f(...args)

    console.info(f, x)

    return x
  }

export const createUuid = <T extends Uuid = Uuid>() => window.crypto.randomUUID() as T

export const getUserDisplayName = (user: AuthUser) => {
  const defaultInitials = "TC"
  const givenName = user?.given_name ?? ""
  const familyName = user?.family_name ?? ""
  const preferredName = user?.preferred_username ?? ""
  const userName = preferredName || givenName || defaultInitials
  const initials = (givenName?.[0] ?? "") + (familyName?.[0] ?? defaultInitials)
  const fullName = `${givenName} ${familyName}`.trim()

  return { userName, initials, fullName, givenName, familyName, preferredName }
}

// FUTURE: This functionality should be refactored into the above
export const getUserNameAndInitials = (user: AuthUser) => {
  const givenName = user?.given_name
  const familyName = user?.family_name
  const preferredName = user?.["custom:preferred_name"]
  const hasFullName = givenName && familyName
  const defaultInitials = "TC"
  const userName = preferredName ?? givenName ?? defaultInitials
  const initials = hasFullName ? (givenName[0] + familyName[0]).toUpperCase() : defaultInitials

  return { userName, initials }
}

export const getOrdinal = (n: number) => {
  // Just to get the ordinal of a number (1st, 2nd, 3rd, 4th, etc.)
  let ord = "th"

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = "st"
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = "nd"
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = "rd"
  }

  return `${n} ${ord}`
}

export const ageIsAdult = (age: number) => age >= ADULT_MINIMUM_AGE

export const valueIsYes = (value: string | undefined) => value === YES

export const valueIsNo = (value: string | undefined) => value === NO

export const dedupe = <T>(arr: T[] | undefined) => uniqWith(arr, isEqual)

export const fromEntriesTyped = <K extends PropertyKey, V = unknown>(entries: Iterable<readonly [K, V]>) =>
  Object.fromEntries(entries) as { [P in K]?: V }

export const getFileBase64 = async (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
  })

export const removeBase64Metadata = (base64text: string) => base64text.replace(/data:\w+\/\w+;base64,/, "")

export const deferPromise = () => {
  let resolve: VoidFn
  let reject: VoidFn

  const promise = new Promise<void>((res, rej) => {
    resolve = res
    reject = rej
  })

  return { promise, resolve: resolve!, reject: reject! }
}

export const interleave = <A, T>(arr: A[], element: T) =>
  flatMap(arr, (value, index, array) => (index === array.length - 1 ? value : [value, element]))

export const coalesce = <T extends NonNullable<unknown>>(x: T | T[]) => (Array.isArray(x) ? x : [x])

export const has = <T = unknown, U extends string = string>(x: object, key: U): x is { [K in U]: T } =>
  Object.hasOwn(x, key)

export const makeOptionsPredicate = <const T extends Readonly<string>, const R extends Readonly<string>>(
  options: readonly T[],
  f: (value: T) => R
) => options.map(value => ({ value, label: f(value) }) as const)

export const makeOptions = <const T extends Readonly<string>>(options: readonly T[]) =>
  makeOptionsPredicate(options, value => toTitleCase<T>(value.replaceAll("_", "-") as never))

export const makeOptionsSentenceTitleCase = <const T extends Readonly<string>>(options: readonly T[]) =>
  makeOptionsPredicate(options, value => toTitleCase<T>(value as never))

export const YES_NO_LABEL_OPTIONS = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
]

export const currentNextYearOptions = () => {
  const currentYear = new Date().getFullYear()
  const nextYear = currentYear + 1

  const currentYearString = currentYear.toString()
  const nextYearString = nextYear.toString()
  return [
    { value: currentYearString, label: currentYearString },
    { value: nextYearString, label: nextYearString },
  ]
}

export const getClassByEmploymentId = (customClassDetails: CustomClassDetails[], employmentId: string) =>
  customClassDetails
    ?.filter(({ healthBenefits }) => healthBenefits.some(h => h.employmentId === employmentId))
    .map(({ classId, customClassName }) => ({ classId, customClassName })) ?? []
