import { GROUP, INDIVIDUAL } from "@/features/TCHub/tcHubConstants"
import { Grid, Typography } from "@mui/material"
import { FormikProps, useFormikContext } from "formik"
import { isEqual, isNil } from "lodash"
import { useCallback, useEffect, useRef } from "react"
import * as Yup from "yup"
import { CarrierEnrollmentQuestion, ShoppingPersonPayload } from "../benefitsElectionTypes"
import { buildEnrollmentQuestionFormKey } from "../utils/carrierQuestions"
import { CarrierQuestion } from "./CarrierQuestion"

interface CarrierQuestionsContainerProps {
  shoppingPersons?: ShoppingPersonPayload[]
  enrollmentQuestions: CarrierEnrollmentQuestion[]
  validationSchema: any
  setValidationSchema: (schema: any) => void
}

export const CarrierQuestionsContainer = ({
  shoppingPersons,
  enrollmentQuestions,
  validationSchema,
  setValidationSchema,
}: CarrierQuestionsContainerProps) => {
  const formikProps: FormikProps<any> = useFormikContext()
  const areMultiplePeopleShopping = shoppingPersons && shoppingPersons.length > 1

  const individualEnrollmentQuestions = enrollmentQuestions.filter(
    (question: CarrierEnrollmentQuestion) => question.scope === INDIVIDUAL
  )

  const groupEnrollmentQuestions = enrollmentQuestions.filter(
    (question: CarrierEnrollmentQuestion) => question.scope === GROUP
  )

  const haveQuestionsForEnrollment = enrollmentQuestions && enrollmentQuestions.length > 0
  const haveQuestionsPerIndividual = individualEnrollmentQuestions && individualEnrollmentQuestions.length > 0
  const haveQuestionsForGroup = groupEnrollmentQuestions && groupEnrollmentQuestions.length > 0
  const prevValuesRef = useRef<any>({})
  const prevSchemaRef = useRef<Record<string, Yup.StringSchema<string>>>({})

  const generateValidationSchema = useCallback(
    (values: Record<string, string>) => {
      const schema: Record<string, Yup.StringSchema<string>> = {}

      shoppingPersons?.forEach(person => {
        individualEnrollmentQuestions?.forEach((parentIndividualQuestion: CarrierEnrollmentQuestion) => {
          schema[buildEnrollmentQuestionFormKey(parentIndividualQuestion, person.id)] =
            Yup.string().required("Please answer this question")

          parentIndividualQuestion.childQuestions.forEach((childQuestion: CarrierEnrollmentQuestion) => {
            const parentQuestionAnswer: string | undefined = values
              ? values[buildEnrollmentQuestionFormKey(parentIndividualQuestion, person.id)]
              : ""

            if (
              !isNil(parentQuestionAnswer) &&
              parentQuestionAnswer.toLowerCase() === childQuestion.parentAnswerForVisibility?.toLowerCase()
            ) {
              schema[buildEnrollmentQuestionFormKey(childQuestion, person.id)] =
                Yup.string().required("Please answer this question")
            } else {
              schema[buildEnrollmentQuestionFormKey(childQuestion, person.id)] =
                Yup.string() as Yup.StringSchema<string>
            }
          })
        })
      })

      groupEnrollmentQuestions?.forEach((question: CarrierEnrollmentQuestion) => {
        schema[buildEnrollmentQuestionFormKey(question)] = Yup.string().required("Please answer this question")

        question.childQuestions.forEach((childQuestion: CarrierEnrollmentQuestion) => {
          const parentQuestionAnswer = values ? values[buildEnrollmentQuestionFormKey(question)] : ""

          if (
            parentQuestionAnswer &&
            parentQuestionAnswer.toLowerCase() === childQuestion.parentAnswerForVisibility?.toLowerCase()
          ) {
            schema[buildEnrollmentQuestionFormKey(childQuestion)] = Yup.string().required("Please answer this question")
          } else {
            schema[buildEnrollmentQuestionFormKey(childQuestion)] = Yup.string() as Yup.StringSchema<string>
          }
        })
      })

      return Yup.object().shape(schema)
    },
    [shoppingPersons, individualEnrollmentQuestions, groupEnrollmentQuestions]
  )

  /**
   * Dynamically generates a validation schema based on the changes to the form values
   */
  useEffect(() => {
    const hasFormValuesChanged = !isEqual(formikProps.values, prevValuesRef.current)

    if (hasFormValuesChanged) {
      setValidationSchema(generateValidationSchema(formikProps.values))
      prevValuesRef.current = formikProps.values
    }
  }, [formikProps.values, setValidationSchema, generateValidationSchema])

  /**
   * Revalidates the form when the validation schema changes
   */
  useEffect(() => {
    const hasValidationSchemaChanged = !isEqual(validationSchema, prevSchemaRef.current)

    if (hasValidationSchemaChanged) {
      prevSchemaRef.current = validationSchema
      formikProps.validateForm()
    }
  }, [validationSchema, formikProps])

  const renderQuestionField = (question: CarrierEnrollmentQuestion, personId?: string) => (
    <Grid item xs={12} key={question.id}>
      <CarrierQuestion question={question} personId={personId} />
    </Grid>
  )

  return (
    <>
      {areMultiplePeopleShopping &&
        haveQuestionsPerIndividual &&
        shoppingPersons.map((person, index) => (
          <Grid container spacing={4} mt={2} key={person.id}>
            <Grid item xs={12}>
              <Typography variant="h4">{`${index + 1}. Questions for ${person.firstName} ${
                person.lastName
              }`}</Typography>
            </Grid>
            {individualEnrollmentQuestions.map((question: CarrierEnrollmentQuestion) => (
              <Grid item xs={12} key={question.id}>
                <CarrierQuestion question={question} personId={person.id} />
              </Grid>
            ))}
          </Grid>
        ))}

      {areMultiplePeopleShopping && haveQuestionsForGroup && (
        <Grid container spacing={4} mt={2}>
          {haveQuestionsPerIndividual && (
            <Grid item xs={12}>
              <Typography variant="h4">{shoppingPersons.length + 1}. Group Questions</Typography>
            </Grid>
          )}
          {groupEnrollmentQuestions.map((question: CarrierEnrollmentQuestion) => (
            <Grid item xs={12} key={question.id}>
              <CarrierQuestion question={question} />
            </Grid>
          ))}
        </Grid>
      )}

      {!areMultiplePeopleShopping && haveQuestionsForEnrollment && formikProps.values && (
        <Grid container spacing={4} mt={2}>
          {enrollmentQuestions.map((question: CarrierEnrollmentQuestion) => {
            const isChildQuestion = !!question.parentQuestionId
            const shoppingPerson = shoppingPersons?.[0]

            if (question.scope === INDIVIDUAL && !shoppingPerson) {
              console.error("No shopping person found for individual question, skipping question")

              return null
            }
            if (isChildQuestion) {
              const parentQuestionAnswer =
                formikProps.values[buildEnrollmentQuestionFormKey(question, shoppingPerson?.id, true)]

              if (parentQuestionAnswer !== question.parentAnswerForVisibility) {
                return null
              }
            }

            return renderQuestionField(question, shoppingPerson?.id)
          })}
        </Grid>
      )}
    </>
  )
}
