import { DrawerForm } from "@/components/DrawerForm"
import { LoadingContentArea } from "@/components/LoadingContentArea"
import { useCompanyId } from "@/features/Auth/useAuth"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { Uuid } from "@/utils/types"
import { Edit as EditIcon, KeyboardArrowLeft } from "@mui/icons-material"
import { Box, Button, Grid, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import { useGetPersonById } from "../peopleService"
import { PeopleDrawerEditProfile } from "./PeopleDrawerEditProfile"
import { PeopleDrawerProfileDetails } from "./PeopleDrawerProfileDetails"
import { PeopleDrawerTerminate } from "./PeopleDrawerTerminate"

export interface PeopleProfileDrawerProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  personProfileId: Uuid | undefined
  companyId?: Uuid
}
export const PeopleProfileDrawer = ({
  open,
  setOpen,
  personProfileId,
  companyId: companyIdProp,
}: PeopleProfileDrawerProps) => {
  const companyId = useCompanyId(false)
  const finalCompanyId = companyIdProp ?? companyId
  const { data: person, isLoading: isLoadingPerson } = useGetPersonById(finalCompanyId, personProfileId)
  const { data: company, isLoading: isCompanyLoading } = useGetCompany(finalCompanyId)
  const [isTerminateOpen, setIsTerminateOpen] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const isEmployeeInformationLoading = isLoadingPerson || isCompanyLoading

  const toggleDrawer = (newOpen: boolean) => () => {
    setIsEditMode(false)
    setIsTerminateOpen(false)
    setOpen(newOpen)
  }

  const handleBackClick = () => {
    if (isTerminateOpen) {
      setIsTerminateOpen(false)
    } else {
      setOpen(false)
    }
  }

  return (
    <DrawerForm
      open={open}
      onClose={toggleDrawer(false)}
      paperStyle={{ padding: "2rem", width: "93%", maxWidth: "37.5rem" }}
      data-qa="drawer-profile"
    >
      {isEditMode && !isTerminateOpen ? (
        <PeopleDrawerEditProfile
          setIsEditMode={setIsEditMode}
          person={person!}
          company={company!}
          setIsTerminateOpen={setIsTerminateOpen}
        />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button startIcon={<KeyboardArrowLeft />} color="inherit" onClick={handleBackClick} sx={{ width: "6rem" }}>
              Back
            </Button>
            {!isTerminateOpen && (
              <Button
                startIcon={<EditIcon />}
                variant="outlined"
                disabled={isEmployeeInformationLoading}
                onClick={() => {
                  setIsEditMode(!isEditMode)
                }}
                data-qa="edit-profile-button"
                sx={{ borderRadius: "20px" }}
              >
                Edit
              </Button>
            )}
          </Box>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Typography variant="h2tiempos" gutterBottom>
                {isTerminateOpen ? "Terminate Employee" : "Profile"}
              </Typography>
            </Grid>
            {isEmployeeInformationLoading && <LoadingContentArea data-qa="loading-employee-informtation" />}
            {!isEmployeeInformationLoading && isTerminateOpen && (
              <PeopleDrawerTerminate
                isTerminateOpen={isTerminateOpen}
                setIsTerminateOpen={setIsTerminateOpen}
                personProfileId={personProfileId}
                person={person!}
                company={company!}
              />
            )}
            {!isEmployeeInformationLoading && !isTerminateOpen && (
              <PeopleDrawerProfileDetails
                setIsTerminateOpen={setIsTerminateOpen}
                person={person}
                company={company}
                personProfileId={personProfileId}
                companyId={finalCompanyId}
              />
            )}
          </Grid>
        </>
      )}
    </DrawerForm>
  )
}
