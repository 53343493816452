import { ThemeProvider } from "@/theme/ThemeContext"
import { datadogRum } from "@datadog/browser-rum"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import "chart.js/auto"
import "react-app-polyfill/stable"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { App } from "./App"
import { appConfig } from "./config"
import { reportWebVitals } from "./reportWebVitals"

datadogRum.init({
  applicationId: appConfig.datadogApplicationId,
  clientToken: appConfig.datadogClientToken,
  site: "us5.datadoghq.com",
  service: "hra-hub",
  env: appConfig.tchEnv,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: appConfig.appVersion,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 80,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
})

datadogRum.startSessionReplayRecording()

const container = document.getElementById("root")
const root = createRoot(container!)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
      retry: 2,
      refetchOnMount: true,
      staleTime: 1000 * 60 * 2,
    },
  },
})

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(msg => console.info(msg)))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
