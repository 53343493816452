import { ConfirmationModal } from "@/components/ConfirmationModal"
import { LoadingMasonryArea } from "@/components/LoadingContentArea"
import { StyledCard } from "@/components/StyledCard"
import { appConfig } from "@/config"
import { ALL_COMPANY_ACCESS_ID } from "@/constants"
import { CompanyAssociation } from "@/features/Auth/authTypes"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { useRoleStore } from "@/features/Auth/roleStore"
import { getActiveCompany, getAllCompanyAssociations } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { ADMINISTRATOR_EXTERNAL_ROLE_ID } from "@/features/People/peopleConstants"
import { useNotifications } from "@/services/notificationService"
import { createDateFromText, formatCents, formatDate } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { Box, Grid, Stack, Typography, TypographyProps } from "@mui/material"
import { useFeatureFlag } from "configcat-react"
import { differenceInDays } from "date-fns"
import { compact } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { DashboardLayout } from "../components/DashboardLayout"
import { EmployeeDashboard } from "../components/EmployeeDashboard"
import { AutopayUpcomingFundingWidget } from "../components/adminWidgets/AutopayUpcomingFundingWidget"
import { EmployeeEnrollmentStatusWidget } from "../components/adminWidgets/EmployeeEnrollmentStatusWidget"
import {
  EMPLOYEE_DASHBOARD_WIDGETS,
  EMPLOYEE_ENROLLMENT_STATUS,
  EMPLOYER_DASHBOARD_WIDGETS,
  UNAUTHORIZED_STATUS,
} from "../dashboardConstants"
import { useGetAutoPayCutOffDate, useGetDashboardWidgetData } from "../dashboardService"
import { DashboardWidgetResult, WidgetData, WidgetDataRetrievalStatus } from "../dashboardTypes"

interface ThreeLineWidgetInfo {
  title: string
  content: string
  caption: string
  contentColor?: TypographyProps["color"]
}

interface ThreeLineWidgetProps {
  widgetInfo: ThreeLineWidgetInfo
}

export const ThreeLineWidget = ({ widgetInfo: { title, content, caption, contentColor } }: ThreeLineWidgetProps) => (
  <StyledCard sx={{ width: "100%" }}>
    <Stack>
      <Typography gutterBottom>{title}</Typography>
      <Typography variant="body1bold" color={contentColor} gutterBottom>
        {content}
      </Typography>
      <Typography variant="caption">{caption}</Typography>
    </Stack>
  </StyledCard>
)

// FUTURE: Remove this hardcoded value
const topWidgetInfo = [
  {
    title: "Employer contribution",
    caption: "You + Spouse | Monthly",
    content: formatCents(89000),
  },
  {
    title: "Total premiums",
    caption: "Monthly",
    content: formatCents(90832),
  },
  {
    title: "Payroll deduction",
    caption: "Monthly",
    content: formatCents(1832),
    contentColor: "error",
  },
  {
    title: "2024 balance",
    content: formatCents(0),
    caption: "$109.12 | 2023 balance",
  },
] satisfies ThreeLineWidgetInfo[]

interface ThreeLineWidgetContainerProps {
  widgetInfoList: ThreeLineWidgetInfo[]
}

export const ThreeLineWidgetContainer = ({ widgetInfoList }: ThreeLineWidgetContainerProps) => (
  <Grid
    container
    mb={4}
    gap={2}
    width="100%"
    display="grid"
    gridTemplateColumns={{
      xs: `repeat(${1}, minmax(0, 1fr))`,
      sm: `repeat(${2}, minmax(0, 1fr))`,
      lg: `repeat(${4}, minmax(0, 1fr))`,
    }}
  >
    {widgetInfoList.map((info: ThreeLineWidgetInfo) => (
      <Grid item key={info.title}>
        <ThreeLineWidget widgetInfo={info} />
      </Grid>
    ))}
  </Grid>
)

const validateWidgetStatus = (status: WidgetDataRetrievalStatus) => status !== UNAUTHORIZED_STATUS

const getEmployerWidgetComponent = (
  widgetId: string,
  companyId: Uuid,
  data: WidgetData,
  isLoading: boolean,
  status: WidgetDataRetrievalStatus
) => {
  switch (widgetId) {
    // FUTURE: show this widget depending on a condition, currently it's using the dashboard endpoint to know if it has to render this
    case EMPLOYEE_ENROLLMENT_STATUS:
      return <EmployeeEnrollmentStatusWidget companyId={companyId} />
    default:
      return <AutopayUpcomingFundingWidget data={data} isLoading={isLoading} status={status} />
  }
}

const isEmployeeWidget = (widget: DashboardWidgetResult) =>
  validateWidgetStatus(widget.status) && EMPLOYEE_DASHBOARD_WIDGETS.includes(widget.widgetId as never)

const isEmployerWidget = (widget: DashboardWidgetResult) =>
  validateWidgetStatus(widget.status) && EMPLOYER_DASHBOARD_WIDGETS.includes(widget.widgetId as never)

export const Dashboard = () => {
  const { user } = useAuth()
  const { activeRole } = useRoleStore()
  const navigate = useNavigate()
  const companyAssociations: CompanyAssociation[] = useMemo(() => getAllCompanyAssociations(user), [user])
  const [selectedCompany, _] = useState(getActiveCompany(user))
  const [isAutoPayReminderModalOpen, setIsAutoPayReminderModalOpen] = useState(false)
  const isInternalAdminOnly = useMemo(
    () => companyAssociations.length === 1 && companyAssociations[0].companyId === ALL_COMPANY_ACCESS_ID,
    [companyAssociations]
  )
  const companyId = isInternalAdminOnly ? appConfig.internalAdminCompanyId : selectedCompany?.companyId
  const { notify } = useNotifications("dashboard")
  const { value: employeeLedgerDashboardWidgets, loading: isLoadingTopBar } = useFeatureFlag(
    "employeeLedgerDashboardWidgets",
    false
  )

  const hraPlanId = user?.companyHRAPlan?.[0].id

  const {
    data: widgetData,
    isLoading: isWidgetDataLoading,
    isRefetching: isWidgetDataRefetching,
    isError: isWidgetDataError,
  } = useGetDashboardWidgetData(companyId)

  const {
    data: autoPayCutOffDateResponse,
    isLoading: isAutoPayCutOffDateResponseLoading,
    isError: isAutoPayCutOffDateResponseError,
  } = useGetAutoPayCutOffDate(companyId, hraPlanId!)

  const isLoading = isLoadingTopBar || isWidgetDataLoading

  useEffect(() => {
    if (isWidgetDataError) {
      notify("Error getting dashboard data", "error")
    }
  }, [isWidgetDataError, notify])

  useEffect(() => {
    if (
      !isAutoPayCutOffDateResponseLoading &&
      !isAutoPayCutOffDateResponseError &&
      autoPayCutOffDateResponse?.isAutoPayAvailable &&
      autoPayCutOffDateResponse?.autoPayCutOffDate
    ) {
      const cutOffDayDifference = differenceInDays(
        createDateFromText(autoPayCutOffDateResponse.autoPayCutOffDate.toString()),
        new Date()
      )
      if (cutOffDayDifference < 5 && cutOffDayDifference >= 0) {
        setIsAutoPayReminderModalOpen(true)
      }
    }
  }, [isAutoPayCutOffDateResponseLoading, isAutoPayCutOffDateResponseError, autoPayCutOffDateResponse])

  const autoPayCutOffDate = autoPayCutOffDateResponse?.autoPayCutOffDate

  const employeeDashboardWidgets: DashboardWidgetResult[] = widgetData?.filter(isEmployeeWidget) ?? []

  const employerDashboardWidgets: DashboardWidgetResult[] = widgetData?.filter(isEmployerWidget).toReversed() ?? []

  const closeConfirmationModal = () => {
    setIsAutoPayReminderModalOpen(false)
  }

  const handleConfirmationModalConfirm = () => {
    navigate("/company/settings?tab=autopay")
  }

  return (
    <AuthGuard>
      <DashboardLayout>
        <Helmet title="Dashboard" />
        <Box>
          <Typography variant="h1" gutterBottom data-qa="dashboard-title">
            Dashboard
          </Typography>
          {isLoading ? (
            <Grid item xs={12}>
              <LoadingMasonryArea data-qa="loading-dashboard" />
            </Grid>
          ) : (
            <Box>
              {activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID ? (
                <Box display="flex" flexDirection="row" gap={6} alignItems="flex-start">
                  {compact(employerDashboardWidgets).map(w =>
                    getEmployerWidgetComponent(w.widgetId, companyId as Uuid, w.data, isWidgetDataRefetching, w.status)
                  )}
                </Box>
              ) : (
                <>
                  {employeeLedgerDashboardWidgets && <ThreeLineWidgetContainer widgetInfoList={topWidgetInfo} />}
                  <Box display="flex" flexDirection="column" gap={6}>
                    {employeeDashboardWidgets.length > 0 && !isWidgetDataLoading && (
                      <EmployeeDashboard data={employeeDashboardWidgets} isLoading={isWidgetDataRefetching} />
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}

          {autoPayCutOffDate && (
            <ConfirmationModal
              isOpen={isAutoPayReminderModalOpen}
              onClose={closeConfirmationModal}
              onConfirm={handleConfirmationModalConfirm}
              title="AutoPay Setup Notice"
              message={`Important! To ensure your AutoPay enrollment is successful, please complete your setup before ${formatDate(createDateFromText(autoPayCutOffDate.toString()))}. After this date, AutoPay enrollment will no longer be available.`}
              cancelLabel="Cancel"
              actionLabel="Set Up AutoPay"
              maxWidth="xs"
            />
          )}
        </Box>
      </DashboardLayout>
    </AuthGuard>
  )
}
