import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { Redirect } from "@/components/Navigation"
import { useGetPeople } from "@/features/People/peopleService"
import { TcHubAddPersonModal } from "@/features/TCHub/Users/TcHubAddPersonModal/TcHubAddPersonModal"
import { createDataQa } from "@/utils/dataQa"
import { Option, Uuid } from "@/utils/types"
import { getClassByEmploymentId } from "@/utils/util"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Grid, Tab, Typography } from "@mui/material"
import { Dispatch, ReactNode, SetStateAction, SyntheticEvent, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useSearchParams } from "react-router-dom"
import { isUserAdministrator } from "../Auth/authUtils"
import { AuthGuard } from "../Auth/guards/AuthGuard"
import { useAuth } from "../Auth/useAuth"
import { convertHraClassModelToCustomClassDetails } from "../Benefits/benefitsUtils"
import { useGetCurrentClasses } from "../CreateCompany/components/Steps/Setup/PlanStructure/planStructureService"
import { CustomClassDetails } from "../CreateCompany/components/Steps/Setup/PlanStructure/planStructureTypes"
import { DashboardLayout } from "../Dashboard/components/DashboardLayout"
import { activeUsersFilter, inactiveUsersFilter } from "../TCHub/tcHubService"
import { AddPersonModal } from "./AddPersonModal/AddPersonModal"
import { PeopleClassesDrawer } from "./components/PeopleClassesDrawer"
import { PeopleProfileDrawer } from "./components/PeopleProfileDrawer"
import { PeopleTable } from "./components/PeopleTable"
import { PeopleTableFilterDropdown } from "./components/PeopleTableFilterDropdown"
import { ACTIVE_PEOPLE_TAB, INACTIVE_PEOPLE_TAB } from "./peopleConstants"
import { EditPersonInfo, Person, PersonEditClassInfo, PersonModel } from "./peopleTypes"

interface CommonPeopleTableProps {
  people: Person[]
  isLoading: boolean
  companyField?: (row: PersonModel) => string
  isTchubPage: boolean
}

const tabPanels = [
  { label: "Active", value: ACTIVE_PEOPLE_TAB, "data-qa": createDataQa("active-people-tab") },
  { label: "Inactive", value: INACTIVE_PEOPLE_TAB, "data-qa": createDataQa("inactive-people-tab") },
] as const

interface PeopleTabsProps extends CommonPeopleTableProps {
  searchCriteria: string
  hideSendSignUpLink?: boolean
  hideCopySignUpLink?: boolean
  hideWaive?: boolean
  filterCriteria: { status: string[]; class: string[] }
  showClassColumn: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setPersonProfileId: Dispatch<SetStateAction<Uuid | undefined>>
  setPersonEditClassInfo?: EditPersonInfo
}

const PeopleTabs = ({
  searchCriteria,
  hideSendSignUpLink,
  hideCopySignUpLink,
  hideWaive,
  setOpen,
  setPersonProfileId,
  filterCriteria,
  people,
  isLoading,
  isTchubPage,
  showClassColumn,
  companyField,
  setPersonEditClassInfo,
}: PeopleTabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab") === INACTIVE_PEOPLE_TAB ? INACTIVE_PEOPLE_TAB : ACTIVE_PEOPLE_TAB

  const handleChange = (event: SyntheticEvent, newTab: string) => {
    setSearchParams({ tab: newTab, ...searchParams })
  }

  return (
    <TabContext value={tab} data-qa="people-tabs">
      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <TabList onChange={handleChange} data-qa="people-tabs">
              {tabPanels.map(panel => (
                <Tab key={panel.value} label={panel.label} value={panel.value} data-qa={panel["data-qa"]} />
              ))}
            </TabList>
          </Grid>
        </Grid>
      </Box>
      <TabPanel value={ACTIVE_PEOPLE_TAB} sx={{ padding: "0 0", mt: 2 }}>
        <PeopleTable
          people={activeUsersFilter(people ?? [])}
          searchCriteria={searchCriteria}
          filterCriteria={filterCriteria}
          setPersonProfileId={setPersonProfileId}
          setOpen={setOpen}
          hideSendSignUpLink={hideSendSignUpLink}
          hideCopySignUpLink={hideCopySignUpLink}
          hideWaive={hideWaive}
          isLoading={Boolean(isLoading)}
          isTchubPage={isTchubPage}
          companyField={companyField}
          showClassColumn={showClassColumn}
          setPersonEditClassInfo={setPersonEditClassInfo}
        />
      </TabPanel>
      <TabPanel value={INACTIVE_PEOPLE_TAB} sx={{ padding: "0 0", mt: 2 }}>
        <PeopleTable
          people={inactiveUsersFilter(people ?? [])}
          searchCriteria={searchCriteria}
          filterCriteria={filterCriteria}
          setPersonProfileId={setPersonProfileId}
          setOpen={setOpen}
          hideSendSignUpLink
          hideCopySignUpLink
          hideWaive
          isLoading={Boolean(isLoading)}
          isTchubPage={isTchubPage}
          companyField={companyField}
          showClassColumn={showClassColumn}
          setPersonEditClassInfo={setPersonEditClassInfo}
        />
      </TabPanel>
    </TabContext>
  )
}

interface PeoplePageContentProps {
  peopleTitle?: ReactNode
  hideSendSignUpLink?: boolean
  hideCopySignUpLink?: boolean
  hideWaive?: boolean
  showClassColumn?: boolean
  customClassDetails?: CustomClassDetails[]
  classOptions?: Option<string, string>[]
}

export const PeoplePageContent = ({
  peopleTitle,
  hideSendSignUpLink = false,
  hideCopySignUpLink = false,
  hideWaive = false,
  people,
  isLoading,
  isTchubPage,
  classOptions,
  showClassColumn = false,
  customClassDetails,
  companyField,
}: PeoplePageContentProps & CommonPeopleTableProps) => {
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [personProfileId, setPersonProfileId] = useState<Uuid>()
  const [personEditClassInfo, setPersonEditClassInfo] = useState<PersonEditClassInfo>()
  const [isAddingPerson, setIsAddingPerson] = useState<boolean>(false)
  const [filterStatusValues, setFilterStatusValues] = useState<string[]>([])
  const [filterClassValues, setFilterClassValues] = useState<string[]>([])

  const handleStatusValuesChange = (statusValues: string[]) => setFilterStatusValues(statusValues)

  const handleClassValuesChange = (classValues: string[]) => setFilterClassValues(classValues)

  return (
    <>
      {customClassDetails && (
        <PeopleClassesDrawer
          customClassDetails={customClassDetails}
          open={!!personEditClassInfo}
          handleClose={() => setPersonEditClassInfo(undefined)}
          personEditClassInfo={personEditClassInfo}
        />
      )}

      <Grid container>
        <Grid item xs={12} mb={5}>
          {peopleTitle ?? (
            <Typography variant="h1" gutterBottom display="inline" data-qa="page-header">
              {isTchubPage ? "Users" : "People"}
            </Typography>
          )}
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
          <Grid item>
            <Grid container justifyContent="flex-start" spacing={3}>
              <Grid item>
                <ClearableSearchInput
                  onChange={event => setSearchInputValue(event.target.value)}
                  handleClearClick={() => setSearchInputValue("")}
                  data-qa="input-search-people-table"
                />
              </Grid>

              <PeopleTableFilterDropdown
                filterStatusValues={filterStatusValues}
                handleStatusValuesChange={handleStatusValuesChange}
                classOptions={classOptions}
                filterClassValues={filterClassValues}
                handleClassValuesChange={handleClassValuesChange}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              data-qa="add-people-button"
              variant="contained"
              onClick={() => setIsAddingPerson(true)}
              startIcon={<AddOutlinedIcon />}
            >
              Add new
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <PeopleTabs
          searchCriteria={searchInputValue}
          setOpen={setOpen}
          setPersonProfileId={setPersonProfileId}
          hideSendSignUpLink={hideSendSignUpLink}
          hideCopySignUpLink={hideCopySignUpLink}
          hideWaive={hideWaive}
          filterCriteria={{ status: filterStatusValues, class: filterClassValues }}
          people={people}
          isLoading={isLoading}
          isTchubPage={isTchubPage}
          companyField={companyField}
          showClassColumn={showClassColumn}
          setPersonEditClassInfo={(personInfo: PersonEditClassInfo) => setPersonEditClassInfo(personInfo)}
        />
      </Grid>
      <PeopleProfileDrawer
        open={open}
        setOpen={setOpen}
        personProfileId={personProfileId}
        companyId={people.find(person => person.id === personProfileId)?.companyId}
      />
      {isAddingPerson &&
        (isTchubPage ? (
          <TcHubAddPersonModal onClose={() => setIsAddingPerson(false)} />
        ) : (
          <AddPersonModal onClose={() => setIsAddingPerson(false)} />
        ))}
    </>
  )
}

export const PeoplePageContainer = ({
  peopleTitle,
  hideSendSignUpLink = false,
  hideCopySignUpLink = true,
  hideWaive = false,
}: PeoplePageContentProps) => {
  const { user, companyId } = useAuth()
  const { data: people, isLoading } = useGetPeople(companyId!, peopleData => peopleData)

  const currentPlanId = user?.companyHRAPlan?.[0]?.id
  const { data: currentClasses, isLoading: isLoadingClasses } = useGetCurrentClasses(companyId!, currentPlanId!)

  const customClassDetails = useMemo(
    () => convertHraClassModelToCustomClassDetails(currentClasses) ?? [],
    [currentClasses]
  )

  const classOptions = customClassDetails.map(customClass => ({
    label: customClass.customClassName,
    value: customClass.classId,
  }))

  const peopleWithClasses =
    people?.map(
      person =>
        ({
          ...person,
          classes: getClassByEmploymentId(customClassDetails, person.employmentId),
        }) as Person
    ) ?? []

  return (
    <AuthGuard>
      <PeoplePageContent
        people={peopleWithClasses}
        isLoading={isLoading || isLoadingClasses}
        isTchubPage={false}
        peopleTitle={peopleTitle}
        hideSendSignUpLink={hideSendSignUpLink}
        hideCopySignUpLink={hideCopySignUpLink}
        hideWaive={hideWaive}
        classOptions={classOptions}
        customClassDetails={customClassDetails as CustomClassDetails[]}
        showClassColumn
      />
    </AuthGuard>
  )
}

export const PeoplePage = () => {
  const { user } = useAuth()
  const company = user?.company
  const roles = company?.roles ?? []
  const isAdmin = isUserAdministrator(roles)

  return (
    <DashboardLayout>
      {!isAdmin ? (
        <Redirect to="/" />
      ) : (
        <AuthGuard>
          <Helmet title="People" />
          <PeoplePageContainer />
        </AuthGuard>
      )}
    </DashboardLayout>
  )
}
