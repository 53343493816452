import { EASY_ENROLL } from "@/constants"
import { useAuth, useCompanyEmployment } from "@/features/Auth/useAuth"
import { ON_MARKET, OTHER_INSURANCE, PENDING, WAIVED } from "@/features/BenefitsElection/benefitsElectionConstants"
import { HealthBenefitsElection } from "@/features/BenefitsElection/benefitsElectionTypes"
import { planYearSuffix } from "@/features/BenefitsElection/benefitsElectionUtils"
import { EnrollmentBanner } from "@/features/BenefitsElection/components/EnrollmentBanner"
import { useGetAllRecurringPremiums } from "@/features/BenefitsElection/recurringPremiumsService"
import { RecurringPremium } from "@/features/BenefitsElection/recurringPremiumsTypes"
import { createDataQa } from "@/utils/dataQa"
import { toTitleCase } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { Box, Button, Chip, Typography } from "@mui/material"
import { useFeatureFlag } from "configcat-react"
import { isEmpty } from "lodash"
import { ReactNode } from "react"
import {
  useCheckQleAvailability,
  useGetEnrollmentTimePeriodsValue,
  useGetShoppingSessionsByCompanyAndEmploymentId,
} from "../../dashboardService"
import { WidgetData } from "../../dashboardTypes"
import { getColorByPremiumStatus } from "../../dashboardUtils"
import { RecurringPremiumCard } from "../RecurringPremiumCard"
import { WidgetCard } from "../WidgetCard"
import { WidgetLayout } from "../WidgetLayout"

const HEALTH_INSURANCE_ITEMS_KEY = "healthInsuranceOverviewItems"
const SUCCESS_STATUS = "SUCCESS"

interface HealthInsuranceOverviewWidgetProps {
  isLoading: boolean
  data: WidgetData
  status: string
}

interface PremiumStatusChipProps {
  dataQa: string
  status: string
}
export const PremiumStatusChip = ({ dataQa, status }: PremiumStatusChipProps) => {
  const statusColor = getColorByPremiumStatus(status)

  return (
    <Chip
      data-qa={createDataQa(dataQa, "premium-status-chip")}
      sx={{
        color: "white",
        backgroundColor: statusColor,
      }}
      label={toTitleCase(status)}
    />
  )
}

const QleCard = () => (
  <WidgetCard
    title="Has your situation changed?"
    description="If you've recently experienced a qualifying life event, such as a change in your household or loss of health coverage, you may have options."
    actions={{
      key: "explore-qle",
      label: "Explore Qualifying Life Events",
      routerLink: "/qle",
    }}
  />
)

const AdditionalPremiumsCard = () => (
  <WidgetCard
    title="Do you have a new recurring premium?"
    // FUTURE SEG-6667: Add matching action for medical expense
    // description="To be able to upload your receipt we will need your Letter of medical necessity"
    actions={[
      {
        key: "add-additional-premium",
        label: "Add recurring premium claim",
        routerLink: "/additional-premiums",
      },
    ]}
  />
)

const EmptyWidgetContainer = ({ children }: { children: ReactNode }) => (
  <WidgetLayout>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
        height: "100%",
      }}
    >
      {children}
    </Box>
  </WidgetLayout>
)

const NoDataAvailableWidget = () => (
  <EmptyWidgetContainer>
    <Typography variant="body1" color="textSecondary">
      We cannot currently retrieve your health insurance information
    </Typography>
  </EmptyWidgetContainer>
)

interface ShoppingStatusWidgetProps {
  shoppingSessionId: Uuid | undefined
  canEmployeeShop: boolean
  noItemsAvailable: boolean
  planYear: string | undefined
}

const ShoppingStatusWidget = ({
  shoppingSessionId,
  canEmployeeShop,
  noItemsAvailable,
  planYear,
}: ShoppingStatusWidgetProps) => {
  const suffix = planYearSuffix(planYear)

  if (canEmployeeShop && !!shoppingSessionId)
    return (
      <EmptyWidgetContainer>
        <Typography variant="body1" color="textSecondary">
          No health plan selected{suffix}. Please select a health plan to continue.
        </Typography>
        <Button variant="contained" href={`/benefits-election/${shoppingSessionId}/welcome`}>
          Shop
        </Button>
      </EmptyWidgetContainer>
    )

  if (noItemsAvailable)
    return (
      <EmptyWidgetContainer>
        <Typography variant="body1" color="textSecondary">
          No health insurance on file{suffix}
        </Typography>
      </EmptyWidgetContainer>
    )
}

const mapHealthBenefitsElectionToRecurringPremium = (
  election: HealthBenefitsElection,
  companyId: Uuid
): RecurringPremium => ({
  companyId,
  carrierName: election.carrierName ?? "",
  companyName: election.carrierName,
  enrolledAllowanceCents: election.premiumAmountCents ?? 0,
  premiumAmountCents: election.premiumAmountCents ?? 0,
  coverageStartDate: election.planEffectiveDate ?? "",
  carrierId: election.carrierId,
  insuranceType: election.insuranceType ?? OTHER_INSURANCE,
  coverageEndDate: election.planEndDate ?? "",
  recurringPremiumMonths: [],
  isPrimary: election.isPrimary ?? false,
  isReimbursement: false,
  coveredFamilyMembers: [],
  healthPlanId: election.healthPlanId ?? "",
  healthPlanName: election.healthPlanName ?? "",
  id: election.id,
  healthBenefitId: election.id,
  employmentId: election.employmentId,
  enrollmentType: election.enrollmentType ?? EASY_ENROLL,
  planMarket: election.planMarket ?? ON_MARKET,
  status: PENDING,
})

export const HealthInsuranceOverviewWidget = ({ isLoading, data, status }: HealthInsuranceOverviewWidgetProps) => {
  const { user } = useAuth()
  const shoppingSession = user?.shoppingSession
  const employeeId = user?.company?.employeeId!
  const { companyId, employmentId } = useCompanyEmployment()

  const { data: ledgerRecurringPremiums, isLoading: isLoadingRps } = useGetAllRecurringPremiums(companyId, employmentId)
  const { data: shoppingSessions, isLoading: isLoadingShoppingSession } =
    useGetShoppingSessionsByCompanyAndEmploymentId(companyId, employmentId)

  const { data: enrollmentTimePeriods } = useGetEnrollmentTimePeriodsValue(employeeId)
  const { data: qleAvailabilityResponse, isSuccess: isSuccessQle } = useCheckQleAvailability(employeeId)
  const { value: qleEnabledValue } = useFeatureFlag("qleEnabled", false)
  const showQleCard = isSuccessQle && qleAvailabilityResponse.isQleAvailable && qleEnabledValue

  const noDataAvailable =
    (isEmpty(data) && status !== SUCCESS_STATUS) || (!(HEALTH_INSURANCE_ITEMS_KEY in data) && status === SUCCESS_STATUS)

  if (noDataAvailable) {
    return <NoDataAvailableWidget />
  }

  const noItemsAvailable =
    status === SUCCESS_STATUS && HEALTH_INSURANCE_ITEMS_KEY in data && data.healthInsuranceOverviewItems.length === 0

  const timePeriod = enrollmentTimePeriods?.find(period => period.canShop)
  const canEmployeeShop = !!timePeriod?.canShop && !!shoppingSession
  const canAddOtherPremiums = !canEmployeeShop && !noItemsAvailable
  const planYear = timePeriod?.planYear ?? (new Date().getFullYear() + 1).toString()

  const waivedHealthBenefitsElections =
    shoppingSessions
      ?.filter(session => session.shoppingStatus === WAIVED && session.planYear === parseInt(planYear))
      .flatMap(session => session.healthBenefitElections || []) || []

  const waivedRecurringPremiums = waivedHealthBenefitsElections.map(election =>
    mapHealthBenefitsElectionToRecurringPremium(election, companyId)
  )

  const allRecurringPremiums = [...(ledgerRecurringPremiums ?? []), ...waivedRecurringPremiums]
  const isSingleColumn = allRecurringPremiums.length === 1
  return (
    <>
      {canEmployeeShop && <EnrollmentBanner variant="outside-shopping-flow" planYear={planYear} />}
      <ShoppingStatusWidget
        planYear={planYear}
        canEmployeeShop={!!canEmployeeShop}
        shoppingSessionId={shoppingSession?.id}
        noItemsAvailable={noItemsAvailable}
      />
      {canAddOtherPremiums && (
        <WidgetLayout isLoading={isLoading} sx={{ p: 0 }}>
          <AdditionalPremiumsCard />
        </WidgetLayout>
      )}
      <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: isSingleColumn ? "1fr" : "repeat(2, 1fr)" }} gap={6}>
        {allRecurringPremiums.map(item => (
          <Box key={item.healthPlanId}>
            <RecurringPremiumCard
              key={item.healthPlanId}
              data={item}
              isLoading={isLoadingRps || isLoadingShoppingSession}
            />
          </Box>
        ))}
      </Box>
      {showQleCard && (
        <WidgetLayout sx={{ p: 0, m: 0 }} isLoading={isLoading}>
          <QleCard />
        </WidgetLayout>
      )}
    </>
  )
}
