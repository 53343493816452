import bankBuildingPiggyBank from "@/assets/svg/bank-building-piggy-bank.svg"
import { StyledCard } from "@/components/StyledCard"
import { appConfig } from "@/config"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { Button, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useScript } from "usehooks-ts"
import { PermissionGuard } from "../Auth/guards/PermissionGuard"
import { DisbursementAccountDetails, FundingEventSummary } from "../Funding/fundingTypes"
import { useAuthToken } from "./autopayService"
import { BankingActivityModal } from "./components/BankingActivityModal"
import { DisbursementAccountSnapshot } from "./components/DisbursementAccountSnapshot"
import { RecentFundingEventSnapshot } from "./components/RecentFundingEventSnapshot"
import { UpcomingFundingEventSnapshot } from "./components/UpcomingFundingEventSnapshot"

interface AutoPayOverviewProps {
  latestScheduledEvent: FundingEventSummary
  disbursementAccountDetails: DisbursementAccountDetails
  recentFundingEvent: FundingEventSummary
}

export const AutoPayOverview = ({
  latestScheduledEvent,
  disbursementAccountDetails,
  recentFundingEvent,
}: AutoPayOverviewProps) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  const { data: authTokenData, isLoading: loading } = useAuthToken(companyId)
  const [showActivityModal, setShowActivityModal] = useState(false)

  const onClose = () => {
    setShowActivityModal(false)
  }

  const openActivityModal = () => {
    setShowActivityModal(true)
  }
  useScript(appConfig.unitScriptUrl)
  return (
    <PermissionGuard in="accounts_account_management">
      <Grid container data-qa="autopay-overview" spacing={4}>
        <Grid item xs={12} sm={6}>
          <UpcomingFundingEventSnapshot latestScheduledEvent={latestScheduledEvent} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DisbursementAccountSnapshot disbursementAccountDetails={disbursementAccountDetails} />
        </Grid>
      </Grid>
      <Grid item>
        <StyledCard>
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <unit-elements-activity
              customer-token={authTokenData?.token ?? ""}
              theme={appConfig.unitThemeUrl}
              query-filter=""
              hide-title="false"
              hide-filter-button="false"
              transactions-per-page={10}
              pagination-type="pagination"
            />
          )}
        </StyledCard>
      </Grid>
      <Grid container data-qa="autopay-overview" spacing={4}>
        <Grid item xs={12} sm={6}>
          <RecentFundingEventSnapshot recentFundingEvent={recentFundingEvent} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledCard sx={{ p: 2 }}>
            <CardHeader
              title={<Typography variant="h5">Bank Statements</Typography>}
              action={<Button onClick={() => navigate("/autopay?tab=autopay-activity")}>View all</Button>}
            />
            <CardContent sx={{ display: "flex", gap: 4, alignItems: "center" }}>
              <CardMedia
                component="img"
                src={bankBuildingPiggyBank}
                alt="bank-icon"
                sx={{ width: "120px", objectFit: "fill" }}
              />
              <Typography>Download your statements by searching for the account and the statement date.</Typography>
            </CardContent>
            <CardActions>
              <Button fullWidth variant="contained" onClick={openActivityModal}>
                Download
              </Button>
            </CardActions>
          </StyledCard>
        </Grid>
        <BankingActivityModal companyId={companyId} onClose={onClose} open={showActivityModal} />
      </Grid>
    </PermissionGuard>
  )
}
