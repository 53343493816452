import { addEmployeeToClass, addPerson } from "@/features/People/peopleManagementEndpoints"
import { Uuid } from "@/utils/types"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { EmployeeClassRequest } from "../peopleTypes"
import { addPersonFormToPayload, handleAddPersonResult, mapAddEmployeeRespToPayload } from "./addPersonUtils"
import { AddPersonValues } from "./addPersonValidations"

export const useAddPerson = (companyId: Uuid | undefined, currentPlanId: Uuid | undefined) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["add-person"],
    mutationFn: async (values: AddPersonValues) => {
      if (!companyId || !currentPlanId) {
        return
      }
      return handleAddPersonResult(
        companyId,
        await addPerson(companyId, addPersonFormToPayload(values, companyId, currentPlanId)),
        queryClient
      )
    },
  })
}

export const useAddEmployeeClass = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: EmployeeClassRequest) => addEmployeeToClass(mapAddEmployeeRespToPayload(values)),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getCurrentClasses"],
      })
    },
  })
}
