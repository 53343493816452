import { createDataQa } from "@/utils/dataQa"
import { AlertColor } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback } from "react"

export const useNotifications = (name: string, showRetryMessageOnError = false) => {
  const { enqueueSnackbar } = useSnackbar()

  const notify = useCallback(
    (message: string, status: AlertColor = "info", reason?: string) => {
      const notificationMessage =
        status === "error" && showRetryMessageOnError
          ? `${message} Please try again later. If the issue persists, contact support for assistance.`
          : message
      enqueueSnackbar(notificationMessage, {
        variant: "simpleNotification",
        selection: status,
        dataQa: createDataQa(name, reason, status),
      })
    },
    [enqueueSnackbar, name, showRetryMessageOnError]
  )

  return { notify }
}
