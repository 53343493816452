import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { StyledCard } from "@/components/StyledCard"
import { BaseTable, OrderByItemType } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { ServiceDocument } from "@/features/Documents/documentsTypes"
import { formatDate, toTitleCase } from "@/utils/formatting"
import { ExpandMore, FileDownloadOutlined } from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
  TableCell,
  Typography,
} from "@mui/material"
import { constant, noop } from "lodash"
import { useMemo, useState } from "react"

interface DocumentsAccordionProps {
  title: string
  documents?: ServiceDocument[]
  isLoading: boolean
  expanded: boolean
  onExpandChange: (expanded: boolean) => void
  selectedDocumentId?: string
  isDownloading: boolean
  onDownload: (documentId: string) => void
  qaPrefix: string
}

const DocumentsTable = ({
  sortedDocuments,
  orderBy,
  setOrderBy,
  qaPrefix,
  onDownload,
  isDownloading,
  selectedDocumentId,
}: {
  sortedDocuments: ServiceDocument[]
  orderBy: OrderByItemType[]
  setOrderBy: (fn: (state: OrderByItemType[]) => OrderByItemType[]) => void
  qaPrefix: string
  onDownload: (documentId: string) => void
  isDownloading: boolean
  selectedDocumentId?: string
}) => (
  <BaseTable
    rows={sortedDocuments}
    selected={[]}
    searchCriteria=""
    onToggleSelect={noop}
    onToggleOrderBy={headId =>
      setOrderBy(state => {
        if (state[0]?.headCellId === headId) {
          return [
            {
              headCellId: headId,
              direction: state[0].direction === "asc" ? "desc" : "asc",
            },
          ]
        }
        return [{ headCellId: headId, direction: "asc" }]
      })
    }
    onToggleSelectAll={noop}
    onPageChange={noop}
    onRowsPerPageChange={noop}
    uniqueIdSelector={constant("")}
    headCells={[
      { id: "name", label: "Document Name", sortable: true, alignment: "left" },
      { id: "documentType", label: "Type", sortable: true, alignment: "left" },
      { id: "createdAt", label: "Date", sortable: true, alignment: "left" },
      { id: "download", label: "Download", alignment: "left" },
    ]}
    rowsPerPage={25}
    page={0}
    fullWidth
    orderBy={orderBy}
    hidePagination
  >
    {({ row }) => (
      <>
        <TableCell>
          <Typography sx={TABLE_CELL_PADDING}>{row.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography sx={TABLE_CELL_PADDING}>{toTitleCase(row.documentType ?? "")}</Typography>
        </TableCell>
        <TableCell>
          <Typography sx={TABLE_CELL_PADDING}>{formatDate(new Date(row.createdAt))}</Typography>
        </TableCell>
        <TableCell>
          <IconButton
            data-qa={`${qaPrefix}-document-download`}
            onClick={() => onDownload(row.documentId ?? "")}
            aria-label="View"
            disabled={isDownloading && selectedDocumentId === row.documentId}
          >
            {isDownloading && selectedDocumentId === row.documentId ? (
              <CircularProgress size={24} />
            ) : (
              <FileDownloadOutlined />
            )}
          </IconButton>
        </TableCell>
      </>
    )}
  </BaseTable>
)

const NoDocuments = ({ qaPrefix }: { qaPrefix: string }) => (
  <Typography variant="body1" textAlign="center" color="text.secondary" py={4} data-qa={`no-${qaPrefix}-documents`}>
    No {qaPrefix} documents available
  </Typography>
)

const LoadingState = ({ qaPrefix }: { qaPrefix: string }) => (
  <SkeletonTableLoader
    data-qa={`skeleton-table-loader-${qaPrefix}-documents`}
    headerTitles={["Document Name", "Type", "Date", "Download"]}
    bodyRowsCount={4}
  />
)

const AccordionContent = ({
  isLoading,
  sortedDocuments,
  qaPrefix,
  orderBy,
  setOrderBy,
  onDownload,
  isDownloading,
  selectedDocumentId,
}: {
  isLoading: boolean
  sortedDocuments: ServiceDocument[]
  qaPrefix: string
  orderBy: OrderByItemType[]
  setOrderBy: (fn: (state: OrderByItemType[]) => OrderByItemType[]) => void
  onDownload: (documentId: string) => void
  isDownloading: boolean
  selectedDocumentId?: string
}) => {
  if (isLoading) {
    return <LoadingState qaPrefix={qaPrefix} />
  }

  if (sortedDocuments.length === 0) {
    return <NoDocuments qaPrefix={qaPrefix} />
  }

  return (
    <DocumentsTable
      sortedDocuments={sortedDocuments}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      qaPrefix={qaPrefix}
      onDownload={onDownload}
      isDownloading={isDownloading}
      selectedDocumentId={selectedDocumentId}
    />
  )
}

export const DocumentsAccordion = ({
  title,
  documents = [],
  isLoading,
  expanded,
  onExpandChange,
  selectedDocumentId,
  isDownloading,
  onDownload,
  qaPrefix,
}: DocumentsAccordionProps) => {
  const [orderBy, setOrderBy] = useState<OrderByItemType[]>([])

  const sortedDocuments = useMemo(() => {
    if (!documents) return []

    return [...documents].sort((a, b) => {
      const [order] = orderBy
      if (!order) return 0

      let aValue = ""
      let bValue = ""

      switch (order.headCellId) {
        case "name":
          aValue = a.name || ""
          bValue = b.name || ""
          break
        case "documentType":
          aValue = a.documentType || ""
          bValue = b.documentType || ""
          break
        case "createdAt":
          aValue = a.createdAt || ""
          bValue = b.createdAt || ""
          break
        default:
          return 0
      }

      return order.direction === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
    })
  }, [documents, orderBy])

  return (
    <StyledCard>
      <Accordion expanded={expanded} onChange={(_, isExpanded) => onExpandChange(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMore data-qa={`expand-${qaPrefix}-documents`} />}
          aria-controls={`${qaPrefix}-documents-content`}
          id={`${qaPrefix}-documents-header`}
        >
          <Typography variant="h5">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionContent
            isLoading={isLoading}
            sortedDocuments={sortedDocuments}
            qaPrefix={qaPrefix}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            onDownload={onDownload}
            isDownloading={isDownloading}
            selectedDocumentId={selectedDocumentId}
          />
        </AccordionDetails>
      </Accordion>
    </StyledCard>
  )
}
