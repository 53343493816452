import { createDataQa, WithDataQa } from "@/utils/dataQa"
import { VoidFn } from "@/utils/types"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { Button, ButtonProps, CircularProgress, Grid, useMediaQuery } from "@mui/material"

// FUTURE SEG-2077: standarize button component to avoid repeating asyncronous logic for startIcon

export const NavigationButton = (props: WithDataQa<ButtonProps>) => <Button {...props} />

export const SkipNavigationButton = (props: ButtonProps) => (
  <NavigationButton data-qa={createDataQa("skip-button")} color="inherit" {...props} />
)

export type ContinueNavigationButtonProps = ButtonProps & {
  isSubmitting?: boolean
  type?: "button" | "submit"
  handleContinue?: VoidFn
}

export const ContinueNavigationButton = ({
  handleContinue,
  isSubmitting,
  disabled,
  children,
  ...props
}: ContinueNavigationButtonProps) => (
  <NavigationButton
    data-qa={createDataQa("continue-button")}
    variant="contained"
    color="primary"
    disabled={isSubmitting || disabled}
    onClick={handleContinue}
    endIcon={isSubmitting ? <CircularProgress size={20} /> : <KeyboardArrowRight />}
    {...props}
  >
    {children ?? "Continue"}
  </NavigationButton>
)

export const BackNavigationButton = ({ ...props }: ButtonProps) => (
  <NavigationButton
    data-qa={createDataQa("back-button")}
    color="inherit"
    startIcon={<KeyboardArrowLeft />}
    {...props}
  />
)

interface FlowNavigationButtonsBaseProps {
  handleBack?: () => void
  handleSkip?: () => void
  continueLabel?: string
  backLabel?: string
  skipLabel?: string
  skipVariant?: "text" | "outlined" | "contained"
  hideContinue?: boolean
}

export type FlowNavigationButtonsProps = ContinueNavigationButtonProps & FlowNavigationButtonsBaseProps

export const FlowNavigationButtons = ({
  handleBack,
  handleSkip,
  continueLabel = "Continue",
  backLabel = "Back",
  skipLabel = "Skip",
  skipVariant = "outlined",
  hideContinue = false,
  sx = {},
  ...props
}: FlowNavigationButtonsProps) => {
  const isMediumOrUp = useMediaQuery(theme => theme.breakpoints.up("md"))
  const showSkip = !!handleSkip
  const showBack = !!handleBack

  const continueButton = !hideContinue && (
    <ContinueNavigationButton
      sx={{
        width: { xs: "100%", md: "auto" },
        ml: { xs: 0, md: 3 },
      }}
      {...props}
    >
      {continueLabel}
    </ContinueNavigationButton>
  )

  const skipButton = showSkip && (
    <SkipNavigationButton
      onClick={handleSkip}
      sx={{ mt: { xs: 3, md: 0 }, width: { xs: "100%", md: "auto" } }}
      variant={skipVariant}
    >
      {skipLabel}
    </SkipNavigationButton>
  )

  const backButton = showBack && (
    <BackNavigationButton onClick={handleBack} sx={{ mt: { xs: 3, md: 0 }, width: { xs: "100%", md: "auto" } }}>
      {backLabel}
    </BackNavigationButton>
  )

  return (
    <Grid container sx={{ alignItems: { sm: "center" }, justifyContent: { sm: "space-between" }, mt: 3, ...sx }}>
      {isMediumOrUp ? (
        <>
          <Grid item>{backButton}</Grid>
          <Grid item>
            {skipButton}
            {continueButton}
          </Grid>
        </>
      ) : (
        <>
          {continueButton}
          {skipButton}
          {backButton}
        </>
      )}
    </Grid>
  )
}
