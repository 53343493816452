import { useRoleStore } from "@/features/Auth/roleStore"
import { ADMINISTRATOR_EXTERNAL_ROLE_ID } from "@/features/People/peopleConstants"
import {
  AccountBalanceWalletOutlined,
  AddchartOutlined,
  DescriptionOutlined,
  GroupOutlined,
  HomeOutlined,
  SettingsApplicationsOutlined,
  SettingsOutlined,
  SyncOutlined,
} from "@mui/icons-material"
import { useMemo } from "react"

export const useDashboardItems = (showAutoPay: boolean, showHris: boolean) => {
  const activeRole = useRoleStore(state => state.activeRole)
  const isAdmin = activeRole === ADMINISTRATOR_EXTERNAL_ROLE_ID

  const items = useMemo(
    () => [
      {
        href: "/",
        title: "Dashboard",
        icon: HomeOutlined,
      },
      {
        href: "/people",
        title: "People",
        icon: GroupOutlined,
        hidden: !isAdmin,
      },
      {
        href: "/hris",
        title: "HRIS Import",
        icon: SyncOutlined,
        hidden: !showHris || !isAdmin,
      },
      {
        href: "/documents",
        title: "Documents",
        icon: DescriptionOutlined,
      },
      {
        href: "/autopay?tab=autopay-overview",
        title: "AutoPay",
        icon: AccountBalanceWalletOutlined,
        hidden: !showAutoPay || !isAdmin,
      },
      {
        href: "/benefits",
        title: "Benefits",
        icon: SettingsApplicationsOutlined,
        hidden: !isAdmin,
      },
      {
        href: "/reports",
        title: "Reports",
        icon: AddchartOutlined,
        hidden: !isAdmin,
      },
      {
        href: "/company/settings?tab=company-profile",
        title: "Settings",
        icon: SettingsOutlined,
        hidden: !isAdmin,
      },
    ],
    [isAdmin, showAutoPay, showHris]
  )

  return items
}
