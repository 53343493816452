import {
  AmountTextField,
  AmountTextFieldWithChips,
  SingleEmployeeAmountTextFieldWithTable,
} from "@/components/AmountTextField"
import { DrawerForm } from "@/components/DrawerForm"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { SearchInput } from "@/components/SearchInput"
import { SelectMultiField } from "@/components/SelectMultiField"
import { ClampedTextField } from "@/components/TextFields"
import {
  CUSTOM_CLASS_REIMBURSEMENT_STRUCTURES,
  ELIGIBLE_FOR_REIMBURSEMENT,
  PREMIUM_ONLY,
  WAITING_PERIODS,
} from "@/features/CreateCompany/createCompanyConstants"
import { useNotifications } from "@/services/notificationService"
import { takeCommandPrimary } from "@/theme/palette"
import { formatCentsFlat, formatDollarToCents } from "@/utils/formatting"
import { STATE_OPTIONS } from "@/utils/States"
import {
  ContentCopyOutlined,
  DeleteOutlined,
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowUp,
} from "@mui/icons-material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Chip,
  ChipProps,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Radio,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material"
import { ErrorMessage, Formik } from "formik"
import { isEqual, isString } from "lodash"
import { Dispatch, ReactNode, SetStateAction, useRef, useState } from "react"
import * as Yup from "yup"
import { AmountAgesTable } from "../../../common/AmountAgesTable"
import { MultiSelectClassSection } from "./MultiSelectClassSection"
import { WaitingPeriodRadioCard } from "./PlanStructure"
import { calculateReimbursementRate } from "./planStructureEndpoints"
import {
  ClassCardKey,
  CustomClassData,
  CustomClassDataWithoutDeleted,
  CustomClassDetails,
  CustomClassReimbursementStructure,
  EligibleForReimbursement,
  WaitingPeriod,
} from "./planStructureTypes"

const ClassChip = ({ sx = {}, ...props }: ChipProps) => (
  <Chip variant="outlined" sx={{ borderRadius: 5, ...sx }} {...props} />
)

export const CREATE_NEW_CLASS_INITIAL_VALUES = {
  customClassName: "",
  isFullTime: false,
  isPartTime: false,
  isSalary: false,
  isNonSalary: false,
  isSeasonal: false,
  isNonSeasonal: false,
  isSpecificGeography: "",
  reimbursementStructure: "",
  geographyDescription: "",
  employeeAmount: "0",
  employeeAndSpouseAmount: "0",
  employeeAndChildrenAmount: "0",
  employeeAndSpouseAndChildrenAmount: "0",
  eligibleForReimbursement: PREMIUM_ONLY,
  waitingPeriod: "",
  unique: "",
}

interface ClassEditorFormValues {
  ageCurveId?: string
  customClassName: string
  isFullTime: boolean
  isPartTime: boolean
  isSalary: boolean
  isNonSalary: boolean
  isSeasonal: boolean
  isNonSeasonal: boolean
  isSpecificGeography: boolean
  reimbursementStructure: CustomClassReimbursementStructure
  geographyDescription: string
  employeeAmount: string
  employeeAndSpouseAmount: string
  employeeAndChildrenAmount: string
  employeeAndSpouseAndChildrenAmount: string
  eligibleForReimbursement: EligibleForReimbursement
  waitingPeriod: WaitingPeriod
  unique?: boolean
}

const CreateNewClassValidationSchema = Yup.object({
  customClassName: Yup.string().required("Please enter a class name"),
  isFullTime: Yup.bool(),
  isPartTime: Yup.bool(),
  isSalary: Yup.bool(),
  isNonSalary: Yup.bool(),
  isSeasonal: Yup.bool(),
  isNonSeasonal: Yup.bool(),
  isSpecificGeography: Yup.boolean().required("Geographic area is required"),
  reimbursementStructure: Yup.string().required("Reimbursement structure is required"),
  eligibleForReimbursement: Yup.string().required("What do you want to reimburse is required"),
  waitingPeriod: Yup.string().required("Waiting period is required"),
  employeeAmount: Yup.string().required("Amount is required"),
  employeeAndSpouseAmount: Yup.string().when("reimbursementStructure", {
    is: (reimbursementStructure: any) =>
      reimbursementStructure === "VARY_BY_FAMILY_SIZE" || reimbursementStructure === "VARY_BY_FAMILY_SIZE_AND_AGE",
    then: () => Yup.string().required("Amount is required"),
  }),
  employeeAndChildrenAmount: Yup.string().when("reimbursementStructure", {
    is: (reimbursementStructure: any) =>
      reimbursementStructure === "VARY_BY_FAMILY_SIZE" || reimbursementStructure === "VARY_BY_FAMILY_SIZE_AND_AGE",
    then: () => Yup.string().required("Amount is required"),
  }),
  employeeAndSpouseAndChildrenAmount: Yup.string().when("reimbursementStructure", {
    is: (reimbursementStructure: any) =>
      reimbursementStructure === "VARY_BY_FAMILY_SIZE" || reimbursementStructure === "VARY_BY_FAMILY_SIZE_AND_AGE",
    then: () => Yup.string().required("Amount is required"),
  }),
  geographyDescription: Yup.string().when("isSpecificGeography", {
    is: true,
    then: schema => schema.required("Specific area is required"),
  }),
})
  .test("fullTimeOrPartTime", "", obj => {
    if (obj.isFullTime || obj.isPartTime) {
      return true
    }

    return new Yup.ValidationError(
      "Must select at least one checkbox", // your custom error message
      null,
      "fullTimeOrPartTime"
    )
  })
  .test("seasonalOrNonSeasonal", "", obj => {
    if (obj.isSeasonal || obj.isNonSeasonal) {
      return true
    }

    return new Yup.ValidationError(
      "Must select at least one checkbox", // your custom error message
      null,
      "seasonalOrNonSeasonal"
    )
  })
  .test("salaryOrNonSalary", "", obj => {
    if (obj.isSalary || obj.isNonSalary) {
      return true
    }

    return new Yup.ValidationError(
      "Must select at least one checkbox", // your custom error message
      null,
      "salaryOrNonSalary"
    )
  })

interface ConfirmModalProps {
  title: string
  message: string
  cancelLabel?: string
  confirmLabel?: string
  open: boolean
  handleConfirm: () => void
  handleCancel: () => void
  children?: ReactNode
}

const ConfirmModal = ({
  title,
  message,
  cancelLabel = "Cancel",
  confirmLabel = "Yes",
  open,
  handleConfirm,
  handleCancel,
  children,
}: ConfirmModalProps) => (
  <Dialog
    open={open}
    onClose={handleCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="xs"
  >
    <DialogTitle id="alert-dialog-title" variant="h2" fontFamily="Tiempos Headline">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      {children}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} sx={{ color: "black" }}>
        {cancelLabel}
      </Button>
      <Button variant="contained" color="primary" onClick={handleConfirm} autoFocus>
        {confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
)

const CreateNewClassButton = ({ handleClick }: { handleClick: () => void }) => (
  <Button
    onClick={handleClick}
    fullWidth
    sx={{
      minHeight: "20rem",
      color: "primary.dark",
      border: "1px dashed",
      borderColor: "colors.borderGray",
      borderRadius: "4px",
      backgroundColor: "colors.regionGray",
    }}
    data-qa="create-new-class-button"
  >
    <Grid container direction="column" alignItems="center" spacing={2} sx={{ width: "15rem" }}>
      <Grid item>
        <Avatar sx={{ bgcolor: takeCommandPrimary[100] }}>
          <AddCircleOutlineIcon color="primary" />
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="h5">Add new class</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">Create a new class</Typography>
      </Grid>
    </Grid>
  </Button>
)

/** @deprecated Define a specific modal instead, when isOpen is false the modal will not render */
interface ModalConfig {
  handleConfirm: () => void
  title: string
  message: string
  children?: ReactNode
}

interface MultipleSelectionMenuProps {
  selections: ClassCardKey[]
  buttonsEnabled: boolean
  getClassName: (classKey: ClassCardKey) => string
  deleteClasses: (classKeys: ClassCardKey[]) => void
  editClass: (classKey: ClassCardKey) => void
  duplicateClass: (classKey: ClassCardKey) => void
  toggleSelection: () => void
  selectAll: boolean
}

const MultipleSelectionMenu = ({
  selections,
  buttonsEnabled,
  getClassName,
  deleteClasses,
  editClass,
  duplicateClass,
  toggleSelection,
  selectAll,
}: MultipleSelectionMenuProps) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null)
  const dataQaBase = "multiple-ichra-class-select"
  const multipleSelected = selections.length > 1

  const buttonStyling = {
    color: "colors.darkBody",
    "&:hover": {
      color: "primary.main",
    },
  }

  const classNoun = multipleSelected ? "classes" : "class"

  const sortedclassKeys = selections.toSorted((a, b) =>
    isString(a) && isString(b) ? a.localeCompare(b, undefined, { numeric: true }) : 0
  )

  const modalActive = !!modalConfig

  return (
    <>
      {modalActive && <ConfirmModal handleCancel={() => setModalConfig(null)} open={modalActive} {...modalConfig} />}
      <Grid container alignItems="center" display="flex" justifyContent="space-between" wrap="nowrap">
        <Grid container item>
          <FormControlLabel
            label="Select All / Unselect All"
            control={<Checkbox checked={selectAll} onClick={toggleSelection} />}
          />
        </Grid>
        {selections.length > 0 && (
          <Grid container item justifyContent="flex-end" gap={2} sx={{ color: "colors.darkBody" }}>
            <Button
              sx={buttonStyling}
              startIcon={<ContentCopyOutlined />}
              data-qa={`${dataQaBase}-duplicate-button`}
              disabled={multipleSelected || !buttonsEnabled}
              onClick={() => duplicateClass(selections[0])}
            >
              Duplicate
            </Button>
            <Button
              sx={buttonStyling}
              startIcon={<EditOutlined />}
              data-qa={`${dataQaBase}-edit-button`}
              disabled={multipleSelected || !buttonsEnabled}
              onClick={() => editClass(selections[0])}
            >
              Edit
            </Button>
            <Button
              sx={buttonStyling}
              startIcon={<DeleteOutlined />}
              data-qa={`${dataQaBase}-delete-button`}
              disabled={!buttonsEnabled}
              onClick={() => {
                setModalConfig({
                  handleConfirm: () => {
                    deleteClasses(selections)
                    setModalConfig(null)
                  },
                  title: `Are you sure you want to delete the selected ${classNoun}?`,
                  message: `This action will permanently delete the selected ${classNoun}`,
                  children: (
                    <ul>
                      {sortedclassKeys.map(classKey => (
                        <li key={`${classKey}-list-item`}>{getClassName(classKey)}</li>
                      ))}
                    </ul>
                  ),
                })
              }}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}

interface CustomClassCardProps {
  classDetails: CustomClassDetails
  selected: boolean
  showRadio: boolean
  handleClick: () => void
}

const CustomClassCard = ({ classDetails, selected, showRadio, handleClick }: CustomClassCardProps) => {
  const {
    customClassName,
    isSpecificGeography,
    geographyDescription,
    isPartTime,
    isFullTime,
    isSalary,
    isNonSalary,
    isSeasonal,
    isNonSeasonal,
    reimbursementStructure,
    eligibleForReimbursement,
    employeeAmount,
    waitingPeriod,
  } = classDetails

  const location = isSpecificGeography ? `Geographic Area: ${geographyDescription}` : "All Geographic Areas"

  return (
    <Card
      sx={{
        minHeight: "20rem",
        border: "1px solid",
        borderColor: selected ? "colors.borderGreen" : "colors.borderGray",
        backgroundColor: selected ? "#eff8f5" : null,
      }}
      onClick={handleClick}
      data-qa={`custom-card-${customClassName}`}
    >
      <CardActionArea
        sx={{
          padding: "1rem",
          height: "100%",
          display: "flex",
          alignItems: "start",
        }}
      >
        <Grid container>
          <Grid container item direction="row" wrap="nowrap">
            <Grid item>
              {showRadio ? (
                <Radio disableRipple checked={selected} data-qa={`custom-radio-${customClassName}`} />
              ) : (
                <Checkbox disableRipple checked={selected} data-qa={`custom-checkbox-${customClassName}`} />
              )}
            </Grid>
            <Grid container item direction="column">
              <Typography variant="h6">{customClassName}</Typography>
              <Typography variant="caption">{location}</Typography>
            </Grid>
          </Grid>
          <CardContent>
            <Grid container gap={1}>
              {isFullTime && <ClassChip label="Full-Time" />}
              {isPartTime && <ClassChip label="Part-Time" />}
              {isSalary && <ClassChip label="Salary" />}
              {isNonSalary && <ClassChip label="Non-Salary" />}
              {isSeasonal && <ClassChip label="Seasonal" />}
              {isNonSeasonal && <ClassChip label="Non-Seasonal" />}
              <ClassChip label={location} />
              <ClassChip
                label={`${
                  CUSTOM_CLASS_REIMBURSEMENT_STRUCTURES.find(item => item.value === reimbursementStructure)?.title
                }: ${formatCentsFlat(employeeAmount)}`}
              />
              <ClassChip
                label={ELIGIBLE_FOR_REIMBURSEMENT.find(item => item.value === eligibleForReimbursement)?.title}
              />
              <ClassChip
                label={`Waiting Period: ${WAITING_PERIODS.find(item => item.value === waitingPeriod)?.title}`}
              />
            </Grid>
          </CardContent>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

const NoClassesFound = () => (
  <Box sx={{ width: "100%", display: "flex", minHeight: "15rem", justifyContent: "center", alignItems: "center" }}>
    <Typography>No classes found. Please try searching with a different name.</Typography>
  </Box>
)

const DEFAULT_CLASS_PER_PAGE = 4

interface MultipleClassesGridProps {
  classes: CustomClassDataWithoutDeleted | CustomClassDetails[]
  canAddNewClass: boolean
  showRadio?: boolean
  toggleSelection?: (classKey: ClassCardKey) => void
  isSelected?: (classKey: ClassCardKey) => boolean
  createClass?: () => void
}

export const MultipleClassesGrid = ({
  classes,
  canAddNewClass,
  showRadio = false,
  isSelected,
  toggleSelection,
  createClass,
}: MultipleClassesGridProps) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [pageNumber, setPageNumber] = useState(0)
  const [cardsPerPage, setCardsPerPage] = useState(DEFAULT_CLASS_PER_PAGE)

  const classesIsArray = Array.isArray(classes)

  const classesArray: CustomClassDetails[] = classesIsArray
    ? classes
    : Object.keys(classes).map(classKey => classes[classKey])
  const totalClasses = classesArray.length

  const filteredClasses = searchTerm
    ? classesArray.filter(customClass => customClass.customClassName.toUpperCase().includes(searchTerm.toUpperCase()))
    : classesArray
  const amountOfFilteredClasses = filteredClasses.length

  const startIndex = pageNumber * cardsPerPage
  const endIndex = (pageNumber + 1) * cardsPerPage
  const currentClasses = filteredClasses.slice(startIndex, endIndex)

  return (
    <>
      {totalClasses > DEFAULT_CLASS_PER_PAGE && (
        <Grid container>
          <Grid item>
            <TablePagination
              labelRowsPerPage="Show per page"
              rowsPerPageOptions={[2, DEFAULT_CLASS_PER_PAGE, 10]}
              component="div"
              count={amountOfFilteredClasses}
              rowsPerPage={cardsPerPage}
              page={pageNumber}
              onPageChange={(_, newPage) => setPageNumber(newPage)}
              onRowsPerPageChange={event => {
                const newValue = event.target.value
                setCardsPerPage(Number(newValue))
                setPageNumber(0)
              }}
              data-qa="classes-pagination"
              sx={{
                ".MuiTablePagination-toolbar": {
                  minHeight: "2rem",
                  maxHeight: "2rem",
                  my: 1,
                  p: 0,
                },
              }}
            />
          </Grid>

          <Grid item ml="auto" minWidth="14rem">
            <SearchInput
              name="search-class"
              handleChange={newTerm => {
                setSearchTerm(newTerm)
                setPageNumber(0)
              }}
              options={classesArray.map(customClass => customClass.customClassName)}
              small
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        mb={4}
        gap={4}
        mt={3}
        width="100%"
        display="grid"
        gridTemplateColumns={{
          xs: `repeat(${1}, minmax(0, 1fr))`,
          md: `repeat(${2}, minmax(0, 1fr))`,
          lg: `repeat(auto-fill, minmax(20rem, 1fr))`,
        }}
        data-qa="custom-classes-grid"
      >
        {currentClasses.map(classDetails => (
          <CustomClassCard
            classDetails={classDetails}
            selected={isSelected ? isSelected(classDetails.classId) : false}
            handleClick={() => toggleSelection?.(classDetails.classId)}
            key={`custom-class-${classDetails.classId}`}
            showRadio={showRadio}
          />
        ))}
        {canAddNewClass && createClass && <CreateNewClassButton handleClick={createClass} />}
      </Grid>
      {!amountOfFilteredClasses && <NoClassesFound />}
    </>
  )
}

interface CustomClassesFormProps {
  customClasses: CustomClassDataWithoutDeleted
  selections: ClassCardKey[]
  formValues: ClassEditorFormValues
  isEditing: boolean
  selectedGeographicValues: string[]
  classEditorActive: boolean
  createCustomClass: (customClass: CustomClassDetails) => void
  setSelectedGeographicValues: Dispatch<SetStateAction<string[]>>
  setFormValues: Dispatch<SetStateAction<ClassEditorFormValues>>
  setClassEditorActive: Dispatch<SetStateAction<boolean>>
}

const CustomClassesForm = ({
  formValues,
  isEditing,
  customClasses,
  selections,
  selectedGeographicValues,
  classEditorActive,
  createCustomClass,
  setSelectedGeographicValues,
  setFormValues,
  setClassEditorActive,
}: CustomClassesFormProps) => {
  const drawerRef = useRef()
  const [agesTableOpen, setAgesTableOpen] = useState(false)
  const { notify } = useNotifications("custom-classes")

  const hideClassEditor = () => setClassEditorActive(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)

  const resetFormValues = () => setFormValues(CREATE_NEW_CLASS_INITIAL_VALUES as any)

  const handleClose = () => setOpenConfirmation(false)

  const handleClickOpen = () => {
    setOpenConfirmation(true)
  }

  const classIsUnique = (classDetails: CustomClassDetails, classes = customClasses): boolean => {
    const { customClassName: newClassName } = classDetails
    const existingClasses = Object.values(classes)

    for (const customClass of existingClasses) {
      if (newClassName === customClass.customClassName) return false
    }

    return true
  }

  return (
    <Formik
      initialValues={{ ...CREATE_NEW_CLASS_INITIAL_VALUES, ...formValues }}
      enableReinitialize
      validationSchema={CreateNewClassValidationSchema}
      validate={() => ({})}
      innerRef={drawerRef.current}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting, resetForm }) => {
        const {
          ageCurveId,
          customClassName,
          isSpecificGeography,
          geographyDescription,
          isPartTime,
          isFullTime,
          isSalary,
          isNonSalary,
          isSeasonal,
          isNonSeasonal,
          reimbursementStructure,
          eligibleForReimbursement,
          employeeAmount,
          employeeAndSpouseAmount,
          employeeAndChildrenAmount,
          employeeAndSpouseAndChildrenAmount,
          waitingPeriod,
        } = values as ClassEditorFormValues

        try {
          const classDetails = {
            ageCurveId,
            customClassName,
            classId: "",
            isSpecificGeography,
            geographyDescription,
            isPartTime,
            isFullTime,
            isSalary,
            isNonSalary,
            isSeasonal,
            isNonSeasonal,
            reimbursementStructure,
            eligibleForReimbursement,
            employeeAmount: formatDollarToCents(employeeAmount),
            employeeAndSpouseAmount: formatDollarToCents(employeeAndSpouseAmount),
            employeeAndChildrenAmount: formatDollarToCents(employeeAndChildrenAmount),
            employeeAndSpouseAndChildrenAmount: formatDollarToCents(employeeAndSpouseAndChildrenAmount),
            waitingPeriod,
            healthBenefits: [],
          }

          const classes = { ...customClasses }
          const selectedClassId = selections[0]

          if (isEditing) {
            // If editing, don't consider the original class in the uniqueness check
            delete classes[selectedClassId]
          }

          if (!classIsUnique(classDetails, classes)) {
            setFieldError("unique", "You need to change the class name to activate the save button.")

            return
          }

          if (isEditing) {
            classDetails.classId = selectedClassId.toString()
          }

          if (!ageCurveId) {
            const ratesWithAgeCurve = await calculateReimbursementRate({
              states: values.geographyDescription ? values.geographyDescription.split(",") : [],
              employeeAmount: values.employeeAmount,
              employeeAndSpouseAmount: values.employeeAndSpouseAmount,
              employeeAndChildrenAmount: values.employeeAndChildrenAmount,
              employeeAndSpouseAndChildrenAmount: values.employeeAndSpouseAndChildrenAmount,
            })
            const { ageCurveId: fetchedAgeCurveId } = ratesWithAgeCurve || {}
            classDetails.ageCurveId = fetchedAgeCurveId
          }

          createCustomClass?.(classDetails)
          notify(`The ${customClassName} class was ${isEditing ? "saved" : "created"} successfully`, "success")
          resetForm()
          hideClassEditor()
          resetFormValues()
          setSelectedGeographicValues([])
        } catch (error: any) {
          console.error(error.message || "Something went wrong")
          notify("Error creating plan structure", "error")
          setStatus({ success: false })
          setSubmitting(false)
        }
      }}
    >
      {({
        errors,
        isValid,
        dirty,
        values,
        touched,
        initialValues,
        handleChange,
        setFieldValue,
        handleSubmit,
        handleBlur,
        resetForm,
      }) => {
        const confirmAbandon = () => {
          if (dirty && !isEqual(values, initialValues)) handleClickOpen()
          else {
            hideClassEditor()
            resetForm()
            resetFormValues()
            setSelectedGeographicValues([])
          }
        }

        return (
          <>
            <ConfirmModal
              handleCancel={handleClose}
              open={openConfirmation}
              title="Back to reimbursement?"
              message="Are you sure you want to close this form? All changes will be lost."
              handleConfirm={() => {
                handleClose()
                hideClassEditor()
                resetForm()
                resetFormValues()
                setSelectedGeographicValues([])
              }}
            />
            <DrawerForm open={classEditorActive} onClose={confirmAbandon} paperStyle={{ maxWidth: "62rem" }}>
              <Grid item xs={12} mt={5} px={8}>
                <Button
                  data-qa="back-button"
                  type="submit"
                  color="inherit"
                  sx={{ ml: "auto", mr: 3, pr: 4 }}
                  onClick={confirmAbandon}
                >
                  <KeyboardArrowLeft />
                  Back to reimbursement
                </Button>
              </Grid>
              <form noValidate onSubmit={handleSubmit} data-qa="create-class-setup-form">
                <Grid container px={8} pb={10} spacing={5}>
                  <Grid item xs={12} mt={5}>
                    <Typography variant="h1">Custom Classes</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      The input fields below will help you configure a unique class of employees based on criteria
                      specified in ICHRA regulations.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt={5}>
                    <Typography variant="h6" textAlign="left" data-qa="class-name-title">
                      Name your class
                    </Typography>
                    <ClampedTextField
                      data-qa="customClassName-textfield"
                      type="text"
                      name="customClassName"
                      label="Class name"
                      required
                      value={values.customClassName}
                      error={Boolean(touched.customClassName && errors.customClassName)}
                      fullWidth
                      helperText={touched.customClassName && errors.customClassName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      sx={{ my: 3 }}
                    />
                  </Grid>
                  <MultiSelectClassSection
                    title="Please select full-time, part-time, or both"
                    formName="classEditor"
                    handleChange={setFieldValue}
                    elements={[
                      { title: "Full-Time", value: "isFullTime" },
                      { title: "Part-Time", value: "isPartTime" },
                    ]}
                    dataQa="full-part-time-title"
                  />

                  <MultiSelectClassSection
                    title="Please select salary, non-salary, or both"
                    formName="classEditor"
                    handleChange={setFieldValue}
                    elements={[
                      { title: "Salary", value: "isSalary" },
                      { title: "Non-Salary", value: "isNonSalary" },
                    ]}
                    dataQa="salary-non-title"
                  />

                  <MultiSelectClassSection
                    title="Please select seasonal, non-seasonal, or both"
                    formName="classEditor"
                    handleChange={setFieldValue}
                    elements={[
                      { title: "Seasonal", value: "isSeasonal" },
                      { title: "Non-Seasonal", value: "isNonSeasonal" },
                    ]}
                    dataQa="seasonal-non-title"
                  />
                  <Grid item xs={12} mt={3}>
                    <Typography variant="h6" textAlign="left" data-qa="geographic-area-title">
                      Please select specific geographic area or all areas
                    </Typography>
                    <Grid item xs={12} mt={5}>
                      <RadioGroupCard
                        formName="classEditor"
                        name="isSpecificGeography"
                        value={values.isSpecificGeography}
                        handleChange={setFieldValue}
                        elements={[
                          {
                            title: "Specific Geographic Areas",
                            value: true,
                          },
                          {
                            title: "All Geographic Areas",
                            value: false,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  {values.isSpecificGeography && (
                    <Grid item xs={6}>
                      <SelectMultiField
                        selectedValues={selectedGeographicValues}
                        fieldLabel="Specific Geographic Area"
                        required
                        data={STATE_OPTIONS}
                        name="Specific Geographic Area"
                        sx={{ my: 0 }}
                        placeholder="Specific Geographic Area"
                        data-qa="geographic-select"
                        onChange={event => {
                          setSelectedGeographicValues(event.target.value)
                          setFieldValue("geographyDescription", (event.target.value as unknown as string[]).join(","))
                        }}
                        onBlur={handleBlur}
                        helperText={touched.geographyDescription ? errors.geographyDescription : undefined}
                        error={Boolean(touched.geographyDescription && errors.geographyDescription)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} mt={3}>
                    <Typography variant="h6" textAlign="left" data-qa="reimbursement-title">
                      How do you want to structure your reimbursements (reimbursements are set for month)?
                    </Typography>
                    <Grid item xs={12} mt={2}>
                      <Typography variant="caption">You will only reimburse what employees actually spend</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroupCard
                      formName="classEditor"
                      name="reimbursementStructure"
                      value={values.reimbursementStructure}
                      handleChange={(field, value) => {
                        setFieldValue(field, value)
                      }}
                      elements={CUSTOM_CLASS_REIMBURSEMENT_STRUCTURES}
                    />
                  </Grid>
                  {values.reimbursementStructure === "ALL_EMPLOYEES" && (
                    <Grid item xs={12} mt={3}>
                      <Collapse
                        in={values.reimbursementStructure === "ALL_EMPLOYEES"}
                        timeout="auto"
                        orientation="vertical"
                        translate="yes"
                        unmountOnExit
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6">Amount</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <AmountTextField
                              name="employeeAmount"
                              touched={touched.employeeAmount!}
                              errorString={errors.employeeAmount!}
                              error={Boolean(touched.employeeAmount && errors.employeeAmount)}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.employeeAmount}
                              label="Amount"
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  )}
                  {values.reimbursementStructure === "VARY_BY_AGE" && (
                    <Grid item xs={12} mt={3}>
                      <Collapse
                        in={values.reimbursementStructure === "VARY_BY_AGE"}
                        timeout="auto"
                        orientation="vertical"
                        translate="yes"
                        unmountOnExit
                      >
                        <>
                          <Grid item xs={12} mb={2}>
                            <Typography variant="h6">Reimbursement amounts per month</Typography>
                          </Grid>
                          <SingleEmployeeAmountTextFieldWithTable
                            errorString={errors.employeeAmount!}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Employee - Age 21"
                            agesTableOpen={agesTableOpen}
                            setAgesTableOpen={setAgesTableOpen}
                          />
                        </>
                      </Collapse>
                    </Grid>
                  )}
                  {values.reimbursementStructure === "VARY_BY_FAMILY_SIZE" && (
                    <Grid item xs={12} mt={3}>
                      <Collapse
                        in={values.reimbursementStructure === "VARY_BY_FAMILY_SIZE"}
                        timeout="auto"
                        orientation="vertical"
                        translate="yes"
                        unmountOnExit
                      >
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h6">Reimbursement amounts per month</Typography>
                            <Typography variant="caption">
                              Amounts entered for each group are total monthly costs for the entire group, not per
                              person.
                            </Typography>
                          </Grid>
                          <Grid item xs={6} mt={5}>
                            <AmountTextField
                              error={Boolean(touched.employeeAmount && errors.employeeAmount)}
                              touched={touched.employeeAmount!}
                              errorString={errors.employeeAmount!}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.employeeAmount}
                              label="Employee - Age 21"
                              name="employeeAmount"
                            />
                          </Grid>
                          <Grid item xs={6} mt={5}>
                            <AmountTextField
                              error={Boolean(touched.employeeAndSpouseAmount && errors.employeeAndSpouseAmount)}
                              touched={touched.employeeAndSpouseAmount!}
                              errorString={errors.employeeAndSpouseAmount!}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.employeeAndSpouseAmount}
                              label="Employee + Spouse"
                              name="employeeAndSpouseAmount"
                            />
                          </Grid>
                          <Grid item xs={6} mt={5}>
                            <AmountTextField
                              error={Boolean(touched.employeeAndChildrenAmount && errors.employeeAndChildrenAmount)}
                              touched={touched.employeeAndChildrenAmount!}
                              errorString={errors.employeeAndChildrenAmount!}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.employeeAndChildrenAmount}
                              label="Employee + Children"
                              name="employeeAndChildrenAmount"
                            />
                          </Grid>
                          <Grid item xs={6} mt={5}>
                            <AmountTextField
                              error={Boolean(
                                touched.employeeAndSpouseAndChildrenAmount && errors.employeeAndSpouseAndChildrenAmount
                              )}
                              touched={touched.employeeAndSpouseAndChildrenAmount!}
                              errorString={errors.employeeAndSpouseAndChildrenAmount!}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.employeeAndSpouseAndChildrenAmount}
                              label="Employee + Spouse + Children"
                              name="employeeAndSpouseAndChildrenAmount"
                            />
                          </Grid>
                        </>
                      </Collapse>
                    </Grid>
                  )}
                  {values.reimbursementStructure === "VARY_BY_FAMILY_SIZE_AND_AGE" && (
                    <Grid item xs={12} mt={3}>
                      <Collapse
                        in={values.reimbursementStructure === "VARY_BY_FAMILY_SIZE_AND_AGE"}
                        timeout="auto"
                        orientation="vertical"
                        translate="yes"
                        unmountOnExit
                      >
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h6">Reimbursement amounts per month</Typography>
                            <Typography variant="caption">
                              Amounts entered for each group are total monthly costs for the entire group, not per
                              person.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} mt={2} display="flex">
                            <Typography variant="caption" data-qa="varybyfamilysizeandage-reimbursement-description">
                              Here you can see your generated reimbursement roles by age:
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={2}
                              display="flex"
                              ml="auto"
                              alignItems="center"
                              sx={{ cursor: "pointer" }}
                              onClick={() => setAgesTableOpen(!agesTableOpen)}
                            >
                              {agesTableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                              <Link ml="auto" alignItems="center" color="black">
                                <Typography variant="body2" alignItems="center">
                                  Expand
                                </Typography>
                              </Link>
                            </Stack>
                          </Grid>
                          <AmountAgesTable agesTableOpen={agesTableOpen} isAgeAndFamily />
                          <AmountTextFieldWithChips
                            touched={touched.employeeAmount!}
                            errorString={errors.employeeAmount!}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.employeeAmount}
                            label="Employee - Age 21"
                            name="employeeAmount"
                          />
                          <AmountTextFieldWithChips
                            touched={touched.employeeAndSpouseAmount!}
                            errorString={errors.employeeAndSpouseAmount!}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.employeeAndSpouseAmount}
                            label="Employee + Spouse"
                            name="employeeAndSpouseAmount"
                          />
                          <AmountTextFieldWithChips
                            touched={touched.employeeAndChildrenAmount!}
                            errorString={errors.employeeAndChildrenAmount!}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.employeeAndChildrenAmount}
                            label="Employee + Children"
                            name="employeeAndChildrenAmount"
                          />
                          <AmountTextFieldWithChips
                            touched={touched.employeeAndSpouseAndChildrenAmount!}
                            errorString={errors.employeeAndSpouseAndChildrenAmount!}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.employeeAndSpouseAndChildrenAmount}
                            label="Employee + Spouse + Children"
                            name="employeeAndSpouseAndChildrenAmount"
                          />
                        </>
                      </Collapse>
                    </Grid>
                  )}
                  {/* <Grid item xs={12} mt={3}>
                      <Typography variant="h6">What do you want to reimburse?</Typography>
                    </Grid>
                    <EligibleForReimbursementRadioCard
                      value={values.eligibleForReimbursement}
                      setFieldValue={setFieldValue}
                    /> */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Waiting period</Typography>
                  </Grid>
                  <WaitingPeriodRadioCard value={values.waitingPeriod} setFieldValue={setFieldValue} />
                  {errors.unique && (
                    <Grid item>
                      <ErrorMessage
                        name="unique"
                        render={(errorMessage: string) => <FormHelperText error>{errorMessage}</FormHelperText>}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ display: "flex" }} mt={10}>
                    <Box ml="auto" display="flex" alignItems="center">
                      <Button
                        data-qa="class-editor-continue-button"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!(isValid && dirty)}
                        sx={{ ml: "auto", mr: 3 }}
                      >
                        {isEditing ? "Save changes" : "Create"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </DrawerForm>
          </>
        )
      }}
    </Formik>
  )
}

interface CustomClassesProps {
  customClasses: CustomClassDataWithoutDeleted
  createCustomClass?: (customClass: CustomClassDetails) => void
  deleteCustomClasses?: (classKeys: ClassCardKey[]) => Readonly<CustomClassData>
}

export const CustomClasses = ({ customClasses, createCustomClass, deleteCustomClasses }: CustomClassesProps) => {
  const [selectedClasses, setSelectedClasses] = useState<ClassCardKey[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [classEditorActive, setClassEditorActive] = useState(false)
  const [formValues, setFormValues] = useState<ClassEditorFormValues>(CREATE_NEW_CLASS_INITIAL_VALUES as any)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedGeographicValues, setSelectedGeographicValues] = useState<string[]>([])

  const showClassEditor = (initialValues?: CustomClassDetails, edit = false) => {
    setIsEditing(edit)
    if (initialValues) {
      const {
        employeeAmount: single,
        employeeAndSpouseAmount: spouse,
        employeeAndChildrenAmount: children,
        employeeAndSpouseAndChildrenAmount: spouseAndChildren,
        isSpecificGeography,
        geographyDescription,
        ...values
      } = initialValues

      setFormValues({
        employeeAmount: String(single / 100),
        employeeAndSpouseAmount: String(spouse / 100),
        employeeAndChildrenAmount: String(children / 100),
        employeeAndSpouseAndChildrenAmount: String(spouseAndChildren / 100),
        isSpecificGeography,
        geographyDescription,
        ...values,
      })

      if (isSpecificGeography && geographyDescription) {
        setSelectedGeographicValues(geographyDescription.split(","))
      } else {
        setSelectedGeographicValues([])
      }
    } else {
      setFormValues(CREATE_NEW_CLASS_INITIAL_VALUES as any)
      setSelectedGeographicValues([])
    }
    setClassEditorActive(true)
  }

  const setClassSelected = (classKey: ClassCardKey, shouldSelect: boolean) => {
    if (shouldSelect) {
      setSelectedClasses(previouslySelected => [...previouslySelected, classKey])
    } else {
      setSelectedClasses(previouslySelected => previouslySelected.filter(selectedClass => selectedClass !== classKey))
    }
  }

  const isSelected = (classKey: ClassCardKey) => selectedClasses.includes(classKey)

  const editClass = (classKey: ClassCardKey) => {
    const initialValues = customClasses[classKey]
    showClassEditor(initialValues, true)
  }

  const deleteClasses = (classKeys: ClassCardKey[]) => {
    deleteCustomClasses?.(classKeys)
    setSelectedClasses([])
  }

  const duplicateClass = (classKey: ClassCardKey) => {
    const sourceClass = customClasses[classKey]

    const duplicateDetails = {
      ...sourceClass,
      customClassName: sourceClass.customClassName + " - NEW",
    }

    showClassEditor(duplicateDetails)
  }

  const toggleSelectAll = () => {
    const shouldSelectAll = !selectAll
    setSelectAll(shouldSelectAll)
    setSelectedClasses(
      shouldSelectAll ? Object.keys(customClasses).map(classKey => customClasses[classKey].classId) : []
    )
  }

  const toggleSelection = (classKey: ClassCardKey) => {
    const newSelectionStatus = !isSelected(classKey)
    setClassSelected(classKey, newSelectionStatus)
  }

  return (
    <>
      <MultipleSelectionMenu
        selections={selectedClasses}
        buttonsEnabled={!classEditorActive}
        getClassName={(classKey: ClassCardKey) => customClasses[classKey].customClassName}
        editClass={editClass}
        deleteClasses={deleteClasses}
        duplicateClass={duplicateClass}
        toggleSelection={toggleSelectAll}
        selectAll={selectAll}
      />

      <MultipleClassesGrid
        classes={customClasses}
        canAddNewClass
        createClass={() => {
          showClassEditor()
        }}
        isSelected={isSelected}
        toggleSelection={toggleSelection}
      />

      <CustomClassesForm
        customClasses={customClasses}
        selections={selectedClasses}
        createCustomClass={createCustomClass!}
        isEditing={isEditing}
        formValues={formValues}
        selectedGeographicValues={selectedGeographicValues}
        classEditorActive={classEditorActive}
        setFormValues={setFormValues}
        setSelectedGeographicValues={setSelectedGeographicValues}
        setClassEditorActive={setClassEditorActive}
      />
    </>
  )
}
