import { Callout } from "@/components/Branding"
import { ConfirmationModal } from "@/components/ConfirmationModal"
import { ALLOWANCE_TIERS, GENDER_OPTIONS } from "@/constants"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import {
  FAMILY,
  FAMILY_RELATIONSHIP_OPTIONS,
  HOUSEHOLD_INCOME,
  OPEN_ENROLLMENT,
  PERSONAL_INFO,
  PRESCRIPTIONS,
  RECURRING_REIMBURSEMENT,
  SELF,
  WAIVE_COVERAGE,
} from "@/features/BenefitsElection/benefitsElectionConstants"
import {
  useGetAllowances,
  useShoppingSession,
  useShoppingUrl,
  useUpdateAllowance,
} from "@/features/BenefitsElection/benefitsElectionService"
import { useBenefitsElectionStore } from "@/features/BenefitsElection/benefitsElectionStore"
import { Allowance, ShoppingPerson } from "@/features/BenefitsElection/benefitsElectionTypes"
import {
  DifferentAddressAlert,
  FamilyMedicaidAlert,
  FamilyMedicareAlert,
} from "@/features/BenefitsElection/components/BenefitsElectionAlerts"
import { BenefitsElectionStep } from "@/features/BenefitsElection/components/BenefitsElectionStep"
import { CountiesModel } from "@/features/CreateCompany/createCompanyTypes"
import { useNotifications } from "@/services/notificationService"
import { takeCommandPrimary } from "@/theme/palette"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, transformDate } from "@/utils/formatting"
import { AddCircleOutline } from "@mui/icons-material"
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"
import { Box, Button, Card, CardContent, Chip, ChipProps, Grid, IconButton, Skeleton, Typography } from "@mui/material"
import { isEmpty, times } from "lodash"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { EmployeeMedicareAndMedicaidAlerts } from "../PersonalInfo"
import { FamilyMemberForm } from "./FamilyMemberForm"
import { useFamilyState } from "./useFamilyState"

const iconSize = { height: "1.5rem", width: "1.5rem" }

const FamilyChip = ({ sx = {}, ...props }: ChipProps) => (
  <Chip variant="outlined" sx={{ borderRadius: 5, ...sx }} {...props} />
)

interface FamilyMemberCardProps {
  familyMember: ShoppingPerson
  isPending: boolean
  isAddressDifferent: boolean
  showEmployeeName?: boolean
  handleEdit?: () => void
  handleDelete?: () => Promise<void>
}

const stringifyZipCode = (countiesModel: CountiesModel | null) =>
  countiesModel ? `${countiesModel.zipCode} - ${countiesModel.countyName}` : ""

export const FamilyMemberCard = ({
  familyMember,
  isPending,
  isAddressDifferent,
  showEmployeeName = false,
  handleEdit,
  handleDelete,
}: FamilyMemberCardProps) => {
  const {
    firstName = "",
    lastName = "",
    dateOfBirth = "",
    gender = "",
    relationship = "",
    zipCode = null,
    isMedicareEligible = false,
    isEnrolledInMedicaid: enrolledInMedicaid = false,
  } = familyMember.personalInformation ?? {}

  const fullName = `${firstName} ${lastName}`
  const stringifiedZipCode = stringifyZipCode(zipCode)
  const isEnrolledInMedicaid = enrolledInMedicaid
  // Filter and format relationship
  const isEmployee = relationship === SELF
  const filteredRelationship = FAMILY_RELATIONSHIP_OPTIONS.find(item => item.value === relationship)
  const formattedRelationship = filteredRelationship?.label ?? ""
  // Filter and format gender
  const filteredGender = GENDER_OPTIONS.find(item => item.value === gender)
  const formattedGender = filteredGender?.label ?? ""
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const cardBaseDataQa = "family-card"
  const isWarningCard = isMedicareEligible || isEnrolledInMedicaid || isAddressDifferent
  const borderColor = isWarningCard ? "colors.lightWarning" : takeCommandPrimary.light
  const backgroundColor = isWarningCard ? "colors.amber" : "background.welcome"
  const showDeleteButton = Boolean(!isEmployee && handleDelete)
  return (
    <>
      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete!}
        isError
        title="Are you sure?"
        message={`This action will permanently delete ${fullName}`}
        isCancelable
        actionLabel="Delete"
        cancelLabel="Back"
        isSubmitting={isPending}
      />
      <Card
        sx={{
          border: "1px solid",
          borderColor,
          backgroundColor,
          height: "100%",
        }}
        data-qa={cardBaseDataQa}
      >
        <CardContent
          sx={{
            padding: "1rem",
            display: "flex",
            alignItems: "start",
          }}
        >
          <Grid container>
            <Grid container direction="row" wrap="nowrap" alignItems="center">
              <Grid item sx={iconSize}>
                {isWarningCard ? (
                  <WarningAmberOutlinedIcon sx={{ ...iconSize, color: "colors.lightWarning" }} />
                ) : (
                  <CheckCircleOutlinedIcon color="primary" sx={iconSize} />
                )}
              </Grid>
              <Grid container item direction="column" sx={{ ml: 4 }}>
                <Typography variant="h4" data-qa={`${cardBaseDataQa}-name`}>
                  {isEmployee && !showEmployeeName ? "My Card" : fullName}
                </Typography>
              </Grid>
              {handleEdit && (
                <Grid item>
                  <IconButton
                    aria-label={`edit ${fullName}`}
                    onClick={handleEdit}
                    data-qa={`${cardBaseDataQa}-edit-button`}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Grid>
              )}

              {showDeleteButton && (
                <Grid item>
                  <IconButton
                    aria-label={`delete ${fullName}`}
                    onClick={() => setShowDeleteModal(true)}
                    data-qa={`${cardBaseDataQa}-delete-button`}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            <Grid container item gap={2} sx={{ mt: 4 }}>
              {dateOfBirth && (
                <FamilyChip
                  data-qa={`${cardBaseDataQa}-date-of-birth-chip`}
                  label={transformDate(dateOfBirth, DATE_FORMAT_MONTH_DAY_YEAR)}
                />
              )}
              {formattedGender && <FamilyChip data-qa={`${cardBaseDataQa}-gender-chip`} label={formattedGender} />}
              {formattedRelationship && (
                <FamilyChip data-qa={`${cardBaseDataQa}-relationship-chip`} label={formattedRelationship} />
              )}
              {stringifiedZipCode && (
                <FamilyChip data-qa={`${cardBaseDataQa}-zip-code-chip`} label={stringifiedZipCode} />
              )}
              {isMedicareEligible && (
                <FamilyChip
                  label="Medicare"
                  data-qa={`${cardBaseDataQa}-medicare-chip`}
                  icon={<BeenhereOutlinedIcon color="primary" sx={{ ...iconSize, ml: "0.5rem !important" }} />}
                />
              )}
              {isEnrolledInMedicaid && (
                <FamilyChip
                  label="Medicaid"
                  data-qa={`${cardBaseDataQa}-medicaid-chip`}
                  icon={<BeenhereOutlinedIcon color="primary" sx={{ ...iconSize, ml: "0.5rem !important" }} />}
                />
              )}
              {familyMember.doctors?.map(doctor => (
                <FamilyChip
                  label={`${doctor.name} - ${doctor.specialty}`}
                  key={doctor.id}
                  data-qa={`${cardBaseDataQa}-doctor-chip`}
                />
              ))}
              {familyMember.hospitals?.map(hospital => (
                <FamilyChip label={hospital.name} key={hospital.id} data-qa={`${cardBaseDataQa}-hospital-chip`} />
              ))}
              {familyMember.prescriptions?.map(prescription => (
                <FamilyChip
                  label={prescription.description}
                  key={prescription.id}
                  data-qa={`${cardBaseDataQa}-prescription-chip`}
                />
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

const CreateNewFamilyMemberButton = ({ handleClick }: { handleClick: () => void }) => (
  <Button
    onClick={handleClick}
    fullWidth
    sx={{
      minHeight: "7.5rem",
      height: "100%",
      color: "colors.darkBody",
      border: "2px dashed",
      borderColor: "colors.borderGray",
      borderRadius: "4px",
      backgroundColor: "colors.regionGray",
    }}
    data-qa="create-new-family-member-button"
  >
    <Grid item>
      <AddCircleOutline sx={iconSize} color="disabled" />
      <Typography>Add a new family member</Typography>
    </Grid>
  </Button>
)

const HelpCardWithAvatar = () => (
  <Callout
    mainText={
      <>
        This <Typography variant="body1bold">monthly allowance</Typography> is offered by your employer and can vary
        based on age, county, and the number of household members. Below are your allowance options. Please choose who
        you'd like to include in your health plan shopping.
      </>
    }
  />
)

interface AllowanceAmountCardProps {
  allowance: Allowance
}
const AllowanceAmountCard = ({ allowance }: AllowanceAmountCardProps) => {
  const { description, amountCents, designated } = allowance

  return (
    <Card
      sx={{
        minHeight: "4.75rem",
        height: "100%",
        border: "1px solid",
        borderColor: designated ? takeCommandPrimary.light : "colors.borderGray",
        backgroundColor: designated ? "background.welcome" : "colors.regionGray",
      }}
      data-qa="allowance-card"
    >
      <CardContent
        sx={{
          padding: "0.5rem !important",
          height: "100%",
          display: "flex",
        }}
      >
        <Grid container alignItems="center" justifyItems="center">
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" data-qa="allowance-amount" color={designated ? "primary" : "gray"}>
                {formatCents(amountCents)}
              </Typography>
            </Grid>
            {designated && (
              <Grid item>
                <CheckCircleOutlinedIcon data-qa="allowance-check-icon" color="primary" sx={iconSize} />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography color={!designated ? "colors.lightGreyButton" : ""} data-qa="allowance-description">
              {description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const AllowanceCardSkeleton = () => (
  <Skeleton variant="rounded" data-qa="allowances-loading-card">
    <Box sx={{ height: "4.75rem", width: "70rem" }} />
  </Skeleton>
)

const LoadingAllowanceGrid = () => (
  <>
    {times(ALLOWANCE_TIERS, num => (
      <Grid item xs={12} md={4} key={`allowance-skeleton-${num}`}>
        <AllowanceCardSkeleton />
      </Grid>
    ))}
  </>
)

interface AllowancesProps {
  allowances: Allowance[]
  isLoading: boolean
  isError: boolean
  numberOfEligibleMembers: number
  planYear: number
}

const Allowances = ({ allowances, isLoading, isError, numberOfEligibleMembers, planYear }: AllowancesProps) => (
  <>
    <Grid item xs={12} sx={{ mt: 8 }}>
      <Typography variant="h5" data-qa="allowances-label">
        Here's your {planYear} monthly allowance
      </Typography>
    </Grid>

    <HelpCardWithAvatar />

    <Grid container spacing={4} sx={{ mb: 5 }} alignItems="stretch">
      {allowances.map(allowance => (
        <Grid item xs={12} md={4} key={allowance.description}>
          <AllowanceAmountCard allowance={allowance} />
        </Grid>
      ))}
      {isLoading && <LoadingAllowanceGrid />}
      {isError && (
        <Grid
          item
          xs={12}
          sx={{
            height: "4.75rem",
          }}
        >
          <Typography data-qa="allowances-error-message">
            There was an error getting your allowances. Please try again in a few minutes.
          </Typography>
        </Grid>
      )}
    </Grid>
  </>
)

const getNextStep = (isMedicare: boolean, isMedicaid: boolean) => {
  if (isMedicaid) return WAIVE_COVERAGE

  if (isMedicare) return RECURRING_REIMBURSEMENT

  return HOUSEHOLD_INCOME
}

export const Family = () => {
  const navigate = useNavigate()
  const { notify } = useNotifications("family")
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const allowance = useBenefitsElectionStore(state => state.allowance)
  const setAllowance = useBenefitsElectionStore(state => state.setAllowance)
  const setEmployeeOnlyAllowance = useBenefitsElectionStore(state => state.setEmployeeOnlyAllowance)
  const showPreferences = useBenefitsElectionStore(state => state.showPreferences)
  const setShoppingSession = useBenefitsElectionStore(state => state.setCurrentShoppingSession)

  const {
    currentFamilyMember,
    familyMemberEditorVisible,
    familyMembers,
    medicaidEligibleMembers,
    medicareEligibleMembers,
    differentAddressMembers,
    storedEmployee,
    zipCode,
    isPending,
    createFamilyMember,
    deleteFamilyMember,
    editFamilyMember,
    handleMemberCreate,
    handleMemberEdit,
    hideFamilyMemberForm,
  } = useFamilyState(() => navigate(shoppingUrl + PERSONAL_INFO), shoppingSessionId)

  const { user } = useAuth()
  const company = getActiveCompany(user)
  const companyId = company?.companyId
  // FUTURE: Remove unsafe non-null assertion
  const shoppingSession = user?.shoppingSession!
  const isOpenEnrollment = shoppingSession.enrollmentTimePeriod.enrollmentTimePeriodType === OPEN_ENROLLMENT

  const {
    data: allowances,
    isLoading,
    isError,
    error,
  } = useGetAllowances(
    shoppingSession.employmentId,
    companyId,
    shoppingSession.planYear,
    storedEmployee,
    isOpenEnrollment,
    familyMembers
  )

  const { mutateAsync: updateAllowance } = useUpdateAllowance(shoppingSession.id)
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const isEmployeeMedicareEligible = !!storedEmployee.personalInformation?.isMedicareEligible
  const isEmployeeEnrolledInMedicaid = !!storedEmployee.personalInformation?.isEnrolledInMedicaid
  const previous = shoppingUrl + (showPreferences ? PRESCRIPTIONS : PERSONAL_INFO)
  const next = shoppingUrl + getNextStep(isEmployeeMedicareEligible, isEmployeeEnrolledInMedicaid)

  useEffect(() => {
    if (allowances && allowances.length > 0) {
      const wholeFamilyAllowance = allowances.find(currentAllowance => currentAllowance.designated)!
      const employeeOnlyAllowance = allowances.find(currentAllowance => currentAllowance.memberIds.length === 1)!
      setAllowance(wholeFamilyAllowance)
      setEmployeeOnlyAllowance(employeeOnlyAllowance)
    }
  }, [allowances, setAllowance, setEmployeeOnlyAllowance])

  useEffect(() => {
    if (error) {
      notify(`Error retrieving allowances. Please try again later`, "error")
      console.error(error.message)
    }
  }, [error, notify])

  return (
    <BenefitsElectionStep
      title="Family"
      description="Add or update the family members you want to include in your health insurance."
      previous={previous}
      next={next}
      advanceOnSuccess
      disabled={isLoading || isError}
      continueLabel={isEmployeeEnrolledInMedicaid ? "Waive" : undefined}
      handleContinue={async () => {
        // FUTURE SEG-2392: Call manage shopping persons api here
        // manageShoppingPersons( /* snip */ )
        const updatedShoppingSession = await updateAllowance(
          { token: allowance?.token! },
          {
            onError: errorData => {
              notify(`Error updating allowance. Please try again later`, "error")
              console.error(errorData.message)
            },
          }
        )
        setShoppingSession(updatedShoppingSession)

        if (currentStep === FAMILY) {
          setCurrentStep(getNextStep(isEmployeeMedicareEligible, isEmployeeEnrolledInMedicaid))
        }
      }}
      required
    >
      <>
        <FamilyMemberForm
          open={familyMemberEditorVisible}
          onClose={hideFamilyMemberForm}
          createFamilyMember={createFamilyMember}
          editProps={{ editFamilyMember, currentEditingMember: currentFamilyMember }}
          zipCode={zipCode!}
          isPending={isPending}
        />

        <Grid item xs={12} sx={{ mt: 4 }}>
          <Typography variant="h5" data-qa="family-members-family-list-label">
            Your family list:
          </Typography>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 0 }} alignItems="stretch">
          <EmployeeMedicareAndMedicaidAlerts
            shoppingUrl={shoppingUrl}
            isEmployeeMedicareEligible={isEmployeeMedicareEligible}
            isEmployeeEnrolledInMedicaid={isEmployeeEnrolledInMedicaid}
          />

          {medicareEligibleMembers.length > 0 && <FamilyMedicareAlert members={medicareEligibleMembers} />}
          {medicaidEligibleMembers.length > 0 && <FamilyMedicaidAlert members={medicaidEligibleMembers} />}
          {differentAddressMembers.length > 0 && <DifferentAddressAlert members={differentAddressMembers} />}

          {!isEmpty(storedEmployee) && (
            <Grid item xs={12} md={6} key={storedEmployee.shoppingPersonId}>
              <FamilyMemberCard
                familyMember={storedEmployee}
                handleEdit={() => handleMemberEdit(storedEmployee)}
                isPending={false}
                isAddressDifferent={false}
              />
            </Grid>
          )}

          {familyMembers.map(familyMember => {
            const isAddressDifferent = familyMember.personalInformation?.zipCode?.fipsCode !== zipCode?.fipsCode

            return (
              <Grid item xs={12} md={6} key={familyMember.shoppingPersonId}>
                <FamilyMemberCard
                  familyMember={familyMember}
                  handleEdit={() => handleMemberEdit(familyMember)}
                  handleDelete={async () => {
                    await deleteFamilyMember(familyMember.shoppingPersonId)
                  }}
                  isPending={isPending}
                  isAddressDifferent={isAddressDifferent}
                />
              </Grid>
            )
          })}

          <Grid item xs={12} md={6}>
            <CreateNewFamilyMemberButton handleClick={handleMemberCreate} />
          </Grid>
        </Grid>

        {!isEmployeeEnrolledInMedicaid && (
          <Allowances
            allowances={allowances ?? []}
            isLoading={isLoading}
            isError={isError}
            numberOfEligibleMembers={familyMembers.length - medicaidEligibleMembers.length}
            planYear={shoppingSession.planYear}
          />
        )}
      </>
    </BenefitsElectionStep>
  )
}
