import { Box, Card, Chip, Grid, Typography } from "@mui/material"
import { ReactNode } from "react"

interface PackageCardProps {
  clarificationTitle: string
  clarificationText: string | ReactNode
  planTitle: string
  planDescription: string
  planOptions: string
  chipLabel: string
  chipColor: string
  chipBackgroundColor: string
}

export const PackageCardInfo = ({
  clarificationTitle,
  clarificationText,
  planTitle,
  planDescription,
  planOptions,
  chipLabel,
  chipColor,
  chipBackgroundColor,
}: PackageCardProps) => (
  <Grid container sx={{ minHeight: "3.75rem" }} direction="row" mt={5} spacing={4} ml="auto">
    <Grid item xs={7} pr={4} my={3}>
      <Card sx={{ pl: 5, pr: 20 }} data-qa="package-plan-clarification-card">
        <Typography variant="h5" pt={4}>
          {clarificationTitle}
        </Typography>
        <Typography variant="body1" textAlign="left" pb={8}>
          {clarificationText}
        </Typography>
      </Card>
    </Grid>
    <Grid item xs={5} pr={4} my={3}>
      <Card sx={{ pl: 5, pr: 20 }} data-qa="package-plan-description-card">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h5" pt={4}>
              {planTitle}
            </Typography>
            <Typography variant="body1" textAlign="left" pb={8}>
              {planDescription}
            </Typography>
          </Box>
          <Box pt={4}>
            <Chip label={chipLabel} style={{ backgroundColor: chipBackgroundColor, color: chipColor }} />
          </Box>
        </Box>
        <Typography variant="body1" textAlign="left" pb={8}>
          {planOptions}
        </Typography>
      </Card>
    </Grid>
  </Grid>
)
