import { useDebouncedQuery } from "@/utils/useDebouncedQuery"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  calculateReimbursementRate,
  createPlanStructure,
  getClassDetails,
  getCurrentClasses,
  updatePlanStructure,
} from "./planStructureEndpoints"
import {
  CalculateReimbursementRatePayload,
  PlanStructurePatchRequest,
  PlanStructureRequest,
} from "./planStructureTypes"

export const useGetCurrentClasses = (companyId: string, planId: string) =>
  useQuery({
    queryKey: ["getCurrentClasses", companyId, planId],
    queryFn: () => getCurrentClasses(companyId, planId),
    enabled: !!companyId && !!planId,
  })

export const useGetClassDetails = (companyId: string, planId: string, classId: string) =>
  useQuery({
    queryKey: ["getClassDetails"],
    queryFn: () => getClassDetails(companyId, planId, classId),
  })

export const useCreatePlanStructure = (companyId: string, planId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: PlanStructureRequest) => createPlanStructure(companyId, planId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getCurrentClasses", companyId, planId],
      })
    },
  })
}

export const useUpdatePlanStructure = (companyId: string, planId: string) =>
  useMutation({
    mutationFn: (payload: PlanStructurePatchRequest) => updatePlanStructure(companyId, planId, payload),
  })

export const useCalculateReimbursementRate = (payload: CalculateReimbursementRatePayload) =>
  useDebouncedQuery({
    queryKey: ["calculateReimbursementRate", JSON.stringify(payload ?? {})],
    queryFn: () => calculateReimbursementRate(payload),
    enabled: !!payload.employeeAmount,
    debounce: 500,
  })
