import { EASY_ENROLL, EXTRA_STEP, SELF_ENROLL } from "@/constants"

export const PENDING = "PENDING"
export const IN_PROGRESS = "IN_PROGRESS"
export const PURCHASED = "PURCHASED"
export const ENROLLED = "ENROLLED"
export const WAIVED = "WAIVED"
export const SHOPPING = "SHOPPING"
export const COMPLETED = "COMPLETED"
export const INELIGIBLE = "INELIGIBLE"

export const SHOPPING_STATUSES = [
  PENDING,
  IN_PROGRESS,
  PURCHASED,
  ENROLLED,
  WAIVED,
  SHOPPING,
  COMPLETED,
  INELIGIBLE,
] as const

export const SELF = "EMPLOYEE"
export const SPOUSE = "SPOUSE"
export const DEPENDENT = "DEPENDENT"

export const FAMILY_RELATIONSHIPS = [SELF, SPOUSE, DEPENDENT] as const

export const FAMILY_RELATIONSHIP_OPTIONS = [
  {
    value: SELF,
    label: "Employee",
  },
  {
    value: SPOUSE,
    label: "Spouse",
  },
  {
    value: DEPENDENT,
    label: "Child",
  },
] as const

export const ON_COBRA = "ON_COBRA"
export const ON_GROUP_PLAN = "ON_GROUP_PLAN"
export const TOO_EXPENSIVE = "TOO_EXPENSIVE"
export const ON_MEDICAID = "ON_MEDICAID"
export const ON_PARENTS_PLAN = "ON_PARENTS_PLAN"
export const ON_SPOUSES_PLAN = "ON_SPOUSES_PLAN"
export const DECLINED_TO_ANSWER = "DECLINED_TO_ANSWER"
export const USING_TAX_CREDITS = "USING_TAX_CREDITS"
export const OTHER = "OTHER"

export const WAIVE_COVERAGE_REASONS = [
  ON_COBRA,
  ON_GROUP_PLAN,
  TOO_EXPENSIVE,
  ON_MEDICAID,
  ON_PARENTS_PLAN,
  ON_SPOUSES_PLAN,
  DECLINED_TO_ANSWER,
  USING_TAX_CREDITS,
  OTHER,
] as const

export const WAIVE_COVERAGE_OPTIONS = [
  {
    value: ON_COBRA,
    label: "I am on COBRA",
  },
  {
    value: ON_GROUP_PLAN,
    label: "I am on a group plan",
  },
  {
    value: TOO_EXPENSIVE,
    label: "Medical insurance is too expensive",
  },
  {
    value: ON_MEDICAID,
    label: "I am on Medicaid or government assistance",
  },
  {
    value: ON_PARENTS_PLAN,
    label: "I am on my parent’s plan",
  },
  {
    value: ON_SPOUSES_PLAN,
    label: "I am on my spouse’s plan",
  },
  {
    value: DECLINED_TO_ANSWER,
    label: "Decline to answer",
  },
  {
    value: OTHER,
    label: "Other",
  },
] as const

export const TAX_CREDITS_OPTIONS = [
  {
    title: "Accept the HRA offer",
    value: false,
    subtitle: "This is an annual option.",
  },
  {
    title: "Accept tax credits",
    value: true,
  },
] as const

export const CATASTROPHIC = "CATASTROPHIC"
export const BRONZE = "BRONZE"
export const EXPANDED_BRONZE = "EXPANDED_BRONZE"
export const SILVER = "SILVER"
export const GOLD = "GOLD"
export const PLATINUM = "PLATINUM"

export const PLAN_SEARCH_CATEGORIES_WITHOUT_CATASTROPHIC = [BRONZE, EXPANDED_BRONZE, SILVER, GOLD, PLATINUM] as const

export const PLAN_SEARCH_CATEGORIES = [CATASTROPHIC, ...PLAN_SEARCH_CATEGORIES_WITHOUT_CATASTROPHIC] as const

export const BRONZE_COLOR = "colors.bronze"
export const SILVER_COLOR = "colors.silver"
export const GOLD_COLOR = "colors.gold"
export const PLATINUM_COLOR = "colors.platinum"
export const EXPANDED_BRONZE_COLOR = "colors.expandedBronze"

export const METAL_TIERS = {
  BRONZE: BRONZE_COLOR,
  SILVER: SILVER_COLOR,
  GOLD: GOLD_COLOR,
  PLATINUM: PLATINUM_COLOR,
  EXPANDED_BRONZE: EXPANDED_BRONZE_COLOR,
} as const

export const METAL_COLORS = [BRONZE_COLOR, SILVER_COLOR, GOLD_COLOR, PLATINUM_COLOR, EXPANDED_BRONZE_COLOR] as const

export const HMO = "HMO"
export const PPO = "PPO"
export const EPO = "EPO"
export const POS = "POS"
export const PLAN_TYPES = [EPO, POS, HMO, PPO] as const

export const ON_MARKET = "ON_MARKET"
export const OFF_MARKET = "OFF_MARKET"
export const BOTH_MARKETS = "BOTH_MARKETS"
export const PLAN_MARKETS = [ON_MARKET, OFF_MARKET, BOTH_MARKETS] as const
export const PLAN_MARKET_OPTIONS = [
  { value: ON_MARKET, label: "On Market" },
  { value: OFF_MARKET, label: "Off Market" },
  { value: BOTH_MARKETS, label: "Both Markets" },
]

export const INDIVIDUAL_HEALTH_INSURANCE = "INDIVIDUAL_HEALTH_INSURANCE"
export const MEDICARE = "MEDICARE"
export const STUDENT_HEALTH_INSURANCE = "STUDENT_HEALTH_INSURANCE"

export const PRIMARY_INSURANCE_TYPES = [INDIVIDUAL_HEALTH_INSURANCE, STUDENT_HEALTH_INSURANCE, MEDICARE] as const

export const PRIMARY_INSURANCE_TYPE_OPTIONS = [
  {
    value: INDIVIDUAL_HEALTH_INSURANCE,
    label: "Individual Health Insurance",
  },
  {
    value: MEDICARE,
    label: "Medicare",
  },
  {
    value: STUDENT_HEALTH_INSURANCE,
    label: "Student Health Insurance",
  },
] as const

export const DENTAL_INSURANCE = "DENTAL_INSURANCE"
export const VISION_INSURANCE = "VISION_INSURANCE"
export const OTHER_INSURANCE = "OTHER_INSURANCE"

export const SUPPLEMENTARY_INSURANCE_TYPES = [DENTAL_INSURANCE, VISION_INSURANCE, OTHER_INSURANCE] as const

export const SUPPLEMENTARY_INSURANCE_TYPE_OPTIONS = [
  {
    value: DENTAL_INSURANCE,
    label: "Dental",
  },
  {
    value: VISION_INSURANCE,
    label: "Vision",
  },
  {
    value: OTHER_INSURANCE,
    label: "Other",
  },
] as const

export const ALL_INSURANCE_TYPE_OPTIONS = [
  {
    value: INDIVIDUAL_HEALTH_INSURANCE,
    label: "Individual Health Insurance",
  },
  {
    value: MEDICARE,
    label: "Medicare",
  },
  {
    value: STUDENT_HEALTH_INSURANCE,
    label: "Student Health Insurance",
  },
  {
    value: DENTAL_INSURANCE,
    label: "Dental",
  },
  {
    value: VISION_INSURANCE,
    label: "Vision",
  },
  {
    value: OTHER_INSURANCE,
    label: "Other",
  },
] as const

export const INSURANCE_TYPES = [...PRIMARY_INSURANCE_TYPES, ...SUPPLEMENTARY_INSURANCE_TYPES] as const

export const INSURANCE_TYPE_OPTIONS = [
  ...PRIMARY_INSURANCE_TYPE_OPTIONS,
  ...SUPPLEMENTARY_INSURANCE_TYPE_OPTIONS,
] as const

export const PREMIUM = "PREMIUM"
export const DEDUCTIBLE = "DEDUCTIBLE"
export const OUT_OF_POCKET = "OUT_OF_POCKET"
export const NONE = "NONE"
export const SORT_VALUES = [PREMIUM, DEDUCTIBLE, OUT_OF_POCKET, NONE] as const
export const SORT_VALUES_PROPERTIES = {
  PREMIUM: "premiumAmountCents",
  DEDUCTIBLE: "individualMedicalDeductibleAmountCents",
  OUT_OF_POCKET: "individualMedicalMaxOutOfPocketAmountCents",
} as const

export const SORT_OPTIONS = [
  {
    value: PREMIUM,
    label: "By Premiums",
  },
  {
    value: OUT_OF_POCKET,
    label: "By Out-of-pocket",
  },
  {
    value: DEDUCTIBLE,
    label: "By Deductible",
  },
] as const

export const PLAN_ENROLLMENT_TYPES = [EASY_ENROLL, EXTRA_STEP, SELF_ENROLL] as const

export const PLAN_ENROLLMENT_TYPE_OPTIONS = [
  {
    value: "true",
    label: "Easy Enroll",
  },
  {
    value: "false",
    label: "Self Enroll",
  },
] as const

export const EXTRA_STEP_CHIP_LABEL = "Extra Step"

export const TAX_ADVANTAGED = "TAX_ADVANTAGED"
export const TAX_ADVANTAGED_COLOR = "colors.lightCyan"
export const PAY_LATER = "PAY_LATER"
export const PAY_LATER_COLOR = "colors.mustard"

export const PLAN_EXTRA_INFORMATION = [TAX_ADVANTAGED, PAY_LATER] as const

export const PLAN_ENROLLMENT_TYPES_COLORS = {
  [EASY_ENROLL]: "colors.aquamarine",
  [EXTRA_STEP]: "colors.pumpkin",
  [SELF_ENROLL]: "colors.vermilion",
} as const

export const BENEFITS_SUMMARY = "summary_of_benefits_and_coverage"

export const PLAN_DOCUMENT_TYPES = [BENEFITS_SUMMARY] as const

export const OPEN_ENROLLMENT = "OE"
export const SPECIAL_ENROLLMENT = "SEP"

export const ENROLLMENT_PERIOD_TYPES = [OPEN_ENROLLMENT, SPECIAL_ENROLLMENT] as const

export const NO_PLAN_SELECTED = "NO_PLAN_SELECTED"
export const PLAN_SELECTED = "PLAN_SELECTED"
export const EMPLOYEE_PURCHASED = "EMPLOYEE_PURCHASED"
export const APPLICATION_RECEIVED = "APPLICATION_RECEIVED"
export const PENDING_RESPONSE = "PENDING_RESPONSE"
export const HOLD_FOR_DELAYED_PAYMENT = "HOLD_FOR_DELAYED_PAYMENT"
export const HOLD_FOR_SUBMISSION = "HOLD_FOR_SUBMISSION"
export const POC_PENDING = "POC_PENDING"
export const POC_SUBMITTED = "POC_SUBMITTED"
export const POC_DENIED = "POC_DENIED"
export const SUBMITTED_TO_CARRIER = "SUBMITTED_TO_CARRIER"
export const CANCELED = "CANCELED"

export const ENROLLMENT_STATUSES = [
  NO_PLAN_SELECTED,
  PLAN_SELECTED,
  EMPLOYEE_PURCHASED,
  APPLICATION_RECEIVED,
  PENDING_RESPONSE,
  HOLD_FOR_DELAYED_PAYMENT,
  HOLD_FOR_SUBMISSION,
  IN_PROGRESS,
  POC_PENDING,
  POC_SUBMITTED,
  POC_DENIED,
  SUBMITTED_TO_CARRIER,
  CANCELED,
] as const

export const ENROLLMENT_STATUS_OPTIONS = [
  {
    value: NO_PLAN_SELECTED,
    label: "No Plan Selected",
  },
  {
    value: PLAN_SELECTED,
    label: "Plan Selected",
  },
  {
    value: EMPLOYEE_PURCHASED,
    label: "Employee Purchased",
  },
  {
    value: APPLICATION_RECEIVED,
    label: "Application Received",
  },
  {
    value: PENDING_RESPONSE,
    label: "Pending Response",
  },
  {
    value: HOLD_FOR_DELAYED_PAYMENT,
    label: "Hold for Delayed Payment",
  },
  {
    value: HOLD_FOR_SUBMISSION,
    label: "Hold for Submission",
  },
  {
    value: IN_PROGRESS,
    label: "In Progress",
  },
  {
    value: POC_PENDING,
    label: "POC Pending",
  },
  {
    value: POC_SUBMITTED,
    label: "POC Submitted",
  },
  {
    value: POC_DENIED,
    label: "POC Denied",
  },
  {
    value: SUBMITTED_TO_CARRIER,
    label: "Submitted to Carrier",
  },
  {
    value: CANCELED,
    label: "Canceled",
  },
] as const

export const RECOMMENDED_PLANS_DEFAULT_LIMIT = 9
export const ALL_PLANS_DEFAULT_LIMIT = 9

export const IN_NETWORK = "In-Network:"
export const OUT_OF_NETWORK = "Out-of-Network:"

export const STEP_MY_INFO = 0
export const STEP_FIND_PLAN = 1
export const STEP_PURCHASE = 2

export const WELCOME = "welcome"
export const MY_INFO = "my-info"
export const FIND_PLAN = "find-plan"
export const PURCHASE = "purchase"
export const QUESTIONS = `${FIND_PLAN}/questions` as const

export const WAIVE = "waive-coverage"

export const PERSONAL_INFO = `${MY_INFO}/personal-info` as const
export const DOCTORS = `${MY_INFO}/doctors` as const
export const HOSPITALS = `${MY_INFO}/hospitals` as const
export const PRESCRIPTIONS = `${MY_INFO}/prescriptions` as const
export const FAMILY = `${MY_INFO}/family` as const
export const HOUSEHOLD_INCOME = `${MY_INFO}/household-income` as const

export const HEALTH_NEEDS = `${QUESTIONS}/health-needs` as const
export const PLAN_TYPE = `${QUESTIONS}/plan-type` as const
export const CARRIERS = `${QUESTIONS}/carriers` as const

export const RECOMMENDED_PLANS = `${FIND_PLAN}/recommended-plans` as const
export const COMPARE_PLANS = `${FIND_PLAN}/compare-plans` as const

export const WAIVE_COVERAGE = `${WAIVE}/` as const
export const WAIVE_COVERAGE_SUCCESS = `${WAIVE}/success` as const

// Self Enroll
export const SELF_ENROLL_PURCHASE = `${PURCHASE}/self-enroll` as const
export const RECURRING_REIMBURSEMENT = `${PURCHASE}/recurring-reimbursements` as const
export const FINAL_CHECK = `${PURCHASE}/final-check` as const
export const SELF_ENROLLED_PLAN = `${PURCHASE}/self-enrolled-plan` as const

// Easy Enroll
export const CARRIER_QUESTIONS = `${PURCHASE}/carrier-questions` as const
export const SSN = `${PURCHASE}/ssn` as const
export const SIGNATURES = `${PURCHASE}/signature` as const
export const CARRIER = `${PURCHASE}/carrier` as const
export const SUMMARY = `${PURCHASE}/summary` as const
export const CHECKOUT = `${PURCHASE}/checkout` as const

export const COMPLETE = `${PURCHASE}/complete` as const

export const MY_INFO_PATHS = [PERSONAL_INFO, DOCTORS, HOSPITALS, PRESCRIPTIONS, FAMILY, HOUSEHOLD_INCOME] as const

export const QUESTIONS_PATHS = [HEALTH_NEEDS, PLAN_TYPE, CARRIERS] as const

export const FIND_PLAN_PATHS = [RECOMMENDED_PLANS, COMPARE_PLANS] as const

export const WAIVE_PATHS = [WAIVE_COVERAGE, WAIVE_COVERAGE_SUCCESS] as const

const EASY_ENROLL_PATHS = [CARRIER_QUESTIONS, SSN, SIGNATURES, CARRIER, SUMMARY, CHECKOUT] as const

const SELF_ENROLL_PATHS = [SELF_ENROLLED_PLAN, SELF_ENROLL_PURCHASE, RECURRING_REIMBURSEMENT, FINAL_CHECK] as const

export const PURCHASE_PATHS = [...EASY_ENROLL_PATHS, ...SELF_ENROLL_PATHS, COMPLETE] as const

export const BENEFITS_ELECTION_PATHS = [
  ...MY_INFO_PATHS,
  ...QUESTIONS_PATHS,
  ...FIND_PLAN_PATHS,
  ...WAIVE_PATHS,
  ...PURCHASE_PATHS,
] as const

export const BENEFITS_ELECTION = "benefits-election"

export const URL_CALL_SPECIALIST = "https://www.takecommandhealth.com/welcome-to-take-command-health#enrollment-call"

export const PLAN_VIEWS = [RECOMMENDED_PLANS] as const

export const MAX_POC_FILES = 1

export const BANK_ACCOUNT_PAYMENT_OPTION = "ACH"
export const CREDIT_CARD_PAYMENT_OPTION = "CREDIT_CARD"
export const BOTH_PAYMENT_OPTION = "ACH_OR_CREDIT_CARD"

export const PAYMENT_OPTIONS = [BANK_ACCOUNT_PAYMENT_OPTION, CREDIT_CARD_PAYMENT_OPTION, BOTH_PAYMENT_OPTION] as const

const PREFERRED_GENERIC = "preferred_generic"
const NON_PREFERRED_GENERIC = "non_preferred_generic"
const GENERIC = "generic"
const PREFERRED_BRAND = "preferred_brand"
const NON_PREFERRED_BRAND = "non_preferred_brand"
const SPECIALTY = "specialty"
const NON_PREFERRED_SPECIALTY = "non_preferred_specialty"
const NOT_COVERED = "not_covered"
const NOT_LISTED = "not_listed"

export const DRUG_TIERS = {
  PREFERRED_GENERIC,
  NON_PREFERRED_GENERIC,
  GENERIC,
  PREFERRED_BRAND,
  NON_PREFERRED_BRAND,
  SPECIALTY,
  NON_PREFERRED_SPECIALTY,
  NOT_COVERED,
  NOT_LISTED,
} as const

export const ALL_ENROLLMENT_TYPE_OPTIONS = [
  {
    value: "SELF_ENROLL",
    label: "Self Enroll",
  },
  {
    value: "EASY_ENROLL",
    label: "Easy Enroll",
  },
  {
    value: "EASY_ENROLL_WITH_EXTRA_STEP",
    label: "Easy Enroll with Extra Step",
  },
] as const

export const ALL_RECURRING_PREMIUM_STATUS_OPTIONS = [
  {
    value: "APPROVED",
    label: "Approved",
  },
  {
    value: "DECLINED",
    label: "Declined",
  },
  {
    value: "PENDING",
    label: "Pending",
  },
] as const

export const RECURRING_PREMIUM_INSURANCE_TYPE_OPTIONS = [
  {
    value: "PRIMARY",
    label: "Primary",
  },
  {
    value: "DENTAL",
    label: "Dental",
  },
  {
    value: "VISION",
    label: "Vision",
  },
  {
    value: "OTHER",
    label: "Other",
  },
] as const
