import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { UsaState } from "@/utils/States"
import { Uuid } from "@/utils/types"
import { BusinessUnit, Meta } from "../TCHub/tcHubTypes"
import { HraPlanModel } from "./components/Steps/Setup/PlanSetup/planSetupTypes"
import { CountiesModel, PurchaseHraStep } from "./createCompanyTypes"

export interface CompanyModel {
  id: Uuid
  companyInfo: {
    companyName: string
    companyType: string
    industry: string
    ein: string
    isALE: boolean | null | undefined
    isAssisted: boolean | null
    autoPayAvailableOption?: boolean
    isHrisEnabled?: boolean
    isADP: boolean | null
  }
  companyAddress: {
    streetAddress: string
    suiteApt?: string
    city: string
    state: UsaState
    zip: string
  }
  benefitsAdministrator: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  }
  hraPlan?: HraPlanModel[]
  companyOnboardingStatus?: CompanyOnboardingStatus[]
}

export interface CompanyListResponse {
  companies: CompanyModel[]
  meta: Meta
}

interface ValidateCompanyResults {
  field: string
  errorMessage: string
  valid: boolean
  value: string
  inUse: boolean
}

export interface CompanyOnboardingStatus {
  step: PurchaseHraStep
  isComplete: boolean
  statusValue: string
}
export interface ValidateCompany {
  validations: ValidateCompanyResults[]
}

export interface ValidateCompanyFields {
  ein: string
  currentCompanyId?: string
}

export interface CheckoutUrl {
  paymentDetailsPageUrl: string
}

const baseApiUrl = appConfig.baseApiUrl

export const addCompany = async (company: Partial<CompanyModel>) => {
  const newCompany = (await axios.post(`${baseApiUrl}/benefits/v1/companies`, company)).data as CompanyModel

  return newCompany
}

export const updateCompany = async (company: Partial<CompanyModel>) => {
  const updatedCompany = (await axios.patch(`${baseApiUrl}/benefits/v1/companies`, company)).data as CompanyModel

  return updatedCompany
}

export const validateCompany = async (companyFieldValidations: Partial<ValidateCompanyFields>) => {
  try {
    const companyValidationResults = (
      await axios.post(`${baseApiUrl}/benefits/v1/companies/validate`, companyFieldValidations)
    ).data as ValidateCompany

    return companyValidationResults
  } catch {
    console.warn("Failed to validate company: ein=" + companyFieldValidations?.ein)
  }
}

export const getCompany = async (companyId: string) => {
  try {
    const company = (await axios.get(`${baseApiUrl}/benefits/v1/companies/${companyId}`)).data as CompanyModel

    return company
  } catch {
    console.warn("Failed to get company: companyId=" + companyId)

    return undefined as never
  }
}

export const getCountiesFromZip = async (zipCode: string) => {
  const counties = (await axios.get<CountiesModel[]>(`${baseApiUrl}/health-plans/v1/zip-counties?zipCode=${zipCode}`))
    .data

  return counties
}

export const getOnboardingStatus = async (companyId: string) => {
  try {
    const company = (await axios.get(`${baseApiUrl}/benefits/v1/companies/${companyId}/onboarding-statuses`))
      .data as CompanyOnboardingStatus[]

    return company
  } catch {
    console.warn("Failed to get onboarding status: companyId=" + companyId)
  }
}

export const getCheckoutUrl = async (companyId: string) => {
  try {
    const checkourUrl = (await axios.get(`${baseApiUrl}/billing/v1/company/${companyId}/checkout/url`))
      .data as CheckoutUrl

    return checkourUrl
  } catch {
    throw new Error(`Failed to get checkout url for company: companyId=${companyId}`)
  }
}

export const getBusinessUnit = async (companyId: string) => {
  const businessUnits = (await axios.get(`${baseApiUrl}/benefits/v1/companies/${companyId}/business-units`))
    .data as BusinessUnit[]

  return businessUnits
}

export const createBusinessUnit = async (companyId: string, businessUnit: Partial<BusinessUnit>) => {
  const newBusinessUnit = (
    await axios.post(`${baseApiUrl}/benefits/v1/companies/${companyId}/business-units`, businessUnit)
  ).data as BusinessUnit

  return newBusinessUnit
}

export const updateBusinessUnit = async (companyId: string, businessUnit: Partial<BusinessUnit>) => {
  const updatedBusinessUnit = (
    await axios.patch(`${baseApiUrl}/benefits/v1/companies/${companyId}/business-units`, businessUnit)
  ).data as BusinessUnit

  return updatedBusinessUnit
}

export const deleteBusinessUnit = async (companyId: string, businessUnitId: string) => {
  await axios.delete(`${baseApiUrl}/benefits/v1/companies/${companyId}/business-units/${businessUnitId}`)
}
