import { StyledCard } from "@/components/StyledCard"
import { FundingEventSummary } from "@/features/Funding/fundingTypes"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, transformDate } from "@/utils/formatting"
import { CardContent, CardHeader, Grid, Typography } from "@mui/material"
import { SkeletonSnapshotLoader } from "./SkeletonSnapshotLoader"

interface UpcomingFundingEventSnapshotProps {
  latestScheduledEvent: FundingEventSummary
}

export const UpcomingFundingEventSnapshot = ({ latestScheduledEvent }: UpcomingFundingEventSnapshotProps) => {
  if (!latestScheduledEvent) {
    return <SkeletonSnapshotLoader />
  }

  return (
    <StyledCard sx={{ p: 2 }}>
      <CardHeader title={<Typography variant="h5">Upcoming Funding Event</Typography>} />
      <CardContent>
        <Grid
          container
          direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              Funding Amount
            </Typography>
            <Typography variant="h5" align="left">
              {latestScheduledEvent?.totalFundingAmountCents !== undefined
                ? formatCents(latestScheduledEvent.totalFundingAmountCents)
                : "—"}
            </Typography>
          </Grid>
          <Typography sx={{ pb: 4, pt: 4, pl: 4, pr: 4, color: "colors.silverSand" }} fontWeight="bold">
            FOR
          </Typography>
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              # Employees
            </Typography>
            <Typography variant="h5" align="left">
              {latestScheduledEvent?.numberOfEmployees !== undefined ? latestScheduledEvent.numberOfEmployees : "—"}
            </Typography>
          </Grid>
          <Typography sx={{ pb: 4, pt: 4, pl: 4, pr: 4, color: "colors.silverSand" }} fontWeight="bold">
            ON
          </Typography>
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              Transfer Date
            </Typography>
            <Typography variant="h5" align="left">
              {latestScheduledEvent?.periodEndAt
                ? transformDate(latestScheduledEvent?.periodEndAt, DATE_FORMAT_MONTH_DAY_YEAR)
                : "—"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  )
}
