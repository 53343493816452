import { DocumentsAccordion } from "@/features/Documents/components/DocumentsAccordion/DocumentsAccordion"
import { useEmployeeDocDownloadUrl, useEmployeeDocuments } from "@/features/Documents/documentsService"
import { useEffect, useState } from "react"

interface TcHubUserDocumentsProps {
  employeeId: string
  companyId: string
}

export const TcHubUserDocuments = ({ employeeId, companyId }: TcHubUserDocumentsProps) => {
  const [expanded, setExpanded] = useState(false)
  const { data: documents, isLoading } = useEmployeeDocuments(companyId, employeeId, expanded)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>()
  const { data: url, isLoading: isDownloading } = useEmployeeDocDownloadUrl(
    companyId,
    employeeId,
    selectedDocumentId,
    expanded
  )

  useEffect(() => {
    if (url) {
      window.open(url, "_blank")
      setSelectedDocumentId(undefined)
    }
  }, [url])

  return (
    <DocumentsAccordion
      title="Employee Documents"
      documents={documents}
      isLoading={isLoading}
      expanded={expanded}
      onExpandChange={setExpanded}
      selectedDocumentId={selectedDocumentId}
      isDownloading={isDownloading}
      onDownload={setSelectedDocumentId}
      qaPrefix="employee"
    />
  )
}
