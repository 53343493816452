import { appConfig } from "@/config"
import { axiosInstance as axios } from "@/services/axios"
import { CompanyId, Uuid } from "@/utils/types"
import {
  ApplicationFormResponse,
  AutoPayConfig,
  CompanyFundingEntriesRequest,
  CompanyFundingEntriesResponse,
  CompanyFundingEventResponseItem,
  CompanyFundingEventsResponse,
  CreateBankAccountRequest,
  CreateBankAccountResponse,
  DisbursementAccountDetails,
  FundingEventSummary,
  FundingStatus,
  GetBankAccountResponse,
  PaymentAccountInformation,
} from "./fundingTypes"

const fundingBaseUrl = `${appConfig.baseApiUrl}/funding`
const settingsBaseUrl = appConfig.baseApiUrl

export const reinitiateApplicationForm = async (companyId: string) =>
  (await axios.post<ApplicationFormResponse>(`${fundingBaseUrl}/v1/companies/${companyId}/application`)).data

export const getApplicationForm = async (companyId: string) =>
  (await axios.get<ApplicationFormResponse>(`${fundingBaseUrl}/v1/companies/${companyId}/application`)).data

export const getAutoPayConfig = async (companyId: string) =>
  (await axios.get<AutoPayConfig>(`${fundingBaseUrl}/v1/companies/${companyId}/config`)).data

export const getFundingStatus = async (companyId: CompanyId) =>
  (await axios.get<FundingStatus>(`${fundingBaseUrl}/v1/companies/${companyId}/autopay-status`)).data

/**
 * This function is used to request a bank account from the banking vendor.
 * @param companyId
 * @returns linkToken
 */
export const getBankAccountUrl = async (companyId: string) =>
  (await axios.get<GetBankAccountResponse>(`${settingsBaseUrl}/funding/v1/companies/${companyId}/bank-account-request`))
    .data

/**
 * This function is used to create a bank account.
 * @param companyId
 * @param CreateBankAccountRequest
 * @returns void
 * */
export const createBankAccount = async (companyId: string, bankAccountRequest: CreateBankAccountRequest) => {
  const url = `${settingsBaseUrl}/funding/v1/companies/${companyId}/create-bank-account`
  const response = (await axios.post(url, bankAccountRequest)).data as CreateBankAccountResponse

  return response
}

/**
 * This function retrieves all company funding events for company by id
 * @param companyId
 * @returns CompanyFundingEventsResponse
 */
export const getCompanyFundingEvents = async (companyId: string) =>
  (await axios.get(`${fundingBaseUrl}/v1/companies/${companyId}/funding-events`)).data as CompanyFundingEventsResponse

export const getNextCompanyFundingEvent = async (companyId: string) =>
  (await axios.get(`${fundingBaseUrl}/v1/companies/${companyId}/funding-events/next`))
    .data as CompanyFundingEventResponseItem

/**
 * This function retrieves a company funding event summary by id
 * @param companyId
 * @param fundingEventId
 * @returns FundingEventSummary
 * */
export const getCompanyFundingEventSummary = async (companyId: string, fundingEventId: string) =>
  (await axios.get(`${fundingBaseUrl}/v1/companies/${companyId}/funding-events/${fundingEventId}/summary`))
    .data as FundingEventSummary

/**
 * This function retrieves the latest funding entity summary by company id and employment id
 * @param companyId
 * @param employmentId
 * @returns PaymentAccountInformation
 * */
export const getPaymentAccountInformation = async (companyId: Uuid, employmentId: Uuid) => {
  const paymentAccountInformation = (
    await axios.get<PaymentAccountInformation>(
      `${fundingBaseUrl}/v1/companies/${companyId}/employments/${employmentId}/funding-entities`
    )
  ).data

  return paymentAccountInformation
}

/**
 * This function is used to get funding event entries for a company
 * @param companyFundingEntriesRequest
 * @returns CompanyFundingEntriesRequest
 * */
export const getCompanyFundingEventEntries = async (companyFundingEntriesRequest: CompanyFundingEntriesRequest) => {
  const url = `${settingsBaseUrl}/ledger/v1/funding-events/funding-entries`
  const response = (await axios.post(url, companyFundingEntriesRequest)).data as CompanyFundingEntriesResponse

  return response
}

/**
 * This function is used to get disbursement account into by company id
 * @param companyId
//  * @returns Disbursement account information
 * */
export const getDisbursementAccountDetails = async (companyId: string) => {
  const disbursementAccountDetails = (
    await axios.get<DisbursementAccountDetails>(
      `${fundingBaseUrl}/v1/funding-entities/company-disbursement-details/${companyId}`
    )
  ).data
  return disbursementAccountDetails
}
