import { DocumentsAccordion } from "@/features/Documents/components/DocumentsAccordion/DocumentsAccordion"
import { useCompanyDocDownloadUrl, useCompanyDocuments } from "@/features/Documents/documentsService"
import { useEffect, useState } from "react"

interface TcHubCompanyDocumentsProps {
  companyId: string
}

export const TcHubCompanyDocuments = ({ companyId }: TcHubCompanyDocumentsProps) => {
  const [expanded, setExpanded] = useState(false)
  const { data: documents, isLoading } = useCompanyDocuments(companyId, expanded)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>()
  const { data: url, isLoading: isDownloading } = useCompanyDocDownloadUrl(companyId, selectedDocumentId, true, false)

  useEffect(() => {
    if (url) {
      window.open(url, "_blank")
      setSelectedDocumentId(undefined)
    }
  }, [url])

  return (
    <DocumentsAccordion
      title="Company Documents"
      documents={documents}
      isLoading={isLoading}
      expanded={expanded}
      onExpandChange={setExpanded}
      selectedDocumentId={selectedDocumentId}
      isDownloading={isDownloading}
      onDownload={setSelectedDocumentId}
      qaPrefix="company"
    />
  )
}
