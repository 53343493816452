import { StyledCard } from "@/components/StyledCard"
import { FundingStatusChip } from "@/features/Funding/components/FundingStatusChip"
import { FundingEventSummary, FundingStatus } from "@/features/Funding/fundingTypes"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, transformDate } from "@/utils/formatting"
import { CardContent, CardHeader, Grid, Typography } from "@mui/material"
import { SkeletonSnapshotLoader } from "./SkeletonSnapshotLoader"

interface RecentFundingEventSnapshotProps {
  recentFundingEvent: FundingEventSummary
}

export const RecentFundingEventSnapshot = ({ recentFundingEvent }: RecentFundingEventSnapshotProps) => {
  if (!recentFundingEvent) {
    return <SkeletonSnapshotLoader />
  }

  return (
    <StyledCard sx={{ p: 2 }}>
      <CardHeader
        title={<Typography variant="h5">Recent Funding Event</Typography>}
        action={<FundingStatusChip fundingStatus={recentFundingEvent.status as FundingStatus} />}
      />
      <CardContent>
        <Grid
          container
          direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              Funding Amount
            </Typography>
            <Typography variant="h5" align="left">
              {recentFundingEvent?.totalFundingAmountCents !== undefined
                ? formatCents(recentFundingEvent.totalFundingAmountCents)
                : "—"}
            </Typography>
          </Grid>
          <Typography sx={{ pb: 4, pt: 4, pl: 4, pr: 4, color: "colors.silverSand" }} fontWeight="bold">
            FOR
          </Typography>
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              # Employees
            </Typography>
            <Typography variant="h5" align="left">
              {recentFundingEvent?.numberOfEmployees !== undefined ? recentFundingEvent.numberOfEmployees : "—"}
            </Typography>
          </Grid>
          <Typography sx={{ pb: 4, pt: 4, pl: 4, pr: 4, color: "colors.silverSand" }} fontWeight="bold">
            ON
          </Typography>
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1.5 }}>
              Transfer Date
            </Typography>
            <Typography variant="h5" align="left">
              {recentFundingEvent?.periodEndAt
                ? transformDate(recentFundingEvent?.periodEndAt, DATE_FORMAT_MONTH_DAY_YEAR)
                : "—"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  )
}
