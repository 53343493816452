import { WrapperProps } from "@/utils/types"
import { Masonry } from "@mui/lab"
import { Box, CircularProgress, Grid, Skeleton, Typography, useMediaQuery } from "@mui/material"

const LoadingContainer = ({ children }: WrapperProps) => (
  <Box
    sx={{
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "55%",
      width: {
        xs: "90%",
        md: "75%",
      },
      overflow: "hidden",
    }}
  >
    {children}
  </Box>
)

interface LoadingContentAreaProps {
  "data-qa": string
}

export const LoadingContentArea = ({ "data-qa": dataQa }: LoadingContentAreaProps) => (
  <LoadingContainer>
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        mb: 4,
        p: 8,
      }}
      data-qa={dataQa}
    >
      <CircularProgress size={35} />
    </Box>
    <Typography variant="body1">Loading... Please wait</Typography>
  </LoadingContainer>
)

export const LoadingBox = ({ "data-qa": dataQa }: LoadingContentAreaProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      mb: 4,
      p: 8,
      gap: 4,
    }}
    data-qa={dataQa}
  >
    <CircularProgress size={35} />
    <Typography variant="body1">Loading... Please wait</Typography>{" "}
  </Box>
)

const heights = [8.5, 20, 15, 18, 13] as const

export const LoadingMasonryArea = ({ "data-qa": dataQa }: LoadingContentAreaProps) => {
  const isMediumOrUp = useMediaQuery(theme => theme.breakpoints.up("md"))
  if (isMediumOrUp) {
    return (
      <Grid item xs={12}>
        <Box sx={{ width: "100%" }}>
          <Masonry columns={2} spacing={6}>
            {heights.map(height => (
              <Skeleton key={height} height={`${height}rem`} variant="rounded" />
            ))}
          </Masonry>
        </Box>
      </Grid>
    )
  }
  return <LoadingContentArea data-qa={dataQa} />
}
