import { createDateFromText } from "@/utils/formatting"
import { Uuid } from "@/utils/types"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { isNil } from "lodash"
import {
  createRecurringPremium,
  deleteRecurringPremium,
  getAllRecurringPremiums,
  getMembers,
} from "./recurringPremiumsEndpoints"
import { CreateRecurringPremiumRequest, RecurringPremium } from "./recurringPremiumsTypes"

const REFETCH_RP_INTERVAL = 4 * 1000 // 4 seconds

export const useGetMembers = (employmentId: Uuid | undefined, planYear: number | undefined) =>
  useQuery({
    queryKey: ["employees", employmentId, "shopping-persons", planYear],
    enabled: !!employmentId && !isNil(planYear),
    queryFn: async () => getMembers(employmentId!, planYear!),
  })

export const isPlanYearRecurringPremiumReady = (recurringPremiums: undefined | RecurringPremium[], planYear: number) =>
  recurringPremiums?.some(rp => rp.isPrimary && createDateFromText(rp.coverageStartDate).getFullYear() === planYear)

export const useGetAllRecurringPremiums = (
  companyId: Uuid | undefined,
  employmentId: Uuid | undefined,
  planYear?: number
) =>
  useQuery({
    queryKey: ["recurring-premiums", companyId, employmentId],
    enabled: !!companyId && !!employmentId,
    // SAFETY: We can assert these values are non-null because enabled is set to false if they are null
    queryFn: async () => getAllRecurringPremiums(companyId!, employmentId!),
    refetchInterval: planYear
      ? data => (isPlanYearRecurringPremiumReady(data.state.data, planYear) ? false : REFETCH_RP_INTERVAL)
      : undefined,
  })

export const useCreateRecurringPremium = (companyId: Uuid | undefined, employmentId: Uuid | undefined) => {
  const queryClient = useQueryClient()
  const key = ["recurring-premiums", companyId, employmentId]

  return useMutation({
    mutationKey: key,
    mutationFn: async (premium: CreateRecurringPremiumRequest) => {
      if (!companyId) throw new Error("companyId cannot be null")

      if (!employmentId) throw new Error("employmentId cannot be null")

      return createRecurringPremium(companyId, employmentId, premium)
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: key }),
  })
}

export const useDeleteRecurringPremium = (companyId: Uuid | undefined, employmentId: Uuid | undefined) => {
  const queryClient = useQueryClient()
  const key = ["recurring-premiums", companyId, employmentId]

  return useMutation({
    mutationKey: key,
    mutationFn: async (electionId: Uuid) => {
      if (!companyId) throw new Error("companyId cannot be null")

      if (!employmentId) throw new Error("employmentId cannot be null")

      return deleteRecurringPremium(companyId, employmentId, electionId)
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: key }),
  })
}
