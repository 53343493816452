import { AsyncButton } from "@/components/Buttons"
import { DatePickerField } from "@/components/DatePickerField"
import { SelectField } from "@/components/SelectField"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import { useNotifications } from "@/services/notificationService"
import { formatDateToYyyyMmDd, getDateWithoutTime } from "@/utils/dates"
import { copyRecurringPremiumSchema } from "@/utils/validations"
import CloseIcon from "@mui/icons-material/Close"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { Formik, FormikProps } from "formik"
import { Helmet } from "react-helmet-async"
import { REIMBURSEMENT_TYPE_OPTIONS } from "../tcHubConstants"
import { RecurringPremiumCopyRequest } from "../tcHubTypes"
import { useCopyRecurringPremium } from "./tcHubComplianceService"

type RecurringPremiumType = RecurringPremiumCopyRequest & { submit?: boolean }

interface NewFundingEventDetailsFormProps extends FormikProps<RecurringPremiumCopyRequest> {
  employeeName?: string
}

const CopyRecurringPremiumDetailsForm = ({
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  values,
  touched,
  employeeName,
}: NewFundingEventDetailsFormProps) => (
  <Grid container spacing={5} my={6}>
    <Grid item xs={12} sm={6}>
      <TextField
        fullWidth
        name="name"
        label="Name"
        value={employeeName ?? ""}
        InputProps={{
          readOnly: true,
        }}
        disabled
        data-qa="recurring-premium-name"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <SelectField
        data-qa="is-reimbursement-select"
        name="isReimbursement"
        value={String(values.isReimbursement)}
        data={REIMBURSEMENT_TYPE_OPTIONS.map(option => ({
          label: option.title,
          value: String(option.value),
        }))}
        type="boolean"
        label="Reimbursement Type"
        placeholder="Please select"
        onChange={handleChange}
        onBlur={handleBlur}
        required
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <DatePickerField
        name="coverageStartDate"
        label="Start Date"
        value={getDateWithoutTime(values.coverageStartDate)}
        onChange={handleChange}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <DatePickerField
        name="coverageEndDate"
        label="End Date"
        value={getDateWithoutTime(values.coverageEndDate)}
        onChange={handleChange}
        fullWidth
      />
    </Grid>
  </Grid>
)

interface CopyRecurringPremiumModalProps {
  recurringPremiumId: string
  showCopyRecurringPremiumModal: boolean
  employmentId: string
  employeeName?: string
  premium?: RecurringPremiumType
  onClose: () => void
}

export const CopyRecurringPremiumModal = ({
  onClose,
  showCopyRecurringPremiumModal,
  recurringPremiumId,
  employmentId,
  premium,
  employeeName,
}: CopyRecurringPremiumModalProps) => {
  const { mutateAsync: copyRecurringPremium, isPending } = useCopyRecurringPremium(recurringPremiumId, employmentId)

  const { notify } = useNotifications("copy-recurring-premium")

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Helmet title="Add new company auto funding event" />
      <Formik
        validationSchema={copyRecurringPremiumSchema}
        enableReinitialize
        initialValues={
          {
            coverageStartDate: premium?.coverageStartDate ?? "",
            coverageEndDate: premium?.coverageEndDate ?? "",
            isReimbursement: premium?.isReimbursement ?? false,
            submit: false,
          } as unknown as RecurringPremiumType
        }
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const payload: RecurringPremiumCopyRequest = {
              coverageStartDate: formatDateToYyyyMmDd(values.coverageStartDate!),
              coverageEndDate: formatDateToYyyyMmDd(values.coverageEndDate!),
              isReimbursement: values.isReimbursement!,
            }
            await copyRecurringPremium(payload)
            onClose()
            notify(`Recurring Premium created successfully`, "success")
          } catch (error: any) {
            const message = error.message || "Something went wrong"
            setErrors({ submit: error.message })
            notify(`${message}`, "error")
            setSubmitting(false)
          }
        }}
      >
        {formikProps => (
          <Dialog
            open={showCopyRecurringPremiumModal}
            onClose={(evt, reason) => {
              if (reason === "backdropClick") {
                onClose()
              }
            }}
            scroll="body"
            data-qa="copy-recurring-premium-modal"
            aria-labelledby="copy-recurring-premium-modal"
          >
            <DialogTitle>
              <Grid
                container
                sx={{ justifyContent: "space-between", alignItems: "center" }}
                className="dialog-headline-container"
              >
                <Typography className="dialog-headline" variant="h3tiempos">
                  Copy Recurring Premium
                </Typography>
                <CloseIcon
                  data-qa="copy-recurring-premium-dialog-close-icon-button"
                  className="dialog-close-icon"
                  onClick={onClose}
                />
              </Grid>
            </DialogTitle>
            <DialogContent className="dialog-content-container">
              <form noValidate onSubmit={formikProps.handleSubmit}>
                <Typography variant="body1">Please update the recurring premium details below.</Typography>
                <CopyRecurringPremiumDetailsForm employeeName={employeeName} {...formikProps} />
                <DialogActions>
                  <Button variant="text" onClick={onClose}>
                    Cancel
                  </Button>
                  <AsyncButton
                    isLoading={isPending}
                    type="submit"
                    variant="contained"
                    color="primary"
                    data-qa="copy-recurring-premium-button"
                  >
                    Copy with changes
                  </AsyncButton>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        )}
      </Formik>
    </TcHubGuard>
  )
}
