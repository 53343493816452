import { SEARCH_THRESHOLD } from "@/constants"
import { PersonModel } from "@/features/People/peopleTypes"
import { createFuseFilterOptions } from "@/utils/createFuseFilterOptions"
import { Autocomplete, CircularProgress, SxProps, TextField, Typography } from "@mui/material"
import { isNil, size } from "lodash"
import { useUsers } from "../tcHubService"

interface PersonSearchAutoCompleteProps {
  error?: string
  name: string
  handleBlur: any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  touched: boolean
  value: PersonModel | null
  sx?: SxProps
  dataQa?: string
  label?: string
  disabled?: boolean
}

const fuseFilterOptions = createFuseFilterOptions<PersonModel>({
  includeMatches: true,
  ignoreLocation: true,
  threshold: SEARCH_THRESHOLD,
  limit: 10,
  keys: ["firstName", "lastName", "email"],
})

export const PersonSearchAutoComplete = ({
  error,
  name,
  handleBlur,
  setFieldValue,
  touched,
  value: propsValue,
  sx,
  disabled,
  label = "Employee",
}: PersonSearchAutoCompleteProps) => {
  const { data: users, isLoading: loading } = useUsers()

  return (
    <Autocomplete
      disabled={disabled}
      autoComplete
      forcePopupIcon={false}
      filterOptions={fuseFilterOptions}
      data-qa="person-search-autocomplete"
      getOptionLabel={option => `${option.firstName ?? ""} ${option.lastName ?? ""}`}
      loading={loading && size(users) === 0}
      isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
      options={users ?? []}
      sx={sx}
      value={propsValue ?? null}
      onChange={(e, newValue) => {
        setFieldValue(name, newValue)
      }}
      renderOption={(props, option, { index }) => (
        <li {...props} key={index}>
          <Typography variant="body1">{option.firstName + " " + option.lastName + " " + option.email} </Typography>
        </li>
      )}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          error={!isNil(error) && touched}
          helperText={touched && error && "Please search for a valid name"}
          label={label}
          name={name}
          onBlur={handleBlur}
          placeholder="Search for an employee"
          required
          InputProps={{
            ...InputProps,
            endAdornment: loading ? (
              <CircularProgress color="inherit" size={20} sx={{ mx: 3 }} />
            ) : (
              InputProps.endAdornment
            ),
          }}
          inputProps={{ ...params.inputProps }}
        />
      )}
    />
  )
}
