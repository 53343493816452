import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { TcHubGuard } from "@/features/Auth/guards/TcHubGuard"
import {
  useCompanyFundingEventEntries,
  useCompanyFundingEvents,
  useCompanyFundingEventSummary,
} from "@/features/AutoPay/autopayService"
import { CompanyModel } from "@/features/CreateCompany/createCompanyEndpoints"
import { FUNDING_EVENT_STATUS_SCHEDULED } from "@/features/Funding/fundingConstants"
import { FundingEventSummary } from "@/features/Funding/fundingTypes"
import { getValueLabelsFromYearsToNext, months } from "@/utils/dates"
import { DATE_FORMAT_MONTH_DAY_YEAR, formatCents, transformDate } from "@/utils/formatting"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { uniq } from "lodash"
import { useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useAutoPayCompanies } from "../../tcHubService"
import { TchubAutoPayFundingReportsTable } from "./TchubAutoPayFundingReportsTable"
interface AutoPayFudingReportSnapshotProps {
  latestScheduledEvent: FundingEventSummary
}

const buildCompanyOptions = (companies: CompanyModel[]) => {
  const uniqueCarrierNames = uniq(companies.map(company => company.companyInfo.companyName))
  const sortedOptions = uniqueCarrierNames.toSorted()

  return ["", ...sortedOptions]
}

const AutoPayFudingReportSnapshot = ({ latestScheduledEvent }: AutoPayFudingReportSnapshotProps) => (
  <Grid container alignItems="center" sx={{ justifyContent: { xs: "space-between", md: "flex-start" }, py: 2 }}>
    <Grid item xs={3}>
      <Grid container>
        <Grid item>
          <Typography variant="body1">Next Funding Amount</Typography>
        </Grid>
        <Grid item ml={1}>
          <HelpOutlineOutlinedIcon />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {latestScheduledEvent.totalFundingAmountCents
            ? formatCents(latestScheduledEvent.totalFundingAmountCents)
            : "—"}
        </Typography>
      </Grid>
    </Grid>
    <Grid item sm={1} sx={{ px: 4 }}>
      <Typography variant="caption">FOR</Typography>
    </Grid>
    <Grid item xs={2}>
      <Grid container>
        <Grid item>
          <Typography variant="body1"># of Employees</Typography>
        </Grid>
        <Grid item ml={1}>
          <HelpOutlineOutlinedIcon />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">{latestScheduledEvent.numberOfEmployees || "—"}</Typography>
      </Grid>
    </Grid>
    <Grid item xs={1} sx={{ px: 4 }}>
      <Typography variant="caption">ON</Typography>
    </Grid>
    <Grid item xs={2}>
      <Grid container>
        <Grid item>
          <Typography variant="body1">Transfer Date</Typography>
        </Grid>
        <Grid item ml={1}>
          <HelpOutlineOutlinedIcon />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {latestScheduledEvent.periodEndAt
            ? transformDate(latestScheduledEvent.periodEndAt, DATE_FORMAT_MONTH_DAY_YEAR)
            : "—"}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)

export const TchubAutoPayFundingReportsPage = () => {
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>()
  const [selectedYear, setSelectedYear] = useState<number | undefined>()
  const [searchCompany, setSearchCompany] = useState<string | null | undefined>("")
  const { data: companies, isLoading: isCompaniesLoading } = useAutoPayCompanies()

  const selectedCompany = useMemo(
    () => companies?.find(c => c.companyInfo.companyName === searchCompany),
    [searchCompany, companies]
  )
  const companyOptions = useMemo(() => buildCompanyOptions(companies ?? []), [companies])
  const companyId = selectedCompany?.id ?? ""

  const { data: companyFundingEventEntries, isLoading: loadingCompanyFundingEventEntries } =
    useCompanyFundingEventEntries({
      companyId,
      month: selectedMonth,
      year: selectedYear,
    })
  const { data: companyFundingEvents, isLoading: loadingCompanyFundingEvents } = useCompanyFundingEvents(companyId)
  const scheduledEvents =
    companyFundingEvents?.companyFundingEvents.filter(event => event.status === FUNDING_EVENT_STATUS_SCHEDULED) ?? []
  const { data: latestScheduledEvent } = useCompanyFundingEventSummary(companyId, scheduledEvents?.[0]?.id)

  const handleCompanySelectChange = (event: any, newValue: string | null | undefined) => {
    setSearchCompany(newValue)
  }

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Grid container data-qa="tc-hub-funding-reports-page">
        <Helmet title="TC Hub Funding Reports" />
        <Grid item xs={12} mb={5}>
          <Typography variant="h1" gutterBottom data-qa="funding-events">
            Funding Reports
          </Typography>
        </Grid>
        {latestScheduledEvent && <AutoPayFudingReportSnapshot latestScheduledEvent={latestScheduledEvent} />}
        <Grid container spacing={4}>
          <Grid item>
            <FormControl variant="outlined" size="small" sx={{ backgroundColor: "white", minWidth: 250 }}>
              <Autocomplete
                autoComplete
                data-qa="carrier-options"
                options={companyOptions}
                value={searchCompany}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={params => <TextField {...params} label="Company" />}
                filterOptions={(options, { inputValue }) =>
                  options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase()))
                }
                onChange={handleCompanySelectChange}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" size="small" sx={{ backgroundColor: "white", minWidth: 150 }}>
              <InputLabel id="month" sx={{ mt: "0.5rem" }}>
                Month
              </InputLabel>
              <Select
                labelId="month"
                id="month"
                value={selectedMonth}
                onChange={e => setSelectedMonth(Number(e.target.value))}
                sx={{ height: "3.5rem", textAlign: "justify" }}
                label="Month"
              >
                {months.map(month => (
                  <MenuItem sx={{ alignItems: "center" }} key={`month-select-${month.value}`} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl size="small" sx={{ backgroundColor: "white", minWidth: 120 }}>
              <InputLabel id="year" sx={{ mt: "0.5rem" }}>
                Year
              </InputLabel>
              <Select
                id="year"
                labelId="year"
                value={selectedYear}
                sx={{ height: "3.5rem" }}
                onChange={e => setSelectedYear(Number(e.target.value))}
                label="Year"
              >
                {getValueLabelsFromYearsToNext(2024).map(year => (
                  <MenuItem key={`year-select-${year.value}`} value={year.value}>
                    {year.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={12}>
            {selectedMonth &&
            selectedYear &&
            companyId &&
            (isCompaniesLoading || loadingCompanyFundingEvents || loadingCompanyFundingEventEntries) ? (
              <SkeletonTableLoader
                headerTitles={["Name", "Amount", "Date"]}
                bodyRowsCount={7}
                data-qa="skeleton-table-loader-company-funding-events"
              />
            ) : (
              <TchubAutoPayFundingReportsTable
                events={companyFundingEventEntries ?? []}
                companyId={selectedCompany?.id ?? null}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </TcHubGuard>
  )
}
