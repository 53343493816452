import { AsyncButton } from "@/components/Buttons"
import { createDataQa } from "@/utils/dataQa"
import { Uuid } from "@/utils/types"
import { KeyboardArrowLeft } from "@mui/icons-material"
import { Button, Divider, Grid, Typography } from "@mui/material"
import { noop } from "lodash"
import { ReactNode } from "react"
import { UploadCsvFile } from "../../components/UploadCsvFile"

export interface AddPersonStepProps {
  title?: string
  children: ReactNode
  continueButtonType?: "button" | "submit"
  continueButtonLabel?: string
  isAdmin?: boolean
  userId?: Uuid
  companyId?: Uuid
  licenseKey?: string
  dataQa?: string
  planId?: Uuid
  token?: string
  disabled?: boolean
  isLoading?: boolean
  onClose: () => void
  onBack: () => void
  onContinue?: () => void
}

export const AddPersonStep = ({
  title,
  children,
  continueButtonType = "button",
  continueButtonLabel = "Continue",
  isAdmin,
  userId,
  companyId,
  licenseKey,
  dataQa,
  planId,
  token,
  disabled = false,
  isLoading = false,
  onClose,
  onContinue = noop,
  onBack,
}: AddPersonStepProps) => {
  const uniqueKey = `${isAdmin}-${userId}-${companyId}-${planId}`

  return (
    <>
      {title && (
        <Typography variant="h5" gutterBottom sx={{ p: 3 }} data-qa={createDataQa("add-person-title", title)}>
          {title}
        </Typography>
      )}

      <Grid sx={{ px: 4, height: "100%", mb: 8 }} container spacing={4}>
        {children}
      </Grid>

      <Divider sx={{ mx: 4 }} />

      <Grid container sx={{ justifyContent: "space-between", alignItems: "center", pt: 4, pb: 0, px: 2 }}>
        <Grid item xs={12} sm={6}>
          <Button
            data-qa="back-button"
            startIcon={<KeyboardArrowLeft />}
            sx={{ color: "inherit", width: { xs: "100%", sm: "auto" }, ml: { xs: -4, sm: 0 } }}
            onClick={onBack}
          >
            Back
          </Button>
        </Grid>

        <Grid
          sx={{
            width: { xs: "100%", sm: "auto" },
            display: "flex",
            gap: 4,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Button data-qa="cancel-button" onClick={onClose} sx={{ color: "inherit", mr: 2, mt: { xs: 4, sm: 0 } }}>
            Cancel
          </Button>

          {isAdmin ? (
            <UploadCsvFile
              title="Continue"
              userId={userId ?? ""}
              companyId={companyId ?? ""}
              licenseKey={licenseKey ?? ""}
              data-qa={dataQa}
              planId={planId}
              token={token}
              key={uniqueKey}
              onClose={onClose}
            />
          ) : (
            <AsyncButton
              isLoading={isLoading}
              data-qa="continue-save-button"
              type={continueButtonType}
              onClick={onContinue}
              variant="contained"
              disabled={disabled}
            >
              {continueButtonLabel}
            </AsyncButton>
          )}
        </Grid>
      </Grid>
    </>
  )
}
