import { AmountTextField } from "@/components/AmountTextField"
import { DatePickerField } from "@/components/DatePickerField"
import { DrawerForm, DrawerFormProps } from "@/components/DrawerForm"
import { BackNavigationButton, FlowNavigationButtons } from "@/components/FlowNavigationButtons"
import { SelectField } from "@/components/SelectField"
import { SelectMultiField } from "@/components/SelectMultiField"
import { EXTERNAL_LINKS, PLAN_PREMIUM_LABEL, SELF_ENROLL } from "@/constants"
import { useCompanyEmployment } from "@/features/Auth/useAuth"
import { POC } from "@/features/Documents/documentsConstants"
import { useCreateDocument } from "@/features/Documents/documentsService"
import { useConfirmation } from "@/services/Confirmations"
import { useNotifications } from "@/services/notificationService"
import { takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { getOnlyDate, yearFirstDay, yearLastDay } from "@/utils/dates"
import {
  createDateFromText,
  createDateFromTextOrElse,
  formatCents,
  formatDate,
  formatDollarToCents,
  toTitleCase,
  transformDate,
} from "@/utils/formatting"
import { Cents, Uuid } from "@/utils/types"
import { getFileBase64, removeBase64Metadata } from "@/utils/util"
import { AddCircleOutlineOutlined, Close, DeleteOutlined, EditOutlined } from "@mui/icons-material"
import {
  Avatar,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Box } from "@mui/system"
import { addDays } from "date-fns"
import { Formik } from "formik"
import { sortBy } from "lodash"
import { useState } from "react"
import * as Yup from "yup"
import { create } from "zustand"
import { INSURANCE_TYPE_OPTIONS, INSURANCE_TYPES, MEDICARE, PENDING } from "../../benefitsElectionConstants"
import { useGetHealthBenefitElectionById, useManageShoppingPersons } from "../../benefitsElectionService"
import { InsuranceType, ProofOfCoverageFile, ShoppingPerson } from "../../benefitsElectionTypes"
import { createNewShoppingPersonPayload, getPlanBalance } from "../../benefitsElectionUtils"
import { PerMonthSpan } from "../../components/PlanComponents"
import {
  useCreateRecurringPremium,
  useDeleteRecurringPremium,
  useGetAllRecurringPremiums,
  useGetMembers,
} from "../../recurringPremiumsService"
import {
  CreateRecurringPremiumRequest,
  RecurringPremium,
  RecurringPremiumFamilyMember,
} from "../../recurringPremiumsTypes"
import { FamilyMemberFormik } from "../myInfo/Family/FamilyMemberForm"
import {
  findOptionLabel,
  getRecurringReimbursementSchema,
  ProofOfCoverage,
  RecurringReimbursementForm,
  useRenderSelectValue,
} from "./RecurringReimbursement"

interface PriceProps {
  amountCents: Cents
}

const Price = ({ amountCents }: PriceProps) => (
  <>
    <Typography variant="body1bold" component="span" color="primary">
      {formatCents(amountCents)}
    </Typography>
    <PerMonthSpan />
  </>
)

interface ReimbursementsOverviewProps {
  allowanceCents: Cents
  reimbursementsCents: Cents
}

const ReimbursementsOverview = ({ allowanceCents, reimbursementsCents }: ReimbursementsOverviewProps) => {
  const { balanceValue, balanceLabel, balanceLabelColor } = getPlanBalance(allowanceCents, reimbursementsCents)

  return (
    <Stack direction={{ md: "row" }} justifyContent="space-between" width={{ md: "100%" }} gap={4}>
      <Typography variant="h5" alignSelf={{ md: "center" }}>
        Your Reimbursements
      </Typography>

      <Card sx={{ p: 2 }}>
        <Stack direction="row" spacing={8} width="100%" justifyContent="space-between">
          <Typography variant="body1" component="span">
            <Typography component="span" mr={4}>
              Total Allowance
            </Typography>
            <Price amountCents={allowanceCents} />
          </Typography>
          <Typography component="span">
            <Typography component="span" mr={4}>
              {balanceLabel}
            </Typography>
            <Typography variant="body1bold" color={balanceLabelColor}>
              {balanceValue}
            </Typography>
            <PerMonthSpan />
          </Typography>
        </Stack>
      </Card>
    </Stack>
  )
}

export const renderInsuranceType = (insuranceType: InsuranceType) => toTitleCase(insuranceType?.replace(/_[A-Z_]+/, ""))

interface ReimbursementCardProps {
  members: RecurringPremiumFamilyMember[]
  premium: RecurringPremium
  editElection: () => void
  deleteElection: (electionId: Uuid) => void
}

const ReimbursementCard = ({
  members,
  premium: {
    id: premiumId,
    premiumAmountCents,
    coveredFamilyMembers,
    coverageStartDate,
    coverageEndDate,
    insuranceType,
    isPrimary,
  },
  deleteElection,
  editElection,
}: ReimbursementCardProps) => {
  const startDate = createDateFromText(coverageStartDate)
  const endDate = createDateFromText(coverageEndDate || `${startDate.getFullYear()}-12-31`)

  return (
    <Card sx={{ p: 4, height: "100%" }}>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2bold">Family members covered</Typography>
          {/* FUTURE: Refactor these buttons. Hiding in the meantime */}
          <Stack direction="row" height="100%" visibility="hidden">
            <IconButton onClick={() => editElection()}>
              <EditOutlined />
            </IconButton>
            <IconButton onClick={() => deleteElection(premiumId)}>
              <DeleteOutlined />
            </IconButton>
          </Stack>
        </Stack>
        <Box>
          {members
            .filter(member => coveredFamilyMembers?.map(m => m.id).includes(member.id))
            .map(({ id, firstName, lastName }) => (
              <Chip
                key={id}
                label={`${firstName} ${lastName}`}
                variant="outlined"
                sx={{
                  m: 1,
                  width: "min-content",
                }}
              />
            ))}
        </Box>
        <Typography variant="body2bold" mt={4}>
          {PLAN_PREMIUM_LABEL}
        </Typography>
        <Box>
          <Price amountCents={premiumAmountCents} />
        </Box>
        <Stack direction="row" mt={6} gap={16}>
          <Box>
            <Typography variant="body2">Insurance type</Typography>
            <Typography variant="body2bold" color="primary">
              {renderInsuranceType(insuranceType)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Start date</Typography>
            <Typography variant="body2bold" color="primary">
              {formatDate(startDate)}
            </Typography>
          </Box>
          {insuranceType !== MEDICARE && (
            <Box>
              <Typography variant="body2">End date</Typography>
              <Typography variant="body2bold" color="primary">
                {formatDate(endDate)}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
    </Card>
  )
}

interface AddReimbursementButtonProps {
  handleClick: () => void
}

const AddReimbursementButton = ({ handleClick }: AddReimbursementButtonProps) => (
  <Button
    onClick={handleClick}
    fullWidth
    sx={{
      minHeight: "16rem",
      height: "100%",
      color: "primary.dark",
      border: "1px dashed",
      borderColor: "colors.borderGray",
      borderRadius: "4px",
      backgroundColor: "colors.regionGray",
    }}
    data-qa="add-reimbursement-button"
  >
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <Avatar sx={{ bgcolor: takeCommandPrimary[100] }}>
          <AddCircleOutlineOutlined color="primary" />
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="caption">Add a new reimbursement</Typography>
      </Grid>
    </Grid>
  </Button>
)

interface ReimbursementCardsContainerProps {
  members: RecurringPremiumFamilyMember[]
  premiums: RecurringPremium[]
  showReimbursementsDrawer: (premium?: RecurringPremium) => void
  deleteElection: (electionId: Uuid) => void
}

const ReimbursementCardsContainer = ({
  members,
  premiums,
  showReimbursementsDrawer,
  deleteElection,
}: ReimbursementCardsContainerProps) => {
  // Please don't copy+paste this
  const isMediumOrUp = useMediaQuery(theme => theme.breakpoints.up("md"))
  const cols = isMediumOrUp ? 2 : 1

  return (
    <ImageList cols={cols} sx={{ width: "100%", minHeight: "16rem", rowHeight: "16rem" }}>
      {sortBy(premiums, p => createDateFromText(p.coverageStartDate).getFullYear()).map(premium => (
        <ImageListItem key={premium.id} sx={{ p: 2 }}>
          <ReimbursementCard
            members={members}
            premium={premium}
            deleteElection={deleteElection}
            editElection={() => showReimbursementsDrawer(premium)}
          />
        </ImageListItem>
      ))}
      <ImageListItem sx={{ p: 2 }}>
        <AddReimbursementButton handleClick={() => showReimbursementsDrawer()} />
      </ImageListItem>
    </ImageList>
  )
}

export const otherPremiumsTitle = "Do you have any other premiums you want to claim reimbursement for?"

export const OtherPremiumsDescription = () => (
  <Typography variant="body1" pb="1rem">
    This might include additional Medicare parts or supplements, premiums for family members covered by a different plan
    than you, or a{" "}
    <Link target="_blank" href={EXTERNAL_LINKS.DENTAL_PARTNER}>
      dental
    </Link>{" "}
    or vision plan.
  </Typography>
)

interface OtherPremiumForm extends RecurringReimbursementForm {
  insuranceType: InsuranceType
  pocDocumentId?: string
}

const ADD_REIMBURSEMENT_INITIAL_VALUES = {
  selectedMembers: [],
  premiumAmount: "",
  startDate: null,
  endDate: "",
  insuranceType: "",
  // SAFETY: Formik isValid verifies this for us
} as unknown as OtherPremiumForm

interface State {
  initialValues: OtherPremiumForm
  setInitialValues: (initialValues: OtherPremiumForm) => void
}

const useEditStore = create<State>()(set => ({
  initialValues: ADD_REIMBURSEMENT_INITIAL_VALUES,
  setInitialValues: initialValues => set({ initialValues }),
}))

interface AddMembersButtonProps {
  handleClick: () => void
}

const AddMembersButton = ({ handleClick }: AddMembersButtonProps) => (
  <Button
    startIcon={<AddCircleOutlineOutlined />}
    sx={{
      width: "100%",
      color: "colors.darkBody",
      bgcolor: "colors.lightBlueShade",
      borderRadius: 0,
      py: 3,
      px: 4,
      justifyContent: "left",
    }}
    onClick={event => {
      event.preventDefault()
      handleClick()
    }}
  >
    Add another family member
  </Button>
)

interface AddMemberModalProps {
  isOpen: boolean
  planYear: number
  shoppingSessionId?: Uuid
  employmentId: Uuid
  handleClose: () => void
}

const AddMemberModal = ({ isOpen, planYear, shoppingSessionId, employmentId, handleClose }: AddMemberModalProps) => {
  const membersQueryKey = ["employees", employmentId, "shopping-persons", planYear]
  const { mutateAsync: manageShoppingPersons, isPending } = useManageShoppingPersons(
    shoppingSessionId ?? "",
    membersQueryKey
  )

  // The error is caught by the child component
  const createFamilyMember = async (newMember: ShoppingPerson) => {
    const postMemberPayload = createNewShoppingPersonPayload(newMember)
    await manageShoppingPersons([postMemberPayload])
  }
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      data-qa="modal-confirmation"
      PaperProps={{ sx: { minWidth: { xs: "90%", sm: "70%" } } }}
      closeAfterTransition={false}
    >
      <DialogTitle id="alert-dialog-title" variant="h3tiempos">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3tiempos" sx={{ py: 2 }}>
            Add a new family member
          </Typography>
          <IconButton
            data-qa="alert-dialog-close-icon-button"
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: theme => theme.palette.grey[900],
              padding: 0,
              ml: "auto",
              height: "2rem",
              width: "2rem",
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FamilyMemberFormik
          isEmployee={false}
          planYear={planYear}
          isPending={isPending}
          createFamilyMember={createFamilyMember}
          onClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  )
}

type ReimbursementsEditorProps = Omit<DrawerFormProps, "paperStyle" | "onClose" | "children"> & {
  members: RecurringPremiumFamilyMember[]
  editingElectionId: Uuid | undefined
  minStartDate: Date
  planYear: number
  healthBenefitId: Uuid
  shoppingSessionId?: Uuid

  handleClose: () => void
}

const ReimbursementsEditor = ({
  members,
  handleClose,
  editingElectionId,
  minStartDate,
  planYear,
  healthBenefitId,
  shoppingSessionId,
  ...props
}: ReimbursementsEditorProps) => {
  const minEndDate = addDays(minStartDate, 1)

  const [proofOfCoverageFile, setProofOfCoverageFile] = useState<ProofOfCoverageFile>({
    file: null,
    isPreviousFile: false,
  })
  const [showAddMemberForm, setShowAddMemberForm] = useState(false)

  const { companyId, employmentId } = useCompanyEmployment()
  const { mutateAsync: createDocument } = useCreateDocument(companyId)
  const { mutateAsync: createRecurringPremium } = useCreateRecurringPremium(companyId, employmentId)
  const initialValues = useEditStore(state => state.initialValues)

  const selectOptions = members.map(({ id: value, firstName, lastName }) => ({
    value,
    label: `${firstName} ${lastName}`,
  }))
  const renderSelectValue = useRenderSelectValue(findOptionLabel, selectOptions)

  const { notify } = useNotifications("add-other-premium")

  const validationSchema = getRecurringReimbursementSchema(planYear, minStartDate, false).shape({
    insuranceType: Yup.string().oneOf(INSURANCE_TYPES).required("Insurance type is required"),
  })

  return (
    <DrawerForm
      paperStyle={{
        p: "1.5rem 2rem",
        width: { xs: "90%", md: "80%" },
        maxWidth: "40rem",
      }}
      onClose={() => handleClose()}
      {...props}
    >
      <AddMemberModal
        isOpen={showAddMemberForm}
        handleClose={() => setShowAddMemberForm(false)}
        planYear={planYear}
        shoppingSessionId={shoppingSessionId}
        employmentId={employmentId}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async ({ insuranceType, selectedMembers, premiumAmount, startDate, endDate }) => {
          try {
            const premiumRequest: CreateRecurringPremiumRequest = {
              companyId,
              employmentId,
              coverageStartDate: transformDate(startDate!),
              coverageEndDate: transformDate(endDate!),
              coveredFamilyMembers: selectedMembers
                .map((id): RecurringPremiumFamilyMember | undefined => {
                  const member = members.find(a => a.id === id)
                  if (!member) return

                  const { firstName, lastName, dateOfBirth, relationship } = member

                  return {
                    id,
                    firstName,
                    lastName,
                    dateOfBirth: transformDate(dateOfBirth),
                    relationship,
                  }
                })
                .filter(e => !!e),
              premiumAmountCents: formatDollarToCents(premiumAmount),
              insuranceType,
              enrollmentType: SELF_ENROLL,
              isPrimary: false,
              isReimbursement: true,
              recurringPremiumMonths: [],
              status: PENDING,
              healthPlanId: "",
              healthPlanName: "",
              healthBenefitId,
            }

            if (editingElectionId) {
              // FUTURE Remove this if editing is not supported
              // await updateHealthBenefitsElection({
              //   id: editingElectionId,
              //   ...electionInfo,
              // })
            } else {
              const file = proofOfCoverageFile.file
              if (!file) {
                throw new Error("File is null")
              }
              const base64 = await getFileBase64(file)
              const document = removeBase64Metadata(base64)
              const documentName = `supplemental-poc-file-${file.name}`

              const result = await createDocument({
                document,
                documentName,
                documentType: POC,
                employeeId: employmentId,
              })

              const pocDocumentId = result.id
              await createRecurringPremium({ ...premiumRequest, pocDocumentId })
            }

            handleClose()
          } catch (e) {
            notify("An error occurred submitting your premium", "error")
            console.error(e)
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isValid,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldTouched,
          handleSubmit,
        }) => (
          <>
            <Grid container spacing={4}>
              <Grid item>
                <BackNavigationButton onClick={() => handleClose()}>Back</BackNavigationButton>
              </Grid>
              <Grid item>
                <Typography variant="h1" gutterBottom>
                  {editingElectionId ? "Edit" : "Add new"} premium reimbursement
                </Typography>
                <OtherPremiumsDescription />
              </Grid>
              <Grid item xs={12}>
                <SelectMultiField
                  selectedValues={values.selectedMembers}
                  fieldLabel="Who is covered in this plan?"
                  data={selectOptions}
                  name="selectedMembers"
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("selectedMembers")}
                  error={Boolean(touched.selectedMembers && errors.selectedMembers)}
                  helperText={
                    touched.selectedMembers && errors.selectedMembers ? (errors.selectedMembers as string) : undefined
                  }
                  renderValue={renderSelectValue}
                  data-qa="members-select-field"
                >
                  <AddMembersButton handleClick={() => setShowAddMemberForm(true)} />
                </SelectMultiField>
              </Grid>
              <Grid item xs={12}>
                <AmountTextField
                  touched={!!touched.premiumAmount}
                  errorString={errors.premiumAmount!}
                  error={Boolean(touched.premiumAmount && errors.premiumAmount)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="premiumAmount"
                  label="Premium Amount"
                  value={values.premiumAmount}
                  sx={{ mt: 0 }}
                  maxAmount={10000}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  required
                  name="insuranceType"
                  value={values.insuranceType}
                  label="Insurance type"
                  placeholder="Please Select"
                  data={INSURANCE_TYPE_OPTIONS}
                  onBlur={handleBlur}
                  dataQa={createDataQa("insurance-type-dropdown")}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePickerField
                  data-qa="start-date-field"
                  name="startDate"
                  label="Start Date"
                  required
                  fullWidth
                  variant="outlined"
                  type="date"
                  value={getOnlyDate(values.startDate ?? null)}
                  minDate={minStartDate}
                  defaultCalendarMonth={minStartDate}
                  maxDate={yearLastDay(planYear)}
                  error={Boolean(errors.startDate)}
                  helperText={errors.startDate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              {values.insuranceType !== MEDICARE && (
                <Grid item xs={12}>
                  <DatePickerField
                    data-qa="end-date-field"
                    name="endDate"
                    label="End Date"
                    required
                    fullWidth
                    variant="outlined"
                    type="date"
                    minDate={minEndDate}
                    defaultCalendarMonth={minEndDate}
                    value={getOnlyDate(values.endDate ?? null)}
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Upload proof of coverage for an existing plan
                </Typography>
                <Typography mb="1rem">
                  If you or a family member have an insurance plan purchased outside of Take Command, upload the plan
                  details to confirm the plan qualifies for reimbursement.
                </Typography>
                <Typography>Proof of coverage document needs to show the following:</Typography>
                <ul>
                  <li>Enrollee's first and last name</li>
                  <li>Name of the health plan</li>
                  <li>{planYear} monthly premium amount</li>
                </ul>
              </Grid>
            </Grid>
            <ProofOfCoverage
              companyId={companyId}
              existingPocDocumentId={initialValues.pocDocumentId}
              setProofOfCoverageFile={setProofOfCoverageFile}
              hidePreamble
            />
            <FlowNavigationButtons
              handleSkip={() => handleClose()}
              skipLabel="Close"
              handleContinue={() => handleSubmit()}
              continueLabel={editingElectionId ? "Save changes" : "Add new"}
              isSubmitting={isSubmitting}
              disabled={!dirty || !isValid || (!editingElectionId && !proofOfCoverageFile.file)}
              sx={{ mt: 8 }}
            />
          </>
        )}
      </Formik>
    </DrawerForm>
  )
}

const createOtherPremiumForm = ({
  coveredFamilyMembers,
  premiumAmountCents,
  coverageStartDate,
  coverageEndDate,
  insuranceType,
  pocDocumentId,
}: RecurringPremium) => ({
  selectedMembers: coveredFamilyMembers?.map(p => p.id) ?? [],
  premiumAmount: formatCents(premiumAmountCents),
  startDate: createDateFromText(coverageStartDate),
  endDate: createDateFromTextOrElse(coverageEndDate, null),
  insuranceType,
  pocDocumentId,
})

export const OtherPremiumsCore = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [editingPremium, setEditingPremium] = useState<Uuid>()
  const setInitialValues = useEditStore(state => state.setInitialValues)

  const { confirmAction: confirmDelete } = useConfirmation({
    title: "Are you sure you want to delete this premium?",
    message: "This action will permanently delete this premium",
    actionLabel: "Delete",
    isError: true,
  })
  const { companyId, employmentId } = useCompanyEmployment()

  const {
    data: recurringPremiums,
    isPending: isPendingPremiums,
    isError: isErrorPremiums,
  } = useGetAllRecurringPremiums(companyId, employmentId)

  const maybePlanYear = recurringPremiums
    ?.map(p => createDateFromText(p.coverageStartDate).getFullYear())
    .reduce((acc, e) => Math.max(acc, e), 0)

  const primaryRp =
    maybePlanYear && recurringPremiums
      ? recurringPremiums.find(rp => rp.isPrimary && rp.coverageStartDate.includes(String(maybePlanYear)))
      : undefined

  const { data: maybeFamilyMembers, isPending: isPendingMembers } = useGetMembers(employmentId, maybePlanYear)
  const { mutateAsync: deleteRecurringPremium } = useDeleteRecurringPremium(companyId, employmentId)

  const healthBenefitElectionId = primaryRp?.healthBenefitElectionId
  const { data: healthBenefitElection } = useGetHealthBenefitElectionById(employmentId, healthBenefitElectionId)

  const shoppingSessionId = healthBenefitElection?.shoppingSession?.id

  const isWaiting = !companyId || !employmentId || isPendingPremiums || isPendingMembers

  if (isWaiting) return "Loading..."

  if (isErrorPremiums) return "An error occurred"

  if (maybePlanYear === 0) return "Information about your premiums is not yet available, please try again later!"

  const allFamilyMembers = maybeFamilyMembers!
  const planYear = maybePlanYear!

  const minStartDate = createDateFromTextOrElse(
    recurringPremiums.find(e => e.isPrimary)?.coverageStartDate,
    yearFirstDay(planYear)
  )

  const planYearPremiums = recurringPremiums.filter(
    rp =>
      createDateFromText(rp.coverageStartDate).getFullYear() === planYear &&
      createDateFromText(rp.coverageEndDate).getFullYear() === planYear
  )

  //find healthBenefitId for creating new RP
  const healthBenefitId = primaryRp?.healthBenefitId

  const monthlyAllowanceCents = planYearPremiums.find(rp => rp.isPrimary)?.enrolledAllowanceCents ?? 0
  const reimbursementsTotalCents = planYearPremiums.reduce((acc, e) => e.premiumAmountCents + acc, 0)

  const deleteElection = async (electionId: Uuid) => {
    await confirmDelete()
    await deleteRecurringPremium(electionId)
  }

  const showReimbursementsDrawer = (premium?: RecurringPremium) => {
    setEditingPremium(premium?.id)

    setInitialValues(
      premium
        ? createOtherPremiumForm(premium)
        : { ...ADD_REIMBURSEMENT_INITIAL_VALUES, endDate: yearLastDay(planYear) }
    )

    setDrawerOpen(true)
  }

  return (
    <>
      <ReimbursementsOverview allowanceCents={monthlyAllowanceCents} reimbursementsCents={reimbursementsTotalCents} />
      <ReimbursementCardsContainer
        members={allFamilyMembers}
        premiums={recurringPremiums}
        showReimbursementsDrawer={showReimbursementsDrawer}
        deleteElection={deleteElection}
      />
      {!!healthBenefitId && (
        <ReimbursementsEditor
          editingElectionId={editingPremium}
          members={allFamilyMembers}
          open={drawerOpen}
          minStartDate={minStartDate}
          planYear={planYear}
          healthBenefitId={healthBenefitId}
          handleClose={() => setDrawerOpen(false)}
          shoppingSessionId={shoppingSessionId as Uuid}
        />
      )}
    </>
  )
}
