import {
  CompanyModel,
  createBusinessUnit,
  getBusinessUnit,
  updateBusinessUnit,
} from "@/features/CreateCompany/createCompanyEndpoints"
import {
  BusinessUnit,
  CarrierIntegration,
  CarrierUpdatesTableHeader,
  JournalEntryRequest,
  RecurringPremiumRequest,
  RegistrationTrackingDetailModel,
} from "@/features/TCHub/tcHubTypes"
import { Uuid } from "@/utils/types"
import { useDebouncedQuery } from "@/utils/useDebouncedQuery"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import * as idb from "idb-keyval"
import { size, values } from "lodash"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { getFundingAccounts } from "../AutoPay/autopayEndpoints"
import {
  createCarrierIntegration,
  getCarrierIntegrations,
  getCarrierUpdates,
  getPlanByIdAndPlanYear,
  getSignedUrl,
  updateCarrierIntegration,
} from "../BenefitsElection/healthPlansEndpoints"
import { PEOPLE_ACTIVE_STATUS, PEOPLE_INACTIVE_STATUSES } from "../People/peopleConstants"
import { Person, PersonModel } from "../People/peopleTypes"
import { EditPersonValues } from "./editPersonValidations"
import {
  createAdHocBookTransfer,
  createAdHocFundingTransfer,
  createJournalEntry,
  createSubAccount,
  getAllActiveCompanies,
  getAllFundingEvents,
  getAllowanceBalanceCentsByEmploymentId,
  getAllPeople,
  getAllRegistrationTracking,
  getAutopayCompanies,
  getFundingEntityDetails,
  getFundingEventById,
  getHealthBenefitElectionDetails,
  getPeopleSearch,
  getPersonDetails,
  getPersonRpDetails,
  getRegistrationTrackingDetails,
  getReserveBalanceCents,
  reverseJournalEntry,
  searchRecurringPremiums,
  updatePerson,
  updateRegistrationTracking,
} from "./tcHubEndpoints"
import { FundsTransferRequest } from "./tcHubTypes"
import { updatePersonFormToPayload } from "./TcHubUtils"

export const filterPeopleByStatus = <T extends { status: string }>(people: T[], statuses: string[]) =>
  people.filter(person => statuses.includes(person.status))

export const convertUserModelToPerson = (user: PersonModel): Person => ({
  ...user,
  avatarUrl: "",
  allowanceInCents: "",
  lastLoginAt: "",
})

export const activeUsersFilter = (people: PersonModel[]): Person[] =>
  filterPeopleByStatus(people, Object.values(PEOPLE_ACTIVE_STATUS)).map(convertUserModelToPerson)

export const inactiveUsersFilter = (people: PersonModel[]): Person[] =>
  filterPeopleByStatus(people, Object.values(PEOPLE_INACTIVE_STATUSES)).map(convertUserModelToPerson)

export const useMultiSelectHeader = (originalHeaders: CarrierUpdatesTableHeader[], multiSelect?: boolean) => {
  const [headers, setHeaders] = useState(originalHeaders)

  useEffect(() => {
    if (!multiSelect) {
      // Remove the first header if multiSelect is false
      setHeaders(originalHeaders.slice(1))
    } else {
      // Keep all headers if multiSelect is true
      setHeaders(originalHeaders)
    }
  }, [multiSelect, originalHeaders])

  return headers
}

export const useFundingAccounts = (companyId: string) =>
  useQuery({
    queryKey: ["fundingAccounts", companyId],
    queryFn: () => getFundingAccounts(companyId),
    enabled: Boolean(companyId),
  })

export const useReserveBalance = (companyId: string) =>
  useQuery({
    queryKey: ["reserveBalance", companyId],
    queryFn: () => getReserveBalanceCents(companyId),
    enabled: Boolean(companyId),
  })

export const useEmploymentAllowanceBalance = (employmentId: string) =>
  useQuery({
    queryKey: ["employmentAllowanceBalance", employmentId],
    queryFn: () => getAllowanceBalanceCentsByEmploymentId(employmentId),
    enabled: Boolean(employmentId),
  })

export const useRecurringPremiums = (companyId: string, data: Partial<RecurringPremiumRequest>) =>
  useQuery({
    queryKey: ["recurringPremiums", companyId, data.year, data.month],
    queryFn: () => searchRecurringPremiums(companyId, data as RecurringPremiumRequest),
    enabled: Boolean(companyId && data.year != null && data.month != null),
  })

export const useFundingTransfer = () =>
  useMutation({
    mutationFn: (payload: { companyId: string; data: FundsTransferRequest }) =>
      createAdHocFundingTransfer(payload.companyId, payload.data),
  })

export const useBookTransfer = () =>
  useMutation({
    mutationFn: (payload: { companyId: string; data: FundsTransferRequest }) =>
      createAdHocBookTransfer(payload.companyId, payload.data),
  })

export const useCreateJournalEntry = () =>
  useMutation({
    mutationFn: (payload: { data: JournalEntryRequest }) => createJournalEntry(payload.data),
  })

export const useReverseJournalEntry = () =>
  useMutation({
    mutationFn: (payload: { journalEntryId: Uuid }) => reverseJournalEntry(payload.journalEntryId),
  })

const allCompaniesQueryKey = ["tc-hub", "all-companies"]
const allAutopayCompaniesQueryKey = ["tc-hub", "all-autopay-companies"]

export const useCompanies = () => {
  const [enabled, setEnabled] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (window.indexedDB) {
      idb.get(allCompaniesQueryKey.join("-")).then(result => {
        queryClient.setQueryData(allCompaniesQueryKey, result)
        queryClient.invalidateQueries({
          queryKey: allCompaniesQueryKey,
        })
        setEnabled(true)
      })
    } else {
      setEnabled(true)
    }
  }, [queryClient])

  return useQuery({
    enabled,
    queryKey: allCompaniesQueryKey,
    queryFn: async () => {
      const previous = queryClient.getQueryData<CompanyModel[] | undefined>(allCompaniesQueryKey)
      const limit = 200
      const result: CompanyModel[] = []
      const progressById: Record<string, CompanyModel> = {}
      previous?.forEach(company => (progressById[company.id] = company))
      let current: CompanyModel[] = []

      do {
        current = await getAllActiveCompanies(result.length, limit)
        current.forEach(company => (progressById[company.id] = company))
        if (!previous || size(progressById) > previous.length) {
          const progress = values(progressById)

          queryClient.setQueryData(allCompaniesQueryKey, progress)
          if (window.indexedDB) {
            idb.set(allCompaniesQueryKey.join("-"), progress).catch(console.error)
          }
        }
        result.push(...current)
      } while (current.length === limit)
      if (window.indexedDB) {
        idb.set(allCompaniesQueryKey.join("-"), result).catch(console.error)
      }

      return result
    },
  })
}

export const useAutoPayCompanies = () => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: allAutopayCompaniesQueryKey,
    queryFn: async () => {
      const result = [] as CompanyModel[]
      let current
      const limit = 200
      let createdAt = DateTime.now().toISO()

      do {
        current = await getAutopayCompanies({
          latestCreatedAt: createdAt,
          createdBefore: new Date().toISOString(),
          limit,
        })

        result.push(...current.companies)
        createdAt = current.meta.cursorValue
        queryClient.setQueryData([allAutopayCompaniesQueryKey], result)
      } while (current.meta.hasNext && createdAt)

      return result
    },
  })
}

export const useFundingEntityDetails = (fundingEntityId: string) =>
  useQuery({
    queryKey: ["funding-entities", fundingEntityId, "details"],
    queryFn: () => getFundingEntityDetails(fundingEntityId),
    enabled: Boolean(fundingEntityId),
  })

export const useRegistrationTrackingDetails = (registrationTrackingId: string) =>
  useQuery({
    queryKey: ["registration-trackings", registrationTrackingId],
    queryFn: () => getRegistrationTrackingDetails(registrationTrackingId),
  })

export const useUpdateRegistrationTrackingDetails = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: { registrationTrackingId: string; data: RegistrationTrackingDetailModel }) =>
      updateRegistrationTracking(payload.registrationTrackingId, payload.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getAllRegistrationTracking", "registration-trackings"],
      })
    },
  })
}

export const useRegistrationTracking = () =>
  useQuery({
    queryKey: ["getAllRegistrationTracking"],
    queryFn: () => getAllRegistrationTracking(),
  })

export const useFundingEvents = () =>
  useQuery({ queryKey: ["getAllFundingEvents"], queryFn: () => getAllFundingEvents() })

export const useFundingEventById = (companyId: string | null, fundingEventById: string | null) =>
  useQuery({
    queryKey: ["funding-events", fundingEventById],
    queryFn: () => getFundingEventById(companyId!, fundingEventById!),
    enabled: Boolean(fundingEventById),
  })

const allUsersQueryKey = ["tc-hub", "all-people"]

export const useUsers = () => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: allUsersQueryKey,
    queryFn: async () => {
      const limit = 1000
      const result = []
      let current

      do {
        current = (await getAllPeople(result.length, limit)).people
        result.push(...current)
        queryClient.setQueryData(allUsersQueryKey, [...result])
      } while (current?.length)

      return result
    },
    staleTime: 1000 * 60 * 2,
  })
}

export const useGetCarriersUpdate = () =>
  useQuery({
    queryKey: ["carrierUpdates"],
    queryFn: () => getCarrierUpdates(),
  })

export const useGetCarrierIntegrations = () =>
  useQuery({
    queryKey: ["carrierIntegrations"],
    queryFn: () => getCarrierIntegrations(),
  })

export const useGetPlanByIdAndYear = (id: string, planYear: string | number) =>
  useQuery({
    queryKey: ["carriersIntegration"],
    queryFn: () => getPlanByIdAndPlanYear(id, planYear),
    enabled: !!id,
  })

export const useUpdateCarrierIntegration = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: { carrierIntegration: CarrierIntegration; applyAllStates: boolean }) =>
      updateCarrierIntegration(payload.carrierIntegration, payload.applyAllStates),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["carrierIntegrations"],
      })
    },
  })
}

export const useCreateCarrierIntegration = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: { carrierIntegration: CarrierIntegration }) =>
      createCarrierIntegration(payload.carrierIntegration),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["carrierUpdates"],
      })
      queryClient.invalidateQueries({
        queryKey: ["carrierIntegrations"],
      })
    },
    onSettled: async () => {
      while (true) {
        const queryState = queryClient.getQueryState(["carrierUpdates"])

        if (!queryState || queryState.isInvalidated || queryState.status === "pending") {
          // FUTURE: Remove these eslint disable directive
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          await new Promise(resolve => setTimeout(resolve, 100))
        } else {
          if (queryState.status === "success") {
            return queryState.data
          } else {
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            await new Promise(resolve => setTimeout(resolve, 100))
          }
        }
      }
    },
  })
}

export const useGetSignedUrl = () =>
  useMutation({
    mutationFn: (payload: { fileName: string }) => getSignedUrl(payload.fileName),
  })

export const uploadCarrierFile = async (file: File, signedUrl: string) =>
  axios.put(signedUrl, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

export const useUploadCarrierFile = () =>
  useMutation({
    mutationFn: (payload: { file: File; signedUrl: string }) => uploadCarrierFile(payload.file, payload.signedUrl),
  })

export const useUser = (companyId: Uuid | null, userId: string | null) =>
  useQuery({
    queryKey: ["user", userId],
    queryFn: () => getPersonDetails(companyId!, userId!),
    enabled: Boolean(userId),
  })

export const useBusinessUnits = (companyId: string | null) =>
  useQuery({
    queryKey: ["businessUnit", companyId],
    queryFn: () => getBusinessUnit(companyId!),
    enabled: Boolean(companyId),
  })

export const usePersonRpDetails = (employmentId: string) =>
  useQuery({
    queryKey: ["PersonRpDetails", employmentId],
    queryFn: () => getPersonRpDetails(employmentId),
    enabled: Boolean(employmentId),
  })

export const useGetHealthBenefitElectionDetails = (healthBenefitElectionId: string) =>
  useQuery({
    queryKey: ["healthBenefitElectionDetails", healthBenefitElectionId],
    queryFn: () => getHealthBenefitElectionDetails(healthBenefitElectionId),
    enabled: Boolean(healthBenefitElectionId),
  })

export const useCreateSubAccount = () =>
  useMutation({
    mutationFn: (payload: { recurringPremiumId: string }) => createSubAccount(payload.recurringPremiumId),
  })

export const useUpdateUser = (companyId: string, personId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ payload, employmentId }: { payload: EditPersonValues; employmentId: string }) =>
      updatePerson(updatePersonFormToPayload(payload, companyId, employmentId), companyId, personId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", personId],
      }),
  })
}

export const useGetPeopleSearch = ({ name = "", email = "", company = "" } = {}) =>
  useDebouncedQuery({
    queryKey: ["peopleSearch"],
    queryFn: () => getPeopleSearch(name, email, company),
    enabled: false,
    debounce: 0,
    staleTime: 0,
  })

export const useGetBusinessUnit = (companyId: string) =>
  useQuery({
    queryKey: ["businessUnit", companyId],
    queryFn: () => getBusinessUnit(companyId),
    enabled: Boolean(companyId),
  })

export const useCreateBusinessUnit = (companyId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: Partial<BusinessUnit>) => createBusinessUnit(companyId, payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["businessUnit", companyId],
      }),
  })
}

export const useUpdateBusinessUnit = (companyId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: Partial<BusinessUnit>) => updateBusinessUnit(companyId, payload),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["businessUnit", companyId],
      }),
  })
}
